import { ReactNode } from 'react';
import { dialogClasses } from '@mui/material/Dialog';

import {
  MPDialog,
  MPDialogProps,
  useIsMobile,
} from '@mp-frontend/core-components';

import * as styles from 'css/components/dialogs/StandardDialog.module.css';

export interface StandardDialogProps
  extends Pick<
    MPDialogProps,
    | 'actionButton'
    | 'container'
    | 'disableEscapeKeyDown'
    | 'onClose'
    | 'onPrefixClick'
  > {
  children: ReactNode;
  title: string;
  maxDesktopWidth?: number | string;
  open?: MPDialogProps['open'];
}

export default function StandardDialog({
  children,
  maxDesktopWidth,
  open = true,
  ...props
}: StandardDialogProps) {
  const isMobile = useIsMobile();

  return (
    <MPDialog
      {...props}
      className={styles.dialog}
      contentClasses={{ root: styles.content }}
      open={open}
      classes={{ container: styles.container, paper: styles.paper }}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: isMobile
            ? '100%'
            : maxDesktopWidth ?? 'var(--mp-defaultDialogWidth, 502px)',
        },
      }}
    >
      {children}
    </MPDialog>
  );
}
