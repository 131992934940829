/**
 * @generated SignedSource<<a7309bd22734e48ed9556105dd99380c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmergingCtaActionTypeEnum = "URL" | "FOLLOW";
export type EmergingCtaTypeEnum = "PRIMARY" | "SECONDARY";
export type HomepageArtistSpotlightQuery$variables = {};
export type HomepageArtistSpotlightQuery$data = {
  readonly homepageArtistSpotlight: {
    readonly artistDescription: string | null;
    readonly ctas: ReadonlyArray<{
      readonly actionType: EmergingCtaActionTypeEnum | null;
      readonly id: string;
      readonly rank: number | null;
      readonly title: string | null;
      readonly type: EmergingCtaTypeEnum | null;
      readonly url: string | null;
    } | null> | null;
    readonly id: string;
    readonly nft: {
      readonly listing: {
        readonly productSlug: string;
      } | null;
      readonly metadata: {
        readonly hasVideo: boolean | null;
        readonly highResImage: string | null;
        readonly id: string;
        readonly rawfileExtension: string;
        readonly standardImage: string | null;
        readonly thumbnailImage: string | null;
        readonly title: string;
        readonly videoUrl: string | null;
        readonly mediaMetadata: {
          readonly height: number | null;
          readonly width: number | null;
        } | null;
      } | null;
    } | null;
    readonly user: {
      readonly followerCount: number;
      readonly fullName: string | null;
      readonly id: string;
      readonly pk: string;
      readonly profileImageUrl: string;
      readonly totalVolume: {
        readonly totalVolumeInUsd: number | null;
      } | null;
      readonly username: string;
    } | null;
  } | null;
};
export type HomepageArtistSpotlightQuery = {
  variables: HomepageArtistSpotlightQuery$variables;
  response: HomepageArtistSpotlightQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "artistDescription",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ArtistSpotlightCtaType",
  "kind": "LinkedField",
  "name": "ctas",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionType",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rank",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productSlug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasVideo",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highResImage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawfileExtension",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "standardImage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailImage",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoUrl",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountAbstractType",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "followerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pk",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalVolumeType",
      "kind": "LinkedField",
      "name": "totalVolume",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalVolumeInUsd",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomepageArtistSpotlightQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ArtistSpotlightType",
        "kind": "LinkedField",
        "name": "homepageArtistSpotlight",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractType",
            "kind": "LinkedField",
            "name": "nft",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTListingAbstractType",
                "kind": "LinkedField",
                "name": "listing",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTMetadataAbstractType",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v1/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTMediaMetadataType",
                    "kind": "LinkedField",
                    "name": "mediaMetadata",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomepageArtistSpotlightQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ArtistSpotlightType",
        "kind": "LinkedField",
        "name": "homepageArtistSpotlight",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractType",
            "kind": "LinkedField",
            "name": "nft",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTListingAbstractType",
                "kind": "LinkedField",
                "name": "listing",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTMetadataAbstractType",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v1/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTMediaMetadataType",
                    "kind": "LinkedField",
                    "name": "mediaMetadata",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fcbe55a7b182203414ea2fa8a625d84",
    "id": null,
    "metadata": {},
    "name": "HomepageArtistSpotlightQuery",
    "operationKind": "query",
    "text": "query HomepageArtistSpotlightQuery {\n  homepageArtistSpotlight {\n    artistDescription\n    ctas {\n      actionType\n      id\n      rank\n      title\n      type\n      url\n    }\n    id\n    nft {\n      listing {\n        productSlug\n        id\n      }\n      metadata {\n        hasVideo\n        highResImage\n        id\n        rawfileExtension\n        standardImage\n        thumbnailImage\n        title\n        videoUrl\n        mediaMetadata {\n          height\n          width\n          id\n        }\n      }\n      id\n    }\n    user {\n      followerCount\n      fullName\n      id\n      pk\n      profileImageUrl\n      totalVolume {\n        totalVolumeInUsd\n      }\n      username\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c6ea7bef25f95389c93c5e607a8c7f6";

export default node;
