enum FontFamily {
  Bold = 'NB Akademie Pro Bold',
  Book = 'NB Akademie Pro Book',
  Light = 'NB Akademie Pro Light',
  Medium = 'NB Akademie Pro Medium',
  Regular = 'NB Akademie Pro Regular',
  SemiBold = 'NB Akademie Pro SemiBold',
}

export default FontFamily;
