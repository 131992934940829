/**
 * @generated SignedSource<<467d1c6369faa1bf5951626dbb43488b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OpenEditionLeaderboardQuery$variables = {
  openEditionSlug: string;
};
export type OpenEditionLeaderboardQuery$data = {
  readonly dropsMedias: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly nftMetadata: {
          readonly dropMetadata: {
            readonly dropsAt: any;
            readonly endsAt: any | null;
            readonly listing: {
              readonly pk?: string;
              readonly editionsSold?: number | null;
              readonly leaderboard?: ReadonlyArray<{
                readonly count: number | null;
                readonly buyer: {
                  readonly fullName: string | null;
                  readonly username: string;
                  readonly profileImageUrl: string;
                  readonly store: {
                    readonly storeSlug: string | null;
                  } | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};
export type OpenEditionLeaderboardQuery = {
  variables: OpenEditionLeaderboardQuery$variables;
  response: OpenEditionLeaderboardQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "openEditionSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "openEditionSlug",
    "variableName": "openEditionSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dropsAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editionsSold",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 200
  },
  {
    "kind": "Literal",
    "name": "skip",
    "value": 0
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeSlug",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = [
  (v12/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OpenEditionLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DropMediaMetadataTypeConnection",
        "kind": "LinkedField",
        "name": "dropsMedias",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DropMediaMetadataTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DropMediaMetadataType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTMetadataAbstractType",
                    "kind": "LinkedField",
                    "name": "nftMetadata",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DropMediaMetadataType",
                        "kind": "LinkedField",
                        "name": "dropMetadata",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "listing",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": (v6/*: any*/),
                                    "concreteType": "OpenEditionLeaderboardBuyerAbstractType",
                                    "kind": "LinkedField",
                                    "name": "leaderboard",
                                    "plural": true,
                                    "selections": [
                                      (v7/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AccountAbstractType",
                                        "kind": "LinkedField",
                                        "name": "buyer",
                                        "plural": false,
                                        "selections": [
                                          (v8/*: any*/),
                                          (v9/*: any*/),
                                          (v10/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StoreTypeV3",
                                            "kind": "LinkedField",
                                            "name": "store",
                                            "plural": false,
                                            "selections": [
                                              (v11/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "leaderboard(first:200,skip:0)"
                                  }
                                ],
                                "type": "NFTOpenEditionListingType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OpenEditionLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DropMediaMetadataTypeConnection",
        "kind": "LinkedField",
        "name": "dropsMedias",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DropMediaMetadataTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DropMediaMetadataType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTMetadataAbstractType",
                    "kind": "LinkedField",
                    "name": "nftMetadata",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DropMediaMetadataType",
                        "kind": "LinkedField",
                        "name": "dropMetadata",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "listing",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": (v6/*: any*/),
                                    "concreteType": "OpenEditionLeaderboardBuyerAbstractType",
                                    "kind": "LinkedField",
                                    "name": "leaderboard",
                                    "plural": true,
                                    "selections": [
                                      (v7/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AccountAbstractType",
                                        "kind": "LinkedField",
                                        "name": "buyer",
                                        "plural": false,
                                        "selections": [
                                          (v8/*: any*/),
                                          (v9/*: any*/),
                                          (v10/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StoreTypeV3",
                                            "kind": "LinkedField",
                                            "name": "store",
                                            "plural": false,
                                            "selections": [
                                              (v11/*: any*/),
                                              (v12/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v12/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "leaderboard(first:200,skip:0)"
                                  },
                                  (v12/*: any*/)
                                ],
                                "type": "NFTOpenEditionListingType",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v13/*: any*/),
                                "type": "NFTListingAbstractType",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v13/*: any*/),
                                "type": "NFTMysteryBoxListingType",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v13/*: any*/),
                                "type": "NFTRaffleListingType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fde554984866c87fd98c78a3a6ce320",
    "id": null,
    "metadata": {},
    "name": "OpenEditionLeaderboardQuery",
    "operationKind": "query",
    "text": "query OpenEditionLeaderboardQuery(\n  $openEditionSlug: String!\n) {\n  dropsMedias(openEditionSlug: $openEditionSlug) {\n    edges {\n      node {\n        nftMetadata {\n          dropMetadata {\n            dropsAt\n            endsAt\n            listing {\n              __typename\n              ... on NFTOpenEditionListingType {\n                pk\n                editionsSold\n                leaderboard(first: 200, skip: 0) {\n                  count\n                  buyer {\n                    fullName\n                    username\n                    profileImageUrl\n                    store {\n                      storeSlug\n                      id\n                    }\n                    id\n                  }\n                }\n                id\n              }\n              ... on NFTListingAbstractType {\n                id\n              }\n              ... on NFTMysteryBoxListingType {\n                id\n              }\n              ... on NFTRaffleListingType {\n                id\n              }\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1dbc46bddbc5b7f42e768214014dcc2f";

export default node;
