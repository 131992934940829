import { useAtom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { commitMutation } from 'react-relay';
import { selector } from 'recoil';

import GetCreatorDetails, {
  SessionCreatorDetailsMutation,
} from './graphql/__generated__/SessionCreatorDetailsMutation.graphql';
import GetFreeMintingInfo, {
  SessionFreeMintingInfoMutation,
} from './graphql/__generated__/SessionFreeMintingInfoMutation.graphql';
import GetSession, {
  SessionUserInformationMutation,
} from './graphql/__generated__/SessionUserInformationMutation.graphql';
import GetSessionWallets, {
  SessionWalletsMutation,
} from 'graphql/__generated__/SessionWalletsMutation.graphql';
import { MpErrors, UserAccountType } from 'types/__generated__/graphql';

import { AUTH_TOKEN } from 'constants/Auth';
import GTM from 'GTM';
import getProfileImage from 'utils/getProfileImage';
import saveBearerToken from 'utils/jwt/saveBearerToken';
import saveBearerTokenExpireAt from 'utils/jwt/saveBearerTokenExpireAt';

import RelayEnvironment from './RelayEnvironment';

export type DeepWriteable<T> = T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepWriteable<U>>
  : { -readonly [P in keyof T]: DeepWriteable<T[P]> };

type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> | undefined };
export type SessionType = DeepWriteable<
  SessionUserInformationMutation['response']['session'] &
    DeepPartial<SessionCreatorDetailsMutation['response']['session']>
>;
export type CreatorDetails =
  SessionCreatorDetailsMutation['response']['session']['account']['creatorDetails'];
export type FreeMintingInfo =
  SessionFreeMintingInfoMutation['response']['session']['account']['freeMintingInfo'];

export type Wallets =
  SessionWalletsMutation['response']['session']['account']['wallets'] & {
    invalidate: () => void;
  };

async function getWallets() {
  let wallets;
  let res;
  const p = new Promise((resolve) => {
    res = resolve;
  });
  await commitMutation<SessionWalletsMutation>(RelayEnvironment, {
    mutation: GetSessionWallets,
    onCompleted: (data, errors) => {
      if (!errors) {
        wallets = Object.assign([...data.session.account.wallets], {
          invalidate: () => {
            wallets = undefined;
          },
        });
      }
      res();
    },
    onError: () => res(),
    variables: {},
  });
  await p;
  return wallets;
}

const SessionMixin = function SessionMixin() {
  let creatorDetails: CreatorDetails;
  let freeMintingInfo: FreeMintingInfo;
  const walletsAtom = atomWithDefault(getWallets);

  return {
    didCompleteOnboard(this: SessionType) {
      return !!this.account?.didCompleteOnboard;
    },
    async getAsyncCreatorDetails(this: SessionType) {
      if (creatorDetails !== undefined) return creatorDetails;
      let res;
      const p = new Promise((resolve) => {
        res = resolve;
      });
      await commitMutation<SessionCreatorDetailsMutation>(RelayEnvironment, {
        mutation: GetCreatorDetails,
        onCompleted: (data, errors) => {
          if (!errors) {
            creatorDetails = data.session.account.creatorDetails;
          }
          res();
        },
        onError: () => res(),
        variables: {},
      });
      await p;
      return creatorDetails;
    },
    async getAsyncFreeMintingInfo(this: SessionType) {
      if (freeMintingInfo !== undefined) return freeMintingInfo;
      let res;
      const p = new Promise((resolve) => {
        res = resolve;
      });
      await commitMutation<SessionFreeMintingInfoMutation>(RelayEnvironment, {
        mutation: GetFreeMintingInfo,
        onCompleted: (data, errors) => {
          if (!errors) {
            freeMintingInfo = data.session.account.freeMintingInfo;
          }
          res();
        },
        onError: () => res(),
        variables: {},
      });
      await p;
      return freeMintingInfo;
    },
    getProfileImage(this: SessionType) {
      return getProfileImage(this.account?.profileImageUrl);
    },
    isAnon(this: SessionType) {
      return this.account?.accountType === UserAccountType.Anon;
    },
    isCollector(this: SessionType) {
      return this.account?.accountType === UserAccountType.Consumer;
    },
    isCreator(this: SessionType) {
      return this.account?.accountType === UserAccountType.Creator;
    },

    isDowngradedCreator(this: SessionType) {
      return this.account?.accountType === UserAccountType.DowngradedCreator;
    },

    isLoggedIn(this: SessionType) {
      return !!this.account;
    },

    useWalletInfo(this: SessionType): [Wallets, () => void] {
      const [wallets, setWallets] = useAtom(walletsAtom);
      return [
        wallets,
        async () => {
          const newWallets = await getWallets();
          setWallets(newWallets);
        },
      ];
    },
  };
};

/* eslint-disable func-names */
const Session = (function () {
  let sessionData: SessionType & ReturnType<typeof SessionMixin>;
  let error;

  const get = async () => {
    if (sessionData) return sessionData;
    let res;
    const p = new Promise((resolve) => {
      res = resolve;
    });
    await commitMutation<SessionUserInformationMutation>(RelayEnvironment, {
      mutation: GetSession,
      onCompleted: (data, errors) => {
        if (!errors) {
          sessionData = { ...data.session, ...SessionMixin() };
          error = null;
          GTM.initPageView(sessionData.account?.pk, sessionData.lastLogin);
        }
        res();
      },
      onError: (e) => {
        error = e;
        if (
          !sessionData &&
          error.name === MpErrors.JwtSessionExpired &&
          localStorage.getItem(AUTH_TOKEN)
        ) {
          // Special Case, it's the initial page load, we want to show a special screen, or simply load in a logged out state
          error.additionalData = {
            isInitialPageLoad: true,
          };
        }
        res();
      },
      variables: {},
    });
    await p;
    if (error) throw error;
    return sessionData;
  };

  const deleteSession = () => {
    sessionData = undefined;
  };

  const recoilValue = selector({
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    key: 'Session',
    get,
  });

  const processLogin = function (token: string, expiryAt: string) {
    saveBearerToken(token);
    saveBearerTokenExpireAt(expiryAt);
  };

  // Hacky way to get session data for gtm?
  return {
    awaitSessionData: get,
    recoilValue,
    deleteSession,
    processLogin,
  };
})();

export default Session;
