.productCardGalleryFlex {
  box-sizing: border-box;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  padding: var(--pdp-padding) var(--pdp-padding) var(--pdp-padding) 0;
  position: sticky;
  top: 0;
  width: 100%;

  &::after {
    background-color: var(--color-backgroundDefault);
    bottom: var(--pdp-padding);
    content: ' ';
    display: block;
    position: absolute;
    right: -2px;
    top: var(--pdp-padding);
    width: 2px;
  }

  @media (--mq-is-mobile) {
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;
    top: initial;

    &::after {
      display: none;
    }
  }
}

.productCardContainer {
  align-items: flex-start;
  background-color: var(--color-commonWhite);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--pdp-padding) + 2px);
  height: inherit;
  justify-content: center;
  overflow: hidden;
  padding: 0 var(--pdp-padding);
  position: relative;
  width: inherit;
  z-index: 0;

  @media (--mq-is-mobile) {
    border: none;
    flex-direction: column;
    gap: 0;
    padding: var(--pdp-padding) 24px 24px;
  }

  &::before {
    background: linear-gradient(
      0deg,
      var(--color-commonWhite) 0%,
      var(--color-transparentPrimary) 100%
    );
    bottom: 0;
    content: " ";
    display: block;
    height: calc(var(--pdp-padding) * 2);
    position: absolute;
    right: 0;
    width: calc(var(--pdp-rail-width) + var(--pdp-padding) * 2);
    z-index: 100;

    @media (--mq-is-mobile) {
      display: none;
    }
  }
}

.rightRailContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-top: var(--pdp-padding);
  position: relative;

  @media (--mq-is-mobile) {
    padding-top: 0;
  }

  &.scrollable {
    justify-content: flex-start;
    overflow-y: auto;

    @media (--mq-is-mobile) {
      width: 100%;
    }
  }

  & .rightRail {
    height: auto;
    padding: 0;
    padding-bottom: calc(var(--pdp-padding) * 2);

    @media (--mq-is-mobile) {
      margin-top: 0;
      padding-bottom: 0;
    }
  }
}
