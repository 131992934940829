import { Dispatch, SetStateAction, useState } from 'react';
import { merge } from 'lodash';
import { useMediaQuery } from '@mui/material';
import { dialogClasses, DialogProps } from '@mui/material/Dialog';

import { MPActionButton, MPDialog } from '../..';
import useDefaultTheme from '../hooks/useDefaultTheme';

interface MpConfirmDialogProps extends DialogProps {
  /**
   * The body text for this dialog
   */
  content: any;

  /**
   * sets the open value controlling whether or not the dialog is shown.
   */
  onCancel: () => void;

  /**
   * sets the open value controlling whether or not the dialog is shown.
   */
  onConfirm: () => void;

  /**
   * If true, the dialog is shown.
   */
  open: boolean;

  /**
   * The text that indicates what this dialog is for
   */
  title: string;

  /**
   * The Text of the cancel button.
   */
  cancelText?: string;

  /**
   * The Text of the confirm button.
   */
  confirmText?: string;
}

export default function MPConfirmDialog({
  open,
  onCancel,
  onConfirm,
  title,
  content,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  sx = {},
}: MpConfirmDialogProps) {
  const isMobile = useMediaQuery(useDefaultTheme().breakpoints.down('desktop'));
  const mergedSx = merge(
    {
      '& .MuiDialog-paper': {
        maxWidth: isMobile ? '100%' : '600px',
      },
    },
    isMobile
      ? {
          [`& .${dialogClasses.container}`]: {
            bottom: 0,
            height: 'auto',
            maxHeight: '100%',
            position: 'absolute',
          },
        }
      : {},
    sx
  );

  return (
    <MPDialog
      sx={mergedSx}
      maxWidth="desktop"
      title={title}
      open={open}
      onClose={onCancel}
      actionButton={[
        <MPActionButton
          size="large"
          key="cancel"
          fullWidth
          variant="secondary"
          onClick={onCancel}
        >
          {cancelText}
        </MPActionButton>,
        <MPActionButton
          size="large"
          key="confirm"
          fullWidth
          onClick={onConfirm}
          autoFocus
        >
          {confirmText}
        </MPActionButton>,
      ]}
    >
      {content}
    </MPDialog>
  );
}

export function useMPConfirmDialog({
  title,
  content,
  onCancel,
  onConfirm,
  confirmText,
  cancelText,
  sx = {},
}: Omit<MpConfirmDialogProps, 'open'>): [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  any
] {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const cancel = () => {
    close();
    onCancel();
  };
  const confirm = async () => {
    await onConfirm();
    close();
  };

  return [
    open,
    setOpen,
    <MPConfirmDialog
      open={open}
      sx={sx}
      content={content}
      onCancel={cancel}
      onConfirm={confirm}
      title={title}
      confirmText={confirmText}
      cancelText={cancelText}
    />,
  ];
}
