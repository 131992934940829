import { ChangeEvent, PureComponent, useEffect, useState } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { buttonClasses, Paper } from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

import {
  MPButton,
  MPCheckbox,
  MPColorClass,
  MPColorValue,
  MPDropdownSelector,
  MPDropdownSelectorItemProps,
  MPFonts,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import {
  AmexIcon,
  MastercardIcon,
  VisaIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountStripeCardsQueryType, {
  AccountStripeCardsQuery,
} from 'graphql/__generated__/AccountStripeCardsQuery.graphql';

import FourOFourErrorBoundary from 'components/ErrorBoundaries/FourOFourErrorBoundary';
import RedirectErrorBoundary from 'components/ErrorBoundaries/RedirectErrorBoundary';
import useSession from 'hooks/useSession';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import { NFTCardSelectionType } from 'utils/nftUtils';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

const creditCardIconMap = {
  amex: AmexIcon,
  mastercard: MastercardIcon,
  visa: VisaIcon,
};

const actionButtonSx = {
  [`&.${buttonClasses.root}, &.${buttonClasses.root}:hover`]: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    minWidth: 'unset',
    padding: 0,
    position: 'absolute',
    right: 0,
    textDecoration: 'underline',
    top: 0,
  },
  [`&.${buttonClasses.root}`]: {
    [`&:active`]: {
      color: MPColorValue.SolidNeutralGray1,
    },
    [`&:hover`]: {
      color: MPColorValue.SolidNeutralGray3,
    },
    color: MPColorValue.SolidNeutralGray5,
  },
};

interface CreditCardProps {
  cardholderName: string;
  onCardholderNameChange: (name: string) => void;
  onDefaultIdChange: (cardId: string) => void;
  onRememberChange: (remember: boolean) => void;
  onTypeSelect: (c: NFTCardSelectionType) => void;
  remember: boolean;
  stripeCards: AccountStripeCardsQuery['response']['stripeCards'];
  type: NFTCardSelectionType;
  onCardElementChange?: (event: StripeCardElementChangeEvent) => any;
}

function CreditCard({
  type,
  onTypeSelect,
  onDefaultIdChange,
  cardholderName,
  onCardholderNameChange,
  remember,
  onRememberChange,
  stripeCards,
  onCardElementChange,
}: CreditCardProps) {
  const session = useSession();
  const [defaultCard, setDefaultCard] =
    useState<AccountStripeCardsQuery['response']['stripeCards'][0]>(null);
  const [cardElementState, setCardElementState] = useState<
    'empty' | 'error' | 'complete'
  >('empty');
  const [cardElementFocus, setCardElementFocus] = useState<boolean>(false);

  useEffect(() => {
    if (session.isLoggedIn() && stripeCards.length) {
      setDefaultCard(stripeCards[0]);
      onTypeSelect(NFTCardSelectionType.Saved);
    }
  }, [session, stripeCards.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onDefaultIdChange(defaultCard?.id);
  }, [defaultCard?.id, onDefaultIdChange]);

  const handleCardChange = (event: StripeCardElementChangeEvent) => {
    if (event.error?.message) {
      setCardElementState('error');
    } else if (event.empty) {
      setCardElementState('empty');
    } else if (event.complete) {
      setCardElementState('complete');
    }
    onCardElementChange(event);
  };

  return {
    [NFTCardSelectionType.New]: (
      <div className={styles.paymentFormCreditCard}>
        <div className={styles.paymentFormCreditCardInputWrapper}>
          <MPStyledTextField
            label="Credit Card"
            placeholder="Name on Card"
            value={cardholderName}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onCardholderNameChange(event.target.value)
            }
          />
          {stripeCards.length ? (
            <MPButton
              type="button"
              className={MPFonts.buttonMedium}
              onClick={() => onTypeSelect(NFTCardSelectionType.Saved)}
              variant="text"
              sx={actionButtonSx}
            >
              Cancel
            </MPButton>
          ) : null}
        </div>

        <Paper
          variant="outlined"
          className={joinClasses(
            styles.paymentFormCreditCardElementWrapper,
            styles[cardElementState],
            cardElementFocus ? styles.focused : ''
          )}
        >
          <CardElement
            options={{
              classes: {
                base: MPFonts.inputText,
              },
              hideIcon: true,
              style: {
                base: {
                  '::placeholder': {
                    color: MPColorValue.SolidNeutralGray3 as string,
                  },
                  color: MPColorValue.CommonBlack as string,
                  textTransform: 'capitalize',
                },
                invalid: {
                  ':focus': {
                    color: MPColorValue.ErrorMain as string,
                  },
                  ':hover': {
                    color: MPColorValue.ErrorMain as string,
                  },
                  color: MPColorValue.ErrorLight as string,
                },
              },
            }}
            onFocus={() => setCardElementFocus(true)}
            onBlur={() => setCardElementFocus(false)}
            onChange={handleCardChange}
          />
        </Paper>
        <MPCheckbox
          isChecked={remember}
          onChange={(event) => onRememberChange(event?.target?.checked)}
          name="should-save-card"
          label="Save this card for future purchases"
        />
      </div>
    ),
    [NFTCardSelectionType.Saved]: defaultCard ? (
      <div className={styles.paymentFormSavedCardsContainer}>
        <MPDropdownSelector
          label="Credit Card"
          action={{
            onClick: () => onTypeSelect(NFTCardSelectionType.New),
            title: 'New Card',
          }}
          items={stripeCards.map((card): MPDropdownSelectorItemProps => {
            const title = `${capitalizeFirstLetter(card.brand)} ending in ${
              card.number
            }`;
            const Icon = creditCardIconMap[card.brand];
            return {
              prefix: Icon ? (
                <Icon />
              ) : (
                <img
                  src={card.image}
                  alt={title}
                  width="24"
                  height="24"
                  className={styles.paymentFormSavedCardsImage}
                />
              ),
              title: (
                <div className={styles.paymentFormSavedCardContainer}>
                  <span
                    className={joinClasses(
                      MPFonts.textNormalMedium,
                      MPColorClass.CommonBlack
                    )}
                  >
                    {[...new Array(3).fill('xxxx'), card.number].join(' ')}
                  </span>
                  <span
                    className={joinClasses(
                      MPFonts.textSmallMedium,
                      MPColorClass.SolidNeutralGray5
                    )}
                  >
                    {[
                      String(card.expMonth).padStart(2, '0'),
                      String(card.expYear).slice(-2),
                    ].join('/')}
                  </span>
                </div>
              ),
              value: card.id,
            };
          })}
          value={defaultCard.id}
          onChange={(value) => {
            const newDefaultCard = stripeCards.find(
              (card) => card.id === value
            );
            setDefaultCard(newDefaultCard);
          }}
        />
      </div>
    ) : null,
  }[type];
}

interface CreditCardWithQueryProps
  extends Omit<CreditCardProps, 'stripeCards'> {
  stripeCardsQueryRef: PreloadedQuery<AccountStripeCardsQuery>;
}

function CreditCardWithQuery({
  stripeCardsQueryRef,
  ...props
}: CreditCardWithQueryProps) {
  const { stripeCards } = usePreloadedQuery<AccountStripeCardsQuery>(
    AccountStripeCardsQueryType,
    stripeCardsQueryRef
  );

  return <CreditCard {...props} stripeCards={stripeCards} />;
}

class PureCreditCard extends PureComponent<
  CreditCardWithQueryProps,
  { error: any }
> {
  constructor(props: CreditCardWithQueryProps) {
    super(props);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    if (
      FourOFourErrorBoundary.willCatch(error) ||
      RedirectErrorBoundary.willCatch(error)
    ) {
      throw error;
    }
  }

  resetErrorBoundary() {
    this.setState({ error: null });
  }

  render() {
    if (this.state.error) {
      return <CreditCard {...this.props} stripeCards={[]} />;
    }
    return <CreditCardWithQuery {...this.props} />;
  }
}

export default PureCreditCard;
