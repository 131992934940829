import { useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import {
  useAccount,
  type UseAccountReturnType,
  useTransactionCount,
  type UseTransactionCountReturnType,
} from 'wagmi';

import { DefaultBlock } from 'types/enums/blockChain/eth';

const accountAtom = atom<UseAccountReturnType>({
  address: undefined,
  addresses: undefined,
  chain: undefined,
  chainId: undefined,
  connector: undefined,
  isConnected: false,
  isConnecting: true,
  isDisconnected: false,
  isReconnecting: false,
  status: 'connecting',
});

const pointer: {
  cachedAccount: UseAccountReturnType;
  transactionCountQuery: UseTransactionCountReturnType;
} = { cachedAccount: undefined, transactionCountQuery: undefined };

interface CachedAccount {
  asyncTransactionCount: () => Promise<number>;
  refetchTransactionCount: UseTransactionCountReturnType['refetch'];
}

const _cachedAccount = new Proxy(
  {
    async asyncTransactionCount() {
      if (
        pointer.transactionCountQuery.data &&
        !pointer.transactionCountQuery.isError
      )
        return pointer.transactionCountQuery.data;
      const result = await pointer.transactionCountQuery.refetch({
        cancelRefetch: false,
      });
      return result.data ?? 0;
    },
    refetchTransactionCount() {
      return pointer.transactionCountQuery.refetch({
        cancelRefetch: false,
      });
    },
  } as CachedAccount,
  {
    get(target, prop) {
      return Object.hasOwn(target, prop)
        ? target[prop]
        : pointer.cachedAccount[prop];
    },
  }
) as CachedAccount & UseAccountReturnType;

export default function useCachedAccount() {
  const account = useAccount();
  const [cachedAccount, setCachedAccount] = useAtom(accountAtom);
  pointer.cachedAccount = cachedAccount;
  pointer.transactionCountQuery = useTransactionCount({
    address: account.address,
    blockTag: DefaultBlock.PENDING,
  });

  useEffect(() => {
    if (
      Object.keys(cachedAccount).some(
        (prop) => cachedAccount[prop] !== account[prop]
      )
    )
      setCachedAccount(account);
  });
  return _cachedAccount;
}
