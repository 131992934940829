import { useCallback } from 'react';

import { MPDropdown } from '@mp-frontend/core-components';

import useActivityGTM from 'utils/GTM/activity';

import ArtistFilterInner from './ArtistFilterInner';

const TITLE = 'Artists';

interface ArtistFilterProps {
  artistValues: string[];
  isShowFollowing: boolean;
  onChangeArtistSelection: (values: string[]) => void;
  onChangeFollowArtist: (value: boolean) => void;
}

function ArtistsFilter({
  isShowFollowing,
  onChangeFollowArtist,
  artistValues,
  onChangeArtistSelection,
}: ArtistFilterProps) {
  const track = useActivityGTM();
  const handleToggle = useCallback(
    (open: boolean) => track.toggleFilter(TITLE, open),
    [track]
  );

  return (
    <MPDropdown title={TITLE} onToggle={handleToggle}>
      <ArtistFilterInner
        {...{
          artistValues,
          isShowFollowing,
          onChangeArtistSelection,
          onChangeFollowArtist,
        }}
      />
    </MPDropdown>
  );
}

export default ArtistsFilter;
