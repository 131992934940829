import { useCallback, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';

import { joinClasses } from '@mp-frontend/core-utils';

import HomepageLiveExhibitionsQueryType, {
  HomepageLiveExhibitionsQuery,
} from 'graphql/__generated__/HomepageLiveExhibitionsQuery.graphql';

import ExhibitionCard from 'components/cards/ExhibitionCard';
import Carousel from 'components/carousel/Carousel';
import ROUTES from 'constants/Routes';
import { HomepageExhibitionType } from 'types/graphql/Drop';
import useHomepageGTM, { HomepageViewItem } from 'utils/GTM/homepage';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/pages/homepage/HomepageStandardCardsSection.module.css';

interface LiveExhibitionsProps {
  exhibitionsQuery: WithLoadQueryProps<HomepageLiveExhibitionsQuery>;
}

function LiveExhibitions({
  exhibitionsQuery: { queryRef },
}: LiveExhibitionsProps) {
  const track = useHomepageGTM();
  const result = usePreloadedQuery<HomepageLiveExhibitionsQuery>(
    HomepageLiveExhibitionsQueryType,
    queryRef
  );
  const exhibitions = useMemo(
    () =>
      result.topCarouselsUpcomingAndLive.edges.reduce(
        (memo, { node: exhibition }) => {
          if (!exhibition.sortedNftMetadatas.edges.length) {
            return memo;
          }

          memo.push(exhibition);
          return memo;
        },
        [] as HomepageExhibitionType[]
      ),
    [result]
  );

  const logViewAbleCardsToGA = useCallback(
    (start: number, end: number) => {
      const viewItems: HomepageViewItem[] = [];
      exhibitions.slice(start, end).forEach((drop) => {
        viewItems.push({
          currency: null,
          item_brand: drop.slug,
          item_category: 'exhibition',
          item_category4: 'Exhibition',
          item_id: drop.id,
          item_name: drop.dropTitle,
          price: 0,
          price_in_eth: 0,
          quantity: 0,
        });
      });
      track.viewItem(viewItems, 'live_exhibitions');
    },
    [track, exhibitions]
  );

  return exhibitions.length > 0 ? (
    <Carousel
      header={<>New Exhibitions</>}
      viewAllLink={ROUTES.EXHIBITIONS()}
      containerName="new_exhibitions"
      logViewAbleCardsToGA={logViewAbleCardsToGA}
      items={exhibitions.map((exhibition, idx, array) => (
        <ExhibitionCard
          key={exhibition.id}
          className={joinClasses(styles.card, {
            [styles.last]: idx === array.length - 1,
          })}
          exhibition={exhibition}
        />
      ))}
    />
  ) : null;
}

export default withDefaultErrorBoundary(
  withLoadQuery(LiveExhibitions, {
    exhibitionsQuery: { concreteRequest: HomepageLiveExhibitionsQueryType },
  }),
  { errorFallback: null, suspenseless: true }
);
