import { useCallback, useEffect, useState } from 'react';

import {
  joinClasses,
  useOnEnterKey,
  useRefState,
} from '@mp-frontend/core-utils';

import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import * as styles from '../css/collapsibles/MPExpandableText.module.css';

interface MPExpandableTextProps {
  content: string;
  lineClamp?: number;
}

export default function MPExpandableText({
  content,
  lineClamp = 3,
}: MPExpandableTextProps) {
  const [clampedDiv, setRef] = useRefState<HTMLDivElement>(null);
  const [isClamped, setClamped] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [isFullyExpanded, setFullyExpanded] = useState(false);
  const [descriptionMaxHeight, setDescriptionMaxHeight] = useState<
    [string | number, string | number]
  >(['auto', 'auto']);

  const expand = useCallback(() => setExpanded(true), []);
  const collapse = useCallback(() => setExpanded(false), []);
  const expandOnEnter = useOnEnterKey(expand);
  const collapseOnEnter = useOnEnterKey(collapse);

  useEffect(() => {
    setClamped(false);
    setExpanded(false);
    setFullyExpanded(false);
  }, [content]);

  // Not handling resize as this is fixed width, rechecking on resize also won't work as we wouldn't know the clamped height if resized when expanded.
  useEffect(() => {
    if (clampedDiv) {
      if (clampedDiv.scrollHeight > clampedDiv.clientHeight) {
        setDescriptionMaxHeight([
          clampedDiv.clientHeight,
          clampedDiv.scrollHeight,
        ]);
      }
      setClamped(clampedDiv.scrollHeight > clampedDiv.clientHeight);
    }
  }, [content, clampedDiv]);

  // The typings incorrectly complain on string literals, so not inlining.
  const style = {
    '--line-clamp': lineClamp,
    maxHeight: descriptionMaxHeight[isExpanded ? 1 : 0],
  };

  return (
    <div className={styles.container}>
      <div
        onTransitionEnd={() => {
          setFullyExpanded(isExpanded);
        }}
        ref={setRef}
        style={style}
        className={joinClasses(
          styles.content,
          MPFonts.paragraphNormal,
          (isExpanded || isFullyExpanded) && styles.unclamp
        )}
      >
        {content}
      </div>
      {!!isClamped && (
        <>
          {!isExpanded ? (
            <span
              className={joinClasses(MPFonts.textSmallBold, styles.viewMore)}
              onClick={expand}
              onKeyDown={expandOnEnter}
              role="button"
              tabIndex={0}
            >
              View More
            </span>
          ) : (
            <span
              className={joinClasses(MPFonts.textSmallBold, styles.viewMore)}
              onClick={() => setExpanded(false)}
              onKeyDown={collapseOnEnter}
              role="button"
              tabIndex={0}
            >
              View Less
            </span>
          )}
        </>
      )}
    </div>
  );
}
