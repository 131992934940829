.container {
  padding: 24px;

  @media screen and (min-width: 1920px) {
    padding: 32px;
  }

  @media (--mq-is-mobile) {
    padding: 24px;
  }
}

.title {
  --line-clamp: 2;

  height: 42px;
}

.media {
  display: block;
  height: 275px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1920px) {
    height: 320px;
  }

  @media (--mq-is-mobile) {
    height: 190px;
  }

  @media (--mq-is-not-mobile) {
    &:hover::before {
      transform: scale(1.05);
    }
  }

  &::before {
    background-image: var(--exhibitionCard-backgroundImageUrl);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(1);
    transition: transform 300ms ease-out;
    width: 100%;
  }
}

.paragraph {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--line-clamp);
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-unknown */
  line-clamp: var(--line-clamp);
  white-space: pre-wrap;
  word-wrap: break-word;
}

.description {
  --line-clamp: 3;
  height: 60px;

  @media (--mq-is-mobile) {
    --line-clamp: 4;
    height: 80px;
  }
}
