import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { buttonClasses } from '@mui/material';

import {
  MPActionButton,
  MPButton,
  MPColorValue,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTsRankedAuctionLeaderboardQueryType, {
  NFTsRankedAuctionLeaderboardQuery,
} from 'graphql/__generated__/NFTsRankedAuctionLeaderboardQuery.graphql';
import { NftRankedBidType } from 'types/__generated__/graphql';

import StandardDialog from 'components/dialogs/StandardDialog';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTRankedAuctionType } from 'types/graphql/NFT';
import { hasUserRegisteredAddress } from 'utils/areSameAddress';
import generatePriceString, {
  generateShortEthPriceString,
} from 'utils/currency/generatePricing';
import { HexString } from 'utils/jwt/walletUtils';

import LeaderboardContainer from './LeaderboardContainer';
import {
  LeaderboardPriceCell,
  LeaderboardRowData,
  LeaderboardUserCell,
} from './LeaderboardRow';
import LeaderboardTable from './LeaderboardTable';

import * as styles from 'css/pages/product/ProductRankedAuction.module.css';

import { SessionType } from 'Session';

const MAX_RENDERED_BIDS = 10;

const actionButtonSx = {
  [`&.${buttonClasses.root}, &.${buttonClasses.root}:hover`]: {
    background: 'transparent',
    cursor: 'pointer',
    minWidth: 'unset',
    padding: 0,
    textDecoration: 'underline',
  },
  [`&.${buttonClasses.root}`]: {
    [`&:active`]: {
      color: MPColorValue.SolidNeutralGray1,
    },
    [`&:hover`]: {
      color: MPColorValue.SolidNeutralGray3,
    },
    color: MPColorValue.SolidNeutralGray5,
  },
};

type LeaderboardTableData = {
  isCc?: boolean;
  priceInEther?: number;
  priceInUsd?: number;
} & LeaderboardRowData;

interface LeaderboardProps {
  collapsible: boolean;
  hasBottomBorder: boolean;
  queryRef: PreloadedQuery<NFTsRankedAuctionLeaderboardQuery>;
  totalEditions: number;
  currentUserAddress?: HexString;
  currentUserLastBid?: NFTRankedAuctionType['lastBid'];
  currentUserWallets?: SessionType['account']['wallets'];
  currentUsername?: string;
  onAuctionUpdate?: (partialAuction: NFTRankedAuctionType) => void;
}

function Leaderboard({
  queryRef,
  collapsible,
  currentUserAddress,
  currentUserLastBid,
  currentUsername,
  currentUserWallets,
  hasBottomBorder,
  totalEditions,
  onAuctionUpdate,
}: LeaderboardProps) {
  const result = usePreloadedQuery<NFTsRankedAuctionLeaderboardQuery>(
    NFTsRankedAuctionLeaderboardQueryType,
    queryRef
  );
  const { rankedAuction } = result.nfts.edges[0].node.listing;

  useEffect(() => {
    onAuctionUpdate?.({
      endsAt: rankedAuction.endsAt,
      startsAt: rankedAuction.startsAt,
    });
  }, [rankedAuction.endsAt, rankedAuction.startsAt, onAuctionUpdate]);

  const { bids, totalBids, totalPartialBids } = useMemo(
    () => ({
      bids: ((rankedAuction?.leaderboard ?? []) as NftRankedBidType[]).map(
        (bid: NftRankedBidType): LeaderboardTableData => ({
          isCc: bid.isCcBid,
          priceInEther: bid.bidInEther,
          priceInUsd: bid.bidInUsd,
          user: bid.bidderUser,
          userAddress: bid.bidderAddress,
        })
      ),
      totalBids: rankedAuction?.totalBids ?? 0,
      totalPartialBids: rankedAuction?.totalPartialBids ?? 0,
    }),
    [
      rankedAuction?.leaderboard,
      rankedAuction?.totalBids,
      rankedAuction?.totalPartialBids,
    ]
    // TODO: move this logic to a wrapper component to make sure rankedAuction is not undefined
  );
  const hasOwnBidOnLeaderboard = useMemo(
    () =>
      bids.some(
        ({ userAddress, user }) =>
          (user?.username && user.username === currentUsername) ||
          hasUserRegisteredAddress(
            userAddress,
            currentUserAddress,
            currentUserWallets
          )
      ),
    [bids, currentUsername, currentUserAddress, currentUserWallets]
  );

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const handleOpenDetails = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setShowDetails(true);
    },
    [setShowDetails]
  );
  const handleCloseDetails = useCallback(
    () => setShowDetails(false),
    [setShowDetails]
  );

  return (
    <LeaderboardContainer
      collapsible={collapsible}
      hasBottomBorder={hasBottomBorder}
    >
      <LeaderboardTable<LeaderboardTableData>
        columns={['Rank', 'Collector', 'Bid']}
        currentUserAddress={currentUserAddress}
        currentUserWallets={currentUserWallets}
        currentUsername={currentUsername}
        emptyMessage="There have been no bids placed yet."
        maxRows={MAX_RENDERED_BIDS}
        rows={bids}
        renderCells={(
          { user, userAddress, isOwn, isCc, priceInEther, priceInUsd },
          index
        ) => [
          index + 1,
          <LeaderboardUserCell
            user={user}
            userAddress={userAddress}
            isOwn={isOwn}
          />,
          <LeaderboardPriceCell
            priceInEther={
              isCc
                ? generateShortEthPriceString(priceInEther)
                : generatePriceString(priceInEther, CurrencyDisplayMode.ETH)
            }
            priceInUsd={generatePriceString(
              priceInUsd,
              CurrencyDisplayMode.USD
            )}
          />,
        ]}
      />

      <div className={styles.leaderboardFooter}>
        {bids.length > 0 ? (
          <div
            className={joinClasses(
              MPFonts.textSmallMedium,
              styles.leaderboardBidsSummary
            )}
          >
            {totalBids <= totalEditions
              ? `Showing ${bids.length} total ${pluralize(
                  'bidders',
                  bids.length
                )}.`
              : `Showing ${bids.length} qualifying ${pluralize(
                  'bidders',
                  bids.length
                )}${
                  totalBids !== totalPartialBids
                    ? ` (${totalPartialBids} total bids)`
                    : ''
                }.${
                  currentUserLastBid && !hasOwnBidOnLeaderboard
                    ? ` Your offer of ${
                        currentUserLastBid.isCcBid
                          ? generatePriceString(
                              currentUserLastBid.bidInUsd,
                              CurrencyDisplayMode.USD
                            )
                          : generatePriceString(
                              currentUserLastBid.bidInEther,
                              CurrencyDisplayMode.ETH
                            )
                      } is not on the leaderboard.`
                    : ''
                }`}
          </div>
        ) : null}

        <MPButton
          type="button"
          className={MPFonts.buttonMedium}
          onClick={handleOpenDetails}
          variant="text"
          sx={actionButtonSx}
        >
          How It Works
        </MPButton>

        <StandardDialog
          actionButton={
            <MPActionButton
              variant="primary"
              fullWidth
              onClick={handleCloseDetails}
            >
              Continue
            </MPActionButton>
          }
          open={showDetails}
          title="How It Works"
          onClose={handleCloseDetails}
        >
          <div className={styles.details}>
            <div className={styles.detailsContent}>
              <p>
                Ranked Auctions are a mechanic where all bids submitted are
                publicly viewable and their amounts decide which edition of the
                artwork you will receive.
              </p>
              <p>
                Once the auction period has concluded, the highest bidders will
                all receive one of the available editions. For an edition of{' '}
                {totalEditions}, the top {totalEditions} bidders will receive an
                edition. Everyone else is refunded.
              </p>
              <p>
                Leaderboard must not change for the final 15 minutes for the
                auction to end.
              </p>
            </div>
          </div>
        </StandardDialog>
      </div>
    </LeaderboardContainer>
  );
}

export default Leaderboard;
