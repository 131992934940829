import { MPFonts } from '@mp-frontend/core-components';

import StandardDialog from 'components/dialogs/StandardDialog';

interface ArtworkReservedViewProps {
  onClose: () => void;
}

function ArtworkReservedView({ onClose }: ArtworkReservedViewProps) {
  return (
    <StandardDialog onClose={onClose} title="Artwork Reserved">
      <div className={MPFonts.paragraphSmall}>
        This artwork is being purchased by another collector. Please try again.
      </div>
    </StandardDialog>
  );
}

export default ArtworkReservedView;
