import { usePreloadedQuery } from 'react-relay';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import HomepageArtistSpotlightQueryType, {
  HomepageArtistSpotlightQuery,
  HomepageArtistSpotlightQuery$data,
} from 'graphql/__generated__/HomepageArtistSpotlightQuery.graphql';
import ProfilesQueryType, {
  ProfilesQuery,
} from 'graphql/__generated__/ProfilesQuery.graphql';
import { ArtistSpotlightCtaType } from 'types/__generated__/graphql';

import BaseHeroArtistCard from 'components/cards/HeroArtistCard';
import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import useProfileData from 'pages/profiles/useProfileData';
import CSSGlobal from 'types/enums/css/Global';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/pages/homepage/HomepageHeroCardSection.module.css';

interface HomepageArtistSpotlightProps {
  artistSpotlight: HomepageArtistSpotlightQuery$data['homepageArtistSpotlight'];
  profileQuery: WithLoadQueryProps<ProfilesQuery>;
}

function ArtistSpotlight({
  artistSpotlight: { artistDescription, ctas, user, nft },
  profileQuery,
}: HomepageArtistSpotlightProps) {
  const profileData = useProfileData(profileQuery.queryRef);

  return (
    <section className={styles.container}>
      <div
        className={joinClasses(
          MPFonts.headline4,
          CSSGlobal.Cursor.Default,
          styles.title
        )}
      >
        Artist Spotlight
      </div>

      <BaseHeroArtistCard
        actions={ctas as ReadonlyArray<ArtistSpotlightCtaType>}
        artworksCount={profileData?.listingStats?.totalItems}
        description={artistDescription}
        nft={nft}
        followersCount={user.followerCount}
        totalVolumeInUsd={user.totalVolume.totalVolumeInUsd}
        user={user}
      />
    </section>
  );
}

export function HeroArtistCard({
  artist,
}: {
  artist: HomepageArtistSpotlightQuery$data['homepageArtistSpotlight'];
}) {
  return (
    <DefaultErrorBoundary hideState errorFallback={null}>
      {withLoadQuery(ArtistSpotlight, {
        profileQuery: { concreteRequest: ProfilesQueryType },
      })({
        artistSpotlight: artist,
        profileQuery: {
          variables: { slug: artist.user.username },
        },
      })}
    </DefaultErrorBoundary>
  );
}

function ArtistSpotlightInitial({
  artistSpotlightQuery: { queryRef },
}: {
  artistSpotlightQuery: WithLoadQueryProps<HomepageArtistSpotlightQuery>;
}) {
  const { homepageArtistSpotlight } =
    usePreloadedQuery<HomepageArtistSpotlightQuery>(
      HomepageArtistSpotlightQueryType,
      queryRef
    );

  return (
    homepageArtistSpotlight && (
      <HeroArtistCard artist={homepageArtistSpotlight} />
    )
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(ArtistSpotlightInitial, {
    artistSpotlightQuery: { concreteRequest: HomepageArtistSpotlightQueryType },
  }),
  { errorFallback: null, hideState: true }
);
