import { useCallback, useEffect, useRef, useState } from 'react';
import { PreloadedQuery, useMutation, usePreloadedQuery } from 'react-relay';
import { useAccount, useSwitchChain } from 'wagmi';

import BeforePurchaseInETHMutation, {
  ListingBeforePurchaseInETHMutation$data,
  ListingBeforePurchaseInETHMutation$variables,
} from 'graphql/__generated__/ListingBeforePurchaseInETHMutation.graphql';
import PurchaseInCreditCardMutation, {
  ListingPurchaseInCreditCardMutation$data,
  ListingPurchaseInCreditCardMutation$variables,
} from 'graphql/__generated__/ListingPurchaseInCreditCardMutation.graphql';
import PurchaseWithCustodialWalletMutation, {
  ListingPurchaseWithCustodialWalletMutation$data,
  ListingPurchaseWithCustodialWalletMutation$variables,
} from 'graphql/__generated__/ListingPurchaseWithCustodialWalletMutation.graphql';
import PurchaseWithPersonalWalletMutation, {
  ListingPurchaseWithPersonalWalletMutation$data,
  ListingPurchaseWithPersonalWalletMutation$variables,
} from 'graphql/__generated__/ListingPurchaseWithPersonalWalletMutation.graphql';
import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';

import { useTrackingContext } from 'components/trackingContext';
import GTM, { EcommercePaymentType, EcommercePurchaseType } from 'GTM';
import useDepositFundContract from 'hooks/contracts/useDepositFundContract';
import useDigitalMediaSaleCoreContract from 'hooks/contracts/useDigitalMediaSaleCoreContract';
import useLogging from 'hooks/useLogging';
import useSaleContract from 'hooks/useSaleContract';
import useSession from 'hooks/useSession';
import usePublicClient from 'hooks/useWeb3Client';
import { PurchasableNFTType } from 'types/graphql/NFT';
import { getSafeWeiAmountFromEthUserInput } from 'utils/EthPriceUtils';
import getCurrentWalletName from 'utils/getCurrentWalletName';
import { HexString } from 'utils/jwt/walletUtils';
import { getNFTPresaleState, NFTCardSelectionType } from 'utils/nftUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

import {
  CreditCardPaymentFormState,
  FormState,
  usePaymentFormState,
  useStripePayment,
  useWalletConnectionState,
} from './usePaymentFormState';

export const usePurchaseWithCreditCard = ({
  nft,
  cardHolderName,
  cardIsValid,
  cardSelectionType,
  remember,
  savedCardId,
  discountCode,
  onSuccess,
}: CreditCardPaymentFormState & {
  nft: PurchasableNFTType;
  onSuccess: () => void;
}): [() => Promise<void>, FormState] => {
  const [formState, updateFormState, resetFormState] = usePaymentFormState();
  const { logNFTException } = useLogging();
  const { getPaymentMethodId, withNextPaymentAction } = useStripePayment();
  const { source } = useTrackingContext();

  const [commitPurchaseMutation] = useMutation(PurchaseInCreditCardMutation);
  const commitPurchaseAsync = withNextPaymentAction<
    ListingPurchaseInCreditCardMutation$variables,
    ListingPurchaseInCreditCardMutation$data
  >(promisifyMutationWithRequestData(commitPurchaseMutation));

  const purchase = async (): Promise<void> => {
    resetFormState();

    try {
      updateFormState({ isValidating: true });

      const { isPresaleActive, isPresaleEligible } = getNFTPresaleState(
        !!nft.listing.liveSale.custodialPresalePriceUsd,
        nft.metadata.dropMetadata
      );
      const paymentMethodId = await getPaymentMethodId(
        cardSelectionType,
        savedCardId,
        cardHolderName
      );
      const isPresale = isPresaleActive && isPresaleEligible;
      const amountInUsd = isPresale
        ? nft.listing.liveSale.custodialPresalePriceUsd
        : nft.listing?.lowestAskInUsd;
      const amountInEth = isPresale
        ? nft.listing.liveSale.custodialPresalePriceEth
        : nft.listing?.lowestAskInEth;

      const purchaseResult = await commitPurchaseAsync({
        discountCode,
        isPresale,
        paymentMethodId,
        productId: parseInt(nft.listing.pk, 10),
        rememberCard: remember,
      });

      if (!purchaseResult.purchaseInCreditCard.success) {
        throw new Error(`Error purchasing token`);
      }

      updateFormState({ isSuccess: true });

      GTM.ecommerce.addPaymentInfo(nft, {
        payment_type: EcommercePaymentType.CreditCard,
      });
      GTM.ecommerce.purchase(
        nft,
        {
          coupon: null,
          offer_or_purchase: EcommercePurchaseType.Purchase,
          payment_type: EcommercePaymentType.CreditCard,
          total_order_count: 1,
          transaction_id: purchaseResult.purchaseInCreditCard.intentId,
          value: amountInUsd?.toString() ?? null,
          value_ether: amountInEth ?? null,
          wallet_type: null,
        },
        source
      );
      onSuccess();
    } catch (error) {
      updateFormState({
        mutationError: error,
      });
      GTM.ecommerce.error(error.toString());
      logNFTException(nft.pk, error);
    }
  };

  return [
    purchase,
    {
      ...formState,
      isDisabled: !(
        (cardSelectionType === NFTCardSelectionType.New &&
          !!cardHolderName &&
          cardIsValid) ||
        (cardSelectionType === NFTCardSelectionType.Saved && !!savedCardId)
      ),
    },
  ];
};

export interface DepositFundState {
  metadataId: number;
  requestId: number;
  transactionInProgress: boolean;
}

export const DEFAULT_DEPOSIT_FUND_STATE = {
  metadataId: 0,
  requestId: 0,
  transactionInProgress: false,
} as DepositFundState;

export const usePurchaseWithEthereum = ({
  nft,
  discountCode,
  onSuccess,
  depositFundContractQueryRef,
}: {
  depositFundContractQueryRef: PreloadedQuery<NFTContractQuery>;
  discountCode: string;
  nft: PurchasableNFTType;
  onSuccess: () => void;
}): [() => Promise<void>, FormState, string, () => void] => {
  const { listing, contract, onchainId } = nft;
  const productId = parseInt(listing.pk, 10);
  const { source } = useTrackingContext();

  const provider = usePublicClient();
  const session = useSession();
  const { address: buyerAddress } = useAccount();
  const walletConnectionState = useWalletConnectionState();
  const { switchChainAsync } = useSwitchChain();
  const { logNFTException } = useLogging();
  const [emailAddress] = useState(session.account?.email || '');
  const [formState, updateFormState, resetFormState] = usePaymentFormState();
  const depositFundState = useRef<DepositFundState>({
    ...DEFAULT_DEPOSIT_FUND_STATE,
  });
  const [transactionHash, setTransactionHash] = useState<string>(null);
  const resetTransactionHash = () => setTransactionHash(null);
  const { isPresaleActive, isPresaleEligible } = getNFTPresaleState(
    !!listing?.liveSale?.custodialPresalePriceEth,
    nft.metadata.dropMetadata
  );
  const isPresale = isPresaleActive && isPresaleEligible;
  const amountInUsd = isPresale
    ? nft.listing.liveSale.custodialPresalePriceUsd
    : nft.listing?.lowestAskInUsd;
  const amountInEth = isPresale
    ? nft.listing.liveSale.custodialPresalePriceEth
    : nft.listing?.lowestAskInEth;

  const pushPurchaseTag = useCallback(
    (wallet: string, transactionResultHash: string) =>
      GTM.ecommerce.purchase(
        nft,
        {
          coupon: null,
          offer_or_purchase: EcommercePurchaseType.Purchase,
          payment_type: EcommercePaymentType.ETH,
          total_order_count: 1,
          transaction_id: transactionResultHash,
          value: amountInUsd?.toString() ?? null,
          value_ether: amountInEth ?? null,
          wallet_type: wallet,
        },
        source
      ),
    [amountInEth, amountInUsd, nft, source]
  );

  // #region [BidOnTokens]
  const nftGeneralSaleContract = useSaleContract({
    nft,
  });

  // We need to look at the contract address of the live sale since it might be an older DigitalMediaSaleCore
  const saleContract = listing.liveSale?.contract
    ? listing.liveSale.contract
    : nftGeneralSaleContract;

  // For sale and purchase, we should use the sale's contract first if there was any.
  // Otherwise it will cause the listing sale vs purchase contract mismatching
  const { usePurchase } = useDigitalMediaSaleCoreContract({
    abi: JSON.parse(saleContract.abidata).abi,
    contractAddress: saleContract.address as HexString,
  });

  const purchaseContractManager = usePurchase({
    tokenAddress: contract.address as HexString,
    tokenId: onchainId,
    value: getSafeWeiAmountFromEthUserInput(amountInEth),
  });

  const { nftContract: depositFundManagerContract } =
    usePreloadedQuery<NFTContractQuery>(
      NFTContractQueryType,
      depositFundContractQueryRef
    );

  const { useDeposit } = useDepositFundContract({
    abi: JSON.parse(depositFundManagerContract.abidata).abi,
    contractAddress: depositFundManagerContract.address as HexString,
  });

  const depositContractManager = useDeposit({
    metadataId: depositFundState.current.metadataId,
    requestId: depositFundState.current.requestId,
    value: getSafeWeiAmountFromEthUserInput(amountInEth),
  });
  // #endregion

  // #region [Mutations]
  const [commitBeforePurchaseInEthereumMutation] = useMutation(
    BeforePurchaseInETHMutation
  );
  const commitBeforePurchaseInEthereumMutationAsync =
    promisifyMutationWithRequestData<
      ListingBeforePurchaseInETHMutation$variables,
      ListingBeforePurchaseInETHMutation$data
    >(commitBeforePurchaseInEthereumMutation);

  const [commitPurchaseWithCustodialWalletMutation] = useMutation(
    PurchaseWithCustodialWalletMutation
  );
  const commitPurchaseWithCustodialWalletMutationAsync =
    promisifyMutationWithRequestData<
      ListingPurchaseWithCustodialWalletMutation$variables,
      ListingPurchaseWithCustodialWalletMutation$data
    >(commitPurchaseWithCustodialWalletMutation);

  const [commitPurchaseWithPersonalWalletMutation] = useMutation(
    PurchaseWithPersonalWalletMutation
  );
  const commitPurchaseWithPersonalWalletMutationAsync =
    promisifyMutationWithRequestData<
      ListingPurchaseWithPersonalWalletMutation$variables,
      ListingPurchaseWithPersonalWalletMutation$data
    >(commitPurchaseWithPersonalWalletMutation);
  // #endregion

  const handleDepositFundMetadataReceived = async () => {
    if (depositFundState.current.transactionInProgress) {
      return;
    }

    try {
      depositFundState.current.transactionInProgress = true;

      const nonce = (await provider.getTransactionCount(buyerAddress)) + 1;
      const transactionResult =
        await depositContractManager.mutate.writeAsync();

      updateFormState({ isValidating: true });

      const purchaseResult =
        await commitPurchaseWithCustodialWalletMutationAsync({
          buyerAddress,
          depositMetadataId: depositFundState.current.metadataId,
          discountCode,
          emailAddress,
          isPresale,
          nonce,
          productId,
          transactionId: transactionResult,
        });

      updateFormState({ isValidating: false });

      if (!purchaseResult.purchaseWithCustodialWallet.success) {
        throw new Error('Could not validate transaction');
      }

      updateFormState({ isSuccess: true });
      pushPurchaseTag(null, transactionResult);

      depositFundState.current = {
        ...DEFAULT_DEPOSIT_FUND_STATE,
      };

      onSuccess();
    } catch (error) {
      updateFormState({
        mutationError: error,
      });
      GTM.ecommerce.error(error.toString());
      logNFTException(nft.pk, error);
    }
  };

  useEffect(() => {
    if (!depositFundState.current || !depositContractManager) return;

    const { requestId, metadataId } = depositFundState.current;
    const {
      simulate: { isFetching, isPending },
    } = depositContractManager;

    if (isFetching || isPending || !requestId || !metadataId) return;

    handleDepositFundMetadataReceived();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    depositFundState.current,
    depositContractManager,
    depositContractManager.simulate.isFetching,
    depositContractManager.simulate.isPending,
  ]);

  const purchaseCustodialTokenWithEthereum = async () => {
    depositContractManager.mutate.reset();

    updateFormState({ isValidating: true });

    const validationResult = await commitBeforePurchaseInEthereumMutationAsync({
      buyerAddress,
      isPresale,
      productId,
    });

    updateFormState({ isValidating: false });

    if (!validationResult.beforePurchaseInEth.success) {
      throw new Error('Unable to purchase token');
    }

    depositFundState.current = {
      ...depositFundState.current,
      metadataId:
        validationResult.beforePurchaseInEth.response.depositMetadataId,
      requestId: validationResult.beforePurchaseInEth.response.depositRequestId,
    };
  };

  const purchasePersonalListingWithEthereum = async () => {
    purchaseContractManager.mutate.reset();

    const nonce = (await provider.getTransactionCount(buyerAddress)) + 1;
    const transactionResult = await purchaseContractManager.mutate.writeAsync();

    updateFormState({ isValidating: true });

    const purchaseResult = await commitPurchaseWithPersonalWalletMutationAsync({
      buyerAddress,
      discountCode,
      emailAddress,
      isPresale,
      nonce,
      productId,
      transactionId: transactionResult,
    });

    updateFormState({ isValidating: false });

    if (!purchaseResult.purchaseWithPersonalWallet.success) {
      throw new Error('Could not validate transaction');
    }

    setTransactionHash(transactionResult);
    updateFormState({ isSuccess: true });
    pushPurchaseTag(getCurrentWalletName(), transactionResult);
  };

  const purchase = async (): Promise<void> => {
    if (!walletConnectionState.isConnected) {
      return;
    }
    if (walletConnectionState.isConnectedToWrongNetwork) {
      await switchChainAsync({ chainId: session.contractNetwork });
    }

    resetFormState();
    try {
      if (listing.custodialSaleEnabled) {
        await purchaseCustodialTokenWithEthereum();
      } else {
        await purchasePersonalListingWithEthereum();
      }
    } catch (error) {
      updateFormState({
        mutationError: error,
      });
      GTM.ecommerce.error(error.toString());
      logNFTException(nft.pk, error);
    }
  };

  return [
    purchase,
    {
      ...formState,
      isDisabled:
        !walletConnectionState.isConnected ||
        purchaseContractManager.simulate.isFetching ||
        purchaseContractManager.simulate.isPending ||
        purchaseContractManager.mutate.isPending,
      mutationError: purchaseContractManager.mutate.error,
      simulationError: purchaseContractManager.simulate.error,
      validationError: undefined,
    },
    transactionHash,
    resetTransactionHash,
  ];
};

export default { usePurchaseWithCreditCard, usePurchaseWithEthereum };
