import { useEffect, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';

import HomepageSectionsQueryType, {
  HomepageSectionsQuery,
} from 'graphql/__generated__/HomepageSectionsQuery.graphql';
import { HomepageSectionTypeEnum } from 'types/__generated__/graphql';

import HeroArtworkCard from 'components/cards/HeroArtworkCard';
import HeroCollectionCard from 'components/cards/HeroCollectionCard';
import HeroEditorialCard from 'components/cards/HeroEditorialCard';
import HeroExhibitionCard from 'components/cards/HeroExhibitionCard';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import LiveExhibitions from './homepageExhibitions/LiveExhibitions';
import UpcomingExhibitions from './homepageExhibitions/UpcomingExhibitions';
import ExploreArtworks from './ExploreArtworks';
import { HeroArtistCard } from './HomepageArtistSpotlight';
import HomepageEditorial from './HomepageEditorial';

import * as styles from 'css/pages/homepage/HomepageHeroCardSection.module.css';

interface HomepageSectionsProps {
  onLoaded: () => void;
  sectionsQuery: WithLoadQueryProps<HomepageSectionsQuery>;
}

function HomepageSections({
  sectionsQuery: { queryRef },
  onLoaded,
}: HomepageSectionsProps) {
  const result = usePreloadedQuery<HomepageSectionsQuery>(
    HomepageSectionsQueryType,
    queryRef
  );
  const sections = useMemo(
    () => result.homepageSections.edges.map(({ node: section }) => section),
    [result]
  );

  useEffect(() => {
    if (!sections.length) return;

    onLoaded();
  }, [onLoaded, sections]);

  return (
    <>
      {sections.map((section) =>
        section.sectionType === HomepageSectionTypeEnum.HeroArtist ? (
          <HeroArtistCard
            key={`hero-artist-${section.artist.id}`}
            artist={section.artist}
          />
        ) : section.sectionType === HomepageSectionTypeEnum.HeroArtwork ? (
          <section
            key={`hero-artwork-${section.artwork.id}`}
            className={styles.container}
          >
            <HeroArtworkCard
              nft={section.artwork}
              nftMetadata={section.artwork.metadata}
            />
          </section>
        ) : section.sectionType === HomepageSectionTypeEnum.HeroCollection ? (
          <section
            key={`hero-collection-${section.collection.id}`}
            className={styles.container}
          >
            <HeroCollectionCard collection={section.collection} />
          </section>
        ) : section.sectionType === HomepageSectionTypeEnum.HeroEditorial ? (
          <section
            key={`hero-editorial-${section.editorial.id}`}
            className={styles.container}
          >
            <HeroEditorialCard editorial={section.editorial} />
          </section>
        ) : section.sectionType === HomepageSectionTypeEnum.HeroExhibition ? (
          <section
            key={`hero-exhibition-${section.exhibition.id}`}
            className={styles.container}
          >
            <HeroExhibitionCard exhibition={section.exhibition} />
          </section>
        ) : section.sectionType ===
          HomepageSectionTypeEnum.DiscoverArtworksCarousel ? (
          <ExploreArtworks
            key="artworks-carousel"
            featuredNftsQuery={{ variables: {} }}
          />
        ) : section.sectionType ===
          HomepageSectionTypeEnum.EditorialsCarousel ? (
          <HomepageEditorial
            key="editorials-carousel"
            editorialQuery={{ variables: {} }}
          />
        ) : section.sectionType ===
          HomepageSectionTypeEnum.LiveExhibitionsCarousel ? (
          <LiveExhibitions
            key="live-exhibitions-carousel"
            exhibitionsQuery={{ variables: {} }}
            defaultErrorBoundaryConfig={{
              hideState: true,
              suspenseless: false,
            }}
          />
        ) : section.sectionType ===
          HomepageSectionTypeEnum.UpcomingExhibitionsCarousel ? (
          <UpcomingExhibitions
            key="upcoming-exhibitions-carousel"
            exhibitionsQuery={{ variables: {} }}
            defaultErrorBoundaryConfig={{
              hideState: true,
              suspenseless: false,
            }}
          />
        ) : null
      )}
    </>
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(HomepageSections, {
    sectionsQuery: { concreteRequest: HomepageSectionsQueryType },
  }),
  { errorFallback: null, suspenseless: true }
);
