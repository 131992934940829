import { useCallback, useEffect, useRef, useState } from 'react';
import { AddressElement } from '@stripe/react-stripe-js';
import {
  StripeAddressElementChangeEvent,
  StripeAddressElementOptions,
} from '@stripe/stripe-js';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import StandardDialog from 'components/dialogs/StandardDialog';
import GOOGLE_API_KEYS from 'constants/Google';

import * as styles from 'css/pages/product/ProductClaimUnlockable.module.css';

/* eslint-disable @cspell/spellchecker */
export const COUNTRY_SHIPPING_REGION_MAP = {
  EUROPE: new Set([
    'AL', // Albania
    'AD', // Andorra
    'AM', // Armenia
    'AT', // Austria
    'AZ', // Azerbaijan
    'BY', // Belarus
    'BE', // Belgium
    'BA', // Bosnia and Herzegovina
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FO', // Faroe Islands
    'FI', // Finland
    'FR', // France
    'GE', // Georgia
    'DE', // Germany
    'GI', // Gibraltar
    'GR', // Greece
    'HU', // Hungary
    'IS', // Iceland
    'IE', // Ireland
    'IT', // Italy
    'KZ', // Kazakhstan
    'XK', // Kosovo
    'LV', // Latvia
    'LI', // Liechtenstein
    'LT', // Lithuania
    'LU', // Luxembourg
    'MK', // Macedonia
    'MT', // Malta
    'MD', // Moldova
    'MC', // Monaco
    'ME', // Montenegro
    'NL', // Netherlands
    'NO', // Norway
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'RU', // Russia
    'SM', // San Marino
    'RS', // Serbia
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SJ', // Svalbard and Jan Mayen
    'SE', // Sweden
    'CH', // Switzerland
    'TR', // Turkey
    'UA', // Ukraine
    'GB', // United Kingdom
    'VA', // Vatican City
  ]),
  NORTH_AMERICA: new Set([
    'AI', // Anguilla
    'AG', // Antigua and Barbuda
    'AW', // Aruba
    'BS', // Bahamas
    'BB', // Barbados
    'BZ', // Belize
    'BM', // Bermuda
    'VG', // British Virgin Islands
    'CA', // Canada
    'KY', // Cayman Islands
    'CR', // Costa Rica
    'CU', // Cuba
    'DM', // Dominica
    'DO', // Dominican Republic
    'SV', // El Salvador
    'GL', // Greenland
    'GD', // Grenada
    'GP', // Guadeloupe
    'GT', // Guatemala
    'HT', // Haiti
    'HN', // Honduras
    'JM', // Jamaica
    'MQ', // Martinique
    'MX', // Mexico
    'MS', // Montserrat
    'AN', // Netherlands Antilles
    'NI', // Nicaragua
    'PA', // Panama
    'PR', // Puerto Rico
    'BL', // Saint Barthélemy
    'KN', // Saint Kitts and Nevis
    'LC', // Saint Lucia
    'MF', // Saint Martin
    'PM', // Saint Pierre and Miquelon
    'VC', // Saint Vincent and the Grenadines
    'TT', // Trinidad and Tobago
    'TC', // Turks and Caicos Islands
    'VI', // U.S. Virgin Islands
    'US', // United States
  ]),
};
/* eslint-enable @cspell/spellchecker */

export type StripeAddressElementChangeValue =
  StripeAddressElementChangeEvent['value'];

const addressOptions: StripeAddressElementOptions = {
  autocomplete: {
    apiKey: GOOGLE_API_KEYS.MAPS,
    mode: 'google_maps_api',
  },
  fields: {
    phone: 'always',
  },
  mode: 'shipping',
  validation: { phone: { required: 'always' } },
};

interface ShippingProps {
  isPaymentRequired: boolean;
  onChange: (value: StripeAddressElementChangeValue, complete: boolean) => void;
  onClose: () => void;
  onNext: () => void;
  shippingCostEurope: number | null;
  shippingCostNorthAmerica: number | null;
  shippingCostOther: number | null;
}

export default function ShippingModal({
  isPaymentRequired,
  shippingCostEurope,
  shippingCostNorthAmerica,
  shippingCostOther,
  onChange,
  onClose,
  onNext,
}: ShippingProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const elementHeight = useRef<number>(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isSupported, setIsSupported] = useState<boolean>(true);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const isSupportedCountry = useCallback(
    (country: string): boolean => {
      if (!country) return false;

      const shippingCost = COUNTRY_SHIPPING_REGION_MAP.EUROPE.has(country)
        ? shippingCostEurope
        : COUNTRY_SHIPPING_REGION_MAP.NORTH_AMERICA.has(country)
        ? shippingCostNorthAmerica
        : shippingCostOther;

      return !!shippingCost || shippingCost === 0;
    },
    [shippingCostEurope, shippingCostNorthAmerica, shippingCostOther]
  );

  useEffect(() => {
    if (!elementRef.current) return undefined;
    const resizeObserver = new ResizeObserver(() => {
      const height = elementRef.current.clientHeight;
      if (!height) return;

      if (elementHeight.current === 0) {
        elementHeight.current = height;
      } else if (height > elementHeight.current) {
        setIsExpanded(true);
      }
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);

  const handleReady = useCallback(() => setIsReady(true), []);

  const handleChange = useCallback(
    (event: StripeAddressElementChangeEvent): void => {
      setIsComplete(event.complete);
      setIsSupported(isSupportedCountry(event.value.address.country));
      onChange(event.value, event.complete);
    },
    [isSupportedCountry, onChange]
  );

  const handleSubmit = useCallback(() => {
    if (!isComplete) return;

    onNext();
  }, [isComplete, onNext]);

  return (
    <StandardDialog
      title="Unlock Physical"
      actionButton={[
        !isSupported ? (
          <span
            className={joinClasses(
              MPFonts.paragraphSmall,
              MPColorClass.ErrorMain
            )}
          >
            Delivery cannot be completed to your country for this item.
          </span>
        ) : null,
        <MPActionButton
          fullWidth
          autoFocus
          disabled={!isComplete || !isSupported}
          onClick={handleSubmit}
        >
          {isPaymentRequired ? 'Continue' : 'Submit'}
        </MPActionButton>,
      ]}
      onClose={onClose}
    >
      <div className={joinClasses(styles.container, styles.shipping)}>
        <div className={MPFonts.paragraphNormal}>
          Add your delivery information to finish unlocking your physical
          artwork.
        </div>

        <div ref={elementRef}>
          <AddressElement
            className={joinClasses(styles.address, {
              [styles.expanded]: !isReady || isExpanded,
            })}
            options={addressOptions}
            onReady={handleReady}
            onChange={handleChange}
          />
        </div>
      </div>
    </StandardDialog>
  );
}
