import {
  forwardRef,
  MouseEvent,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MPActionButton } from '@mp-frontend/core-components';
import { GenerateIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';

import { Media } from '.';

import * as styles from 'css/pages/product/ProductPreview.module.css';

const DEFAULT_INTERVAL = 3000;

interface usePreviewPaginationStateProps {
  medias: Media[];
  infinite?: boolean;
  intervalMs?: number;
  onChange?: (index: number) => void;
}

export function usePreviewPaginationState({
  medias,
  infinite = false,
  intervalMs = DEFAULT_INTERVAL,
  onChange,
}: usePreviewPaginationStateProps) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isAutoSwiping, setAutoSwipe] = useState<boolean>(false);
  const [isAutoSwipingStarted, setAutoSwipeStarted] = useState<boolean>(false);
  const autoSwipeIntervalRef = useRef<number | null>(null);

  const handleStart = useCallback(() => {
    if (isAutoSwipingStarted) return;

    startTransition(() => {
      setAutoSwipeStarted(true);
      setAutoSwipe(true);
    });
  }, [isAutoSwipingStarted]);

  const handleStop = useCallback(() => setAutoSwipe(false), []);

  const handleSelect = useCallback(
    (index: number, stopAutoSwipe = true) => {
      if (stopAutoSwipe) handleStop();

      if (index < 0 || index >= medias.length || index === currentIndex) return;

      setCurrentIndex(index);
      onChange?.(index);
      if (index === 0 && !infinite) handleStop();
    },
    [currentIndex, infinite, medias, handleStop, onChange]
  );

  const handleSelectNext = useCallback(
    (stopAutoSwipe?: boolean) =>
      handleSelect((currentIndex + 1) % medias.length, stopAutoSwipe),
    [currentIndex, handleSelect, medias.length]
  );

  const handleSelectPrev = useCallback(
    (stopAutoSwipe?: boolean) =>
      handleSelect(
        (currentIndex - 1 + medias.length) % medias.length,
        stopAutoSwipe
      ),
    [currentIndex, handleSelect, medias.length]
  );

  useEffect(() => {
    const stop = () => {
      if (autoSwipeIntervalRef.current) {
        clearInterval(autoSwipeIntervalRef.current);
        autoSwipeIntervalRef.current = null;
      }
    };

    if (isAutoSwiping) {
      autoSwipeIntervalRef.current = window.setInterval(
        () => handleSelectNext(false),
        intervalMs
      );
    } else {
      stop();
    }

    return () => stop();
  }, [intervalMs, isAutoSwiping, handleSelectNext, medias.length]);

  const handleReset = useCallback(() => {
    if (autoSwipeIntervalRef.current) {
      clearInterval(autoSwipeIntervalRef.current);
      autoSwipeIntervalRef.current = null;
    }
    setCurrentIndex(0);
    setAutoSwipe(false);
    setAutoSwipeStarted(false);
  }, []);

  return {
    currentIndex,
    intervalMs,
    isSwiping: isAutoSwiping,
    reset: handleReset,
    select: handleSelect,
    selectNext: handleSelectNext,
    selectPrev: handleSelectPrev,
    start: handleStart,
    stop: handleStop,
  } as const;
}

interface PreviewPaginationProps
  extends ReturnType<typeof usePreviewPaginationState> {
  medias: Media[];
  className?: string;
  disableAutoStart?: boolean;
  disableSelection?: boolean;
  inactive?: boolean;
  onGenerateClick?: () => void;
  showGenerateButton?: boolean;
}

export default forwardRef(
  (
    {
      className,
      medias,
      currentIndex,
      intervalMs,
      isSwiping,
      start,
      select,
      disableAutoStart = false,
      disableSelection = false,
      inactive = false,
      showGenerateButton = false,
      onGenerateClick,
    }: PreviewPaginationProps,
    setRef: (node: HTMLDivElement | null) => void
  ) =>
    medias.length > 1 || showGenerateButton ? (
      <div
        className={joinClasses(
          CSSGlobal.Flex.Row,
          CSSGlobal.Flex.JustifyCenter,
          CSSGap[20],
          styles.paginationContainer,
          { [styles.forceThumbnails]: showGenerateButton }
        )}
      >
        {medias.length > 1 && (
          <div
            ref={(node) => {
              setRef?.(node);
              if (node && !disableAutoStart) start();
            }}
            className={joinClasses(styles.pagination, className)}
          >
            {medias.map((media, idx) => {
              const isCurrent = idx === currentIndex;

              return (
                <button
                  key={media.id}
                  type="button"
                  aria-label="Select this media"
                  className={joinClasses(styles.paginationItem, {
                    [styles.current]: isCurrent && !inactive,
                    [styles.disabled]: disableSelection,
                    [styles.isAutoSwiping]: isSwiping,
                  })}
                  onClick={(event: MouseEvent) => {
                    if (!disableSelection) {
                      event.preventDefault();
                      select(idx);
                    }
                  }}
                >
                  <div
                    className={styles.preview}
                    style={{ backgroundImage: `url(${media.lowResUrl})` }}
                  />

                  <span
                    className={styles.indicator}
                    style={{
                      borderLeftWidth:
                        isCurrent && isSwiping
                          ? 'var(--pdp-image-preview-size)'
                          : undefined,
                      transition:
                        isCurrent && isSwiping
                          ? `border-left-width ${intervalMs}ms linear`
                          : undefined,
                    }}
                  />
                </button>
              );
            })}
          </div>
        )}

        {!!showGenerateButton && (
          <MPActionButton
            className={styles.actionButton}
            size="small"
            variant="tertiary-custom"
            onClick={onGenerateClick}
          >
            <GenerateIcon fontSize="24" className={CSSMargin.RIGHT[4]} />
            Generate Sample
          </MPActionButton>
        )}
      </div>
    ) : null
);
