.content {
  padding: 0 32px 16px;
}

.block {
  border-bottom: 1px solid var(--color-primaryP20);
  padding-bottom: 16px;
}

.block.missingLabel {
  padding-top: 16px;
}

.blockInputs {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px;
}

.blockTextInputs {
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 5%;
}
