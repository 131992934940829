import { useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';

import HomepageHeroExhibitionsQueryType, {
  HomepageHeroExhibitionsQuery,
} from 'graphql/__generated__/HomepageHeroExhibitionsQuery.graphql';

import HeroExhibitionCard from 'components/cards/HeroExhibitionCard';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/pages/homepage/HomepageHeroCardSection.module.css';

interface HeroExhibitionsProps {
  heroExhibitionsQuery: WithLoadQueryProps<HomepageHeroExhibitionsQuery>;
}
function HeroExhibitions({ heroExhibitionsQuery }: HeroExhibitionsProps) {
  const result = usePreloadedQuery<HomepageHeroExhibitionsQuery>(
    HomepageHeroExhibitionsQueryType,
    heroExhibitionsQuery.queryRef
  );
  const exhibitions = useMemo(
    () =>
      result.topCarouselsUpcomingAndLive.edges
        .filter((edge) => !!edge.node.sortedNftMetadatas.edges.length)
        .map((edge) => edge.node),
    [result]
  );

  return (
    <>
      {exhibitions.map((exhibition) => (
        <section key={exhibition.id} className={styles.container}>
          <HeroExhibitionCard exhibition={exhibition} />
        </section>
      ))}
    </>
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(HeroExhibitions, {
    heroExhibitionsQuery: { concreteRequest: HomepageHeroExhibitionsQueryType },
  }),
  { errorFallback: null, suspenseless: true }
);
