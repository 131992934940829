import { useCallback, useEffect, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';

import {
  MPActionButton,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import {
  CarouselLeftArrow,
  CarouselRightArrow,
} from '@mp-frontend/core-components/icons';
import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import useIntersection from 'hooks/useIntersection';
import CSSGlobal from 'types/enums/css/Global';
import useHomepageGTM from 'utils/GTM/homepage';

import 'react-alice-carousel/lib/alice-carousel.css';
import * as styles from 'css/components/carousel/Carousel.module.css';

interface CarouselProps {
  containerName: string;
  header: JSX.Element;
  items: any[];
  logViewAbleCardsToGA: (startIdx: number, endIdx: number) => void;
  className?: string;
  headerFontClassName?: string;
  viewAllLink?: string;
}

const responsive = {
  0: { items: 1 },
  1024: { items: 3, itemsFit: 'contain' },
};

function Carousel({
  items,
  viewAllLink,
  header,
  containerName,
  className,
  headerFontClassName = MPFonts.headline4,
  logViewAbleCardsToGA,
}: CarouselProps) {
  const track = useHomepageGTM();
  const isMobile = useIsMobile();
  const slideJump = isMobile ? 1 : 3;

  const carousel = useRef<AliceCarousel>(null);
  const ref = useRef(null);
  const isVisible = useIntersection(ref);
  const [showNextArrow, setShowNextArrow] = useState(items.length > slideJump);
  const [showPreviousArrow, setShowPreviousArrow] = useState(false);
  const [index, setIndex] = useState(
    items.length > slideJump ? slideJump : items.length
  );

  const handleNextClick = useCallback(() => {
    const maxIndex = isMobile ? items.length : items.length - 3;
    if (carousel?.current?.state.activeIndex < maxIndex) {
      const nextSlide = carousel.current.state.activeIndex + slideJump;
      carousel.current.slideTo(nextSlide);
      track.clickCarouselPaddle(containerName, 'right');
      // User has clicked next so they will view next 3 or 1 we don't need to do the same to repeat the same on previous click.
      const endIdx =
        nextSlide + slideJump > items.length
          ? items.length
          : nextSlide + slideJump;
      logViewAbleCardsToGA(nextSlide, endIdx);
    }
    const nextIndex =
      index + slideJump <= items.length ? index + slideJump : items.length;
    setIndex(nextIndex);
  }, [
    track,
    containerName,
    index,
    isMobile,
    items.length,
    logViewAbleCardsToGA,
    slideJump,
  ]);
  const ariaNextKeypress = useOnEnterKey(handleNextClick);

  const handlePreviousClick = useCallback(() => {
    if (carousel?.current?.state.activeIndex > 0) {
      const nextSlide = carousel.current.state.activeIndex - slideJump;
      carousel.current.slideTo(nextSlide);
      track.clickCarouselPaddle(containerName, 'left');
    }
    const nextIndex = index - slideJump > 0 ? index - slideJump : 0;
    setIndex(nextIndex);
  }, [track, carousel, containerName, index, slideJump]);
  const ariaPreviousKeypress = useOnEnterKey(handlePreviousClick);

  const logToGA = useCallback(() => {
    track.clickCarouselViewAll(containerName);
  }, [track, containerName]);

  useEffect(() => {
    setTimeout(() => {
      setShowNextArrow(index < items.length);
      setShowPreviousArrow(index - slideJump > 0);
    }, 200);
  }, [index, items.length, slideJump]);

  useEffect(() => {
    if (items && items.length > 0 && isVisible) {
      const endIdx = items.length < slideJump ? items.length : slideJump;
      logViewAbleCardsToGA(0, endIdx);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [items, slideJump, isVisible]);

  const showArrows = items?.length > 3 || (isMobile && items?.length > 1);

  return (
    <section ref={ref} className={className}>
      <div
        className={joinClasses(
          headerFontClassName,
          CSSGlobal.Cursor.Default,
          styles.carouselContainerTitle
        )}
      >
        {header}

        {!!viewAllLink && (
          <div>
            <MPActionButton
              href={viewAllLink}
              onClick={logToGA}
              size="small"
              variant="secondary"
            >
              View All
            </MPActionButton>
          </div>
        )}
      </div>

      <div className={styles.carouselContent}>
        <div
          className={joinClasses(CSSGlobal.NoScrollbar, styles.cardsContainer)}
        >
          <AliceCarousel
            items={items}
            responsive={responsive}
            disableButtonsControls
            disableDotsControls
            ref={carousel}
            animationDuration={500}
            paddingRight={isMobile ? 15 : 0}
            paddingLeft={isMobile ? 26 : 0}
          />
        </div>

        {!!showArrows && !isMobile && (
          <div className={styles.carouselScrollIcons}>
            {!!showPreviousArrow && (
              <div
                className={joinClasses(
                  styles.carouselActionArrow,
                  styles.previous
                )}
                onClick={handlePreviousClick}
                onKeyDown={ariaPreviousKeypress}
                tabIndex={-1}
                role="button"
              >
                <CarouselLeftArrow fontSize="16" />
              </div>
            )}
            {!!showNextArrow && (
              <div
                className={joinClasses(styles.carouselActionArrow, styles.next)}
                onClick={handleNextClick}
                onKeyDown={ariaNextKeypress}
                tabIndex={-1}
                role="button"
              >
                <CarouselRightArrow fontSize="16" />
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default Carousel;
