/**
 * Pause execution for `durationInMilliseconds` seconds
 *
 * Example:
 * const now = Date.now();
 * await wait(1000);
 * const later = Date.now();
 *
 * 1000ms will have transpired
 *
 * @param durationInMilliseconds
 * @returns void
 */
export const wait = (durationInMilliseconds: number): Promise<void> =>
  new Promise<void>((resolve) => {
    setTimeout(resolve, durationInMilliseconds);
  });

export default wait;
