import { ChangeEvent } from 'react';

import { MPCheckbox } from '@mp-frontend/core-components';

interface ArtistFilterListProps {
  handleAvailableAccountsChange: (checked: boolean, value: string) => void;
  toExcludeUsernames: string[];
  usernames: string[];
}

function ArtistFilterList({
  toExcludeUsernames,
  usernames,
  handleAvailableAccountsChange,
}: ArtistFilterListProps) {
  return (
    <>
      {usernames.map((username) => (
        <li key={username}>
          <MPCheckbox
            label={username}
            name={username}
            isChecked={toExcludeUsernames.some((a) => a === username)}
            onChange={(
              event: ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => handleAvailableAccountsChange(checked, username)}
          />
        </li>
      ))}
    </>
  );
}

export default ArtistFilterList;
