import { useCallback, useEffect, useState } from 'react';
import { useGateValue } from '@statsig/react-bindings';

import HomepageGenresQueryType, {
  HomepageGenresQuery,
} from 'graphql/__generated__/HomepageGenresQuery.graphql';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import Footer from 'components/Footer';
import { SiteLoader } from 'components/SiteLoadingSuspense';
import { APP_NAME } from 'constants/Utils';
import useSession from 'hooks/useSession';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { getViewCount } from 'utils/localStorageUtils';
import setDocTitle from 'utils/setDocTitle';

import HeroExhibitions from './homepageExhibitions/HeroExhibitions';
import LiveExhibitions from './homepageExhibitions/LiveExhibitions';
import UpcomingExhibitions from './homepageExhibitions/UpcomingExhibitions';
import ExploreArtworks from './ExploreArtworks';
import HomepageArtistSpotlight from './HomepageArtistSpotlight';
import HomepageEditorial from './HomepageEditorial';
import HomepageGenres from './HomepageGenres';
import HomepageSections from './HomepageSections';
import InitialVisitHeader from './InitialVisitHeader';

import * as styles from 'css/pages/homepage/HomePage.module.css';

interface HomePageProps {
  genresQuery: WithLoadQueryProps<HomepageGenresQuery>;
}

function HomePage({ genresQuery }: HomePageProps) {
  const session = useSession();
  const [hasVisited] = useState<boolean>(getViewCount() > 0);
  const [haveSectionsLoaded, setHaveSectionsLoaded] = useState<boolean>(false);

  useEffect(() => setDocTitle(APP_NAME), []);

  const showGenericHomepageSections = useGateValue(
    'gate_generic_homepage_sections'
  );

  const onSectionsLoaded = useCallback(() => setHaveSectionsLoaded(true), []);

  return (
    <>
      <div className={styles.homePage}>
        {!!genresQuery.queryRef && !session.isLoggedIn() && !hasVisited && (
          <InitialVisitHeader />
        )}

        <div className={styles.content}>
          {showGenericHomepageSections ? (
            <HomepageSections
              sectionsQuery={{ variables: {} }}
              onLoaded={onSectionsLoaded}
            />
          ) : (
            <>
              <HeroExhibitions heroExhibitionsQuery={{ variables: {} }} />
              <LiveExhibitions exhibitionsQuery={{ variables: {} }} />
              <UpcomingExhibitions exhibitionsQuery={{ variables: {} }} />
              <ExploreArtworks featuredNftsQuery={{ variables: {} }} />
              <HomepageArtistSpotlight
                artistSpotlightQuery={{ variables: {} }}
              />
              <HomepageEditorial editorialQuery={{ variables: {} }} />
            </>
          )}
        </div>

        {!!genresQuery.queryRef && (
          <>
            <DefaultErrorBoundary hideState>
              <HomepageGenres queryRef={genresQuery.queryRef} />
              <Footer />
            </DefaultErrorBoundary>
          </>
        )}
      </div>

      {showGenericHomepageSections && !haveSectionsLoaded ? (
        <SiteLoader />
      ) : null}
    </>
  );
}

export default withLoadQuery(HomePage, {
  genresQuery: { concreteRequest: HomepageGenresQueryType },
});
