/**
 * @generated SignedSource<<d7935eb6606fc3600f4127b59d4d2b92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SessionWalletsMutation$variables = {};
export type SessionWalletsMutation$data = {
  readonly session: {
    readonly account: {
      readonly id: string;
      readonly wallets: ReadonlyArray<{
        readonly address: string;
      }>;
    } | null;
  } | null;
};
export type SessionWalletsMutation = {
  variables: SessionWalletsMutation$variables;
  response: SessionWalletsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SessionMutation",
    "kind": "LinkedField",
    "name": "session",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountSessionType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WalletAbstractType",
            "kind": "LinkedField",
            "name": "wallets",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionWalletsMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionWalletsMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3511d097f1433c25a6ca8e1fda1b833c",
    "id": null,
    "metadata": {},
    "name": "SessionWalletsMutation",
    "operationKind": "mutation",
    "text": "mutation SessionWalletsMutation {\n  session {\n    account {\n      id\n      wallets {\n        address\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc85f6eba79f131110e6b1e7e4654c1f";

export default node;
