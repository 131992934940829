import { useCallback, useState } from 'react';
import wait from 'utils/wait';

import {
  AspectRatioEnum,
  EditionTypeEnum,
  MediaTypeEnum,
  StatusEnum,
} from 'types/__generated__/graphql';

import GTM from 'GTM';
import useStateBackedObjRef from 'hooks/useStateBackedObjRef';

import useArtworkFilterState, {
  ArtworkFilterMinWidthHeight,
  ArtworkFilterPrice,
} from './UseArtworkFilterState';

const useArtworkFilters = () => {
  const filterParams = useArtworkFilterState();
  const [filterStatus, setFilterStatus] = filterParams.status;
  const [statusRef, getStatus, setStatus] = useStateBackedObjRef(filterStatus);

  const [filterEditions, setFilterEditions] = filterParams.editions;
  const [editionsRef, getEditions, setEditions] =
    useStateBackedObjRef(filterEditions);

  const [filterMedia, setFilterMedia] = filterParams.media;
  const [mediaRef, getMedia, setMedia] = useStateBackedObjRef(filterMedia);

  const [filterPrice, setFilterPrice] = filterParams.price;
  const [priceRef, getPrice, setPrice] = useStateBackedObjRef(filterPrice);
  const [filterTraits] = filterParams.traits;
  const [, getTraits, setTraits] = useStateBackedObjRef(filterTraits);
  const [lastValidMinPrice, setLastValidMinPrice] = useState(filterPrice.min);
  const [lastValidMaxPrice, setLastValidMaxPrice] = useState(filterPrice.max);

  const [filterDimensions, setFilterDimensions] = filterParams.dimensions;
  const [dimensionsRef, getDimensions, setDimensions] =
    useStateBackedObjRef(filterDimensions);

  const [filterMinWidthHeight, setFilterMinWidthHeight] =
    filterParams.minWidthHeight;
  const [minWidthHeightRef, getMinWidthHeight, setMinWidthHeight] =
    useStateBackedObjRef(filterMinWidthHeight);

  const constrainMinPrice = useCallback(() => {
    const isPriceGreaterthanMax =
      priceRef.current.max &&
      parseFloat(priceRef.current.min) > parseFloat(priceRef.current.max);
    const passesFloatyRegex = /^[0-9]*\.?[0-9]*$/.test(priceRef.current.min);
    const hasInvalidLeadingZero =
      priceRef.current.min?.length > 1 && priceRef.current.min[0] === '0';

    isPriceGreaterthanMax || !passesFloatyRegex || hasInvalidLeadingZero
      ? setPrice('min', lastValidMinPrice)
      : setLastValidMinPrice(priceRef.current.min);
  }, [priceRef, lastValidMinPrice, setPrice, setLastValidMinPrice]);

  const clearAll = useCallback(() => {
    Object.values(StatusEnum).forEach((key) => setStatus(key, false));
    Object.values(EditionTypeEnum).forEach((key) => setEditions(key, false));
    Object.values(MediaTypeEnum).forEach((key) => setMedia(key, false));
    Object.values(ArtworkFilterPrice).forEach((key) => setPrice(key, ''));
    Object.values(AspectRatioEnum).forEach((key) => setDimensions(key, false));
    Object.values(ArtworkFilterMinWidthHeight).forEach((key) =>
      setMinWidthHeight(key, '')
    );
  }, [
    setStatus,
    setEditions,
    setMedia,
    setPrice,
    setDimensions,
    setMinWidthHeight,
  ]);

  const constrainMaxPrice = useCallback(() => {
    const isPriceLessthanMin =
      priceRef.current.min &&
      parseFloat(priceRef.current.max) < parseFloat(priceRef.current.min);
    const passesFloatyRegex = /^[0-9]*\.?[0-9]*$/.test(priceRef.current.max);
    const hasInvalidLeadingZero =
      priceRef.current.max?.length > 1 && priceRef.current.max[0] === '0';

    isPriceLessthanMin || !passesFloatyRegex || hasInvalidLeadingZero
      ? setPrice('max', lastValidMaxPrice)
      : setLastValidMaxPrice(priceRef.current.max);
  }, [priceRef, lastValidMaxPrice, setPrice, setLastValidMaxPrice]);

  const update = useCallback(() => {
    setFilterStatus(statusRef.current);
    setFilterEditions(editionsRef.current);
    setFilterMedia(mediaRef.current);
    setFilterPrice(priceRef.current);
    setFilterDimensions(dimensionsRef.current);
    setFilterMinWidthHeight(minWidthHeightRef.current);
    wait(500).then(GTM.explore.trackFiltersApplied);
  }, [
    setFilterStatus,
    setFilterEditions,
    setFilterMedia,
    setFilterPrice,
    setFilterDimensions,
    setFilterMinWidthHeight,
    statusRef,
    editionsRef,
    mediaRef,
    priceRef,
    dimensionsRef,
    minWidthHeightRef,
  ]);

  return {
    clearAll,
    constrainMaxPrice,
    constrainMinPrice,
    getDimensions,
    getEditions,
    getMedia,
    getMinWidthHeight,
    getPrice,
    getStatus,
    getTraits,
    setDimensions,
    setEditions,
    setMedia,
    setMinWidthHeight,
    setPrice,
    setStatus,
    setTraits,
    update,
  };
};

export default useArtworkFilters;
