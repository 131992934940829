import { Route } from 'react-router-dom';

import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import { TrackingContext } from 'components/trackingContext';
import { EcommerceSourceType } from 'GTM';

import HomePage from './HomePage';

const HomePageRoute = (
  <Route
    path=""
    element={
      <PageErrorBoundary>
        <TrackingContext source={EcommerceSourceType.HomePage}>
          <HomePage genresQuery={{ variables: {} }} />
        </TrackingContext>
      </PageErrorBoundary>
    }
  />
);

export default HomePageRoute;
