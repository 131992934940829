import { useCallback } from 'react';

import { MPDropdownSelect } from '@mp-frontend/core-components';

import { SortOrderEnum, SortTypeEnum } from 'types/__generated__/graphql';

import GTM from 'GTM';

import useArtworkFilterState from './UseArtworkFilterState';

const getSortLabel = (sortType: SortTypeEnum, sortOrder: SortOrderEnum) => {
  if (sortType === SortTypeEnum.Recommended || sortType === undefined) {
    return 'Recommended';
  }
  if (sortType === SortTypeEnum.Popularity) {
    return 'Popularity'; // WARNING: To be deprecated soon.
  }
  if (sortType === SortTypeEnum.CreatedAt) {
    if (sortOrder === SortOrderEnum.Asc) return 'Oldest';

    if (sortOrder === SortOrderEnum.Desc) return 'Newest';
  } else if (sortType === SortTypeEnum.Price) {
    if (sortOrder === SortOrderEnum.Asc) return 'Lowest Price';

    if (sortOrder === SortOrderEnum.Desc) return 'Highest Price';
  }

  return '';
};

const FILTERS: [SortTypeEnum, SortOrderEnum][] = [
  [SortTypeEnum.Recommended, undefined],
  [SortTypeEnum.CreatedAt, SortOrderEnum.Desc],
  [SortTypeEnum.CreatedAt, SortOrderEnum.Asc],
  [SortTypeEnum.Price, SortOrderEnum.Asc],
  [SortTypeEnum.Price, SortOrderEnum.Desc],
];

const FILTER_CONFIG = {
  ...Object.fromEntries(
    FILTERS.map(([sortType, sortOrder]) => [
      sortType !== SortTypeEnum.Recommended
        ? `${sortType}-${sortOrder}`
        : SortTypeEnum.Recommended,
      getSortLabel(sortType, sortOrder),
    ])
  ),
};

export default function FilterSortButton() {
  const filterParams = useArtworkFilterState();

  const [sortType, setSortType] = filterParams.sortType;
  const [sortOrder, setSortOrder] = filterParams.sortOrder;

  const handleChange = useCallback(
    (values: string[]) => {
      const [newSortType, newSortOrder] = values[0].split('-') as [
        SortTypeEnum,
        SortOrderEnum
      ];

      setSortType(newSortType);
      setSortOrder(newSortOrder);

      GTM.explore.trackSortByChange(getSortLabel(newSortType, newSortOrder));
    },
    [setSortType, setSortOrder]
  );

  return (
    <MPDropdownSelect
      config={FILTER_CONFIG}
      selectionType="single"
      title="Sorting"
      values={
        sortType !== SortTypeEnum.Recommended && sortType !== undefined
          ? [`${sortType}-${sortOrder}`]
          : [SortTypeEnum.Recommended]
      }
      onChange={handleChange}
    />
  );
}
