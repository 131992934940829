import { ComponentType, FunctionComponent } from 'react';
import { useQueryLoaderHookType } from 'react-relay/relay-hooks/useQueryLoader';
import {
  ConcreteRequest,
  FetchPolicy,
  GraphQLTaggedNode,
  OperationType,
} from 'relay-runtime';

import usePollLoadQuery from 'hooks/graphqlLogic/usePollLoadQuery';
import useLoadQuery, { useLoadQueryHookType } from 'hooks/useLoadQuery';

type LoadQueryPropsV1<TQuery extends OperationType> = {
  fetchPolicy?: FetchPolicy | null | undefined;
  queryVariables?: TQuery['variables'];
};

// @deprecated
export type WithLoadQueryPropsV1<TQuery extends OperationType> = Record<
  string,
  unknown
> & {
  disposeFunction?: useQueryLoaderHookType<TQuery>[2];
  invalidate?: () => void;
  loadQuery?: useQueryLoaderHookType<TQuery>[1];
  queryRef?: useQueryLoaderHookType<TQuery>[0];
};

/**
 * withLoadQueryV1 is a HOC that wraps a component with a query loader hook.
 * @deprecated Use `withLoadQuery` instead
 */
export const withLoadQueryV1 = <
  TQuery extends OperationType,
  TProps extends Record<string, unknown>
>(
  Component: ComponentType<TProps & WithLoadQueryPropsV1<TQuery>>,
  queryType: GraphQLTaggedNode
) => {
  function Wrapper({
    queryVariables,
    fetchPolicy,
    ...otherProps
  }: TProps & WithLoadQueryPropsV1<TQuery> & LoadQueryPropsV1<TQuery>) {
    const [queryRef, loadQuery, disposeFunction, invalidate] =
      useLoadQuery<TQuery>(queryType, queryVariables, fetchPolicy);

    return queryRef ? (
      <Component
        {...(otherProps as TProps)}
        queryRef={queryRef}
        loadQuery={loadQuery}
        disposeFunction={disposeFunction}
        invalidate={invalidate}
      />
    ) : null;
  }
  Wrapper.displayName = `withLoadQueryV1(${
    Component.displayName ?? Component.name ?? 'AnonymousComponent'
  })`;

  return Wrapper;
};

export type WithLoadQueryProps<TQuery extends OperationType> = {
  _variables?: TQuery['variables'];
  disposeFunction?: useQueryLoaderHookType<TQuery>[2];
  invalidate?: () => void;
  loadQuery?: useQueryLoaderHookType<TQuery>[1];
  queryRef?: useQueryLoaderHookType<TQuery>[0];
};

type NonQueryProps<COMP> = {
  [K2 in keyof COMP]: COMP[K2] extends useLoadQueryHookType<OperationType>
    ? never
    : COMP[K2];
};

export default function withLoadQuery<C>(
  // TODO: Figure out how to get Class based components here as well
  Component: FunctionComponent<C>,
  queries: Record<
    keyof Pick<
      Parameters<FunctionComponent<C>>[0],
      keyof {
        [K in keyof Parameters<FunctionComponent<C>>[0] as Parameters<
          FunctionComponent<C>
        >[0][K] extends WithLoadQueryProps<OperationType>
          ? K
          : never]: Parameters<FunctionComponent<C>>[0][K];
      }
    >,
    { concreteRequest: ConcreteRequest; pollingInterval?: number }
  >
) {
  const Wrapper = function Wrapper(props: {
    [K in keyof C]: C[K] extends WithLoadQueryProps<OperationType>
      ? {
          variables: C[K]['_variables'];
          fetchPolicy?: FetchPolicy | null | undefined;
        }
      : C[K];
  }) {
    const queryProps: Record<string, WithLoadQueryProps<OperationType>> = {};
    Object.keys(queries).forEach((key) => {
      const [queryRef, loadQuery, disposeFunction, invalidate] = queries[key]
        .pollingInterval
        ? /* eslint-disable-next-line react-hooks/rules-of-hooks */
          usePollLoadQuery({
            intervalMs: queries[key].pollingInterval,
            queryType: queries[key].concreteRequest,
            queryVariables:
              props[key].variables ?? queries[key].variables ?? {},
          })
        : /* eslint-disable-next-line react-hooks/rules-of-hooks */
          useLoadQuery(
            queries[key].concreteRequest,
            props[key].variables ?? queries[key].variables ?? {},
            props[key].fetchPolicy ?? queries[key].fetchPolicy
          );
      queryProps[key] = {
        disposeFunction,
        invalidate,
        loadQuery,
        queryRef,
      };
    });

    const otherProps = Object.entries(props).reduce((acc, [key, value]) => {
      if (!queryProps[key]) acc[key] = value;
      return acc;
    }, {}) as NonQueryProps<C>;

    return !Object.values(queryProps)
      .map((query) => !!query.queryRef)
      .includes(false) ? (
      <Component {...queryProps} {...otherProps} />
    ) : null;
  };
  Wrapper.displayName = `withLoadQuery(${
    Component.displayName ?? Component.name ?? 'AnonymousComponent'
  })`;

  return Wrapper;
}
