/**
 * @generated SignedSource<<eb94cda0094e50064f8abdc8fda12b56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SessionCreatorDetailsMutation$variables = {};
export type SessionCreatorDetailsMutation$data = {
  readonly session: {
    readonly account: {
      readonly id: string;
      readonly creatorDetails: {
        readonly creationAddress: {
          readonly id: string;
          readonly address: string;
          readonly isCustodial: boolean | null;
        } | null;
        readonly isFirstUpload: boolean;
        readonly canDefer: boolean;
        readonly collabsEnabled: boolean;
        readonly hasMinted: number;
        readonly isSandboxed: boolean;
      } | null;
    } | null;
  } | null;
};
export type SessionCreatorDetailsMutation = {
  variables: SessionCreatorDetailsMutation$variables;
  response: SessionCreatorDetailsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SessionMutation",
    "kind": "LinkedField",
    "name": "session",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountSessionType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorDetailsType",
            "kind": "LinkedField",
            "name": "creatorDetails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAddressType",
                "kind": "LinkedField",
                "name": "creationAddress",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCustodial",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFirstUpload",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canDefer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collabsEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasMinted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSandboxed",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionCreatorDetailsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionCreatorDetailsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a5516664552962be05ad4d1971b7876e",
    "id": null,
    "metadata": {},
    "name": "SessionCreatorDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation SessionCreatorDetailsMutation {\n  session {\n    account {\n      id\n      creatorDetails {\n        creationAddress {\n          id\n          address\n          isCustodial\n        }\n        isFirstUpload\n        canDefer\n        collabsEnabled\n        hasMinted\n        isSandboxed\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d710c1510e0c6555e9446f5ec9d26133";

export default node;
