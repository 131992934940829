import { useCallback } from 'react';
import { noop } from 'lodash';

import { joinClasses } from '@mp-frontend/core-utils';

import ProductPageCard, { ProductPageCardProps } from 'pages/product/card';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';

import * as baseStyles from 'css/components/cards/BaseCard.module.css';
import * as styles from 'css/components/cards/HeroArtworkCard.module.css';

export default function HeroArtworkCard({
  nft,
  nftMetadata,
}: Omit<ProductPageCardProps, 'invalidate'>) {
  const track = useHomepageGTM();

  const handleClick = useCallback(
    () =>
      track.clickCard(
        CardType.HeroArtworkCard,
        nft.listing.pk,
        nft.listing.productSlug
      ),
    [track, nft.listing.pk, nft.listing.productSlug]
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={joinClasses(baseStyles.heroCard, styles.container)}
      onClick={handleClick}
    >
      <ProductPageCard nft={nft} nftMetadata={nftMetadata} invalidate={noop} />
    </div>
  );
}
