.container {
  gap: 14px;
  display: flex;
  flex-direction: column;
}

.content {
  white-space: pre-wrap;
  word-wrap: break-word;
  -webkit-line-clamp: var(--line-clamp, 3);
  /* stylelint-disable-next-line property-no-unknown */
  line-clamp: var(--line-clamp, 3);
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  transition: max-height 0.3s ease;
}

.content.unclamp {
  -webkit-line-clamp: unset;
  /* stylelint-disable-next-line property-no-unknown */
  line-clamp: unset;
}

.viewMore {
  cursor: pointer;
}
