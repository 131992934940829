/**
 * @generated SignedSource<<ec3bf317415c56739650b7ec2131034b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HomepageEditorialTilesQuery$variables = {};
export type HomepageEditorialTilesQuery$data = {
  readonly nftExhibitsTiles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly backgroundImageUrl: string | null;
        readonly description: string;
        readonly id: string;
        readonly order: number;
        readonly pk: string;
        readonly title: string | null;
        readonly type: string;
        readonly viewUrl: string | null;
      } | null;
    } | null>;
  } | null;
};
export type HomepageEditorialTilesQuery = {
  variables: HomepageEditorialTilesQuery$variables;
  response: HomepageEditorialTilesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 12
      }
    ],
    "concreteType": "NFTExibitTileTypeConnection",
    "kind": "LinkedField",
    "name": "nftExhibitsTiles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NFTExibitTileTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTExibitTileType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundImageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pk",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "nftExhibitsTiles(first:12)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomepageEditorialTilesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomepageEditorialTilesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "09bc48404ba6e31a89e4148d4718d201",
    "id": null,
    "metadata": {},
    "name": "HomepageEditorialTilesQuery",
    "operationKind": "query",
    "text": "query HomepageEditorialTilesQuery {\n  nftExhibitsTiles(first: 12) {\n    edges {\n      node {\n        backgroundImageUrl\n        description\n        id\n        order\n        pk\n        title\n        type\n        viewUrl\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "38a54499fac756a8094875057e564043";

export default node;
