import { useCallback, useState } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import {
  MPActionButton,
  MPActionButtonProps,
} from '@mp-frontend/core-components';
import { CheckIcon } from '@mp-frontend/core-components/icons';

import GetFollowersType, {
  FollowersQuery,
} from 'graphql/__generated__/FollowersQuery.graphql';
import { IsFollowingEnum } from 'types/__generated__/graphql';

import LoginRequiredButton from 'components/LoginRequiredButton';
import useFollowMutation, {
  useFollowMutationV2,
} from 'hooks/useFollowMutation';
import useLoadQuery from 'hooks/useLoadQuery';
import useSession from 'hooks/useSession';

function useFollowUser({
  username,
  userId,
  queryRef,
}: {
  queryRef: PreloadedQuery<FollowersQuery>;
  userId: number;
  username: string;
}) {
  const { isFollowing: followingState } = usePreloadedQuery<FollowersQuery>(
    GetFollowersType,
    queryRef
  ).followers;
  const [follow, isLoading] = useFollowMutation();
  const [isFollowing, setIsFollowing] = useState<boolean>(
    followingState === IsFollowingEnum.True
  );

  const isNotAllowed = followingState === IsFollowingEnum.NotAllowed;

  const toggleFollow = useCallback(() => {
    if (isLoading || isNotAllowed) return;

    follow({
      onCompleted: (result) => {
        if (result.success) {
          setIsFollowing((prev) => !prev);
        }
      },
      variables: {
        follow: !isFollowing,
        globalSlug: username,
        userId,
      },
    });
  }, [userId, isNotAllowed, username, follow, isFollowing, isLoading]);

  return [isFollowing, toggleFollow, isNotAllowed] as const;
}

type FollowButtonVariant =
  | 'secondary-black'
  | 'secondary-black-inverted'
  | 'tertiary'
  | 'tertiary-inverted';

const INVERTED_VARIANT: Record<FollowButtonVariant, FollowButtonVariant> = {
  'secondary-black': 'secondary-black-inverted',
  'secondary-black-inverted': 'secondary-black',
  tertiary: 'tertiary-inverted',
  'tertiary-inverted': 'tertiary',
};

function FollowButtonInner({
  username,
  userId,
  queryRef,
  className,
  variant = 'tertiary',
  ...otherProps
}: {
  queryRef: PreloadedQuery<FollowersQuery>;
  userId: number;
  username: string;
  className?: string;
  variant?: FollowButtonVariant;
}) {
  const [isFollowing, toggleFollow] = useFollowUser({
    queryRef,
    userId,
    username,
  });
  const invertedVariant = INVERTED_VARIANT[variant] || variant;

  return (
    <LoginRequiredButton
      variant={isFollowing ? invertedVariant : variant}
      onClick={toggleFollow}
      className={className}
      {...otherProps}
    >
      {isFollowing ? 'Following' : 'Follow'}
    </LoginRequiredButton>
  );
}

function FollowButtonWithLoadQuery({
  username,
  userId,
  className,
  ...otherProps
}) {
  const [followQueryRef] = useLoadQuery<FollowersQuery>(GetFollowersType, {
    global_slug: username,
  });
  return (
    !!followQueryRef && (
      <FollowButtonInner
        queryRef={followQueryRef}
        userId={userId}
        username={username}
        className={className}
        {...otherProps}
      />
    )
  );
}

/**
 * As part of the profile refresh project this should replace the Deprecated version once live.
 */
interface FollowButtonProps extends Omit<MPActionButtonProps, 'variant'> {
  userId: number;
  username: string;
  queryRef?: PreloadedQuery<FollowersQuery>;
  variant?: FollowButtonVariant;
}

export function FollowButton({
  userId,
  username,
  queryRef,
  className,
  ...otherProps
}: FollowButtonProps) {
  const session = useSession();
  if (!!userId && parseInt(session.account?.pk, 10) === userId) return null;
  return queryRef ? (
    <FollowButtonInner
      queryRef={queryRef}
      userId={userId}
      username={username}
      className={className}
      {...otherProps}
    />
  ) : (
    <FollowButtonWithLoadQuery
      userId={userId}
      username={username}
      className={className}
      {...otherProps}
    />
  );
}

interface DeprecatedFollowButtonProps extends MPActionButtonProps {
  isFollowing: IsFollowingEnum | `${IsFollowingEnum}`;
  username: string;
}

export default function DepcreatedFollowButton({
  username,
  isFollowing,
  ...passedProps
}: DeprecatedFollowButtonProps) {
  const [follow, isTogglingFollow] = useFollowMutationV2();

  const toggleFollow = useCallback(() => {
    if (isTogglingFollow || isFollowing === IsFollowingEnum.NotAllowed) return;
    follow({
      variables: {
        follow: !(isFollowing === IsFollowingEnum.True),
        globalSlug: username,
      },
    });
  }, [username, follow, isFollowing, isTogglingFollow]);

  return isFollowing === IsFollowingEnum.NotAllowed ? null : (
    <MPActionButton
      onClick={toggleFollow}
      variant={isFollowing === IsFollowingEnum.True ? 'secondary' : 'primary'}
      {...passedProps}
    >
      <span>
        {isFollowing === IsFollowingEnum.True ? (
          <span className="flexCenter">
            <CheckIcon fontSize="inherit" />
            &nbsp;Following
          </span>
        ) : (
          'Follow'
        )}
      </span>
    </MPActionButton>
  );
}
