import { SyntheticEvent, useState } from 'react';

import {
  MPExpandableText,
  MPFonts,
  MPTabsSize,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import Tabs from 'components/Tabs';
import { useTrackingContext } from 'components/trackingContext';
import GTM from 'GTM';
import CSSMargin from 'types/enums/css/Margin';

import ProductCollapsibleContainer from './ProductCollapsibleContainer';
import ProductPreview from './productPreview';

import * as styles from 'css/pages/product/ProductDescription.module.css';

interface ProductDescriptionContentProps {
  description?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  shouldHaveMinHeight?: boolean;
  videoPreviewImageUrl?: string;
  videoUrl?: string;
}

function ProductDescriptionContent({
  description,
  shouldHaveMinHeight,
  videoPreviewImageUrl,
  videoUrl,
}: ProductDescriptionContentProps) {
  return (
    <div
      className={joinClasses(
        { [styles.productionDescriptionMinHeight]: !!shouldHaveMinHeight },
        styles.productDescription,
        MPFonts.paragraphNormal
      )}
    >
      {!!videoUrl && !!videoPreviewImageUrl && (
        <ProductPreview
          className={styles.productDescriptionVideo}
          primaryMedia={{
            hasVideo: true,
            highResUrl: videoPreviewImageUrl,
            id: videoUrl,
            lowResUrl: videoPreviewImageUrl,
            mediumResUrl: videoPreviewImageUrl,
            videoUrl,
          }}
        />
      )}

      <MPExpandableText content={description} lineClamp={3} />
    </div>
  );
}

enum TabEnum {
  Artwork = 'Artwork',
  CollectorReward = 'Collector Reward',
  Unlockable = 'Unlockable',
}

interface ProductDescriptionsProps {
  artwork: ProductDescriptionContentProps;
  collapsible?: boolean;
  collectorReward?: ProductDescriptionContentProps;
  unlockable?: ProductDescriptionContentProps;
}

export function ProductDescriptions({
  artwork,
  collapsible = false,
  collectorReward,
  unlockable,
}: ProductDescriptionsProps) {
  const tabs = [
    TabEnum.Artwork,
    unlockable?.description && TabEnum.Unlockable,
    collectorReward?.description && TabEnum.CollectorReward,
  ].filter(Boolean) as TabEnum[];
  const [currentTab, setCurrentTab] = useState<TabEnum>(TabEnum.Artwork);
  const { source, dropTitle } = useTrackingContext();

  const showTabs = tabs.length > 1;

  return (
    <ProductCollapsibleContainer
      title="Description"
      collapsed={collapsible}
      collapsible={collapsible}
      showIcon={collapsible}
      showTopBorder={collapsible}
    >
      {!!showTabs && (
        <div className={CSSMargin.BOTTOM[24]}>
          <Tabs
            size={MPTabsSize.Medium}
            currentTab={currentTab}
            tabs={tabs.map((tab) => ({
              label: tab,
              to: '#',
              value: tab,
            }))}
            onChange={(event: SyntheticEvent, value: string) => {
              setCurrentTab(value as TabEnum);
              GTM.pdp.trackClickEventDescriptionsType(value, source, dropTitle);
            }}
          />
        </div>
      )}

      {
        {
          [TabEnum.Artwork]: (
            <ProductDescriptionContent
              key="artwork"
              shouldHaveMinHeight={showTabs}
              {...artwork}
            />
          ),
          [TabEnum.CollectorReward]: (
            <ProductDescriptionContent
              key="collectorReward"
              shouldHaveMinHeight={showTabs}
              {...collectorReward}
            />
          ),
          [TabEnum.Unlockable]: (
            <ProductDescriptionContent
              key="unlockable"
              shouldHaveMinHeight={showTabs}
              {...unlockable}
            />
          ),
        }[currentTab]
      }
    </ProductCollapsibleContainer>
  );
}

interface ProductDescriptionProps extends ProductDescriptionContentProps {
  title: string;
  collapsible?: boolean;
}

export function ProductDescription({
  title,
  description,
  collapsible = true,
  videoUrl,
  videoPreviewImageUrl,
}: ProductDescriptionProps) {
  return description ? (
    <ProductCollapsibleContainer
      title={title}
      showIcon={false}
      collapsed={false}
      collapsible={collapsible}
    >
      <ProductDescriptionContent
        description={description}
        videoPreviewImageUrl={videoPreviewImageUrl}
        videoUrl={videoUrl}
      />
    </ProductCollapsibleContainer>
  ) : null;
}

export default ProductDescription;
