import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { MPActionButton } from '@mp-frontend/core-components';

import { ContractNameEnum } from 'types/__generated__/graphql';

import LoginRequiredButton from 'components/LoginRequiredButton';
import ROUTES from 'constants/Routes';
import ProductActionsContainer from 'pages/product/productActions/ProductActionsContainer';
import ProductNotLiveActions from 'pages/product/productActions/ProductNotLiveActions';
import {
  OpenEditionDropMetadataType,
  OpenEditionType,
} from 'types/graphql/OpenEdition';
import hasDatePassed, { hasDateBetween } from 'utils/hasDatePassed';
import { getNFTPresaleState } from 'utils/nftUtils';
import { getFirstMintedProductSlug } from 'utils/openEditionUtils';

import OpenEditionPurchaseDialog from './purchase';

interface OpenEditionActionsProps {
  allowFiat: boolean;
  dropMetadata: {
    drop: Pick<
      OpenEditionDropMetadataType['drop'],
      | 'artworksAvailable'
      | 'dropTitle'
      | 'dropPhase'
      | 'isEligibleForPresale'
      | 'pk'
      | 'presale'
      | 'subscribePhoneNumber'
    >;
  };
  dropsAt: OpenEditionDropMetadataType['dropsAt'];
  endsAt: OpenEditionDropMetadataType['endsAt'];
  invalidate: () => void;
  openEdition: Pick<
    OpenEditionType,
    | 'nftMetadata'
    | 'pk'
    | 'presalePriceInEther'
    | 'presalePriceInUsd'
    | 'priceInEther'
    | 'priceInUsd'
  >;
}

export default function OpenEditionActions({
  allowFiat,
  dropMetadata,
  dropsAt,
  endsAt,
  openEdition,
  invalidate,
}: OpenEditionActionsProps) {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const handleOpenDialog = useCallback(() => setShowDialog(true), []);
  const handleCloseDialog = useCallback(() => setShowDialog(false), []);
  const { showPresale, isPresaleActive, isPresaleEligible } =
    getNFTPresaleState(!!openEdition.presalePriceInUsd, dropMetadata);
  const canBuy =
    dropMetadata?.drop?.artworksAvailable && hasDateBetween(dropsAt, endsAt);
  const isSoldOut = hasDatePassed(endsAt);
  const firstEditionProductSlug = getFirstMintedProductSlug(openEdition);

  return (
    <>
      <ProductActionsContainer>
        {canBuy ? (
          <LoginRequiredButton
            variant="primary"
            fullWidth
            size="large"
            onClick={handleOpenDialog}
          >
            Purchase
          </LoginRequiredButton>
        ) : isSoldOut && firstEditionProductSlug ? (
          <Link to={ROUTES.NFT(firstEditionProductSlug)}>
            <MPActionButton variant="primary" fullWidth size="large">
              View the Artwork
            </MPActionButton>
          </Link>
        ) : (
          <ProductNotLiveActions
            dropMetadata={dropMetadata}
            dropsAt={dropsAt}
            isAuctionFinalizing={false}
            isPresaleActive={isPresaleActive}
            isPresaleEligible={isPresaleEligible}
            showPresale={showPresale}
            invalidate={invalidate}
            onPresalePurchaseClick={handleOpenDialog}
          />
        )}
      </ProductActionsContainer>

      {!!showDialog && (
        <OpenEditionPurchaseDialog
          allowFiat={allowFiat}
          contractQuery={{ variables: { name: ContractNameEnum.DepositFund } }}
          openEditionId={parseInt(openEdition.pk, 10)}
          isPresale={!!(isPresaleActive && isPresaleEligible)}
          priceInEth={
            isPresaleActive && isPresaleEligible
              ? openEdition.presalePriceInEther
              : openEdition.priceInEther
          }
          priceInUsd={
            isPresaleActive && isPresaleEligible
              ? openEdition.presalePriceInUsd
              : openEdition.priceInUsd
          }
          stripeQuery={{ variables: {} }}
          invalidate={invalidate}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}
