export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
  HexString: any;
  JSONString: any;
  Upload: any;
};

export type AcceptGlobalBidInCreditCardWithPersonalWalletArguments = {
  mediaBidId: Scalars['Int'];
  productId: Scalars['Int'];
};

/** For CC bid accept (not the 1st time) */
export type AcceptGlobalBidInCreditCardWithPersonalWalletMutation = {
  __typename?: 'AcceptGlobalBidInCreditCardWithPersonalWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type AcceptGlobalBidWithCustodialWalletArguments = {
  mediaBidId: Scalars['Int'];
  productId: Scalars['Int'];
};

export type AcceptGlobalBidWithCustodialWalletMutation = {
  __typename?: 'AcceptGlobalBidWithCustodialWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type AcceptGlobalBidWithPersonalWalletArguments = {
  fromAddress: Scalars['HexString'];
  mediaBidId: Scalars['Int'];
  nonce: Scalars['Int'];
  productId: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

/** For ETH bid accept or for CC bid accept of the 1st time (MetaMask approval) */
export type AcceptGlobalBidWithPersonalWalletMutation = {
  __typename?: 'AcceptGlobalBidWithPersonalWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type AcceptProductBidInCreditCardWithPersonalWalletArguments = {
  bidId: Scalars['Int'];
};

/** For ETH bid accept or for CC bid accept of the 1st time (MetaMask approval) */
export type AcceptProductBidInCreditCardWithPersonalWalletMutation = {
  __typename?: 'AcceptProductBidInCreditCardWithPersonalWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type AcceptProductBidWithCustodialWalletArguments = {
  bidId: Scalars['Int'];
};

export type AcceptProductBidWithCustodialWalletMutation = {
  __typename?: 'AcceptProductBidWithCustodialWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type AcceptProductBidWithPersonalWalletArguments = {
  bidId: Scalars['Int'];
  cancelledByCustomer: Scalars['Boolean'];
  fromAddress: Scalars['HexString'];
  nonce: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

/** For CC bid accept (not the 1st time) */
export type AcceptProductBidWithPersonalWalletMutation = {
  __typename?: 'AcceptProductBidWithPersonalWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type AccountAbstractType = GlobalIdConnectionNode & {
  __typename?: 'AccountAbstractType';
  accountType: UserAccountType;
  authentication?: Maybe<AuthenticationAbstractType>;
  biography?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  ethPayoutWallet?: Maybe<EthPayoutType>;
  firstName: Scalars['String'];
  followerCount: Scalars['Int'];
  fullLandingUrl?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isAnonAccount: Scalars['Boolean'];
  isDowngradedCreator: Scalars['Boolean'];
  landingUrl?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  marketplaceUrl?: Maybe<Scalars['String']>;
  payoutEthBalance?: Maybe<Scalars['Float']>;
  payoutUsdBalance?: Maybe<Scalars['Float']>;
  paypalPayoutWallet?: Maybe<PaypalPayoutType>;
  pk: Scalars['ID'];
  profileImageUrl: Scalars['String'];
  store?: Maybe<StoreTypeV3>;
  totalVolume?: Maybe<TotalVolumeType>;
  useraddress?: Maybe<UserAddressType>;
  username: Scalars['String'];
  wallets: Array<WalletAbstractType>;
};

/** Only returned via SessionMutation() */
export type AccountSessionType = {
  __typename?: 'AccountSessionType';
  accountType: UserAccountType;
  authentication?: Maybe<AuthenticationAbstractType>;
  biography?: Maybe<Scalars['String']>;
  contractApproval?: Maybe<Array<Maybe<ApprovalType>>>;
  creatorContracts?: Maybe<Array<Maybe<CreatorContractType>>>;
  creatorDetails?: Maybe<CreatorDetailsType>;
  didCompleteOnboard?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  ethPayoutWallet?: Maybe<EthPayoutType>;
  followerCount: Scalars['Int'];
  freeMintingInfo: FreeMintInfoType;
  fullLandingUrl?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAnonAccount: Scalars['Boolean'];
  isDowngradedCreator: Scalars['Boolean'];
  landingUrl?: Maybe<Scalars['String']>;
  marketplaceUrl?: Maybe<Scalars['String']>;
  numSessions?: Maybe<Scalars['Int']>;
  payoutEthBalance?: Maybe<Scalars['Float']>;
  payoutUsdBalance?: Maybe<Scalars['Float']>;
  paypalPayoutWallet?: Maybe<PaypalPayoutType>;
  phoneNumber?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  profileImageUrl: Scalars['String'];
  store?: Maybe<StoreTypeV3>;
  totalVolume?: Maybe<TotalVolumeType>;
  twoFactorEmailEnabled: Scalars['Boolean'];
  twoFactorSmsEnabled: Scalars['Boolean'];
  useraddress?: Maybe<UserAddressType>;
  username: Scalars['String'];
  wallets: Array<WalletAbstractType>;
};

/** An enumeration. */
export enum ActivityActionEnum {
  Bid = 'BID',
  Mint = 'MINT',
  Offer = 'OFFER',
  Sale = 'SALE',
  Transfer = 'TRANSFER'
}

export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActivityEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Activity` and its cursor. */
export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ActivityType>;
};

/** An enumeration. */
export enum ActivitySortTypeEnum {
  CreatedAt = 'CREATED_AT',
  Price = 'PRICE'
}

export type ActivityType = {
  __typename?: 'ActivityType';
  action?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  digitalMediaRelease?: Maybe<NftAbstractType>;
  fromUser?: Maybe<AccountAbstractType>;
  id?: Maybe<Scalars['String']>;
  isEthTransaction?: Maybe<Scalars['Boolean']>;
  priceEth?: Maybe<Scalars['Float']>;
  priceUsd?: Maybe<Scalars['Float']>;
  toUser?: Maybe<AccountAbstractType>;
  transactionUrl?: Maybe<Scalars['String']>;
};

export type AddCreditCardArguments = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tokenStr: Scalars['String'];
};

export type AddCreditCardMutation = {
  __typename?: 'AddCreditCardMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type AdditionalMediaType = GlobalIdConnectionNode & {
  __typename?: 'AdditionalMediaType';
  createdAt: Scalars['DateTime'];
  digitalMedia?: Maybe<NftMetadataAbstractType>;
  externalContentUrl?: Maybe<Scalars['String']>;
  externalThumbnailUrl?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  rank: Scalars['Int'];
  rawfileExtension: Scalars['String'];
  unlockable?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
};

export type AllBidsTypeV2 = GlobalIdConnectionNode & {
  __typename?: 'AllBidsTypeV2';
  bidAmountInEth?: Maybe<Scalars['Float']>;
  bidAmountInUsd?: Maybe<Scalars['Float']>;
  bidUserProfile?: Maybe<ProfileType>;
  createdSince?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isEthBid?: Maybe<Scalars['Boolean']>;
  pk?: Maybe<Scalars['ID']>;
};

/** For wallet and sale/global bid contracts approval */
export type ApprovalType = GlobalIdConnectionNode & {
  __typename?: 'ApprovalType';
  address: Scalars['String'];
  approvalContractAbidata?: Maybe<Scalars['String']>;
  approvalContractAddress?: Maybe<Scalars['HexString']>;
  approved: Scalars['Boolean'];
  canRevoke?: Maybe<Scalars['Boolean']>;
  deleted: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isObo?: Maybe<Scalars['Boolean']>;
  operatorContractAddress?: Maybe<Scalars['HexString']>;
  pk: Scalars['ID'];
};

export type ApprovalTypeConnection = {
  __typename?: 'ApprovalTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApprovalTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ApprovalType` and its cursor. */
export type ApprovalTypeEdge = {
  __typename?: 'ApprovalTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ApprovalType>;
};

export type ArtistSpotlightCtaType = GlobalIdConnectionNode & {
  __typename?: 'ArtistSpotlightCtaType';
  actionType?: Maybe<EmergingCtaActionTypeEnum>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  rank?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<EmergingCtaTypeEnum>;
  url?: Maybe<Scalars['String']>;
};

export type ArtistSpotlightType = GlobalIdConnectionNode & {
  __typename?: 'ArtistSpotlightType';
  artistDescription?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  ctas?: Maybe<Array<Maybe<ArtistSpotlightCtaType>>>;
  /** The ID of the object. */
  id: Scalars['ID'];
  nft?: Maybe<NftAbstractType>;
  pk: Scalars['ID'];
  user?: Maybe<AccountAbstractType>;
};

export type ArtistSpotlightTypeConnection = {
  __typename?: 'ArtistSpotlightTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ArtistSpotlightTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ArtistSpotlightType` and its cursor. */
export type ArtistSpotlightTypeEdge = {
  __typename?: 'ArtistSpotlightTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ArtistSpotlightType>;
};

export type ArtistType = {
  __typename?: 'ArtistType';
  availableCreationCount?: Maybe<Scalars['Int']>;
  fullLandingUrl?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hasMinted?: Maybe<Scalars['Boolean']>;
  hidePublicStorefront?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  identityVerified?: Maybe<Scalars['Boolean']>;
  isCollectionAuthor?: Maybe<Scalars['Boolean']>;
  isFeaturedStore?: Maybe<Scalars['Boolean']>;
  landingUrl?: Maybe<Scalars['String']>;
  marketplaceUrl?: Maybe<Scalars['String']>;
  profileImageUrl: Scalars['String'];
  slug: Scalars['String'];
  storeSlug?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

/** An enumeration. */
export enum AspectRatioEnum {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE'
}

export type AuctionType = {
  __typename?: 'AuctionType';
  auctionEnded?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  forceEndOnCountdown: Scalars['Boolean'];
  id: Scalars['ID'];
  info?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum AuctionTypeEnum {
  Product = 'PRODUCT',
  Ranked = 'RANKED'
}

export type AuthenticationAbstractType = {
  __typename?: 'AuthenticationAbstractType';
  invite?: Maybe<InviteCodeType>;
  twoFactorRecord?: Maybe<TwoFactorRecordType>;
};

export type AutoCompleteCollectionType = {
  __typename?: 'AutoCompleteCollectionType';
  collectionLogoUrl?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  collectionSlug?: Maybe<Scalars['String']>;
};

export type AutoCompleteCollectorType = {
  __typename?: 'AutoCompleteCollectorType';
  fullName?: Maybe<Scalars['String']>;
  storeSlug?: Maybe<Scalars['String']>;
  userProfileUrl?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AutoCompleteCreationType = {
  __typename?: 'AutoCompleteCreationType';
  productUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AutoCompleteCreatorType = {
  __typename?: 'AutoCompleteCreatorType';
  fullName?: Maybe<Scalars['String']>;
  storeSlug?: Maybe<Scalars['String']>;
  userProfileUrl?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AutoCompleteType = {
  __typename?: 'AutoCompleteType';
  allowedCollections?: Maybe<Scalars['Boolean']>;
  collections?: Maybe<Array<Maybe<AutoCompleteCollectionType>>>;
  collectors?: Maybe<Array<Maybe<AutoCompleteCollectorType>>>;
  creations?: Maybe<Array<Maybe<AutoCompleteCreationType>>>;
  creators?: Maybe<Array<Maybe<AutoCompleteCreatorType>>>;
};

/** The badge type of the product card */
export enum BadgeTypeEnum {
  NoBadge = 'NO_BADGE',
  Unlockable = 'UNLOCKABLE'
}

export type BasicAccountType = {
  __typename?: 'BasicAccountType';
  fullName?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type BeforePurchaseInEthArguments = {
  buyerAddress: Scalars['HexString'];
  emailAddress?: InputMaybe<Scalars['String']>;
  isPresale?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['Int'];
};

/**
 * Extend product lock and get data (if it's a custodial sale),
 * before the sale purchase. If it's succeed, MetaMask should pop up.
 */
export type BeforePurchaseInEthMutation = {
  __typename?: 'BeforePurchaseInETHMutation';
  response?: Maybe<SalePurchaseDepositDataType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum BidType {
  GlobalBid = 'GLOBAL_BID',
  Product = 'PRODUCT',
  RankedAuction = 'RANKED_AUCTION'
}

export type CancelGlobalBidInCreditCardArguments = {
  mediaBidId: Scalars['Int'];
};

export type CancelGlobalBidInCreditCardMutation = {
  __typename?: 'CancelGlobalBidInCreditCardMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CancelGlobalBidInEthArguments = {
  fromAddress: Scalars['HexString'];
  mediaBidId: Scalars['Int'];
  nonce: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

export type CancelGlobalBidInEthMutation = {
  __typename?: 'CancelGlobalBidInETHMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CancelProductBidInCreditCardArguments = {
  bidId: Scalars['Int'];
};

export type CancelProductBidInCreditCardMutation = {
  __typename?: 'CancelProductBidInCreditCardMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CancelProductBidInEthArguments = {
  bidId: Scalars['Int'];
  cancelledByCustomer: Scalars['Boolean'];
  fromAddress: Scalars['HexString'];
  nonce: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

export type CancelProductBidInEthMutation = {
  __typename?: 'CancelProductBidInETHMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum CategoryType {
  Collection = 'COLLECTION',
  Store = 'STORE'
}

export type ChangeFullNameAndEmailArguments = {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type ChangeFullNameAndEmailMutation = {
  __typename?: 'ChangeFullNameAndEmailMutation';
  account?: Maybe<AccountAbstractType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ChangePasswordArguments = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type ChangePasswordMutation = {
  __typename?: 'ChangePasswordMutation';
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type ChangePrimaryWalletArguments = {
  address: Scalars['HexString'];
};

export type ChangePrimaryWalletMutation = {
  __typename?: 'ChangePrimaryWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CollaboratorType = GlobalIdConnectionNode & {
  __typename?: 'CollaboratorType';
  hiddenToBuyers: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  percentageOwnership: Scalars['Int'];
  pk: Scalars['ID'];
  royaltyOwnership?: Maybe<Scalars['Int']>;
  status: DigitalMediaCollabStatus;
  user?: Maybe<AccountAbstractType>;
};

/** Represents a Collection of NFTs. */
export type CollectionAbstractType = GlobalIdConnectionNode & {
  __typename?: 'CollectionAbstractType';
  author?: Maybe<AccountAbstractType>;
  collectionBannerUrl?: Maybe<Scalars['String']>;
  collectionLogoUrl?: Maybe<Scalars['String']>;
  collectionName: Scalars['String'];
  collectionType: ExternalCollectionType;
  contract?: Maybe<NftEthereumContractType>;
  createdAt: Scalars['DateTime'];
  currentPrintIndex: Scalars['Int'];
  delayedReveal: Scalars['Boolean'];
  description: Scalars['String'];
  drop?: Maybe<DropAbstractType>;
  etherscanUrl?: Maybe<Scalars['String']>;
  floorPrice?: Maybe<NftFloorPriceNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  nfts?: Maybe<NftAbstractTypeConnection>;
  numOfListedNfts?: Maybe<Scalars['Int']>;
  numOfOwners?: Maybe<Scalars['Int']>;
  openseaUrl?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  slug: Scalars['String'];
  soldCount?: Maybe<Scalars['Int']>;
  tokenName: Scalars['String'];
  totalItems?: Maybe<Scalars['Int']>;
  totalSupply?: Maybe<Scalars['Int']>;
  totalVolume?: Maybe<NftTotalVolumeNode>;
  traits?: Maybe<Array<Maybe<NftTraitsType>>>;
  updatedAt: Scalars['DateTime'];
  whitelisted: Scalars['Boolean'];
};


/** Represents a Collection of NFTs. */
export type CollectionAbstractTypeNftsArgs = {
  activeMedia?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  authorSlug?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categorySlug?: InputMaybe<Scalars['String']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  collectorSlug?: InputMaybe<Scalars['String']>;
  digitalMediaId?: InputMaybe<Scalars['Float']>;
  dropFamilySlug?: InputMaybe<Scalars['String']>;
  filterPublished?: InputMaybe<Scalars['Boolean']>;
  finderSearchCursor?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isLiveOnChain?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  likesUserBySlug?: InputMaybe<Scalars['String']>;
  mediaBurned?: InputMaybe<Scalars['Boolean']>;
  nftId?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  printEdition?: InputMaybe<Scalars['String']>;
  productSlug?: InputMaybe<Scalars['String']>;
  relatedImageLimit?: InputMaybe<Scalars['Float']>;
  textForSearch?: InputMaybe<Scalars['String']>;
  textSearchLimit?: InputMaybe<Scalars['Float']>;
};

export type CollectionAbstractTypeConnection = {
  __typename?: 'CollectionAbstractTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionAbstractTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollectionAbstractType` and its cursor. */
export type CollectionAbstractTypeEdge = {
  __typename?: 'CollectionAbstractTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionAbstractType>;
};

/**
 * Represents an CollectionDrop, which is assumed to be a 1-to-1 relationship with DropFamily.
 * This can be accessed from `NftDropType.collection`.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type CollectionDropType = GlobalIdConnectionNode & {
  __typename?: 'CollectionDropType';
  canAutoMint?: Maybe<Scalars['Boolean']>;
  collection?: Maybe<CollectionAbstractType>;
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  openEditionFamily?: Maybe<OpenEditionFamilyMetadataType>;
  openEditionPlaceholder?: Maybe<NftOpenEditionListingType>;
  pk: Scalars['ID'];
};

export type CollectionDropTypeConnection = {
  __typename?: 'CollectionDropTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionDropTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollectionDropType` and its cursor. */
export type CollectionDropTypeEdge = {
  __typename?: 'CollectionDropTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionDropType>;
};

export type CollectionInsightsType = {
  __typename?: 'CollectionInsightsType';
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type CollectionOwner = {
  __typename?: 'CollectionOwner';
  fullLandingUrl?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isFollowed?: Maybe<Scalars['Boolean']>;
  landingUrl?: Maybe<Scalars['String']>;
  storeLogoUrl?: Maybe<Scalars['String']>;
  storeSlug?: Maybe<Scalars['String']>;
  totalResults?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<CollectionOwnerType>>>;
};

export type CollectionOwnerType = {
  __typename?: 'CollectionOwnerType';
  fullLandingUrl?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isFollowed?: Maybe<Scalars['Boolean']>;
  landingUrl?: Maybe<Scalars['String']>;
  storeLogoUrl?: Maybe<Scalars['String']>;
  storeSlug?: Maybe<Scalars['String']>;
};

export type CollectionTraitType = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CollectionType = {
  __typename?: 'CollectionType';
  author?: Maybe<ArtistType>;
  collectionLogoUrl?: Maybe<Scalars['String']>;
  collectionName: Scalars['String'];
  collectionUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  totalSupply?: Maybe<Scalars['Int']>;
};

export type CollectionTypeV2 = GlobalIdConnectionNode & {
  __typename?: 'CollectionTypeV2';
  author?: Maybe<AccountSessionType>;
  collectionBannerS3Filename?: Maybe<Scalars['String']>;
  collectionBannerUrl?: Maybe<Scalars['String']>;
  collectionDrop: CollectionDropTypeConnection;
  collectionLogoS3Filename?: Maybe<Scalars['String']>;
  collectionLogoUrl?: Maybe<Scalars['String']>;
  collectionName: Scalars['String'];
  collectionType: ExternalCollectionType;
  contract?: Maybe<NftEthereumContractType>;
  createdAt: Scalars['DateTime'];
  currentPrintIndex: Scalars['Int'];
  defaultTokenDescription?: Maybe<Scalars['String']>;
  defaultTokenTitle?: Maybe<Scalars['String']>;
  delayedReveal: Scalars['Boolean'];
  description: Scalars['String'];
  digitalmediaSet: NftMetadataAbstractTypeConnection;
  dropfamilySet: DropAbstractTypeConnection;
  floorPrice?: Maybe<FloorPriceNode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  numOfOwners?: Maybe<Scalars['Int']>;
  ownerAddress?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  showOwnerStore: Scalars['Boolean'];
  slug: Scalars['String'];
  tokenAuthor?: Maybe<AccountSessionType>;
  tokenName: Scalars['String'];
  totalItems?: Maybe<Scalars['Int']>;
  totalSupply?: Maybe<Scalars['Int']>;
  totalVolume?: Maybe<TotalVolumeNode>;
  traitTypes: Array<TraitTypeType>;
  updatedAt: Scalars['DateTime'];
  whitelisted: Scalars['Boolean'];
};


export type CollectionTypeV2CollectionDropArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CollectionTypeV2DigitalmediaSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CollectionTypeV2DropfamilySetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CollectionTypeV2Connection = {
  __typename?: 'CollectionTypeV2Connection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionTypeV2Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollectionTypeV2` and its cursor. */
export type CollectionTypeV2Edge = {
  __typename?: 'CollectionTypeV2Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CollectionTypeV2>;
};

export type CollectorRewardType = GlobalIdConnectionNode & {
  __typename?: 'CollectorRewardType';
  description?: Maybe<Scalars['String']>;
  descriptionLink?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['String'];
};

export type CommentNftArguments = {
  body: Scalars['String'];
  nftId: Scalars['Int'];
  nftMetadataId: Scalars['Int'];
  parentId?: InputMaybe<Scalars['Int']>;
  replyToUser?: InputMaybe<Scalars['Int']>;
};

/** Create a comment on an NFT. */
export type CommentNftMutation = {
  __typename?: 'CommentNFTMutation';
  comment?: Maybe<NftCommentType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum ContractNameEnum {
  ApprovedCreatorRegistry = 'ApprovedCreatorRegistry',
  ChaosMinter = 'ChaosMinter',
  DepositFund = 'DepositFund',
  DigitalMediaCore = 'DigitalMediaCore',
  DigitalMediaCoreV3 = 'DigitalMediaCoreV3',
  DigitalMediaSaleCore = 'DigitalMediaSaleCore',
  DropSaleCore = 'DropSaleCore',
  EthereumDidRegistry = 'EthereumDIDRegistry',
  GasCredit = 'GasCredit',
  GlobalDigitalMediaBidCore = 'GlobalDigitalMediaBidCore',
  InterimIdentityCore = 'InterimIdentityCore',
  PaymentsBuffer = 'PaymentsBuffer',
  RankedAuctionCore = 'RankedAuctionCore',
  RoyaltyRegistry = 'RoyaltyRegistry',
  SafeBox = 'SafeBox',
  TransferCore = 'TransferCore',
  VaultCore = 'VaultCore'
}

export type CreateAccountMutation = {
  __typename?: 'CreateAccountMutation';
  redirectUrl?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

export type CreateApprovalArguments = {
  approvalContractAddress: Scalars['String'];
  operatorContractAddress: Scalars['String'];
  userAddress: Scalars['String'];
};

export type CreateApprovalMutation = {
  __typename?: 'CreateApprovalMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateApprovalWithTransactionArguments = {
  approvalContractAddress: Scalars['String'];
  nonce: Scalars['Int'];
  operatorContractAddress: Scalars['String'];
  transactionId: Scalars['String'];
  userAddress: Scalars['String'];
};

/** For sale contract approval */
export type CreateApprovalWithTransactionMutation = {
  __typename?: 'CreateApprovalWithTransactionMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCategoryInput = {
  categoryName: Scalars['String'];
  categoryType: CategoryType;
  description?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type CreateGeneralBidOnNftInCreditCardArguments = {
  bidAmountInCents: Scalars['BigInt'];
  bidType: BidType;
  nftId: Scalars['Int'];
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  rememberCard: Scalars['Boolean'];
};

export type CreateGeneralBidOnNftInCreditCardMutation = {
  __typename?: 'CreateGeneralBidOnNFTInCreditCardMutation';
  intentId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateGlobalBidInCreditCardArguments = {
  bidAmountInCents: Scalars['BigInt'];
  digitalMediaId: Scalars['Int'];
  digitalMediaReleaseId: Scalars['Int'];
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  rememberCard: Scalars['Boolean'];
};

export type CreateGlobalBidInCreditCardMutation = {
  __typename?: 'CreateGlobalBidInCreditCardMutation';
  intentId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateGlobalBidInEthArguments = {
  bidAmountInWei: Scalars['BigInt'];
  bidderAddress: Scalars['HexString'];
  digitalMediaId: Scalars['Int'];
  digitalMediaReleaseId: Scalars['Int'];
  email: Scalars['String'];
  nonce: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

export type CreateGlobalBidInEthMutation = {
  __typename?: 'CreateGlobalBidInETHMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePayoutCoinbaseArguments = {
  accessToken: Scalars['String'];
  error?: InputMaybe<Scalars['String']>;
  errorDescription?: InputMaybe<Scalars['String']>;
  refreshToken: Scalars['String'];
};

/** Mutation to create a Coinbase wallet ETH payout */
export type CreatePayoutCoinbaseMutation = {
  __typename?: 'CreatePayoutCoinbaseMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePayoutPaypalArguments = {
  code: Scalars['String'];
  error?: InputMaybe<Scalars['String']>;
  errorDescription?: InputMaybe<Scalars['String']>;
};

/** Mutation to create a PayPal payout */
export type CreatePayoutPaypalMutation = {
  __typename?: 'CreatePayoutPaypalMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePayoutWalletArguments = {
  signature: Scalars['String'];
  walletAddress: Scalars['HexString'];
};

/** Mutation to create a MetaMask wallet ETH payout */
export type CreatePayoutWalletMutation = {
  __typename?: 'CreatePayoutWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateProductBidInCreditCardArguments = {
  bidAmountInCents: Scalars['BigInt'];
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  productSlug: Scalars['String'];
  rememberCard: Scalars['Boolean'];
  storeSlug: Scalars['String'];
};

export type CreateProductBidInCreditCardMutation = {
  __typename?: 'CreateProductBidInCreditCardMutation';
  bidId?: Maybe<Scalars['Int']>;
  intentId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateProductBidInEthArguments = {
  bidAmountInWei: Scalars['BigInt'];
  bidderAddress: Scalars['HexString'];
  email: Scalars['String'];
  nonce: Scalars['Int'];
  productId: Scalars['String'];
  transactionId: Scalars['HexString'];
};

export type CreateProductBidInEthMutation = {
  __typename?: 'CreateProductBidInETHMutation';
  bidId?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateRankedBidInCreditCardArguments = {
  bidAmountInCents: Scalars['BigInt'];
  digitalMediaReleaseId: Scalars['Int'];
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  rememberCard: Scalars['Boolean'];
};

export type CreateRankedBidInCreditCardMutation = {
  __typename?: 'CreateRankedBidInCreditCardMutation';
  intentId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateRankedBidInEthArguments = {
  bidAmountInWei: Scalars['BigInt'];
  bidderAddress: Scalars['HexString'];
  digitalMediaReleaseId: Scalars['Int'];
  email: Scalars['String'];
  nonce: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

export type CreateRankedBidInEthMutation = {
  __typename?: 'CreateRankedBidInETHMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateReservePriceArguments = {
  auctionDate?: InputMaybe<Scalars['String']>;
  availableForSale: Scalars['Boolean'];
  productId: Scalars['Int'];
  reserveCurrency: Scalars['String'];
  reservePrice: Scalars['Float'];
};

/**
 * For Personal Wallet ETH Sale Create
 * Refer to v1 ProductSettings
 */
export type CreateReservePriceMutation = {
  __typename?: 'CreateReservePriceMutation';
  listing?: Maybe<NftListingAbstractType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateSaleWithCustodialWalletArguments = {
  auctionDate?: InputMaybe<Scalars['String']>;
  availableForSale: Scalars['Boolean'];
  productId: Scalars['Int'];
  saleId?: InputMaybe<Scalars['Int']>;
  salePrice: Scalars['Float'];
};

/**
 * For Custodial wallet ETH/CC Sale Create
 * Refer to v1 ProductSettings
 */
export type CreateSaleWithCustodialWalletMutation = {
  __typename?: 'CreateSaleWithCustodialWalletMutation';
  listing?: Maybe<NftListingAbstractType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateSaleWithPersonalWalletArguments = {
  fromAddress: Scalars['HexString'];
  nonce: Scalars['String'];
  price: Scalars['Float'];
  releaseId: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

/**
 * For Personal Wallet ETH Sale Create
 * Refer to v1 PendingSaleCreate
 */
export type CreateSaleWithPersonalWalletMutation = {
  __typename?: 'CreateSaleWithPersonalWalletMutation';
  sale?: Maybe<NftSaleType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Create category for a user's store. */
export type CreateStoreCategory = {
  __typename?: 'CreateStoreCategory';
  categories?: Maybe<ProfileCategoryListType>;
  category?: Maybe<StoreCategoryType>;
  errors?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateWalletNameArguments = {
  address: Scalars['HexString'];
  name: Scalars['String'];
};

export type CreateWalletNameMutation = {
  __typename?: 'CreateWalletNameMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatorContractType = {
  __typename?: 'CreatorContractType';
  abidata: Scalars['String'];
  address: Scalars['String'];
  approvedCreatorRegistryAbidata: Scalars['String'];
  approvedCreatorRegistryAddress: Scalars['String'];
  digitalMediaSaleCoreAddress: Scalars['String'];
  dropSaleCoreAddress: Scalars['String'];
  hasApprovedCreatorRegistryApproval: Scalars['Boolean'];
  hasManifoldAdminApproval: Scalars['Boolean'];
  hasOboApprovalForDigitalMediaSaleCore: Scalars['Boolean'];
  hasOboApprovalForDropSaleCore: Scalars['Boolean'];
  hasTransferCoreApproval: Scalars['Boolean'];
  hasTransientLabsAdminApproval: Scalars['Boolean'];
  hasVaultCoreApproval: Scalars['Boolean'];
  isManifold: Scalars['Boolean'];
  isTransientLabs: Scalars['Boolean'];
  manifoldAdminAddress: Scalars['String'];
  transferCoreAddress: Scalars['String'];
  vaultCoreAddress: Scalars['String'];
};

/** Only referenced by SessionMutation() -> AccountSessionType */
export type CreatorDetailsType = {
  __typename?: 'CreatorDetailsType';
  canDefer: Scalars['Boolean'];
  collabsEnabled: Scalars['Boolean'];
  creationAddress?: Maybe<UserAddressType>;
  hasMinted: Scalars['Int'];
  isFirstUpload: Scalars['Boolean'];
  isSandboxed: Scalars['Boolean'];
};

/** An enumeration. */
export enum CurrencyModeEnum {
  Eth = 'ETH',
  Usd = 'USD'
}

export type DateTimeRangeType = {
  max?: InputMaybe<Scalars['Date']>;
  min?: InputMaybe<Scalars['Date']>;
};

export type DeferGasRequestArguments = {
  digitalMediaId?: InputMaybe<Scalars['Int']>;
  objectAction: Scalars['Int'];
  objectId?: InputMaybe<Scalars['Int']>;
  objectType: Scalars['Int'];
};

export type DeferGasRequestMutation = {
  __typename?: 'DeferGasRequestMutation';
  success?: Maybe<Scalars['Boolean']>;
  totalGasInEth?: Maybe<Scalars['Float']>;
  totalGasInUsd?: Maybe<Scalars['Float']>;
  totalGasInWei?: Maybe<Scalars['BigInt']>;
};

export type DeleteCommentNftArguments = {
  commentId: Scalars['Int'];
};

/** Delete comment on the NFT. */
export type DeleteCommentNftMutation = {
  __typename?: 'DeleteCommentNFTMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteCreditCardArguments = {
  cardId: Scalars['String'];
};

export type DeleteCreditCardMutation = {
  __typename?: 'DeleteCreditCardMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteDraftNftArguments = {
  nftMetadataId: Scalars['Int'];
};

/** Delete the draft of the DigitalMedia and DigitalMediaRelease that not onchain yet */
export type DeleteDraftNftMutation = {
  __typename?: 'DeleteDraftNFTMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** Mutation to delete a Paypal payout */
export type DeletePayoutPaypalMutation = {
  __typename?: 'DeletePayoutPaypalMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** Mutation to delete a MetaMask/Coinbase wallet ETH payout */
export type DeletePayoutWalletMutation = {
  __typename?: 'DeletePayoutWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** Delete store category for a user store */
export type DeleteStoreCategory = {
  __typename?: 'DeleteStoreCategory';
  categories?: Maybe<ProfileCategoryListType>;
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteStoreCategoryInput = {
  id: Scalars['ID'];
};

export type DelistCustodialSaleArguments = {
  productId: Scalars['Int'];
};

/**
 * For delist sale or reserve price
 * Refer to v1 ProductSettings
 */
export type DelistSaleOrReservePriceMutation = {
  __typename?: 'DelistSaleOrReservePriceMutation';
  listing?: Maybe<NftListingAbstractType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DelistSaleWithPersonalWalletArguments = {
  saleId: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

export type DelistSaleWithPersonalWalletMutation = {
  __typename?: 'DelistSaleWithPersonalWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum DigitalMediaCollabStatus {
  /** AwaitingApproval */
  A_1 = 'A_1',
  /** Approved */
  A_2 = 'A_2',
  /** Rejected */
  A_3 = 'A_3'
}

/** An enumeration. */
export enum DigitalMediaFilterOrderBy {
  Collection = 'COLLECTION',
  Created = 'CREATED',
  CreatedDesc = 'CREATED_DESC',
  CustodialPrice = 'CUSTODIAL_PRICE',
  Id = 'ID',
  LikeCreated = 'LIKE_CREATED',
  SalePrice = 'SALE_PRICE',
  Uploaded = 'UPLOADED'
}

export type DigitalMediaMetadataType = {
  __typename?: 'DigitalMediaMetadataType';
  filesize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  width?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum DigitalMediaProcessedEnum {
  Failed = 'FAILED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Published = 'PUBLISHED',
  Unprocessed = 'UNPROCESSED'
}

export type DigitalMediaReleaseNodeType = GlobalIdConnectionNode & {
  __typename?: 'DigitalMediaReleaseNodeType';
  burnedAt?: Maybe<Scalars['DateTime']>;
  contract?: Maybe<NftEthereumContractType>;
  createdAt: Scalars['DateTime'];
  currentOwnerAddress?: Maybe<Scalars['String']>;
  digitalMedia?: Maybe<DigitalMediaTypeV2>;
  digitalmediasaleSet: DigitalMediaSaleTypeV2Connection;
  digitalmediasendSet: NftSendTypeConnection;
  emergingartistSet: ArtistSpotlightTypeConnection;
  ethTx?: Maybe<TransactionAbstractType>;
  exhibitvendorSet: NftExhibitVendorTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
  isCustodialOwner: Scalars['Boolean'];
  isLive: Scalars['Boolean'];
  isOeReserved: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  liveProduct?: Maybe<ProductNodeType>;
  liveSale?: Maybe<DigitalMediaSaleTypeV2>;
  onchainCreatedAt?: Maybe<Scalars['DateTime']>;
  onchainId?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  printEdition: Scalars['Int'];
  productSet: NftListingAbstractTypeConnection;
  productpurchasehistorySet: ProductPurchaseHistoryTypeConnection;
  releasecommentSet: NftCommentTypeConnection;
  shareCount: Scalars['Int'];
  staticPreviewUrl?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  unlockable?: Maybe<NftUnlockable>;
  viewCount: Scalars['Int'];
};


export type DigitalMediaReleaseNodeTypeDigitalmediasaleSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DigitalMediaReleaseNodeTypeDigitalmediasendSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DigitalMediaReleaseNodeTypeEmergingartistSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DigitalMediaReleaseNodeTypeExhibitvendorSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DigitalMediaReleaseNodeTypeProductSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DigitalMediaReleaseNodeTypeProductpurchasehistorySetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type DigitalMediaReleaseNodeTypeReleasecommentSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type DigitalMediaReleaseNodeTypeConnection = {
  __typename?: 'DigitalMediaReleaseNodeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DigitalMediaReleaseNodeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DigitalMediaReleaseNodeType` and its cursor. */
export type DigitalMediaReleaseNodeTypeEdge = {
  __typename?: 'DigitalMediaReleaseNodeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DigitalMediaReleaseNodeType>;
};

/** An enumeration. */
export enum DigitalMediaSalePublicType {
  /** PUBLIC_SALE */
  A_1 = 'A_1',
  /** PRESALE */
  A_2 = 'A_2'
}

/** An enumeration. */
export enum DigitalMediaSaleType {
  /** FIXED_PRICE_SALE */
  A_1 = 'A_1'
}

export type DigitalMediaSaleTypeV2 = GlobalIdConnectionNode & {
  __typename?: 'DigitalMediaSaleTypeV2';
  acceptFiat: Scalars['Boolean'];
  contract?: Maybe<NftEthereumContractType>;
  createdAt: Scalars['DateTime'];
  custodialPresalePriceInCents?: Maybe<Scalars['Int']>;
  custodialPriceEth?: Maybe<Scalars['Float']>;
  custodialPriceInCents: Scalars['Int'];
  custodialPriceInWei: Scalars['Decimal'];
  custodialPriceUsd?: Maybe<Scalars['Float']>;
  digitalMediaRelease?: Maybe<NftAbstractType>;
  discountedFromPriceInCents: Scalars['Int'];
  enableCustodialSale: Scalars['Boolean'];
  ethTx?: Maybe<TransactionAbstractType>;
  fixedpricesale?: Maybe<FixedPriceSaleType>;
  fromAddress: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isFromCustodialWallet?: Maybe<Scalars['Boolean']>;
  minedAt?: Maybe<Scalars['DateTime']>;
  pk: Scalars['ID'];
  postedAt?: Maybe<Scalars['DateTime']>;
  productpurchasehistory?: Maybe<ProductPurchaseHistoryType>;
  publicType: DigitalMediaSalePublicType;
  status: Scalars['Int'];
  toAddress?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  type: DigitalMediaSaleType;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<AccountSessionType>;
};

export type DigitalMediaSaleTypeV2Connection = {
  __typename?: 'DigitalMediaSaleTypeV2Connection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DigitalMediaSaleTypeV2Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DigitalMediaSaleTypeV2` and its cursor. */
export type DigitalMediaSaleTypeV2Edge = {
  __typename?: 'DigitalMediaSaleTypeV2Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DigitalMediaSaleTypeV2>;
};

export type DigitalMediaType = {
  __typename?: 'DigitalMediaType';
  author?: Maybe<ArtistType>;
  collaborators?: Maybe<Array<Maybe<ArtistType>>>;
  collection?: Maybe<CollectionType>;
  description: Scalars['String'];
  drop?: Maybe<DropType>;
  hasVideo?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  media500x500PreviewContent?: Maybe<Scalars['String']>;
  media500xPreviewContent?: Maybe<Scalars['String']>;
  media1000xPreviewContent?: Maybe<Scalars['String']>;
  mediaSlug?: Maybe<Scalars['String']>;
  metadata?: Maybe<DigitalMediaMetadataType>;
  product?: Maybe<ProductType>;
  title: Scalars['String'];
  totalSupply?: Maybe<Scalars['Int']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type DigitalMediaTypeV2 = GlobalIdConnectionNode & {
  __typename?: 'DigitalMediaTypeV2';
  additionalDetails?: Maybe<Scalars['String']>;
  additionalMediasRanked?: Maybe<Array<Maybe<AdditionalMediaType>>>;
  author?: Maybe<AccountSessionType>;
  authorProfile?: Maybe<ProfileType>;
  collaboratorsProfile?: Maybe<Array<Maybe<ProfileType>>>;
  collection?: Maybe<CollectionTypeV2>;
  commentsCount?: Maybe<Scalars['Int']>;
  contract?: Maybe<NftEthereumContractType>;
  createdAt: Scalars['DateTime'];
  currentPrintIndex: Scalars['Int'];
  description: Scalars['String'];
  digitalMediaTraits?: Maybe<Array<Maybe<TraitsType>>>;
  digitalmediametadata?: Maybe<DigitalMediaMetadataType>;
  dropmedia?: Maybe<DropMediaMetadataType>;
  hasVideo: Scalars['Boolean'];
  hiddenOnCreate: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isBurned: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  lastSale?: Maybe<ProductPurchaseHistoryType>;
  latestRelease?: Maybe<DigitalMediaReleaseNodeType>;
  likesCount: Scalars['Int'];
  liveProduct?: Maybe<ProductNodeType>;
  media500x500PreviewContent?: Maybe<Scalars['String']>;
  media500xPreviewContent?: Maybe<Scalars['String']>;
  media1000xPreviewContent?: Maybe<Scalars['String']>;
  metadata?: Maybe<DigitalMediaMetadataType>;
  metadataUploadedAt?: Maybe<Scalars['DateTime']>;
  onchainId?: Maybe<Scalars['String']>;
  ownerAddress?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  rawfileExtension: Scalars['String'];
  s3PreviewFilename?: Maybe<Scalars['String']>;
  s3RawmediaFilename: Scalars['String'];
  storedOwnedReleases?: Maybe<Array<Maybe<DigitalMediaReleaseNodeType>>>;
  title: Scalars['String'];
  totalMissingCollabs: Scalars['Int'];
  totalSupply: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  videoUrl?: Maybe<Scalars['String']>;
  viewCount: Scalars['Int'];
};

export type DigitalMediaTypeV2Connection = {
  __typename?: 'DigitalMediaTypeV2Connection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DigitalMediaTypeV2Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DigitalMediaTypeV2` and its cursor. */
export type DigitalMediaTypeV2Edge = {
  __typename?: 'DigitalMediaTypeV2Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DigitalMediaTypeV2>;
};

export type DisconnectWalletArguments = {
  address: Scalars['HexString'];
};

export type DisconnectWalletMutation = {
  __typename?: 'DisconnectWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** Refer to DigitalMediaForm */
export type DraftNftArguments = {
  description: Scalars['String'];
  immutableMedia?: InputMaybe<Scalars['Boolean']>;
  isHiddenOnCreate?: InputMaybe<Scalars['Boolean']>;
  ownershipPcts?: InputMaybe<Array<InputMaybe<NftOwnersipPcts>>>;
  previewCropRatio?: InputMaybe<Scalars['Float']>;
  previewFile?: InputMaybe<Scalars['Upload']>;
  title: Scalars['String'];
  totalSupply: Scalars['Int'];
  uploadedFile: Scalars['Upload'];
};

/** Create Draft NFT and database models */
export type DraftNftMutation = {
  __typename?: 'DraftNFTMutation';
  digitalMediaId?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Represents a DropFamily, that may or may not have a 1-to-1 relationship with CollectionDrop.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type DropAbstractType = GlobalIdConnectionNode & {
  __typename?: 'DropAbstractType';
  artist?: Maybe<AccountAbstractType>;
  artworksAvailable: Scalars['Boolean'];
  collaborators?: Maybe<AccountAbstractType>;
  collectionDrop?: Maybe<CollectionDropType>;
  creators?: Maybe<Array<Maybe<AccountAbstractType>>>;
  curator?: Maybe<AccountAbstractType>;
  darkMode: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  dropDetails?: Maybe<Scalars['String']>;
  dropPhase: DropPhaseEnum;
  dropTitle?: Maybe<Scalars['String']>;
  dropsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  generativeSlug?: Maybe<Scalars['String']>;
  hasGenerative?: Maybe<Scalars['Boolean']>;
  hasVideoPreview?: Maybe<Scalars['Boolean']>;
  heading1?: Maybe<Scalars['String']>;
  heading2?: Maybe<Scalars['String']>;
  heroBackgroundColor: Rgb;
  heroDescription: Scalars['String'];
  heroImageUrl: Scalars['String'];
  heroVideoUrl: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isEligibleForPresale?: Maybe<Scalars['Boolean']>;
  isLive?: Maybe<Scalars['Boolean']>;
  moreAboutDescription: Scalars['String'];
  moreAboutImages?: Maybe<Array<Maybe<DropLandingPageMoreAboutImageType>>>;
  moreAboutLinks?: Maybe<Array<Maybe<DropLandingPageMoreLinkType>>>;
  nfts?: Maybe<Array<Maybe<NftAbstractUnion>>>;
  pk: Scalars['ID'];
  presale?: Maybe<DropPreSaleAbstractType>;
  presenterText: Scalars['String'];
  previewImageExtension?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  previewMedia?: Maybe<NftMetadataAbstractType>;
  relatedDrops?: Maybe<DropAbstractTypeConnectionTypeConnection>;
  sections?: Maybe<Array<Maybe<NftDropSection>>>;
  slug?: Maybe<Scalars['String']>;
  sortedNftMetadatas?: Maybe<NftMetadataConnectionTypeConnection>;
  subscribeBackgroundGradient?: Maybe<Scalars['String']>;
  subscribeBackgroundImage?: Maybe<Scalars['String']>;
  subscribeHeading?: Maybe<Scalars['String']>;
  subscribeInputBackground?: Maybe<Scalars['String']>;
  subscribePhoneNumber?: Maybe<Scalars['String']>;
  totalCollections?: Maybe<Scalars['Int']>;
  totalLimitedEditions?: Maybe<Scalars['Int']>;
  totalMysteryBoxes?: Maybe<Scalars['Int']>;
  totalOneOfOnes?: Maybe<Scalars['Int']>;
  totalOpenEditions?: Maybe<Scalars['Int']>;
  totalRaffles?: Maybe<Scalars['Int']>;
  totalRankedAuctions?: Maybe<Scalars['Int']>;
};


/**
 * Represents a DropFamily, that may or may not have a 1-to-1 relationship with CollectionDrop.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type DropAbstractTypeNftsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/**
 * Represents a DropFamily, that may or may not have a 1-to-1 relationship with CollectionDrop.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type DropAbstractTypeRelatedDropsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/**
 * Represents a DropFamily, that may or may not have a 1-to-1 relationship with CollectionDrop.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type DropAbstractTypeSortedNftMetadatasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DropAbstractTypeConnection = {
  __typename?: 'DropAbstractTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DropAbstractTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

export type DropAbstractTypeConnectionTypeConnection = {
  __typename?: 'DropAbstractTypeConnectionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DropAbstractTypeConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DropAbstractTypeConnectionType` and its cursor. */
export type DropAbstractTypeConnectionTypeEdge = {
  __typename?: 'DropAbstractTypeConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DropAbstractType>;
};

/** A Relay edge containing a `DropAbstractType` and its cursor. */
export type DropAbstractTypeEdge = {
  __typename?: 'DropAbstractTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DropAbstractType>;
};

export type DropFamilyTypeV2 = {
  __typename?: 'DropFamilyTypeV2';
  dropTitle?: Maybe<Scalars['String']>;
  heroDescription: Scalars['String'];
  pk: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
};

/**
 * Drop Landing Page v2 More about image type
 * Doc: https://www.notion.so/DLPv2-Hero-Section-f7219d3cd5bd49b783e8a05ba0aff635?pvs=4#121613c16eb548aaa74021e495cd7a83
 */
export type DropLandingPageMoreAboutImageType = GlobalIdConnectionNode & {
  __typename?: 'DropLandingPageMoreAboutImageType';
  /** The ID of the object. */
  id: Scalars['ID'];
  imageTitle?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  pk: Scalars['ID'];
};

/** Represents a DropLandingPageMoreLink, which is a 1-to-many relationship with DropFamily. */
export type DropLandingPageMoreLinkType = GlobalIdConnectionNode & {
  __typename?: 'DropLandingPageMoreLinkType';
  /** The ID of the object. */
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
};

/**
 * Represents a DropMedia, which is a 1-to-1 relationship with DigitalMedia.
 * This can be accessed from `NftAbstractType.metadata.drop_metadata`.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type DropMediaMetadataType = GlobalIdConnectionNode & {
  __typename?: 'DropMediaMetadataType';
  additionalDetails?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creatorFeatureUrl?: Maybe<Scalars['String']>;
  drop?: Maybe<DropAbstractType>;
  dropsAt: Scalars['DateTime'];
  endsAt?: Maybe<Scalars['DateTime']>;
  hasEnded?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isLightweightDrop?: Maybe<Scalars['Boolean']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isPresaleLive?: Maybe<Scalars['Boolean']>;
  isSoldInCollectionDrop?: Maybe<Scalars['Boolean']>;
  isSoldOut: Scalars['Boolean'];
  limitPerCustomer?: Maybe<Scalars['Int']>;
  limitToVerifiedAccount: Scalars['Boolean'];
  listing?: Maybe<NftListingAbstractUnion>;
  nftMetadata?: Maybe<NftMetadataAbstractType>;
  pk: Scalars['ID'];
  previewImageUrl?: Maybe<Scalars['String']>;
  previewVideoStartTime: Scalars['String'];
  upcomingUrl?: Maybe<Scalars['String']>;
  videoPreviewImageUrl?: Maybe<Scalars['String']>;
};

export type DropMediaMetadataTypeConnection = {
  __typename?: 'DropMediaMetadataTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DropMediaMetadataTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DropMediaMetadataType` and its cursor. */
export type DropMediaMetadataTypeEdge = {
  __typename?: 'DropMediaMetadataTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DropMediaMetadataType>;
};

/** An enumeration. */
export enum DropPhaseEnum {
  Live = 'LIVE',
  New = 'NEW',
  Presale = 'PRESALE',
  SoldOut = 'SOLD_OUT',
  Upcoming = 'UPCOMING'
}

/** Represents a DropPreSale, which is a 1-to-1 relationship with DropFamily. */
export type DropPreSaleAbstractType = GlobalIdConnectionNode & {
  __typename?: 'DropPreSaleAbstractType';
  dropFamily: DropAbstractType;
  endsAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  preSaleEndAt: Scalars['DateTime'];
  preSaleStartAt: Scalars['DateTime'];
  preSaleUsers: Array<AccountSessionType>;
  startsAt?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum DropSectionEnum {
  Generative = 'GENERATIVE',
  LimitedEdition = 'LIMITED_EDITION',
  OneOfOne = 'ONE_OF_ONE',
  OpenEdition = 'OPEN_EDITION'
}

/** An enumeration. */
export enum DropSubSectionEnum {
  AcceptingOffers = 'ACCEPTING_OFFERS',
  CollectionDrop = 'COLLECTION_DROP',
  FixedSale = 'FIXED_SALE',
  RankedAuction = 'RANKED_AUCTION',
  TimedAuctionOrReserve = 'TIMED_AUCTION_OR_RESERVE'
}

export type DropSubscribeArguments = {
  dropFamilyId: Scalars['Int'];
  isSubscribe?: InputMaybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
};

/** Subscribe to a drop family to receive SMS */
export type DropSubscribeMutation = {
  __typename?: 'DropSubscribeMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DropType = {
  __typename?: 'DropType';
  dropsAt: Scalars['DateTime'];
  hasDropped?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type EditCommentNftArguments = {
  body: Scalars['String'];
  commentId: Scalars['Int'];
};

/** Edit comment on the NFT. */
export type EditCommentNftMutation = {
  __typename?: 'EditCommentNFTMutation';
  comment?: Maybe<NftCommentType>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Refer to DigitalMediaForm
 * Similar to DraftNFTArguments, but all fields are not required
 */
export type EditNftArguments = {
  description?: InputMaybe<Scalars['String']>;
  digitalMediaId: Scalars['Int'];
  immutableMedia?: InputMaybe<Scalars['Boolean']>;
  isHiddenOnCreate?: InputMaybe<Scalars['Boolean']>;
  ownershipPcts?: InputMaybe<Array<InputMaybe<NftOwnersipPcts>>>;
  previewCropRatio?: InputMaybe<Scalars['Float']>;
  previewFile?: InputMaybe<Scalars['Upload']>;
  title?: InputMaybe<Scalars['String']>;
  totalSupply?: InputMaybe<Scalars['Int']>;
  uploadedFile?: InputMaybe<Scalars['Upload']>;
};

/**
 * Edit draft NFT (DigitalMedia)
 * Only allowed before it's processed or minted
 */
export type EditNftMutation = {
  __typename?: 'EditNFTMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** Muatation for editing profile for profiles V2. */
export type EditProfile = {
  __typename?: 'EditProfile';
  error?: Maybe<Scalars['String']>;
  newSlug?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Edit store category for a given user store. */
export type EditStoreCategory = {
  __typename?: 'EditStoreCategory';
  categories?: Maybe<ProfileCategoryListType>;
  category?: Maybe<StoreCategoryType>;
  errors?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum EditionTypeEnum {
  Limited = 'LIMITED',
  Single = 'SINGLE'
}

export type EmailVerificationType = GlobalIdConnectionNode & {
  __typename?: 'EmailVerificationType';
  emailLink: Scalars['String'];
  emailVerificationUrl?: Maybe<Scalars['String']>;
  hasVerified: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
};

/** An enumeration. */
export enum EmergingCtaActionTypeEnum {
  Follow = 'FOLLOW',
  Url = 'URL'
}

/** An enumeration. */
export enum EmergingCtaTypeEnum {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export type EmergingType = {
  __typename?: 'EmergingType';
  artistDescription?: Maybe<Scalars['String']>;
  artistId?: Maybe<Scalars['Int']>;
  artistName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isFollow?: Maybe<Scalars['Boolean']>;
  landingUrl?: Maybe<Scalars['String']>;
  marketplaceUrl?: Maybe<Scalars['String']>;
  mediaImageUrl?: Maybe<Scalars['String']>;
  mediaName?: Maybe<Scalars['String']>;
  overlayUrl?: Maybe<Scalars['String']>;
  product?: Maybe<ProductType>;
  profileImageUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  userBlogUrl?: Maybe<Scalars['String']>;
};

export type EthPayoutType = GlobalIdConnectionNode & {
  __typename?: 'EthPayoutType';
  address: Scalars['String'];
  coinbaseCustomerId?: Maybe<Scalars['String']>;
  coinbaseData?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  user: AccountSessionType;
};

/** An enumeration. */
export enum EthereumTransactionStateEnum {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Mined = 'MINED',
  Pending = 'PENDING',
  Posted = 'POSTED',
  Trying = 'TRYING'
}

export type EthereumTransactionType = {
  __typename?: 'EthereumTransactionType';
  blockchainUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  status?: Maybe<Scalars['Int']>;
  txid?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ExhibitVendorState {
  /** PENDING_LIVE */
  A_1 = 'A_1',
  /** PENDING_SUNSET */
  A_2 = 'A_2',
  /** LIVE */
  A_3 = 'A_3',
  /** SUNSET */
  A_4 = 'A_4'
}

export type ExploreArtworkQuery = {
  __typename?: 'ExploreArtworkQuery';
  nfts?: Maybe<NftConnection>;
  nftsByArtist?: Maybe<NftConnection>;
};


export type ExploreArtworkQueryNftsArgs = {
  after?: InputMaybe<Scalars['String']>;
  artists?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  aspectRatios?: InputMaybe<Array<InputMaybe<AspectRatioEnum>>>;
  before?: InputMaybe<Scalars['String']>;
  editions?: InputMaybe<Array<InputMaybe<EditionTypeEnum>>>;
  filters?: InputMaybe<Array<InputMaybe<KeyValues>>>;
  first?: InputMaybe<Scalars['Int']>;
  genres?: InputMaybe<Array<InputMaybe<GenreEnum>>>;
  last?: InputMaybe<Scalars['Int']>;
  mediaTypes?: InputMaybe<Array<InputMaybe<MediaTypeEnum>>>;
  price?: InputMaybe<RangeType>;
  query?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<SizeType>;
  slug?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  sortType?: InputMaybe<SortTypeEnum>;
  status?: InputMaybe<Array<InputMaybe<StatusEnum>>>;
  traits?: InputMaybe<Array<InputMaybe<CollectionTraitType>>>;
};


export type ExploreArtworkQueryNftsByArtistArgs = {
  after?: InputMaybe<Scalars['String']>;
  artists?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  aspectRatios?: InputMaybe<Array<InputMaybe<AspectRatioEnum>>>;
  before?: InputMaybe<Scalars['String']>;
  editions?: InputMaybe<Array<InputMaybe<EditionTypeEnum>>>;
  filters?: InputMaybe<Array<InputMaybe<KeyValues>>>;
  first?: InputMaybe<Scalars['Int']>;
  genres?: InputMaybe<Array<InputMaybe<GenreEnum>>>;
  last?: InputMaybe<Scalars['Int']>;
  mediaTypes?: InputMaybe<Array<InputMaybe<MediaTypeEnum>>>;
  price?: InputMaybe<RangeType>;
  query?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<SizeType>;
  slug?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  sortType?: InputMaybe<SortTypeEnum>;
  status?: InputMaybe<Array<InputMaybe<StatusEnum>>>;
};

export type ExtendLockListingArguments = {
  productId: Scalars['Int'];
};

/** For Personal Wallet ETH Sale Create, refer to v1 PendingSaleCreate */
export type ExtendLockListingMutation = {
  __typename?: 'ExtendLockListingMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum ExternalCollectionType {
  Artist = 'ARTIST',
  Internal = 'INTERNAL',
  Standalone = 'STANDALONE'
}

export type FeaturedStoreTypeV3 = GlobalIdConnectionNode & {
  __typename?: 'FeaturedStoreTypeV3';
  description: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  store?: Maybe<StoreTypeV3>;
};

export type FeaturedStoreTypeV3Connection = {
  __typename?: 'FeaturedStoreTypeV3Connection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeaturedStoreTypeV3Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FeaturedStoreTypeV3` and its cursor. */
export type FeaturedStoreTypeV3Edge = {
  __typename?: 'FeaturedStoreTypeV3Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FeaturedStoreTypeV3>;
};

export type FinalizeTwoFactorSetupArguments = {
  channel: Scalars['String'];
  code: Scalars['String'];
};

export type FinalizeTwoFactorSetupMutation = {
  __typename?: 'FinalizeTwoFactorSetupMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type FixedPriceSaleType = {
  __typename?: 'FixedPriceSaleType';
  price: Scalars['Decimal'];
  priceEth?: Maybe<Scalars['Float']>;
  priceUsd?: Maybe<Scalars['Float']>;
  sale: DigitalMediaSaleTypeV2;
};

export type FloorPriceNode = {
  __typename?: 'FloorPriceNode';
  floorPriceInEth?: Maybe<Scalars['Float']>;
  floorPriceInUsd?: Maybe<Scalars['Float']>;
};

export type FloorPriceType = {
  __typename?: 'FloorPriceType';
  floorPriceInEth?: Maybe<Scalars['Float']>;
  floorPriceInUsd?: Maybe<Scalars['Float']>;
};

export type FollowUnfollowUserArguments = {
  userId: Scalars['Int'];
};

export type FollowUserMutation = {
  __typename?: 'FollowUserMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type FollowUserType = GlobalIdConnectionNode & {
  __typename?: 'FollowUserType';
  fullName?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isFollowing: IsFollowingEnum;
  landingUrl?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  profileImageUrl?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type FollowUserTypeConnection = {
  __typename?: 'FollowUserTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FollowUserTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FollowUserType` and its cursor. */
export type FollowUserTypeEdge = {
  __typename?: 'FollowUserTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FollowUserType>;
};

export type FollowUserTypeV3 = GlobalIdConnectionNode & {
  __typename?: 'FollowUserTypeV3';
  autoFollow: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  followee?: Maybe<AccountAbstractType>;
  follower?: Maybe<AccountAbstractType>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type FollowUserTypeV3Connection = {
  __typename?: 'FollowUserTypeV3Connection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FollowUserTypeV3Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FollowUserTypeV3` and its cursor. */
export type FollowUserTypeV3Edge = {
  __typename?: 'FollowUserTypeV3Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FollowUserTypeV3>;
};

export type FollowV2 = {
  __typename?: 'FollowV2';
  errors?: Maybe<Scalars['String']>;
  followUser?: Maybe<FollowUserType>;
  followers?: Maybe<FollowersV2Type>;
  status?: Maybe<Scalars['String']>;
};

export type FollowersType = GlobalIdConnectionNode & {
  __typename?: 'FollowersType';
  autoFollow: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  followee: AccountSessionType;
  follower: AccountSessionType;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
};

export type FollowersV2Type = {
  __typename?: 'FollowersV2Type';
  followers: Scalars['Int'];
  following: Scalars['Int'];
  id: Scalars['String'];
  isFollowing: IsFollowingEnum;
  views: Scalars['Int'];
};

export type FreeMintInfoType = {
  __typename?: 'FreeMintInfoType';
  count: Scalars['Int'];
  earliestExpiry?: Maybe<Scalars['DateTime']>;
};

/**
 * Based on GasRequest and SpeedupGasEstimateForm,
 * support Pay gas for the DigitalMediaRelease, DigitalMediaSale, ProductBid, DigitalMediaSend
 * Refer to SpeedupTxView GET method
 */
export type GasRequestAbstractType = GlobalIdConnectionNode & {
  __typename?: 'GasRequestAbstractType';
  abi?: Maybe<Scalars['JSONString']>;
  amountInWei: Scalars['Decimal'];
  contractAddress?: Maybe<Scalars['HexString']>;
  ethToUsd?: Maybe<Scalars['Decimal']>;
  gasInGwei: Scalars['Int'];
  /** The ID of the object. */
  id: Scalars['ID'];
  objectAction: GasRequestObjectAction;
  objectId: Scalars['Int'];
  objectType: GasRequestObjectType;
  paymentState: GasRequestPaymentState;
  pk: Scalars['ID'];
  status?: Maybe<Scalars['Boolean']>;
  totalGasInEth?: Maybe<Scalars['Float']>;
  totalGasInUsd?: Maybe<Scalars['Float']>;
  totalGasInWei?: Maybe<Scalars['BigInt']>;
  txstate: GasRequestTxstate;
  user: AccountSessionType;
};

export type GasRequestAbstractTypeConnection = {
  __typename?: 'GasRequestAbstractTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GasRequestAbstractTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GasRequestAbstractType` and its cursor. */
export type GasRequestAbstractTypeEdge = {
  __typename?: 'GasRequestAbstractTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GasRequestAbstractType>;
};

/** Refer to GasRequestForm */
export type GasRequestArguments = {
  fromAddress: Scalars['HexString'];
  gasRequestId: Scalars['Int'];
  nonce: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

/** An enumeration. */
export enum GasRequestObjectAction {
  /** CREATE */
  A_1 = 'A_1',
  /** CANCEL */
  A_2 = 'A_2',
  /** ACCEPT */
  A_3 = 'A_3',
  /** REJECT */
  A_4 = 'A_4',
  /** PUBLISH */
  A_5 = 'A_5'
}

/** An enumeration. */
export enum GasRequestObjectType {
  /** TOKEN */
  A_1 = 'A_1',
  /** SALE */
  A_2 = 'A_2',
  /** BID */
  A_3 = 'A_3',
  /** SEND */
  A_4 = 'A_4'
}

/** An enumeration. */
export enum GasRequestPaymentState {
  /** CREATED */
  A_1 = 'A_1',
  /** POSTED */
  A_2 = 'A_2',
  /** PAID */
  A_3 = 'A_3',
  /** FAILED */
  A_4 = 'A_4'
}

export type GasRequestSpeedUpTxInCcArguments = {
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  rememberCard?: InputMaybe<Scalars['Boolean']>;
  requestId: Scalars['Int'];
};

/** Get GasRequest and call speedup_transaction in CC */
export type GasRequestSpeedUpTxInCcMutation = {
  __typename?: 'GasRequestSpeedUpTxInCCMutation';
  intentId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Get GasRequest and call speedup_transaction in ETH */
export type GasRequestSpeedUpTxMutation = {
  __typename?: 'GasRequestSpeedUpTxMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum GasRequestTxstate {
  /** CREATED */
  A_1 = 'A_1',
  /** UPGRADED */
  A_2 = 'A_2',
  /** FAILED */
  A_3 = 'A_3'
}

export type GenerateNonceArguments = {
  walletAddress: Scalars['HexString'];
};

export type GenerateNonceMutation = {
  __typename?: 'GenerateNonceMutation';
  key?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['String']>;
  termsOfServiceUrl?: Maybe<Scalars['String']>;
  walletAddress?: Maybe<Scalars['HexString']>;
};

/** An enumeration. */
export enum GenreEnum {
  AiArt = 'AI_ART',
  Animation = 'ANIMATION',
  GenerativeArt = 'GENERATIVE_ART',
  Illustration = 'ILLUSTRATION',
  Photography = 'PHOTOGRAPHY',
  Phygitals = 'PHYGITALS',
  X3DArt = 'X3D_ART'
}

export type GenreType = {
  __typename?: 'GenreType';
  mediaArtist?: Maybe<Scalars['String']>;
  mediaTitle?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type GlobalIdConnectionNode = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum GraphPageViewObjectType {
  Nft = 'NFT',
  Profile = 'PROFILE'
}

export type HomepageSectionType = GlobalIdConnectionNode & {
  __typename?: 'HomepageSectionType';
  artist?: Maybe<ArtistSpotlightType>;
  artwork?: Maybe<NftAbstractType>;
  collection?: Maybe<CollectionAbstractType>;
  editorial?: Maybe<NftExibitTileType>;
  exhibition?: Maybe<DropAbstractType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  position: Scalars['Int'];
  sectionType: HomepageSectionTypeEnum;
};

export type HomepageSectionTypeConnection = {
  __typename?: 'HomepageSectionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HomepageSectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `HomepageSectionType` and its cursor. */
export type HomepageSectionTypeEdge = {
  __typename?: 'HomepageSectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<HomepageSectionType>;
};

/**
 *
 *     Supported homepage section types.
 *
 */
export enum HomepageSectionTypeEnum {
  CuratedArtworksCarousel = 'CURATED_ARTWORKS_CAROUSEL',
  DiscoverArtworksCarousel = 'DISCOVER_ARTWORKS_CAROUSEL',
  EditorialsCarousel = 'EDITORIALS_CAROUSEL',
  HeroArtist = 'HERO_ARTIST',
  HeroArtwork = 'HERO_ARTWORK',
  HeroCollection = 'HERO_COLLECTION',
  HeroEditorial = 'HERO_EDITORIAL',
  HeroExhibition = 'HERO_EXHIBITION',
  LiveExhibitionsCarousel = 'LIVE_EXHIBITIONS_CAROUSEL',
  UpcomingExhibitionsCarousel = 'UPCOMING_EXHIBITIONS_CAROUSEL'
}

export type IgnoreGlobalBidArguments = {
  mediaBidId: Scalars['Int'];
  productId: Scalars['Int'];
};

export type IgnoreGlobalBidMutation = {
  __typename?: 'IgnoreGlobalBidMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ImportToken = {
  __typename?: 'ImportToken';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type InvalidateSessionsMutation = {
  __typename?: 'InvalidateSessionsMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type InviteCodeType = GlobalIdConnectionNode & {
  __typename?: 'InviteCodeType';
  counter: Scalars['Int'];
  creatorInvite: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  inviteCode: Scalars['String'];
  inviteCodeType: Scalars['String'];
  isUserSessionVerified?: Maybe<Scalars['Boolean']>;
  pk: Scalars['ID'];
  sandboxed: Scalars['Boolean'];
  sendEther: Scalars['Boolean'];
};

/** An enumeration. */
export enum IsFollowingEnum {
  False = 'FALSE',
  NotAllowed = 'NOT_ALLOWED',
  True = 'TRUE'
}

export type KeyValues = {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

/** A light weight version of DropFamily for use in the homepage for explore drops carousel */
export type LightWeightDropFamilyAbstractType = GlobalIdConnectionNode & {
  __typename?: 'LightWeightDropFamilyAbstractType';
  createdAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  dropTitle?: Maybe<Scalars['String']>;
  dropsAt?: Maybe<Scalars['DateTime']>;
  hasVideoPreview?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isLive?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  previewImageExtension?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  totalCollections?: Maybe<Scalars['Int']>;
  totalLimitedEditions?: Maybe<Scalars['Int']>;
  totalMysteryBoxes?: Maybe<Scalars['Int']>;
  totalOneOfOnes?: Maybe<Scalars['Int']>;
  totalOpenEditions?: Maybe<Scalars['Int']>;
  totalRaffles?: Maybe<Scalars['Int']>;
  totalRankedAuctions?: Maybe<Scalars['Int']>;
  user?: Maybe<AccountAbstractType>;
};

export type LightWeightDropFamilyAbstractTypeConnection = {
  __typename?: 'LightWeightDropFamilyAbstractTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LightWeightDropFamilyAbstractTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `LightWeightDropFamilyAbstractType` and its cursor. */
export type LightWeightDropFamilyAbstractTypeEdge = {
  __typename?: 'LightWeightDropFamilyAbstractTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<LightWeightDropFamilyAbstractType>;
};

/** Log Page View of NFT */
export type LikeNft = {
  __typename?: 'LikeNFT';
  liked?: Maybe<Scalars['Boolean']>;
  likesCount?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type LikeNftArguments = {
  nftId: Scalars['Int'];
};

export type LikesConnection = {
  __typename?: 'LikesConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LikesEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Likes` and its cursor. */
export type LikesEdge = {
  __typename?: 'LikesEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DigitalMediaReleaseNodeType>;
};

export type ListingStatsType = {
  __typename?: 'ListingStatsType';
  floorPrice?: Maybe<FloorPriceNode>;
  totalItems?: Maybe<Scalars['Int']>;
};

export type LockListingArguments = {
  isPresale?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['Int'];
};

/** For Sale purchase lock */
export type LockListingMutation = {
  __typename?: 'LockListingMutation';
  response?: Maybe<LockProductType>;
};

export type LockProductType = {
  __typename?: 'LockProductType';
  canReserveEditions?: Maybe<Scalars['Boolean']>;
  lockedAt?: Maybe<Scalars['DateTime']>;
  lockedProductId?: Maybe<Scalars['Int']>;
  productUrl?: Maybe<Scalars['String']>;
};

export type LogEthTransaction = {
  __typename?: 'LogETHTransaction';
  success?: Maybe<Scalars['Boolean']>;
};

export type LogEthTransactionArguments = {
  fromAddress: Scalars['String'];
  nonce: Scalars['Int'];
  txid: Scalars['String'];
};

export type LogJs = {
  __typename?: 'LogJs';
  errors?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Log Page View. Supports currently NFT and Profile */
export type LogView = {
  __typename?: 'LogView';
  success?: Maybe<Scalars['Boolean']>;
};

export type LogViewArguments = {
  objectId: Scalars['Int'];
  objectType: GraphPageViewObjectType;
};

export type LoggingFromFrontendArguments = {
  isException?: InputMaybe<Scalars['Boolean']>;
  message: Scalars['String'];
  nftId?: InputMaybe<Scalars['Int']>;
  transactionId?: InputMaybe<Scalars['String']>;
};

export type LoggingFromFrontendMutation = {
  __typename?: 'LoggingFromFrontendMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type LoginArguments = {
  email: Scalars['String'];
  password: Scalars['String'];
  recaptchaToken?: InputMaybe<Scalars['String']>;
  twoFactorCode?: InputMaybe<Scalars['String']>;
  twoFactorSessionId?: InputMaybe<Scalars['String']>;
};

export type LoginWithEmailMutation = {
  __typename?: 'LoginWithEmailMutation';
  account?: Maybe<AccountAbstractType>;
  expireAt?: Maybe<Scalars['DateTime']>;
  isAuthentic?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type LoginWithWalletArguments = {
  inviteCode?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  signature: Scalars['String'];
  walletAddress: Scalars['HexString'];
};

export type LoginWithWalletMutation = {
  __typename?: 'LoginWithWalletMutation';
  account?: Maybe<AccountAbstractType>;
  expireAt?: Maybe<Scalars['DateTime']>;
  isAuthentic?: Maybe<Scalars['Boolean']>;
  isNewUser?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type LogoutMutation = {
  __typename?: 'LogoutMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum MpErrors {
  AccountBannedFromSends = 'ACCOUNT_BANNED_FROM_SENDS',
  AccountNotFound = 'ACCOUNT_NOT_FOUND',
  AllEditionsSoldOut = 'ALL_EDITIONS_SOLD_OUT',
  AllMediaEditionsReserved = 'ALL_MEDIA_EDITIONS_RESERVED',
  ApiMalformed = 'API_MALFORMED',
  ApprovalContractAddressInvalid = 'APPROVAL_CONTRACT_ADDRESS_INVALID',
  ApprovalContractAddressNotFound = 'APPROVAL_CONTRACT_ADDRESS_NOT_FOUND',
  ArtworkUnavailable = 'ARTWORK_UNAVAILABLE',
  BannerImageFormatNotSupported = 'BANNER_IMAGE_FORMAT_NOT_SUPPORTED',
  BannerImageTooLarge = 'BANNER_IMAGE_TOO_LARGE',
  BidAmountBelowMinStoreBidAmount = 'BID_AMOUNT_BELOW_MIN_STORE_BID_AMOUNT',
  BidAmountCannotCalculate = 'BID_AMOUNT_CANNOT_CALCULATE',
  BidAmountIsLargerThanMaxEth = 'BID_AMOUNT_IS_LARGER_THAN_MAX_ETH',
  BidAmountIsLargerThanMaxUsd = 'BID_AMOUNT_IS_LARGER_THAN_MAX_USD',
  BidAmountIsSmallerThanMinEth = 'BID_AMOUNT_IS_SMALLER_THAN_MIN_ETH',
  BidAmountIsSmallerThanMinUsd = 'BID_AMOUNT_IS_SMALLER_THAN_MIN_USD',
  BidCannotAccept = 'BID_CANNOT_ACCEPT',
  BidCannotBeCancelledLiveBidMismatch = 'BID_CANNOT_BE_CANCELLED_LIVE_BID_MISMATCH',
  BidCannotBeCancelledNow = 'BID_CANNOT_BE_CANCELLED_NOW',
  BidCannotBeRejected = 'BID_CANNOT_BE_REJECTED',
  BidHasBeenDisabled = 'BID_HAS_BEEN_DISABLED',
  BidInvalidEmailForCreditCard = 'BID_INVALID_EMAIL_FOR_CREDIT_CARD',
  BidIsNotCreatedByCurrentUser = 'BID_IS_NOT_CREATED_BY_CURRENT_USER',
  BidIsNotRelatedToMedia = 'BID_IS_NOT_RELATED_TO_MEDIA',
  BidMustBeInCcType = 'BID_MUST_BE_IN_CC_TYPE',
  BidMustBeInEthType = 'BID_MUST_BE_IN_ETH_TYPE',
  BidNotFound = 'BID_NOT_FOUND',
  BidNoLongerBeUpdated = 'BID_NO_LONGER_BE_UPDATED',
  BidPriceInvalid = 'BID_PRICE_INVALID',
  CannotSendToCustodial = 'CANNOT_SEND_TO_CUSTODIAL',
  CannotSendToMpContract = 'CANNOT_SEND_TO_MP_CONTRACT',
  CategoryNameFormat = 'CATEGORY_NAME_FORMAT',
  CategoryNameTaken = 'CATEGORY_NAME_TAKEN',
  CategoryNotFound = 'CATEGORY_NOT_FOUND',
  CategoryReservedName = 'CATEGORY_RESERVED_NAME',
  CoinbaseInvalidAccount = 'COINBASE_INVALID_ACCOUNT',
  CoinbaseLoginError = 'COINBASE_LOGIN_ERROR',
  CommentDoesNotExist = 'COMMENT_DOES_NOT_EXIST',
  CommentNftMetadataNotFound = 'COMMENT_NFT_METADATA_NOT_FOUND',
  CommentSaveFail = 'COMMENT_SAVE_FAIL',
  CommentThreadError = 'COMMENT_THREAD_ERROR',
  CommentTooLong = 'COMMENT_TOO_LONG',
  CommentUserError = 'COMMENT_USER_ERROR',
  ContractNotFound = 'CONTRACT_NOT_FOUND',
  ContractSupportForCreditCardIssue = 'CONTRACT_SUPPORT_FOR_CREDIT_CARD_ISSUE',
  CreateMediaDescriptionInvalid = 'CREATE_MEDIA_DESCRIPTION_INVALID',
  CreateMediaDraftUploadAssetsFailed = 'CREATE_MEDIA_DRAFT_UPLOAD_ASSETS_FAILED',
  CreateMediaDuplicateImageSignature = 'CREATE_MEDIA_DUPLICATE_IMAGE_SIGNATURE',
  CreateMediaFileOversize = 'CREATE_MEDIA_FILE_OVERSIZE',
  CreateMediaFileUndersize = 'CREATE_MEDIA_FILE_UNDERSIZE',
  CreateMediaMintHasBeenDisabled = 'CREATE_MEDIA_MINT_HAS_BEEN_DISABLED',
  CreateMediaMissingData = 'CREATE_MEDIA_MISSING_DATA',
  CreateMediaMissingTitle = 'CREATE_MEDIA_MISSING_TITLE',
  CreateMediaMissingTotalSupply = 'CREATE_MEDIA_MISSING_TOTAL_SUPPLY',
  CreateMediaOwnershipPctsIncorretFormat = 'CREATE_MEDIA_OWNERSHIP_PCTS_INCORRET_FORMAT',
  CreateMediaOwnershipPctsInvalid = 'CREATE_MEDIA_OWNERSHIP_PCTS_INVALID',
  CreateMediaOwnershipPctsNotAddUpTo_100 = 'CREATE_MEDIA_OWNERSHIP_PCTS_NOT_ADD_UP_TO_100',
  CreateMediaOwnershipPctsWalletNotConnected = 'CREATE_MEDIA_OWNERSHIP_PCTS_WALLET_NOT_CONNECTED',
  CreateMediaPreviewFileMissed = 'CREATE_MEDIA_PREVIEW_FILE_MISSED',
  CreateMediaTitleInvalid = 'CREATE_MEDIA_TITLE_INVALID',
  CreateMediaTotalSupplyOversize = 'CREATE_MEDIA_TOTAL_SUPPLY_OVERSIZE',
  CreateMediaTotalSupplyUndersize = 'CREATE_MEDIA_TOTAL_SUPPLY_UNDERSIZE',
  CreateMediaUploadedFileMissed = 'CREATE_MEDIA_UPLOADED_FILE_MISSED',
  CreateMediaUploadImageFailure = 'CREATE_MEDIA_UPLOAD_IMAGE_FAILURE',
  CreditCardAlreadyAdded = 'CREDIT_CARD_ALREADY_ADDED',
  CreditCardCouldNotBeAdded = 'CREDIT_CARD_COULD_NOT_BE_ADDED',
  CreditCardCouldNotBeRemoved = 'CREDIT_CARD_COULD_NOT_BE_REMOVED',
  CreditCardInvalidDetails = 'CREDIT_CARD_INVALID_DETAILS',
  DeferGasRequestLimitReached = 'DEFER_GAS_REQUEST_LIMIT_REACHED',
  DeferGasRequestUserOverLimit = 'DEFER_GAS_REQUEST_USER_OVER_LIMIT',
  DeferPaymentNotAuthorized = 'DEFER_PAYMENT_NOT_AUTHORIZED',
  DepositMetadataNotFound = 'DEPOSIT_METADATA_NOT_FOUND',
  DigitalMediaCannotAcceptBid = 'DIGITAL_MEDIA_CANNOT_ACCEPT_BID',
  DigitalMediaCanPublishNotSet = 'DIGITAL_MEDIA_CAN_PUBLISH_NOT_SET',
  DigitalMediaNotFound = 'DIGITAL_MEDIA_NOT_FOUND',
  DigitalMediaReleaseEditionOverflow = 'DIGITAL_MEDIA_RELEASE_EDITION_OVERFLOW',
  DigitalMediaReleasePreviousEditionNotMined = 'DIGITAL_MEDIA_RELEASE_PREVIOUS_EDITION_NOT_MINED',
  DiscountCodeIsInvalid = 'DISCOUNT_CODE_IS_INVALID',
  DowngradeCreatorFailured = 'DOWNGRADE_CREATOR_FAILURED',
  DropFamilyNotFound = 'DROP_FAMILY_NOT_FOUND',
  DropMediaNotFound = 'DROP_MEDIA_NOT_FOUND',
  DropPresaleNotEligible = 'DROP_PRESALE_NOT_ELIGIBLE',
  DropPresaleNotLive = 'DROP_PRESALE_NOT_LIVE',
  DropPreventsBids = 'DROP_PREVENTS_BIDS',
  EditionsSoldOutRetryLater = 'EDITIONS_SOLD_OUT_RETRY_LATER',
  EditMediaIsInProcessingOrPublished = 'EDIT_MEDIA_IS_IN_PROCESSING_OR_PUBLISHED',
  EmailAlreadyVerified = 'EMAIL_ALREADY_VERIFIED',
  EmailIsAlreadyUsed = 'EMAIL_IS_ALREADY_USED',
  EmailIsInvalid = 'EMAIL_IS_INVALID',
  EmailVerificationNotFound = 'EMAIL_VERIFICATION_NOT_FOUND',
  EthereumTransactionCreatedFailed = 'ETHEREUM_TRANSACTION_CREATED_FAILED',
  ExceededMonthlyMintLimit = 'EXCEEDED_MONTHLY_MINT_LIMIT',
  ExpiredInviteCode = 'EXPIRED_INVITE_CODE',
  FacebookValidationError = 'FACEBOOK_VALIDATION_ERROR',
  FollowUserDoesNotExist = 'FOLLOW_USER_DOES_NOT_EXIST',
  FreeMintMetadataInvalid = 'FREE_MINT_METADATA_INVALID',
  FreeMintTokenInvalid = 'FREE_MINT_TOKEN_INVALID',
  FromAddressInvalid = 'FROM_ADDRESS_INVALID',
  GasObjectActionInvalid = 'GAS_OBJECT_ACTION_INVALID',
  GasObjectTypeInvalid = 'GAS_OBJECT_TYPE_INVALID',
  GasRequestAlreadySetup = 'GAS_REQUEST_ALREADY_SETUP',
  GasRequestAlreadyUpdated = 'GAS_REQUEST_ALREADY_UPDATED',
  GasRequestCalculateGasFailed = 'GAS_REQUEST_CALCULATE_GAS_FAILED',
  GasRequestFromAddressInvalid = 'GAS_REQUEST_FROM_ADDRESS_INVALID',
  GasRequestIsStale = 'GAS_REQUEST_IS_STALE',
  GasRequestNotAuthorized = 'GAS_REQUEST_NOT_AUTHORIZED',
  GasRequestNotFound = 'GAS_REQUEST_NOT_FOUND',
  GasRequestNoValidData = 'GAS_REQUEST_NO_VALID_DATA',
  GasRequestPaymentStateIsNotCreated = 'GAS_REQUEST_PAYMENT_STATE_IS_NOT_CREATED',
  GasRequestTxUpgradeFailed = 'GAS_REQUEST_TX_UPGRADE_FAILED',
  GoogleValidationError = 'GOOGLE_VALIDATION_ERROR',
  InvalidCreditCardTransaction = 'INVALID_CREDIT_CARD_TRANSACTION',
  InvalidId = 'INVALID_ID',
  InviteCodeExpired = 'INVITE_CODE_EXPIRED',
  InviteCodeInvalid = 'INVITE_CODE_INVALID',
  JwtSessionExpired = 'JWT_SESSION_EXPIRED',
  JwtTokenNotValid = 'JWT_TOKEN_NOT_VALID',
  ListingNotFound = 'LISTING_NOT_FOUND',
  LiveBidCannotAccept = 'LIVE_BID_CANNOT_ACCEPT',
  LiveProductBidNotFound = 'LIVE_PRODUCT_BID_NOT_FOUND',
  LoginDoesNotExist = 'LOGIN_DOES_NOT_EXIST',
  LoginNotValid = 'LOGIN_NOT_VALID',
  MediaBidAcceptedByAnotherBuyer = 'MEDIA_BID_ACCEPTED_BY_ANOTHER_BUYER',
  MediaBidIsNotPublished = 'MEDIA_BID_IS_NOT_PUBLISHED',
  MediaBidNotFound = 'MEDIA_BID_NOT_FOUND',
  MediaMetadataCannotBeUpdated = 'MEDIA_METADATA_CANNOT_BE_UPDATED',
  MediaTagEmpty = 'MEDIA_TAG_EMPTY',
  MediaTagIsReserved = 'MEDIA_TAG_IS_RESERVED',
  MediaTagIsTooLong = 'MEDIA_TAG_IS_TOO_LONG',
  MediaTagIsTooShort = 'MEDIA_TAG_IS_TOO_SHORT',
  MissingRequiredInputs = 'MISSING_REQUIRED_INPUTS',
  NftMetadataNotFound = 'NFT_METADATA_NOT_FOUND',
  NftNotFound = 'NFT_NOT_FOUND',
  NonceIsInvalid = 'NONCE_IS_INVALID',
  NonceNotFound = 'NONCE_NOT_FOUND',
  NotificationNotFound = 'NOTIFICATION_NOT_FOUND',
  NotActiveUser = 'NOT_ACTIVE_USER',
  NotAllowed = 'NOT_ALLOWED',
  NotAuthenticSignature = 'NOT_AUTHENTIC_SIGNATURE',
  NotAuthorizedToDeferPayment = 'NOT_AUTHORIZED_TO_DEFER_PAYMENT',
  NotAvailableForPurchaseHasLiveDrop = 'NOT_AVAILABLE_FOR_PURCHASE_HAS_LIVE_DROP',
  NotAvailableForPurchaseNoProductLock = 'NOT_AVAILABLE_FOR_PURCHASE_NO_PRODUCT_LOCK',
  NotAvailableForPurchaseReleaseNoOnchainId = 'NOT_AVAILABLE_FOR_PURCHASE_RELEASE_NO_ONCHAIN_ID',
  NotEligibleForGenerativeMint = 'NOT_ELIGIBLE_FOR_GENERATIVE_MINT',
  NotFound = 'NOT_FOUND',
  NotFoundProfile = 'NOT_FOUND_PROFILE',
  NotUnlockable = 'NOT_UNLOCKABLE',
  NotValidInviteCode = 'NOT_VALID_INVITE_CODE',
  NoAccount = 'NO_ACCOUNT',
  NoDigitalMediaId = 'NO_DIGITAL_MEDIA_ID',
  NoDigitalMediaRecord = 'NO_DIGITAL_MEDIA_RECORD',
  NoInviteCode = 'NO_INVITE_CODE',
  NoPassword = 'NO_PASSWORD',
  NoStripePaymentIntentOrMethod = 'NO_STRIPE_PAYMENT_INTENT_OR_METHOD',
  OnboardingChecklistNotComplete = 'ONBOARDING_CHECKLIST_NOT_COMPLETE',
  OpenEditionDepositMetadataStatusInvalid = 'OPEN_EDITION_DEPOSIT_METADATA_STATUS_INVALID',
  OpenEditionDropRuleError = 'OPEN_EDITION_DROP_RULE_ERROR',
  OpenEditionMinCountError = 'OPEN_EDITION_MIN_COUNT_ERROR',
  OpenEditionNotFound = 'OPEN_EDITION_NOT_FOUND',
  OpenEditionPurchaseDataValidateError = 'OPEN_EDITION_PURCHASE_DATA_VALIDATE_ERROR',
  OpenEditionPurchaseNotLive = 'OPEN_EDITION_PURCHASE_NOT_LIVE',
  OpenEditionPurchaseSaleNotEnabled = 'OPEN_EDITION_PURCHASE_SALE_NOT_ENABLED',
  OperatorContractAddressInvalid = 'OPERATOR_CONTRACT_ADDRESS_INVALID',
  OperatorContractNotFound = 'OPERATOR_CONTRACT_NOT_FOUND',
  OverRateLimited = 'OVER_RATE_LIMITED',
  PasswordLengthOverflow = 'PASSWORD_LENGTH_OVERFLOW',
  PayoutPaypalNotFound = 'PAYOUT_PAYPAL_NOT_FOUND',
  PayoutWalletAddressInvalid = 'PAYOUT_WALLET_ADDRESS_INVALID',
  PayoutWalletNotFound = 'PAYOUT_WALLET_NOT_FOUND',
  PaypalInvalidAccount = 'PAYPAL_INVALID_ACCOUNT',
  PaypalLoginError = 'PAYPAL_LOGIN_ERROR',
  PlaceCcProductBidOnExternalTokenError = 'PLACE_CC_PRODUCT_BID_ON_EXTERNAL_TOKEN_ERROR',
  PreviewFileOrUploadedFileNotProvided = 'PREVIEW_FILE_OR_UPLOADED_FILE_NOT_PROVIDED',
  ProductBidNotFound = 'PRODUCT_BID_NOT_FOUND',
  ProductBidStripePaymentCreationFailure = 'PRODUCT_BID_STRIPE_PAYMENT_CREATION_FAILURE',
  ProductBidStripePaymentIntentCreationFailure = 'PRODUCT_BID_STRIPE_PAYMENT_INTENT_CREATION_FAILURE',
  ProductIsNotFoundInNotificationCtaLink = 'PRODUCT_IS_NOT_FOUND_IN_NOTIFICATION_CTA_LINK',
  ProductIsNotOwnedByCurrentUser = 'PRODUCT_IS_NOT_OWNED_BY_CURRENT_USER',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  ProductNoLongerAvailableForPurchase = 'PRODUCT_NO_LONGER_AVAILABLE_FOR_PURCHASE',
  ProductReloadPageToPurchaseNextEdition = 'PRODUCT_RELOAD_PAGE_TO_PURCHASE_NEXT_EDITION',
  ProductUnavailableToPurchase = 'PRODUCT_UNAVAILABLE_TO_PURCHASE',
  ProductUpdateOwnerNotMatched = 'PRODUCT_UPDATE_OWNER_NOT_MATCHED',
  ProfileImageFormatNotSupported = 'PROFILE_IMAGE_FORMAT_NOT_SUPPORTED',
  ProfileImageTooLarge = 'PROFILE_IMAGE_TOO_LARGE',
  PurchaseCannotHasBothDiscountAndBasePrice = 'PURCHASE_CANNOT_HAS_BOTH_DISCOUNT_AND_BASE_PRICE',
  RankedBidFailed = 'RANKED_BID_FAILED',
  Redirect = 'REDIRECT',
  RegisterCreateUserException = 'REGISTER_CREATE_USER_EXCEPTION',
  RegisterRequestDataInvalid = 'REGISTER_REQUEST_DATA_INVALID',
  ReleaseAlreadyMinted = 'RELEASE_ALREADY_MINTED',
  ReleaseOwnershipMismatch = 'RELEASE_OWNERSHIP_MISMATCH',
  ResetProductReservePriceFailed = 'RESET_PRODUCT_RESERVE_PRICE_FAILED',
  ResumbitBidAsAHigherOfferExist = 'RESUMBIT_BID_AS_A_HIGHER_OFFER_EXIST',
  SaleInProcessing = 'SALE_IN_PROCESSING',
  SaleIsNotAllowed = 'SALE_IS_NOT_ALLOWED',
  SaleMissingApproveAll = 'SALE_MISSING_APPROVE_ALL',
  SaleNotFound = 'SALE_NOT_FOUND',
  SalePriceIsLargerThanMaxEth = 'SALE_PRICE_IS_LARGER_THAN_MAX_ETH',
  SalePriceIsLargerThanMaxUsd = 'SALE_PRICE_IS_LARGER_THAN_MAX_USD',
  SalePriceIsSmallerThanMinEth = 'SALE_PRICE_IS_SMALLER_THAN_MIN_ETH',
  SalePriceIsSmallerThanMinUsd = 'SALE_PRICE_IS_SMALLER_THAN_MIN_USD',
  SalePriceLargerThanMaxLimit = 'SALE_PRICE_LARGER_THAN_MAX_LIMIT',
  SalePriceSmallerThanLowerLimit = 'SALE_PRICE_SMALLER_THAN_LOWER_LIMIT',
  SendDisabled = 'SEND_DISABLED',
  SendNoRecipientAddress = 'SEND_NO_RECIPIENT_ADDRESS',
  SendRegexNoMatchRecipient = 'SEND_REGEX_NO_MATCH_RECIPIENT',
  SendRegexNoMatchSender = 'SEND_REGEX_NO_MATCH_SENDER',
  SendRegexNoMatchTxHash = 'SEND_REGEX_NO_MATCH_TX_HASH',
  SessionExpired = 'SESSION_EXPIRED',
  SlugConflict = 'SLUG_CONFLICT',
  SlugEthereum = 'SLUG_ETHEREUM',
  SocialAuthenticationError = 'SOCIAL_AUTHENTICATION_ERROR',
  SocialEmailMissing = 'SOCIAL_EMAIL_MISSING',
  SqlUpdateUserError = 'SQL_UPDATE_USER_ERROR',
  StripePaymentOthers = 'STRIPE_PAYMENT_OTHERS',
  StripePaymentRequiredAction = 'STRIPE_PAYMENT_REQUIRED_ACTION',
  TokenNotSendable = 'TOKEN_NOT_SENDABLE',
  TransactionCannotUpgrade = 'TRANSACTION_CANNOT_UPGRADE',
  TransactionIdInvalid = 'TRANSACTION_ID_INVALID',
  TwilioInvalidPhoneNumber = 'TWILIO_INVALID_PHONE_NUMBER',
  TwilioRateLimitReached = 'TWILIO_RATE_LIMIT_REACHED',
  TwoFactorCannotUseNewNumber = 'TWO_FACTOR_CANNOT_USE_NEW_NUMBER',
  TwoFactorCodeExpired = 'TWO_FACTOR_CODE_EXPIRED',
  TwoFactorCodeInvalid = 'TWO_FACTOR_CODE_INVALID',
  TwoFactorCodeNotFound = 'TWO_FACTOR_CODE_NOT_FOUND',
  TwoFactorMissingContactInfo = 'TWO_FACTOR_MISSING_CONTACT_INFO',
  TwoFactorNeed_2FaToChange_2Fa = 'TWO_FACTOR_NEED_2FA_TO_CHANGE_2FA',
  TwoFactorNetworkError = 'TWO_FACTOR_NETWORK_ERROR',
  TwoFactorSessionIdMissing = 'TWO_FACTOR_SESSION_ID_MISSING',
  UnlockableExpired = 'UNLOCKABLE_EXPIRED',
  UnlockableNotOnChain = 'UNLOCKABLE_NOT_ON_CHAIN',
  UnlockableShippingAddressInvalid = 'UNLOCKABLE_SHIPPING_ADDRESS_INVALID',
  UnlockableUnauthorized = 'UNLOCKABLE_UNAUTHORIZED',
  UserAddressInvalid = 'USER_ADDRESS_INVALID',
  UserIsNotCreator = 'USER_IS_NOT_CREATOR',
  UserIsSuspended = 'USER_IS_SUSPENDED',
  UserMustBeAuthenticated = 'USER_MUST_BE_AUTHENTICATED',
  UserNotCreator = 'USER_NOT_CREATOR',
  UserNotTheOwnerOfNotification = 'USER_NOT_THE_OWNER_OF_NOTIFICATION',
  UserToContractApprovalCheckoutCreatedFailed = 'USER_TO_CONTRACT_APPROVAL_CHECKOUT_CREATED_FAILED',
  WalletAlreadyConnected = 'WALLET_ALREADY_CONNECTED',
  WalletAssociatedWithAnotherAccount = 'WALLET_ASSOCIATED_WITH_ANOTHER_ACCOUNT',
  WalletConnectNotAllowed = 'WALLET_CONNECT_NOT_ALLOWED',
  WalletDisconnectNotAllowedForAnon = 'WALLET_DISCONNECT_NOT_ALLOWED_FOR_ANON',
  WalletDisconnectNotAllowedForCreationWallet = 'WALLET_DISCONNECT_NOT_ALLOWED_FOR_CREATION_WALLET',
  WalletDoesNotBelongToUser = 'WALLET_DOES_NOT_BELONG_TO_USER',
  WalletIsAlreadyPrimary = 'WALLET_IS_ALREADY_PRIMARY',
  WalletNameEmpty = 'WALLET_NAME_EMPTY',
  WalletNotFound = 'WALLET_NOT_FOUND',
  WalletRegistrationFailed = 'WALLET_REGISTRATION_FAILED'
}

/** An enumeration. */
export enum MediaTypeEnum {
  Motion = 'MOTION',
  Still = 'STILL'
}

export type MintNftArguments = {
  digitalMediaId: Scalars['Int'];
};

/**
 * Call token contract and queue Tx
 * Note: Must make sure the digital_media.can_publish is True already
 */
export type MintNftMutation = {
  __typename?: 'MintNFTMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** Top-level mutation object for gql endpoint */
export type Mutation = {
  __typename?: 'Mutation';
  /** For CC bid accept (not the 1st time) */
  acceptGlobalBidInCcWithPersonalWallet?: Maybe<AcceptGlobalBidInCreditCardWithPersonalWalletMutation>;
  acceptGlobalBidWithCustodialWallet?: Maybe<AcceptGlobalBidWithCustodialWalletMutation>;
  /** For ETH bid accept or for CC bid accept of the 1st time (MetaMask approval) */
  acceptGlobalBidWithPersonalWallet?: Maybe<AcceptGlobalBidWithPersonalWalletMutation>;
  /** For ETH bid accept or for CC bid accept of the 1st time (MetaMask approval) */
  acceptProductBidInCcWithPersonalWallet?: Maybe<AcceptProductBidInCreditCardWithPersonalWalletMutation>;
  acceptProductBidWithCustodialWallet?: Maybe<AcceptProductBidWithCustodialWalletMutation>;
  /** For CC bid accept (not the 1st time) */
  acceptProductBidWithPersonalWallet?: Maybe<AcceptProductBidWithPersonalWalletMutation>;
  addCreditCard?: Maybe<AddCreditCardMutation>;
  /**
   * Extend product lock and get data (if it's a custodial sale),
   * before the sale purchase. If it's succeed, MetaMask should pop up.
   */
  beforePurchaseInEth?: Maybe<BeforePurchaseInEthMutation>;
  cancelGlobalBidInCc?: Maybe<CancelGlobalBidInCreditCardMutation>;
  cancelGlobalBidInEth?: Maybe<CancelGlobalBidInEthMutation>;
  cancelProductBidInCc?: Maybe<CancelProductBidInCreditCardMutation>;
  cancelProductBidInEth?: Maybe<CancelProductBidInEthMutation>;
  changeFullNameAndEmail?: Maybe<ChangeFullNameAndEmailMutation>;
  changePassword?: Maybe<ChangePasswordMutation>;
  changePrimaryWallet?: Maybe<ChangePrimaryWalletMutation>;
  /** Create a comment on an NFT. */
  commentNft?: Maybe<CommentNftMutation>;
  createAccount?: Maybe<CreateAccountMutation>;
  createApproval?: Maybe<CreateApprovalMutation>;
  /** For sale contract approval */
  createApprovalWithTransaction?: Maybe<CreateApprovalWithTransactionMutation>;
  createGeneralBidOnNftInCc?: Maybe<CreateGeneralBidOnNftInCreditCardMutation>;
  createGlobalBidInCc?: Maybe<CreateGlobalBidInCreditCardMutation>;
  createGlobalBidInEth?: Maybe<CreateGlobalBidInEthMutation>;
  /** Mutation to create a Coinbase wallet ETH payout */
  createPayoutCoinbase?: Maybe<CreatePayoutCoinbaseMutation>;
  /** Mutation to create a PayPal payout */
  createPayoutPaypal?: Maybe<CreatePayoutPaypalMutation>;
  /** Mutation to create a MetaMask wallet ETH payout */
  createPayoutWallet?: Maybe<CreatePayoutWalletMutation>;
  createProductBidInCc?: Maybe<CreateProductBidInCreditCardMutation>;
  createProductBidInEth?: Maybe<CreateProductBidInEthMutation>;
  createRankedBidInCc?: Maybe<CreateRankedBidInCreditCardMutation>;
  createRankedBidInEth?: Maybe<CreateRankedBidInEthMutation>;
  /**
   * For Personal Wallet ETH Sale Create
   * Refer to v1 ProductSettings
   */
  createReservePrice?: Maybe<CreateReservePriceMutation>;
  /**
   * For Custodial wallet ETH/CC Sale Create
   * Refer to v1 ProductSettings
   */
  createSaleWithCustodialWallet?: Maybe<CreateSaleWithCustodialWalletMutation>;
  /**
   * For Personal Wallet ETH Sale Create
   * Refer to v1 PendingSaleCreate
   */
  createSaleWithPersonalWallet?: Maybe<CreateSaleWithPersonalWalletMutation>;
  /** Create category for a user's store. */
  createStoreCategory?: Maybe<CreateStoreCategory>;
  createWalletName?: Maybe<CreateWalletNameMutation>;
  deferGasRequest?: Maybe<DeferGasRequestMutation>;
  /** Delete comment on the NFT. */
  deleteCommentNft?: Maybe<DeleteCommentNftMutation>;
  deleteCreditCard?: Maybe<DeleteCreditCardMutation>;
  /** Delete the draft of the DigitalMedia and DigitalMediaRelease that not onchain yet */
  deleteDraftNft?: Maybe<DeleteDraftNftMutation>;
  /** Mutation to delete a Paypal payout */
  deletePayoutPaypal?: Maybe<DeletePayoutPaypalMutation>;
  /** Mutation to delete a MetaMask/Coinbase wallet ETH payout */
  deletePayoutWallet?: Maybe<DeletePayoutWalletMutation>;
  /** Delete store category for a user store */
  deleteStoreCategory?: Maybe<DeleteStoreCategory>;
  /**
   * For delist sale or reserve price
   * Refer to v1 ProductSettings
   */
  delistSaleOrReservePrice?: Maybe<DelistSaleOrReservePriceMutation>;
  delistSaleWithPersonalWallet?: Maybe<DelistSaleWithPersonalWalletMutation>;
  disconnectWallet?: Maybe<DisconnectWalletMutation>;
  /** Create Draft NFT and database models */
  draftNft?: Maybe<DraftNftMutation>;
  /** Subscribe to a drop family to receive SMS */
  dropSubscribe?: Maybe<DropSubscribeMutation>;
  /** Edit comment on the NFT. */
  editCommentNft?: Maybe<EditCommentNftMutation>;
  /**
   * Edit draft NFT (DigitalMedia)
   * Only allowed before it's processed or minted
   */
  editNft?: Maybe<EditNftMutation>;
  /** Muatation for editing profile for profiles V2. */
  editProfile?: Maybe<EditProfile>;
  /** Edit store category for a given user store. */
  editStoreCategory?: Maybe<EditStoreCategory>;
  /** For Personal Wallet ETH Sale Create, refer to v1 PendingSaleCreate */
  extendLockListing?: Maybe<ExtendLockListingMutation>;
  finalizeTwoFactorSetup?: Maybe<FinalizeTwoFactorSetupMutation>;
  /** Follows the emerging artist and returns an updated object for the GraphQL cache. */
  followEmerging?: Maybe<EmergingType>;
  followUser?: Maybe<FollowUserMutation>;
  followV2?: Maybe<FollowV2>;
  /** Get GasRequest and call speedup_transaction in ETH */
  gasRequestSpeedUpTx?: Maybe<GasRequestSpeedUpTxMutation>;
  /** Get GasRequest and call speedup_transaction in CC */
  gasRequestSpeedUpTxInCc?: Maybe<GasRequestSpeedUpTxInCcMutation>;
  generateSignatureMessage?: Maybe<GenerateNonceMutation>;
  ignoreGlobalBid?: Maybe<IgnoreGlobalBidMutation>;
  importTokens?: Maybe<ImportToken>;
  invalidateSessions?: Maybe<InvalidateSessionsMutation>;
  /** Log Page View of NFT */
  likeNft?: Maybe<LikeNft>;
  /** For Sale purchase lock */
  lockListing?: Maybe<LockListingMutation>;
  logEthTransaction?: Maybe<LogEthTransaction>;
  logJs?: Maybe<LogJs>;
  /** Log Page View. Supports currently NFT and Profile */
  logView?: Maybe<LogView>;
  loggingFromFrontend?: Maybe<LoggingFromFrontendMutation>;
  loginWithEmail?: Maybe<LoginWithEmailMutation>;
  loginWithWallet?: Maybe<LoginWithWalletMutation>;
  logout?: Maybe<LogoutMutation>;
  /**
   * Call token contract and queue Tx
   * Note: Must make sure the digital_media.can_publish is True already
   */
  mintNft?: Maybe<MintNftMutation>;
  muteNotifications?: Maybe<MuteNotificationsMutation>;
  /** Validate the purchase details and return the deposit request data */
  openEditionBeforePurchaseInEth?: Maybe<OpenEditionBeforePurchaseInEthMutation>;
  /**
   * Purchase OpenEdition in CC
   * It will charge the user's CC and mint the release to the buyer
   */
  openEditionPurchaseInCc?: Maybe<OpenEditionPurchaseInCcMutation>;
  /**
   * Purchase OpenEdition with personal wallet in ETH
   * It should be called after BeforeOpenEditionPurchaseInETHMutation and MetaMask posted the Tx
   */
  openEditionPurchaseInEth?: Maybe<OpenEditionPurchaseInEthMutation>;
  pendingRegistryApproval?: Maybe<PendingRegistryApproval>;
  pendingSaleCancel?: Maybe<PendingSaleCancel>;
  pendingSaleCreate?: Maybe<PendingSaleCreate>;
  purchaseInCreditCard?: Maybe<PurchaseInCreditCardMutation>;
  /**
   * Purchase with custodial wallet in ETH
   * Refer to purchase_custodial_complete
   */
  purchaseWithCustodialWallet?: Maybe<PurchaseWithCustodialWalletMutation>;
  /**
   * Purchase with personal wallet in ETH
   * Refer to purchase_complete
   */
  purchaseWithPersonalWallet?: Maybe<PurchaseWithPersonalWalletMutation>;
  /** Refresh External Token metadata */
  refreshExternalTokenMetadata?: Maybe<RefreshExternalTokenMetadata>;
  refreshToken?: Maybe<Refresh>;
  registerWallet?: Maybe<RegisterWalletMutation>;
  rejectProductBidWithCustodialWallet?: Maybe<RejectProductBidWithCustodialWalletMutation>;
  rejectProductBidWithPersonalWalletInCc?: Maybe<RejectProductBidWithPersonalWalletInCcMutation>;
  rejectProductBidWithPersonalWalletInEth?: Maybe<RejectProductBidWithPersonalWalletInEthMutation>;
  resendVerificationEmail?: Maybe<ResendVerificationEmailMutation>;
  revealProduct?: Maybe<RevealProduct>;
  /** Send an NFT */
  sendNft?: Maybe<SendNftMutation>;
  sendTwoFactorCode?: Maybe<TwoFactorMutation>;
  session?: Maybe<SessionMutation>;
  setDefaultCreditCard?: Maybe<SetDefaultCreditCardMutation>;
  /** If no ids input, it will set all notifications owned by the user to read */
  setNotificationsAsRead?: Maybe<SetNotificationsAsReadMutation>;
  setUpTwoFactor?: Maybe<SetUpTwoFactorMutation>;
  setUserStates?: Maybe<SetUserStates>;
  signGenerativeMintMessage?: Maybe<SignGenerativeMintMessage>;
  speedUpFreeMint?: Maybe<SpeedUpFreeMintMutation>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  unfollowUser?: Maybe<UnfollowUserMutation>;
  /** For Sale purchase unlock */
  unlockListing?: Maybe<UnlockListingMutation>;
  unlockNftUnlockable?: Maybe<UnlockNftUnlockableMutation>;
  /** Update NFT metadata (uploaded or preview file) */
  updateNftMetadata?: Maybe<UpdateNftMetadataMutation>;
  updateNftMetadataTag?: Maybe<UpdateNftMetadataTagMutation>;
  updateProductSettings?: Maybe<ProductSettings>;
  validateAcceptRejectEthBid?: Maybe<ValidateAcceptRejectEthBidMutation>;
  /**
   * This end point will validate the contract address, check to see if:
   * - The contract address is a valid ERC-721 token address.
   * - The contract is not exist.
   * - The submitted user is the owner of the contract address.
   * - Return the validation result and the sign params
   */
  validateContractAddress?: Maybe<ValidateContractAddress>;
  validateCreateEthBid?: Maybe<ValidateCreateEthBidMutation>;
  validateSale?: Maybe<ValidateSale>;
  verifyToken?: Maybe<Verify>;
};


/** Top-level mutation object for gql endpoint */
export type MutationAcceptGlobalBidInCcWithPersonalWalletArgs = {
  requestData: AcceptGlobalBidInCreditCardWithPersonalWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationAcceptGlobalBidWithCustodialWalletArgs = {
  requestData: AcceptGlobalBidWithCustodialWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationAcceptGlobalBidWithPersonalWalletArgs = {
  requestData: AcceptGlobalBidWithPersonalWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationAcceptProductBidInCcWithPersonalWalletArgs = {
  requestData: AcceptProductBidInCreditCardWithPersonalWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationAcceptProductBidWithCustodialWalletArgs = {
  requestData: AcceptProductBidWithCustodialWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationAcceptProductBidWithPersonalWalletArgs = {
  requestData: AcceptProductBidWithPersonalWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationAddCreditCardArgs = {
  requestData: AddCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationBeforePurchaseInEthArgs = {
  requestData: BeforePurchaseInEthArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCancelGlobalBidInCcArgs = {
  requestData: CancelGlobalBidInCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCancelGlobalBidInEthArgs = {
  requestData: CancelGlobalBidInEthArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCancelProductBidInCcArgs = {
  requestData: CancelProductBidInCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCancelProductBidInEthArgs = {
  requestData: CancelProductBidInEthArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationChangeFullNameAndEmailArgs = {
  requestData: ChangeFullNameAndEmailArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationChangePasswordArgs = {
  requestData: ChangePasswordArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationChangePrimaryWalletArgs = {
  requestData: ChangePrimaryWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCommentNftArgs = {
  requestData: CommentNftArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateAccountArgs = {
  requestData: RegisterArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateApprovalArgs = {
  requestData: CreateApprovalArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateApprovalWithTransactionArgs = {
  requestData: CreateApprovalWithTransactionArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateGeneralBidOnNftInCcArgs = {
  requestData: CreateGeneralBidOnNftInCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateGlobalBidInCcArgs = {
  requestData: CreateGlobalBidInCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateGlobalBidInEthArgs = {
  requestData: CreateGlobalBidInEthArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreatePayoutCoinbaseArgs = {
  requestData: CreatePayoutCoinbaseArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreatePayoutPaypalArgs = {
  requestData: CreatePayoutPaypalArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreatePayoutWalletArgs = {
  requestData: CreatePayoutWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateProductBidInCcArgs = {
  requestData: CreateProductBidInCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateProductBidInEthArgs = {
  requestData: CreateProductBidInEthArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateRankedBidInCcArgs = {
  requestData: CreateRankedBidInCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateRankedBidInEthArgs = {
  requestData: CreateRankedBidInEthArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateReservePriceArgs = {
  requestData: CreateReservePriceArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateSaleWithCustodialWalletArgs = {
  requestData: CreateSaleWithCustodialWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateSaleWithPersonalWalletArgs = {
  requestData: CreateSaleWithPersonalWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateStoreCategoryArgs = {
  categoryData: CreateCategoryInput;
};


/** Top-level mutation object for gql endpoint */
export type MutationCreateWalletNameArgs = {
  requestData: CreateWalletNameArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationDeferGasRequestArgs = {
  requestData: DeferGasRequestArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationDeleteCommentNftArgs = {
  requestData: DeleteCommentNftArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationDeleteCreditCardArgs = {
  requestData: DeleteCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationDeleteDraftNftArgs = {
  requestData: DeleteDraftNftArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationDeleteStoreCategoryArgs = {
  categoryData: DeleteStoreCategoryInput;
};


/** Top-level mutation object for gql endpoint */
export type MutationDelistSaleOrReservePriceArgs = {
  requestData: DelistCustodialSaleArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationDelistSaleWithPersonalWalletArgs = {
  requestData: DelistSaleWithPersonalWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationDisconnectWalletArgs = {
  requestData: DisconnectWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationDraftNftArgs = {
  requestData: DraftNftArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationDropSubscribeArgs = {
  requestData: DropSubscribeArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationEditCommentNftArgs = {
  requestData: EditCommentNftArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationEditNftArgs = {
  requestData: EditNftArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationEditProfileArgs = {
  profileData: ProfileInput;
};


/** Top-level mutation object for gql endpoint */
export type MutationEditStoreCategoryArgs = {
  categoryData: UpdateStoreCategoryInput;
};


/** Top-level mutation object for gql endpoint */
export type MutationExtendLockListingArgs = {
  requestData: ExtendLockListingArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationFinalizeTwoFactorSetupArgs = {
  requestData: FinalizeTwoFactorSetupArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationFollowEmergingArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** Top-level mutation object for gql endpoint */
export type MutationFollowUserArgs = {
  requestData: FollowUnfollowUserArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationFollowV2Args = {
  follow: Scalars['Boolean'];
  globalSlug: Scalars['String'];
};


/** Top-level mutation object for gql endpoint */
export type MutationGasRequestSpeedUpTxArgs = {
  requestData: GasRequestArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationGasRequestSpeedUpTxInCcArgs = {
  requestData: GasRequestSpeedUpTxInCcArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationGenerateSignatureMessageArgs = {
  requestData: GenerateNonceArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationIgnoreGlobalBidArgs = {
  requestData: IgnoreGlobalBidArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationImportTokensArgs = {
  contractAddress: Scalars['String'];
  nonce: Scalars['String'];
  signature: Scalars['String'];
};


/** Top-level mutation object for gql endpoint */
export type MutationLikeNftArgs = {
  requestData: LikeNftArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationLockListingArgs = {
  requestData: LockListingArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationLogEthTransactionArgs = {
  requestData: LogEthTransactionArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationLogJsArgs = {
  eventData: Scalars['String'];
  eventType: Scalars['String'];
};


/** Top-level mutation object for gql endpoint */
export type MutationLogViewArgs = {
  requestData: LogViewArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationLoggingFromFrontendArgs = {
  requestData: LoggingFromFrontendArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationLoginWithEmailArgs = {
  requestData: LoginArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationLoginWithWalletArgs = {
  requestData: LoginWithWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationMintNftArgs = {
  requestData: MintNftArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationMuteNotificationsArgs = {
  requestData: MuteNotificationsArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationOpenEditionBeforePurchaseInEthArgs = {
  requestData: OpenEditionBeforePurchaseInEthArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationOpenEditionPurchaseInCcArgs = {
  requestData: OpenEditionPurchaseInCcArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationOpenEditionPurchaseInEthArgs = {
  requestData: OpenEditionPurchaseInEthArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationPendingRegistryApprovalArgs = {
  approvalContractAddress: Scalars['String'];
  fromAddress: Scalars['String'];
  nonce: Scalars['String'];
  operatorContractAddress: Scalars['String'];
  transactionId: Scalars['String'];
  userAddress: Scalars['String'];
};


/** Top-level mutation object for gql endpoint */
export type MutationPendingSaleCancelArgs = {
  fromAddress: Scalars['String'];
  nonce: Scalars['String'];
  saleId: Scalars['Int'];
  transactionId: Scalars['String'];
};


/** Top-level mutation object for gql endpoint */
export type MutationPendingSaleCreateArgs = {
  fromAddress: Scalars['String'];
  nonce: Scalars['String'];
  price: Scalars['String'];
  releaseId: Scalars['Int'];
  transactionId: Scalars['String'];
};


/** Top-level mutation object for gql endpoint */
export type MutationPurchaseInCreditCardArgs = {
  requestData: PurchaseInCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationPurchaseWithCustodialWalletArgs = {
  requestData: PurchaseWithCustodialWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationPurchaseWithPersonalWalletArgs = {
  requestData: PurchaseWithPersonalWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationRefreshExternalTokenMetadataArgs = {
  requestData: RefreshManifoldTokenArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationRefreshTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


/** Top-level mutation object for gql endpoint */
export type MutationRegisterWalletArgs = {
  requestData: RegisterWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationRejectProductBidWithCustodialWalletArgs = {
  requestData: RejectProductBidWithCustodialWalletArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationRejectProductBidWithPersonalWalletInCcArgs = {
  requestData: RejectProductBidWithPersonalWalletInCcArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationRejectProductBidWithPersonalWalletInEthArgs = {
  requestData: RejectProductBidWithPersonalWalletInEthArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationRevealProductArgs = {
  digitalMediaId: Scalars['ID'];
};


/** Top-level mutation object for gql endpoint */
export type MutationSendNftArgs = {
  requestData: SendNftArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationSendTwoFactorCodeArgs = {
  requestData: TwoFactorArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationSetDefaultCreditCardArgs = {
  requestData: SetDefaultCreditCardArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationSetNotificationsAsReadArgs = {
  requestData: SetReadNotificationsArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationSetUpTwoFactorArgs = {
  requestData: SetUpTwoFactorArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationSetUserStatesArgs = {
  userStateIntValue?: InputMaybe<Scalars['Int']>;
  userStateName: Scalars['String'];
  userStateValue?: InputMaybe<Scalars['String']>;
};


/** Top-level mutation object for gql endpoint */
export type MutationSignGenerativeMintMessageArgs = {
  requestData: SignGenerativeMintMessageArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationSpeedUpFreeMintArgs = {
  requestData: SpeedUpFreeMintArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** Top-level mutation object for gql endpoint */
export type MutationUnfollowUserArgs = {
  requestData: FollowUnfollowUserArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationUnlockListingArgs = {
  requestData: UnlockListingArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationUnlockNftUnlockableArgs = {
  requestData: UnlockNftUnlockableArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationUpdateNftMetadataArgs = {
  requestData: UpdateNftMetadataArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationUpdateNftMetadataTagArgs = {
  requestData: UpdateNftMetadataTagArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationUpdateProductSettingsArgs = {
  auctionDate?: InputMaybe<Scalars['String']>;
  availableForSale?: InputMaybe<Scalars['Boolean']>;
  delist?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  reserveCurrency?: InputMaybe<Scalars['String']>;
  reservePrice?: InputMaybe<Scalars['Float']>;
  saleId?: InputMaybe<Scalars['ID']>;
  salePrice?: InputMaybe<Scalars['Float']>;
};


/** Top-level mutation object for gql endpoint */
export type MutationValidateAcceptRejectEthBidArgs = {
  requestData: ValidateAcceptRejectEthBidArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationValidateContractAddressArgs = {
  contractAddress: Scalars['String'];
};


/** Top-level mutation object for gql endpoint */
export type MutationValidateCreateEthBidArgs = {
  requestData: ValidateCreateEthBidArguments;
};


/** Top-level mutation object for gql endpoint */
export type MutationValidateSaleArgs = {
  price: Scalars['String'];
  releaseId: Scalars['Int'];
};


/** Top-level mutation object for gql endpoint */
export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type MuteNotificationsArguments = {
  isMuted: Scalars['Boolean'];
  objectAction: NotificationActionEnum;
};

export type MuteNotificationsMutation = {
  __typename?: 'MuteNotificationsMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type NftAbstractType = GlobalIdConnectionNode & {
  __typename?: 'NFTAbstractType';
  authenticityUrl?: Maybe<Scalars['String']>;
  badgeType: BadgeTypeEnum;
  burnedAt?: Maybe<Scalars['DateTime']>;
  contract?: Maybe<NftEthereumContractType>;
  createdAt: Scalars['DateTime'];
  createdSinceStr?: Maybe<Scalars['String']>;
  currentEthRate?: Maybe<Scalars['Float']>;
  currentOwner?: Maybe<AccountAbstractType>;
  currentOwnerAddress?: Maybe<Scalars['String']>;
  editionsRemaining?: Maybe<Scalars['Int']>;
  ethTx?: Maybe<TransactionAbstractType>;
  generatedProductSlug?: Maybe<Scalars['String']>;
  hasApproveAllRights?: Maybe<Scalars['Boolean']>;
  hasDeferredGasFees?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isCustodialOwner: Scalars['Boolean'];
  isGenerative?: Maybe<Scalars['Boolean']>;
  isLive: Scalars['Boolean'];
  isOnHold?: Maybe<Scalars['Boolean']>;
  isPending?: Maybe<Scalars['Boolean']>;
  isSendable?: Maybe<Scalars['Boolean']>;
  isSpedUp?: Maybe<Scalars['Boolean']>;
  isTxPending: Scalars['Boolean'];
  isVisible?: Maybe<Scalars['Boolean']>;
  likesCount: Scalars['Int'];
  listing?: Maybe<NftListingAbstractType>;
  manifoldTokenUri?: Maybe<Scalars['String']>;
  metadata?: Maybe<NftMetadataAbstractType>;
  onchainCreatedAt?: Maybe<Scalars['DateTime']>;
  onchainId?: Maybe<Scalars['String']>;
  openseaUrl?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  printEdition: Scalars['Int'];
  provenance?: Maybe<Array<Maybe<ProvenanceType>>>;
  shareCount: Scalars['Int'];
  shouldSupportGlobalBid?: Maybe<Scalars['Boolean']>;
  tag: TagTypeEnum;
  transaction?: Maybe<TransactionAbstractType>;
  unlockable?: Maybe<NftUnlockable>;
  viewCount: Scalars['Int'];
};

export type NftAbstractTypeConnection = {
  __typename?: 'NFTAbstractTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftAbstractTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTAbstractType` and its cursor. */
export type NftAbstractTypeEdge = {
  __typename?: 'NFTAbstractTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftAbstractType>;
};

export type NftAbstractUnion = NftAbstractType | UnMintedNftAbstractType;

export type NftAdditionalMediaType = GlobalIdConnectionNode & {
  __typename?: 'NFTAdditionalMediaType';
  createdAt: Scalars['DateTime'];
  digitalMedia?: Maybe<NftMetadataAbstractType>;
  externalContentUrl?: Maybe<Scalars['String']>;
  externalThumbnailUrl?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  rank: Scalars['Int'];
  rawfileExtension: Scalars['String'];
  unlockable?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
};

/**
 * Querying a Union:
 * https://www.apollographql.com/docs/apollo-server/schema/unions-interfaces/#querying-a-union
 */
export type NftAuctionAbstractUnion = NftAuctionType | NftRankedAuctionType;

export type NftAuctionType = GlobalIdConnectionNode & {
  __typename?: 'NFTAuctionType';
  auctionEnded?: Maybe<Scalars['Boolean']>;
  auctionType?: Maybe<AuctionTypeEnum>;
  createdAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  forceEndOnCountdown: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  info?: Maybe<Scalars['String']>;
};

/**
 * A GraphQL Type that can be translated into any of our internal bid types:
 * ProductBid, GlobalBid and RankedAuctionBids.
 */
export type NftBidAbstractUnion = NftBidType | NftRankedBidType;

export type NftBidType = GlobalIdConnectionNode & {
  __typename?: 'NFTBidType';
  bidAmount?: Maybe<Scalars['Float']>;
  bidInEther?: Maybe<Scalars['Float']>;
  bidInUsd?: Maybe<Scalars['Float']>;
  bidType?: Maybe<ProductTypeBidEnum>;
  bidderAddress?: Maybe<Scalars['HexString']>;
  bidderUser?: Maybe<AccountAbstractType>;
  contract?: Maybe<NftEthereumContractType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isBidConfirmed?: Maybe<Scalars['Boolean']>;
  isCcBid?: Maybe<Scalars['Boolean']>;
  isEtherBid?: Maybe<Scalars['Boolean']>;
  isGlobalBid?: Maybe<Scalars['Boolean']>;
  isGlobalBidIgnored?: Maybe<Scalars['Boolean']>;
  onchainId?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
};

export type NftCollectorHistoryType = {
  __typename?: 'NFTCollectorHistoryType';
  collector?: Maybe<AccountAbstractType>;
  collectorAddress?: Maybe<Scalars['HexString']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdSince?: Maybe<Scalars['String']>;
  listing?: Maybe<NftListingAbstractType>;
  nft?: Maybe<NftAbstractType>;
  purchaseHistory?: Maybe<NftPurchaseHistoryType>;
  sale?: Maybe<NftSaleType>;
};

export type NftCommentType = GlobalIdConnectionNode & {
  __typename?: 'NFTCommentType';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['Int']>;
  pk: Scalars['ID'];
  replyToUser?: Maybe<AccountAbstractType>;
  threadCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<AccountAbstractType>;
};

export type NftCommentTypeConnection = {
  __typename?: 'NFTCommentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftCommentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTCommentType` and its cursor. */
export type NftCommentTypeEdge = {
  __typename?: 'NFTCommentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftCommentType>;
};

export type NftConnection = {
  __typename?: 'NFTConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

export type NftDropSection = {
  __typename?: 'NFTDropSection';
  sectionType: DropSectionEnum;
  subSections?: Maybe<Array<Maybe<NftDropSubSection>>>;
};

export type NftDropSubSection = {
  __typename?: 'NFTDropSubSection';
  items?: Maybe<Array<Maybe<NftMetadataAbstractType>>>;
  subSectionType: DropSubSectionEnum;
};

/** A Relay edge containing a `NFT` and its cursor. */
export type NftEdge = {
  __typename?: 'NFTEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftAbstractType>;
};

export type NftEthereumContractType = GlobalIdConnectionNode & {
  __typename?: 'NFTEthereumContractType';
  abidata: Scalars['String'];
  address: Scalars['String'];
  etherscanUrl?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isExternal?: Maybe<Scalars['Boolean']>;
  isGenerative?: Maybe<Scalars['Boolean']>;
  pk: Scalars['String'];
  symbol: Scalars['String'];
  title: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
};

export type NftExhibitType = GlobalIdConnectionNode & {
  __typename?: 'NFTExhibitType';
  curator?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  vendor?: Maybe<NftExhibitVendorType>;
  viewAllUrl?: Maybe<Scalars['String']>;
};

export type NftExhibitTypeConnection = {
  __typename?: 'NFTExhibitTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftExhibitTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTExhibitType` and its cursor. */
export type NftExhibitTypeEdge = {
  __typename?: 'NFTExhibitTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftExhibitType>;
};

export type NftExhibitVendorType = GlobalIdConnectionNode & {
  __typename?: 'NFTExhibitVendorType';
  createdAt: Scalars['DateTime'];
  digitalMediaRelease?: Maybe<NftAbstractType>;
  exhibit: NftExhibitType;
  /** The ID of the object. */
  id: Scalars['ID'];
  nft?: Maybe<NftAbstractType>;
  order: Scalars['Int'];
  pk: Scalars['ID'];
  publishAt?: Maybe<Scalars['DateTime']>;
  state: ExhibitVendorState;
  unpublishAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<AccountAbstractType>;
};

export type NftExhibitVendorTypeConnection = {
  __typename?: 'NFTExhibitVendorTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftExhibitVendorTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTExhibitVendorType` and its cursor. */
export type NftExhibitVendorTypeEdge = {
  __typename?: 'NFTExhibitVendorTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftExhibitVendorType>;
};

export type NftExibitTileType = GlobalIdConnectionNode & {
  __typename?: 'NFTExibitTileType';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  curatorDisplayName?: Maybe<Scalars['String']>;
  curatorName?: Maybe<Scalars['String']>;
  curatorPreferredName?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isVideo?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  pk: Scalars['ID'];
  s3BackgroundFilename: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  viewUrl?: Maybe<Scalars['String']>;
};

export type NftExibitTileTypeConnection = {
  __typename?: 'NFTExibitTileTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftExibitTileTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTExibitTileType` and its cursor. */
export type NftExibitTileTypeEdge = {
  __typename?: 'NFTExibitTileTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftExibitTileType>;
};

export type NftFloorPriceNode = {
  __typename?: 'NFTFloorPriceNode';
  floorPriceInEth?: Maybe<Scalars['Float']>;
  floorPriceInUsd?: Maybe<Scalars['Float']>;
};

/** The NFTListing type provides "market" meta for the NFT. E.g. live sale info, bids, the listing url */
export type NftListingAbstractType = GlobalIdConnectionNode & {
  __typename?: 'NFTListingAbstractType';
  acceptsFiatOffers?: Maybe<Scalars['Boolean']>;
  acceptsFiatPurchase?: Maybe<Scalars['Boolean']>;
  auction?: Maybe<NftAuctionAbstractUnion>;
  availableForBid?: Maybe<Scalars['Boolean']>;
  availableForPurchase?: Maybe<Scalars['Boolean']>;
  availableForSale: Scalars['Boolean'];
  canReserveEditions?: Maybe<Scalars['Boolean']>;
  custodialSaleEnabled?: Maybe<Scalars['Boolean']>;
  disableBidAccepts: Scalars['Boolean'];
  fullNftUrl?: Maybe<Scalars['String']>;
  fullNftUrlLegacy?: Maybe<Scalars['String']>;
  hasReservePrice?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isBidAccepted?: Maybe<Scalars['Boolean']>;
  lastSale?: Maybe<NftPurchaseHistoryType>;
  liveBid?: Maybe<NftBidType>;
  liveSale?: Maybe<NftSaleType>;
  lowestAskInEth?: Maybe<Scalars['Float']>;
  lowestAskInUsd?: Maybe<Scalars['Float']>;
  pk: Scalars['ID'];
  productSlug: Scalars['String'];
  rankedAuctionLeaderboard?: Maybe<Array<Maybe<NftBidAbstractUnion>>>;
  reserveCurrency: ReserveCurrencyEnum;
  reservePriceInEth?: Maybe<Scalars['Float']>;
  reservePriceInUsd?: Maybe<Scalars['Float']>;
  reservePriceMet: Scalars['Boolean'];
  saleCommission?: Maybe<Scalars['Float']>;
  shouldUseDropSaleContract?: Maybe<Scalars['Boolean']>;
  store?: Maybe<StoreTypeV3>;
};

export type NftListingAbstractTypeConnection = {
  __typename?: 'NFTListingAbstractTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftListingAbstractTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTListingAbstractType` and its cursor. */
export type NftListingAbstractTypeEdge = {
  __typename?: 'NFTListingAbstractTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftListingAbstractType>;
};

/**
 * Querying a Union:
 * https://www.apollographql.com/docs/apollo-server/schema/unions-interfaces/#querying-a-union
 *
 * For Example:
 * >>> query {
 * ...     nfts(...) {
 * ...         edges: {
 * ...             node: {
 * ...                 listing: {
 * ...                     __typename
 * ...                     ... on NFTListingAbstractType {
 * ...                         pk
 * ...                         ...
 * ...                     }
 * ...                     ... on NFTOpenEditionListingType {
 * ...                         pk
 * ...                         ...
 * ...                     }
 * ...                     ... on NFTMysteryBoxListingType {
 * ...                         pk
 * ...                         ...
 * ...                     }
 * ...                     ... on NFTRaffleListingType {
 * ...                         pk
 * ...                         ...
 * ...                     }
 * ...                 }
 * ...             }
 * ...         }
 * ...     }
 * ... }
 */
export type NftListingAbstractUnion = NftListingAbstractType | NftMysteryBoxListingType | NftOpenEditionListingType | NftRaffleListingType;

export type NftMediaMetadataType = GlobalIdConnectionNode & {
  __typename?: 'NFTMediaMetadataType';
  filesize?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  width?: Maybe<Scalars['Int']>;
};

/** The NFTMetadata type serves as a source of "additional" meta for NFTs. This range from author context to image         dimensions to NFT traits. */
export type NftMetadataAbstractType = GlobalIdConnectionNode & {
  __typename?: 'NFTMetadataAbstractType';
  additionalContentDownloadUrl?: Maybe<Scalars['String']>;
  additionalDetails?: Maybe<Scalars['String']>;
  applySamePrice: Scalars['Boolean'];
  artworkImageUrl?: Maybe<Scalars['String']>;
  artworkVideoUrl?: Maybe<Scalars['String']>;
  author?: Maybe<AccountAbstractType>;
  canCreatorPublish: Scalars['Boolean'];
  canPublish: Scalars['Boolean'];
  cardImage?: Maybe<Scalars['String']>;
  cardVideoUrl?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Maybe<CollaboratorType>>>;
  collection?: Maybe<CollectionAbstractType>;
  collectorReward?: Maybe<CollectorRewardType>;
  commentCount?: Maybe<Scalars['Int']>;
  contract?: Maybe<NftEthereumContractType>;
  currentAccountComment?: Maybe<Scalars['Boolean']>;
  currentAccountLike?: Maybe<Scalars['Boolean']>;
  currentEthRate?: Maybe<Scalars['Float']>;
  currentPrintIndex: Scalars['Int'];
  description: Scalars['String'];
  descriptionImageUrl?: Maybe<Scalars['String']>;
  descriptionVideoUrl?: Maybe<Scalars['String']>;
  dropMetadata?: Maybe<DropMediaMetadataType>;
  hasAudio?: Maybe<Scalars['Boolean']>;
  hasVideo?: Maybe<Scalars['Boolean']>;
  highResImage?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  immutable: Scalars['Boolean'];
  isAudioMuted?: Maybe<Scalars['Boolean']>;
  isAwaitingApproval: Scalars['Boolean'];
  isBurned: Scalars['Boolean'];
  isDraftReady?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isSoldOut?: Maybe<Scalars['Boolean']>;
  isUpdateable?: Maybe<Scalars['Boolean']>;
  latestAvailableNft?: Maybe<NftAbstractType>;
  likesCount: Scalars['Int'];
  mediaMetadata?: Maybe<NftMediaMetadataType>;
  metadataFullUrl?: Maybe<Scalars['String']>;
  metadataUrl?: Maybe<Scalars['String']>;
  nftAdditionalMediasRanked?: Maybe<Array<Maybe<NftAdditionalMediaType>>>;
  nftCollection?: Maybe<CollectionAbstractType>;
  nftCount?: Maybe<Scalars['Int']>;
  nftTraits?: Maybe<Array<Maybe<NftTraitsType>>>;
  nfts?: Maybe<Array<Maybe<NftAbstractType>>>;
  onchainId?: Maybe<Scalars['String']>;
  openEdition?: Maybe<NftOpenEditionListingType>;
  ownerAddress?: Maybe<Scalars['String']>;
  pendingReleaseCount?: Maybe<Scalars['Int']>;
  pendingState: Scalars['Boolean'];
  pk: Scalars['ID'];
  previewImage?: Maybe<Scalars['String']>;
  previewImageHeight?: Maybe<Scalars['Int']>;
  previewImageWidth?: Maybe<Scalars['Int']>;
  processingState: DigitalMediaProcessedEnum;
  rawAwsUrl?: Maybe<Scalars['String']>;
  rawMediaUrl?: Maybe<Scalars['String']>;
  rawfileExtension: Scalars['String'];
  standardImage?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  thumbnailImage?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalSupply: Scalars['Int'];
  videoUrl?: Maybe<Scalars['String']>;
  viewCount: Scalars['Int'];
};


/** The NFTMetadata type serves as a source of "additional" meta for NFTs. This range from author context to image         dimensions to NFT traits. */
export type NftMetadataAbstractTypeNftsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type NftMetadataAbstractTypeConnection = {
  __typename?: 'NFTMetadataAbstractTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftMetadataAbstractTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTMetadataAbstractType` and its cursor. */
export type NftMetadataAbstractTypeEdge = {
  __typename?: 'NFTMetadataAbstractTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftMetadataAbstractType>;
};

export type NftMetadataConnectionTypeConnection = {
  __typename?: 'NFTMetadataConnectionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftMetadataConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTMetadataConnectionType` and its cursor. */
export type NftMetadataConnectionTypeEdge = {
  __typename?: 'NFTMetadataConnectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftMetadataAbstractType>;
};

/**
 * Represents an OpenEditionBundle, which is the metadata of an OpenEdition indicating
 * that the DigitalMedia is a bundle containing multiple OpenEditions.
 * This can be accessed from `UnMintedNFTAbstractType.listing`.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type NftMysteryBoxListingType = GlobalIdConnectionNode & {
  __typename?: 'NFTMysteryBoxListingType';
  bundleDetails: Scalars['String'];
  bundleSize: Scalars['Int'];
  bundleTitle?: Maybe<Scalars['String']>;
  dropsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
};

/**
 * Represents an OpenEdition, which is a 1-to-1 relationship with DigitalMedia.
 * This can be accessed from `UnMintedNFTAbstractType.listing`.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type NftOpenEditionListingType = GlobalIdConnectionNode & {
  __typename?: 'NFTOpenEditionListingType';
  createdAt: Scalars['DateTime'];
  dropsAt?: Maybe<Scalars['DateTime']>;
  editionsSold?: Maybe<Scalars['Int']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  hasUnlockable: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isCollectionDrop?: Maybe<Scalars['Boolean']>;
  isGenerative?: Maybe<Scalars['Boolean']>;
  leaderboard?: Maybe<Array<Maybe<OpenEditionLeaderboardBuyerAbstractType>>>;
  nftMetadata?: Maybe<NftMetadataAbstractType>;
  numSoldStartsCountdown: Scalars['Int'];
  oeType: OpenEditionOeType;
  pk: Scalars['ID'];
  presalePriceInEther?: Maybe<Scalars['Float']>;
  presalePriceInUsd?: Maybe<Scalars['Float']>;
  priceInCents: Scalars['Int'];
  priceInEther?: Maybe<Scalars['Float']>;
  priceInUsd?: Maybe<Scalars['Float']>;
  reservePriceInWei: Scalars['Decimal'];
  reservePriceMet: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  unlockableDescription?: Maybe<Scalars['String']>;
  viewOnly: Scalars['Boolean'];
};


/**
 * Represents an OpenEdition, which is a 1-to-1 relationship with DigitalMedia.
 * This can be accessed from `UnMintedNFTAbstractType.listing`.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type NftOpenEditionListingTypeLeaderboardArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type NftOwnersipPcts = {
  percentage: Scalars['Float'];
  username: Scalars['String'];
};

export type NftPriceType = {
  __typename?: 'NFTPriceType';
  priceInEth?: Maybe<Scalars['Float']>;
  priceInUsd?: Maybe<Scalars['Float']>;
};

export type NftPurchaseHistoryType = GlobalIdConnectionNode & {
  __typename?: 'NFTPurchaseHistoryType';
  aquiredSince?: Maybe<Scalars['String']>;
  buyer?: Maybe<AccountSessionType>;
  buyerAddress: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  digitalMediaRelease: NftAbstractType;
  digitalMediaSale?: Maybe<DigitalMediaSaleTypeV2>;
  discountCode?: Maybe<Scalars['String']>;
  discountCodeType?: Maybe<ProductPurchaseHistoryDiscountCodeType>;
  discountedSalePrice?: Maybe<Scalars['Int']>;
  ethPriceAtSale: Scalars['Float'];
  ethTx?: Maybe<TransactionAbstractType>;
  fiatConversionSalePrice?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  orderNumber?: Maybe<Scalars['String']>;
  ownerAddress: Scalars['String'];
  pk: Scalars['ID'];
  product: NftListingAbstractType;
  rankedAuctionBid?: Maybe<NftRankedBidType>;
  relatedSale?: Maybe<NftSaleType>;
  returnDiscountEthTxId?: Maybe<Scalars['Int']>;
  returnDiscountTransaction?: Maybe<Scalars['String']>;
  saleCommissionPercentage: Scalars['Decimal'];
  saleContractAddress: Scalars['String'];
  salePrice: Scalars['Decimal'];
  stripeFeePercentage: Scalars['Decimal'];
  stripeIntentId?: Maybe<Scalars['String']>;
  tokenContractAddress: Scalars['String'];
  tokenId: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  usdPriceAtSale: Scalars['Float'];
};

/**
 * Represents a Raffle, which is a 1-to-1 relationship with DigitalMedia.
 * This can be accessed from `NftAbstractType.listing`.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type NftRaffleListingType = GlobalIdConnectionNode & {
  __typename?: 'NFTRaffleListingType';
  additionalDetails?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dropsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  numRequiredPurchases: Scalars['Int'];
  numWinners: Scalars['Int'];
  pk: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
};

export type NftRankedAuctionType = GlobalIdConnectionNode & {
  __typename?: 'NFTRankedAuctionType';
  auctionEnded?: Maybe<Scalars['Boolean']>;
  auctionType?: Maybe<AuctionTypeEnum>;
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  endsAt?: Maybe<Scalars['DateTime']>;
  highestBid?: Maybe<NftRankedBidType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  lastBid?: Maybe<NftRankedBidType>;
  lastBidType?: Maybe<ProductTypeBidEnum>;
  leaderboard?: Maybe<Array<Maybe<NftRankedBidType>>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status: RankedAuctionStatus;
  tokenContract?: Maybe<NftEthereumContractType>;
  totalBids?: Maybe<Scalars['Int']>;
  totalPartialBids?: Maybe<Scalars['Int']>;
};


export type NftRankedAuctionTypeLeaderboardArgs = {
  sortAsc?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

/**
 * Front-end implementation of the `storefront.models.RankedAuctionBid` model.
 * In a RankedAuction, users compete to be on the leaderboard. At the end of the auction,
 * NFTs are distributed to the top bidders.
 * Note: the fields need to aligned with the NFTBidType as they are Union types in NFTBidAbstractUnion
 */
export type NftRankedBidType = GlobalIdConnectionNode & {
  __typename?: 'NFTRankedBidType';
  amountInCents: Scalars['Int'];
  auctionContract: NftEthereumContractType;
  auctionEnded?: Maybe<Scalars['Boolean']>;
  bidAmount?: Maybe<Scalars['Float']>;
  bidInEther?: Maybe<Scalars['Float']>;
  bidInUsd?: Maybe<Scalars['Float']>;
  bidType?: Maybe<ProductTypeBidEnum>;
  bidderAddress?: Maybe<Scalars['HexString']>;
  bidderEmail?: Maybe<Scalars['String']>;
  bidderUser?: Maybe<AccountAbstractType>;
  contract?: Maybe<NftEthereumContractType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ethTx?: Maybe<TransactionAbstractType>;
  finalizationStatus?: Maybe<RankedAuctionBidFinalizationStatus>;
  /** The ID of the object. */
  id: Scalars['ID'];
  intentId?: Maybe<Scalars['String']>;
  isBidConfirmed?: Maybe<Scalars['Boolean']>;
  isCcBid?: Maybe<Scalars['Boolean']>;
  isEtherBid?: Maybe<Scalars['Boolean']>;
  isGlobalBid?: Maybe<Scalars['Boolean']>;
  isGlobalBidIgnored?: Maybe<Scalars['Boolean']>;
  onchainId?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  postedAt?: Maybe<Scalars['DateTime']>;
  productAccepted?: Maybe<NftListingAbstractType>;
  productpurchasehistorySet: ProductPurchaseHistoryTypeConnection;
  rankedAuction: NftRankedAuctionType;
  status: RankedAuctionBidStatus;
  updatedAt: Scalars['DateTime'];
};


/**
 * Front-end implementation of the `storefront.models.RankedAuctionBid` model.
 * In a RankedAuction, users compete to be on the leaderboard. At the end of the auction,
 * NFTs are distributed to the top bidders.
 * Note: the fields need to aligned with the NFTBidType as they are Union types in NFTBidAbstractUnion
 */
export type NftRankedBidTypeProductpurchasehistorySetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type NftRankedBidTypeConnection = {
  __typename?: 'NFTRankedBidTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftRankedBidTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTRankedBidType` and its cursor. */
export type NftRankedBidTypeEdge = {
  __typename?: 'NFTRankedBidTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftRankedBidType>;
};

export type NftRecentHistoryType = {
  __typename?: 'NFTRecentHistoryType';
  collector?: Maybe<AccountAbstractType>;
  collectorAddress?: Maybe<Scalars['HexString']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ensDomain?: Maybe<Scalars['HexString']>;
  price?: Maybe<NftPriceType>;
  reason?: Maybe<Scalars['String']>;
  sender?: Maybe<AccountAbstractType>;
  sinceStr?: Maybe<Scalars['String']>;
};

export type NftRecentOwnersType = {
  __typename?: 'NFTRecentOwnersType';
  auction?: Maybe<NftAuctionType>;
  bid?: Maybe<NftBidType>;
  bidUpdatedStr?: Maybe<Scalars['String']>;
  ensDomain?: Maybe<Scalars['HexString']>;
  issuedSinceStr?: Maybe<Scalars['String']>;
  lastSale?: Maybe<NftPurchaseHistoryType>;
  nft?: Maybe<NftAbstractType>;
  owner?: Maybe<AccountAbstractType>;
  ownerAddress?: Maybe<Scalars['HexString']>;
  price?: Maybe<NftPriceType>;
  sale?: Maybe<NftSaleType>;
  saleUpdatedStr?: Maybe<Scalars['String']>;
  transferredSinceStr?: Maybe<Scalars['String']>;
};

export enum NftSaleStatusEnum {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Disabled = 'DISABLED',
  DisputedSale = 'DISPUTED_SALE',
  Live = 'LIVE',
  New = 'NEW',
  PendingCancelled = 'PENDING_CANCELLED',
  PendingLive = 'PENDING_LIVE',
  PendingSale = 'PENDING_SALE',
  Sold = 'SOLD'
}

/** Can be used to refer to either a live or past sale. */
export type NftSaleType = GlobalIdConnectionNode & {
  __typename?: 'NFTSaleType';
  acceptFiat: Scalars['Boolean'];
  contract?: Maybe<NftEthereumContractType>;
  createdAt: Scalars['DateTime'];
  custodialPresalePriceEth?: Maybe<Scalars['Float']>;
  custodialPresalePriceInCents?: Maybe<Scalars['Int']>;
  custodialPresalePriceUsd?: Maybe<Scalars['Float']>;
  custodialPriceEth?: Maybe<Scalars['Float']>;
  custodialPriceInCents: Scalars['Int'];
  custodialPriceInWei: Scalars['Decimal'];
  custodialPriceUsd?: Maybe<Scalars['Float']>;
  digitalMediaRelease?: Maybe<NftAbstractType>;
  discountedFromPriceInCents: Scalars['Int'];
  enableCustodialSale: Scalars['Boolean'];
  ethTx?: Maybe<TransactionAbstractType>;
  fixedpricesale?: Maybe<FixedPriceSaleType>;
  fromAddress: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isFromCustodialWallet?: Maybe<Scalars['Boolean']>;
  listingStatus: NftSaleStatusEnum;
  minedAt?: Maybe<Scalars['DateTime']>;
  pk: Scalars['ID'];
  postedAt?: Maybe<Scalars['DateTime']>;
  productpurchasehistory?: Maybe<ProductPurchaseHistoryType>;
  publicType: DigitalMediaSalePublicType;
  status: Scalars['Int'];
  toAddress?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  type: DigitalMediaSaleType;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<AccountSessionType>;
};

export type NftSendType = GlobalIdConnectionNode & {
  __typename?: 'NFTSendType';
  createdAt: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isDirectTransfer?: Maybe<Scalars['Boolean']>;
  isOnchain?: Maybe<Scalars['Boolean']>;
  isUpgraded?: Maybe<Scalars['Boolean']>;
  nft?: Maybe<NftAbstractType>;
  pk: Scalars['ID'];
  recipient?: Maybe<AccountAbstractType>;
  recipientAddress?: Maybe<Scalars['String']>;
  recipientEmail?: Maybe<Scalars['String']>;
  sender?: Maybe<AccountAbstractType>;
  senderAddress: Scalars['String'];
  senderMessage?: Maybe<Scalars['String']>;
  speedupParameters?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transaction?: Maybe<TransactionAbstractType>;
};

export type NftSendTypeConnection = {
  __typename?: 'NFTSendTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NftSendTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NFTSendType` and its cursor. */
export type NftSendTypeEdge = {
  __typename?: 'NFTSendTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NftSendType>;
};

export type NftTotalVolumeNode = {
  __typename?: 'NFTTotalVolumeNode';
  totalVolumeInEth?: Maybe<Scalars['Float']>;
  totalVolumeInUsd?: Maybe<Scalars['Float']>;
};

export type NftTraitsType = {
  __typename?: 'NFTTraitsType';
  count?: Maybe<Scalars['Int']>;
  rarity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  typeSlug?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueSlug?: Maybe<Scalars['String']>;
};

export type NftUnlockable = GlobalIdConnectionNode & {
  __typename?: 'NFTUnlockable';
  canBeUnlocked?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<AccountSessionType>;
  description?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isExpired?: Maybe<Scalars['Boolean']>;
  pk: Scalars['ID'];
  shippingCostAp?: Maybe<Scalars['Int']>;
  shippingCostEu?: Maybe<Scalars['Int']>;
  shippingCostUs?: Maybe<Scalars['Int']>;
  shippingPaidSeparately?: Maybe<Scalars['Boolean']>;
  unlocked: Scalars['Boolean'];
  unlockedAt?: Maybe<Scalars['DateTime']>;
  unlockedBy?: Maybe<AccountSessionType>;
  updatedAt: Scalars['DateTime'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum NotificationActionEnum {
  BidAccepted = 'BID_ACCEPTED',
  BidOutbid = 'BID_OUTBID',
  BidReceived = 'BID_RECEIVED',
  Commented = 'COMMENTED',
  Followed = 'FOLLOWED',
  GlobalBidAccepted = 'GLOBAL_BID_ACCEPTED',
  GlobalBidOutbid = 'GLOBAL_BID_OUTBID',
  GlobalBidReceived = 'GLOBAL_BID_RECEIVED',
  Liked = 'LIKED',
  MintFromFollowedCreator = 'MINT_FROM_FOLLOWED_CREATOR',
  MintFromPurchasedBeforeCreator = 'MINT_FROM_PURCHASED_BEFORE_CREATOR',
  MintOfGenerativeArt = 'MINT_OF_GENERATIVE_ART',
  SaleAccepted = 'SALE_ACCEPTED'
}

export type NotificationMuteType = GlobalIdConnectionNode & {
  __typename?: 'NotificationMuteType';
  /** The ID of the object. */
  id: Scalars['ID'];
  isMuted: Scalars['Boolean'];
  objectAction: NotificationActionEnum;
  objectActionValue?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  user: AccountSessionType;
};

export type NotificationType = GlobalIdConnectionNode & {
  __typename?: 'NotificationType';
  createdAt: Scalars['DateTime'];
  ctaLink?: Maybe<Scalars['String']>;
  fullCtaLink?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  hasRead: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  notificationType: NotificationActionEnum;
  pk: Scalars['ID'];
  textFragments?: Maybe<Scalars['JSONString']>;
  user: AccountSessionType;
};

export type NotificationTypeConnection = {
  __typename?: 'NotificationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NotificationType` and its cursor. */
export type NotificationTypeEdge = {
  __typename?: 'NotificationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationType>;
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type OpenEditionBeforePurchaseInEthArguments = {
  buyerAddress: Scalars['HexString'];
  count: Scalars['Int'];
  emailAddress?: InputMaybe<Scalars['String']>;
  isPresale?: InputMaybe<Scalars['Boolean']>;
  openEditionId: Scalars['Int'];
};

/** Validate the purchase details and return the deposit request data */
export type OpenEditionBeforePurchaseInEthMutation = {
  __typename?: 'OpenEditionBeforePurchaseInETHMutation';
  response?: Maybe<OpenEditionPurchaseDepositDataType>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Represents an OpenEditionFamily, which is a 1-to-1 relationship with CollectionDrop.
 * This can be accessed from `NftDropType.collection.open_edition_family`.
 *
 * Documentation:
 * https://www.notion.so/API-v3-Drop-6f310e04cf674d1785b794f59db0306f
 */
export type OpenEditionFamilyMetadataType = GlobalIdConnectionNode & {
  __typename?: 'OpenEditionFamilyMetadataType';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSONString']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  reservePriceInCents: Scalars['Int'];
  reservePriceInWei: Scalars['Decimal'];
};

/** A member of the leaderboard in an OpenEdition V2. */
export type OpenEditionLeaderboardBuyerAbstractType = {
  __typename?: 'OpenEditionLeaderboardBuyerAbstractType';
  buyer?: Maybe<AccountAbstractType>;
  count?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum OpenEditionOeType {
  /** DEFAULT */
  A_1 = 'A_1',
  /** BUNDLE */
  A_2 = 'A_2',
  /** V2 */
  A_3 = 'A_3',
  /** GENERATIVE_ART */
  A_4 = 'A_4'
}

export type OpenEditionPurchaseDepositDataType = {
  __typename?: 'OpenEditionPurchaseDepositDataType';
  depositMetadataId?: Maybe<Scalars['Int']>;
  depositMetadataMarketPriceInWei?: Maybe<Scalars['BigInt']>;
  depositRequestId?: Maybe<Scalars['Int']>;
};

export type OpenEditionPurchaseInCcArguments = {
  count: Scalars['Int'];
  isPresale?: InputMaybe<Scalars['Boolean']>;
  openEditionId: Scalars['Int'];
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  rememberCard: Scalars['Boolean'];
};

/**
 * Purchase OpenEdition in CC
 * It will charge the user's CC and mint the release to the buyer
 */
export type OpenEditionPurchaseInCcMutation = {
  __typename?: 'OpenEditionPurchaseInCCMutation';
  intentId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OpenEditionPurchaseInEthArguments = {
  buyerAddress: Scalars['HexString'];
  count: Scalars['Int'];
  depositMetadataId: Scalars['Int'];
  emailAddress?: InputMaybe<Scalars['String']>;
  nonce: Scalars['Int'];
  openEditionId: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

/**
 * Purchase OpenEdition with personal wallet in ETH
 * It should be called after BeforeOpenEditionPurchaseInETHMutation and MetaMask posted the Tx
 */
export type OpenEditionPurchaseInEthMutation = {
  __typename?: 'OpenEditionPurchaseInETHMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type PaypalPayoutType = GlobalIdConnectionNode & {
  __typename?: 'PaypalPayoutType';
  accessToken: Scalars['String'];
  customerId: Scalars['String'];
  disabled: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  refreshToken: Scalars['String'];
  user: AccountSessionType;
};

export type PendingRegistryApproval = {
  __typename?: 'PendingRegistryApproval';
  errors?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PendingSaleCancel = {
  __typename?: 'PendingSaleCancel';
  errors?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PendingSaleCreate = {
  __typename?: 'PendingSaleCreate';
  errors?: Maybe<Scalars['String']>;
  sale?: Maybe<SaleType>;
  status?: Maybe<Scalars['String']>;
};

export type PersonnalCollectionType = GlobalIdConnectionNode & {
  __typename?: 'PersonnalCollectionType';
  /** The ID of the object. */
  id: Scalars['ID'];
  latestRelease?: Maybe<DigitalMediaReleaseNodeType>;
};

export type PersonnalCollectionTypeConnection = {
  __typename?: 'PersonnalCollectionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PersonnalCollectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PersonnalCollectionType` and its cursor. */
export type PersonnalCollectionTypeEdge = {
  __typename?: 'PersonnalCollectionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PersonnalCollectionType>;
};

export type PreRevealDetailsType = {
  __typename?: 'PreRevealDetailsType';
  authorLogoUrl?: Maybe<Scalars['String']>;
  authorSignatureUrl?: Maybe<Scalars['String']>;
  media500xPreviewContent?: Maybe<Scalars['String']>;
  media1000xPreviewContent?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type PriceRangeType = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type PriceRecommendationType = {
  __typename?: 'PriceRecommendationType';
  isCollection?: Maybe<Scalars['Boolean']>;
  lowestCustodialPriceEth?: Maybe<Scalars['Float']>;
  lowestCustodialPriceUsd?: Maybe<Scalars['Float']>;
  openseaFloorprice?: Maybe<Scalars['Float']>;
};

/** For both ProductBid and MediaBid */
export type ProductBidType = {
  __typename?: 'ProductBidType';
  bidAmount?: Maybe<Scalars['Float']>;
  bidInEther?: Maybe<Scalars['Float']>;
  bidInUsd?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isCcBid?: Maybe<Scalars['Boolean']>;
  isEtherBid?: Maybe<Scalars['Boolean']>;
};

export type ProductNodeType = GlobalIdConnectionNode & {
  __typename?: 'ProductNodeType';
  additionalDetails: Scalars['String'];
  auction?: Maybe<AuctionType>;
  availableForSale: Scalars['Boolean'];
  /** MakersPlace frame price */
  basePriceInCents: Scalars['Int'];
  basePriceInWei: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  digitalMediaRelease?: Maybe<DigitalMediaReleaseNodeType>;
  disableBidAccepts: Scalars['Boolean'];
  hasReservePrice?: Maybe<Scalars['Boolean']>;
  hasUnlockable?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isMinimized: Scalars['Boolean'];
  lastProductSale?: Maybe<ProductPurchaseHistoryType>;
  liveBid?: Maybe<ProductBidType>;
  lowestAskInEth?: Maybe<Scalars['Float']>;
  lowestAskInUsd?: Maybe<Scalars['Float']>;
  pk: Scalars['ID'];
  productSlug: Scalars['String'];
  productUrl?: Maybe<Scalars['String']>;
  productauctiondetails?: Maybe<NftAuctionType>;
  productpurchasehistorySet: ProductPurchaseHistoryTypeConnection;
  published: Scalars['Boolean'];
  raffle?: Maybe<RaffleType>;
  rankedauctionBidAccepted: NftRankedBidTypeConnection;
  reserveCurrency: ProductReserveCurrency;
  reserveCurrencyAmountInUsd: Scalars['Decimal'];
  reservePriceInEth?: Maybe<Scalars['Float']>;
  reservePriceInUsd?: Maybe<Scalars['Float']>;
  reservePriceInWei: Scalars['Decimal'];
  reservePriceMet: Scalars['Boolean'];
  staticPreviewUrl?: Maybe<Scalars['String']>;
  store?: Maybe<StoreType>;
  updatedAt: Scalars['DateTime'];
  whitelisted: Scalars['Boolean'];
};


export type ProductNodeTypeProductpurchasehistorySetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type ProductNodeTypeRankedauctionBidAcceptedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ProductNodeTypeConnection = {
  __typename?: 'ProductNodeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductNodeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProductNodeType` and its cursor. */
export type ProductNodeTypeEdge = {
  __typename?: 'ProductNodeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProductNodeType>;
};

/** An enumeration. */
export enum ProductPurchaseHistoryDiscountCodeType {
  /** SITE_WIDE */
  A_1 = 'A_1'
}

export type ProductPurchaseHistoryType = GlobalIdConnectionNode & {
  __typename?: 'ProductPurchaseHistoryType';
  buyer?: Maybe<AccountSessionType>;
  buyerAddress: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  digitalMediaRelease: NftAbstractType;
  digitalMediaSale?: Maybe<DigitalMediaSaleTypeV2>;
  discountCode?: Maybe<Scalars['String']>;
  discountCodeType?: Maybe<ProductPurchaseHistoryDiscountCodeType>;
  discountedSalePrice?: Maybe<Scalars['Int']>;
  ethPriceAtSale: Scalars['Float'];
  ethTx?: Maybe<TransactionAbstractType>;
  fiatConversionSalePrice?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  orderNumber?: Maybe<Scalars['String']>;
  ownerAddress: Scalars['String'];
  pk: Scalars['ID'];
  product: NftListingAbstractType;
  rankedAuctionBid?: Maybe<NftRankedBidType>;
  returnDiscountEthTxId?: Maybe<Scalars['Int']>;
  returnDiscountTransaction?: Maybe<Scalars['String']>;
  saleCommissionPercentage: Scalars['Decimal'];
  saleContractAddress: Scalars['String'];
  salePrice: Scalars['Decimal'];
  stripeFeePercentage: Scalars['Decimal'];
  stripeIntentId?: Maybe<Scalars['String']>;
  tokenContractAddress: Scalars['String'];
  tokenId: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  usdPriceAtSale: Scalars['Float'];
};

export type ProductPurchaseHistoryTypeConnection = {
  __typename?: 'ProductPurchaseHistoryTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductPurchaseHistoryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProductPurchaseHistoryType` and its cursor. */
export type ProductPurchaseHistoryTypeEdge = {
  __typename?: 'ProductPurchaseHistoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProductPurchaseHistoryType>;
};

/** An enumeration. */
export enum ProductReserveCurrency {
  /** ETH */
  A_1 = 'A_1',
  /** USD */
  A_2 = 'A_2'
}

export type ProductSettings = {
  __typename?: 'ProductSettings';
  product?: Maybe<ProductType>;
};

export type ProductType = {
  __typename?: 'ProductType';
  auction?: Maybe<AuctionType>;
  currentOwner?: Maybe<ArtistType>;
  hasReservePrice?: Maybe<Scalars['Boolean']>;
  hasUnlockable?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  lastSalePriceInEth?: Maybe<Scalars['Float']>;
  lastSalePriceInUsd?: Maybe<Scalars['Float']>;
  liveBid?: Maybe<ProductBidType>;
  lowestAskInEth?: Maybe<Scalars['Float']>;
  lowestAskInUsd?: Maybe<Scalars['Float']>;
  printEdition?: Maybe<Scalars['Int']>;
  productUrl?: Maybe<Scalars['String']>;
  raffle?: Maybe<RaffleType>;
  reservePriceMet?: Maybe<Scalars['Boolean']>;
  sale?: Maybe<SaleType>;
};

/** An enumeration. */
export enum ProductTypeBidEnum {
  Creditcard = 'CREDITCARD',
  Ether = 'ETHER'
}

/** An enumeration. */
export enum ProfileAccountType {
  Anon = 'ANON',
  Collection = 'COLLECTION',
  Collector = 'COLLECTOR',
  Creator = 'CREATOR',
  DowngradedCreator = 'DOWNGRADED_CREATOR'
}

export type ProfileCategoryListType = {
  __typename?: 'ProfileCategoryListType';
  id: Scalars['ID'];
  items: Array<Maybe<StoreCategoryType>>;
};

export type ProfileInput = {
  aboutBanner?: InputMaybe<Scalars['Upload']>;
  artstationLink?: InputMaybe<Scalars['String']>;
  behanceLink?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discordUserLink?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  facebookLink?: InputMaybe<Scalars['String']>;
  instagramLink?: InputMaybe<Scalars['String']>;
  pinterestLink?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['Upload']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  steemitLink?: InputMaybe<Scalars['String']>;
  twitterLink?: InputMaybe<Scalars['String']>;
  websiteLink?: InputMaybe<Scalars['String']>;
  youtubeChannelLink?: InputMaybe<Scalars['String']>;
  youtubeUserLink?: InputMaybe<Scalars['String']>;
};

export type ProfileType = GlobalIdConnectionNode & {
  __typename?: 'ProfileType';
  about?: Maybe<Scalars['String']>;
  aboutBanner?: Maybe<Scalars['String']>;
  accountType: ProfileAccountType;
  address?: Maybe<Scalars['String']>;
  collectionType?: Maybe<ExternalCollectionType>;
  curator?: Maybe<UserType>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  defaultCategory?: Maybe<StoreCategoryType>;
  defaultCollection?: Maybe<StoreCategoryType>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  drop?: Maybe<DropFamilyTypeV2>;
  etherscanUrl?: Maybe<Scalars['String']>;
  floorPrice?: Maybe<FloorPriceNode>;
  followers?: Maybe<Array<Maybe<FollowersType>>>;
  fullName?: Maybe<Scalars['String']>;
  hasCollectedDigitalMedias?: Maybe<Scalars['Boolean']>;
  hasLikes?: Maybe<Scalars['Boolean']>;
  hidePublicStorefront?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isCollection: Scalars['Boolean'];
  isCurrentUser: Scalars['Boolean'];
  isFeaturedStore?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<LikesConnection>;
  listingStats?: Maybe<ListingStatsType>;
  numOfItemsListed?: Maybe<Scalars['Int']>;
  numOfOwners?: Maybe<Scalars['Int']>;
  openseaUrl?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  profileImageUrl?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  socialLinks?: Maybe<Array<Maybe<StoreLinkType>>>;
  totalItems?: Maybe<Scalars['Int']>;
  totalVolume?: Maybe<TotalVolumeNode>;
  user?: Maybe<UserType>;
};


export type ProfileTypeLikesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ProvenanceMultiType = {
  __typename?: 'ProvenanceMultiType';
  amountEth?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  dest?: Maybe<Scalars['String']>;
  edition?: Maybe<Scalars['Int']>;
  productSlug?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  store?: Maybe<StoreTypeV3>;
  type: ProvenanceObjectType;
};

/** An enumeration. */
export enum ProvenanceObjectType {
  Listing = 'LISTING',
  MediaBid = 'MEDIA_BID',
  Mint = 'MINT',
  ProductBid = 'PRODUCT_BID',
  Purchase = 'PURCHASE',
  Sale = 'SALE',
  Transfer = 'TRANSFER'
}

export type ProvenanceType = {
  __typename?: 'ProvenanceType';
  amountEth?: Maybe<Scalars['Float']>;
  amountUsd?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  dest?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  store?: Maybe<StoreTypeV3>;
  type: ProvenanceObjectType;
};

export type PurchaseInCreditCardArguments = {
  discountCode?: InputMaybe<Scalars['String']>;
  isPresale?: InputMaybe<Scalars['Boolean']>;
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  productId: Scalars['Int'];
  rememberCard: Scalars['Boolean'];
  sessionId?: InputMaybe<Scalars['String']>;
};

export type PurchaseInCreditCardMutation = {
  __typename?: 'PurchaseInCreditCardMutation';
  intentId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PurchaseWithCustodialWalletArguments = {
  buyerAddress: Scalars['HexString'];
  depositMetadataId: Scalars['Int'];
  discountCode?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  isPresale?: InputMaybe<Scalars['Boolean']>;
  nonce: Scalars['Int'];
  productId: Scalars['Int'];
  sessionId?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['HexString'];
};

/**
 * Purchase with custodial wallet in ETH
 * Refer to purchase_custodial_complete
 */
export type PurchaseWithCustodialWalletMutation = {
  __typename?: 'PurchaseWithCustodialWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type PurchaseWithPersonalWalletArguments = {
  buyerAddress: Scalars['HexString'];
  discountCode?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  isPresale?: InputMaybe<Scalars['Boolean']>;
  nonce: Scalars['Int'];
  productId: Scalars['Int'];
  sessionId?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['HexString'];
};

/**
 * Purchase with personal wallet in ETH
 * Refer to purchase_complete
 */
export type PurchaseWithPersonalWalletMutation = {
  __typename?: 'PurchaseWithPersonalWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** Top-level query object for gql endpoint */
export type Query = {
  __typename?: 'Query';
  accounts?: Maybe<Array<Maybe<BasicAccountType>>>;
  activities?: Maybe<ActivityConnection>;
  approvals?: Maybe<ApprovalTypeConnection>;
  artwork?: Maybe<ExploreArtworkQuery>;
  autocomplete?: Maybe<AutoCompleteType>;
  collectionInsights?: Maybe<CollectionInsightsType>;
  collectionOwner?: Maybe<CollectionOwner>;
  collections?: Maybe<CollectionTypeV2Connection>;
  creatorContracts?: Maybe<Array<Maybe<CreatorContractType>>>;
  digitalMedia?: Maybe<DigitalMediaType>;
  digitalMediasReleasesV2?: Maybe<PersonnalCollectionTypeConnection>;
  digitalMediasV2?: Maybe<DigitalMediaTypeV2Connection>;
  draftNfts?: Maybe<NftMetadataAbstractTypeConnection>;
  drops?: Maybe<DropAbstractTypeConnection>;
  dropsMedias?: Maybe<DropMediaMetadataTypeConnection>;
  editionActivity?: Maybe<DigitalMediaReleaseNodeTypeConnection>;
  emailVerification?: Maybe<EmailVerificationType>;
  ethTx?: Maybe<EthereumTransactionType>;
  exploreDrops?: Maybe<Array<Maybe<LightWeightDropFamilyAbstractType>>>;
  exploreDrops2?: Maybe<LightWeightDropFamilyAbstractTypeConnection>;
  followFeaturedCreatorsList?: Maybe<FeaturedStoreTypeV3Connection>;
  followUserList?: Maybe<FollowUserTypeV3Connection>;
  followUsers?: Maybe<FollowUserTypeConnection>;
  followers: FollowersV2Type;
  gasRequests?: Maybe<GasRequestAbstractTypeConnection>;
  homepageArtistSpotlight?: Maybe<ArtistSpotlightType>;
  homepageFeaturedNfts?: Maybe<NftAbstractTypeConnection>;
  homepageGenres?: Maybe<Array<Maybe<GenreType>>>;
  homepageSections?: Maybe<HomepageSectionTypeConnection>;
  nftCollections?: Maybe<CollectionAbstractTypeConnection>;
  nftCollectorHistory?: Maybe<Array<Maybe<NftCollectorHistoryType>>>;
  nftComments?: Maybe<NftCommentTypeConnection>;
  nftContract?: Maybe<NftEthereumContractType>;
  nftEditionOwners?: Maybe<Array<Maybe<NftRecentOwnersType>>>;
  nftExhibits?: Maybe<NftExhibitTypeConnection>;
  nftExhibitsTiles?: Maybe<NftExibitTileTypeConnection>;
  nftExhibitsVendors?: Maybe<NftExhibitVendorTypeConnection>;
  nftMetadata?: Maybe<NftMetadataAbstractType>;
  nftMultiEditionProvenance?: Maybe<Array<Maybe<ProvenanceMultiType>>>;
  nftRecentHistory?: Maybe<Array<Maybe<NftRecentHistoryType>>>;
  nfts?: Maybe<NftAbstractTypeConnection>;
  nftsByArtist?: Maybe<NftConnection>;
  notifications?: Maybe<NotificationTypeConnection>;
  notificationsCount?: Maybe<Scalars['Int']>;
  notificationsSettings?: Maybe<Array<NotificationMuteType>>;
  ownDigitalMediasV2?: Maybe<DigitalMediaTypeV2Connection>;
  preRevealDetails?: Maybe<PreRevealDetailsType>;
  priceRecommendation?: Maybe<PriceRecommendationType>;
  productBids?: Maybe<Array<Maybe<AllBidsTypeV2>>>;
  productsV2?: Maybe<ProductNodeTypeConnection>;
  profile?: Maybe<ProfileType>;
  releaseComments?: Maybe<ReleaseCommentTypeConnection>;
  sendFollowers?: Maybe<Array<Maybe<SendFollowerType>>>;
  sends?: Maybe<NftSendTypeConnection>;
  sessionUser?: Maybe<SessionType>;
  storeCategoriesV3: ProfileCategoryListType;
  stores?: Maybe<StoreTypeV3Connection>;
  stripeCards: Array<PaymentMethodType>;
  topCarousel?: Maybe<Array<Maybe<DropAbstractType>>>;
  topCarouselsUpcomingAndLive?: Maybe<DropAbstractTypeConnection>;
  transactions?: Maybe<TransactionAbstractTypeConnection>;
  trendingNfts?: Maybe<NftConnection>;
  walletApprovalsGranted?: Maybe<Array<ApprovalType>>;
};


/** Top-level query object for gql endpoint */
export type QueryAccountsArgs = {
  searchText: Scalars['String'];
};


/** Top-level query object for gql endpoint */
export type QueryActivitiesArgs = {
  actions?: InputMaybe<Array<InputMaybe<ActivityActionEnum>>>;
  activityDate?: InputMaybe<DateTimeRangeType>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currencyMode?: InputMaybe<CurrencyModeEnum>;
  first?: InputMaybe<Scalars['Int']>;
  isShowFollowing?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<PriceRangeType>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  sortType?: InputMaybe<ActivitySortTypeEnum>;
  storeCollectionSlug?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Top-level query object for gql endpoint */
export type QueryApprovalsArgs = {
  address?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  approvalContractAddress?: InputMaybe<Scalars['String']>;
  approvalId?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  operatorContractAddress?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryAutocompleteArgs = {
  str?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryCollectionInsightsArgs = {
  slug?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryCollectionOwnerArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  startIndex?: InputMaybe<Scalars['Int']>;
  userStoreCollectionSlug?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  authorSlug?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  ownerSlug?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryDigitalMediaArgs = {
  mediaSlug?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryDigitalMediasReleasesV2Args = {
  activeMedia?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categorySlug?: InputMaybe<Scalars['String']>;
  collectorSlug?: InputMaybe<Scalars['String']>;
  digitalMediaId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  likesUserBySlug?: InputMaybe<Scalars['String']>;
  mediaBurned?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryDigitalMediasV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['Float']>;
  categorySlug?: InputMaybe<Scalars['String']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  collectorSlug?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  globalSlug?: InputMaybe<Scalars['String']>;
  hasCategory?: InputMaybe<Scalars['Boolean']>;
  hiddenOnCreate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBurned?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  likesUserBySlug?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  totalSupply?: InputMaybe<Scalars['Int']>;
  totalSupply_Gte?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryDraftNftsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  mediaId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryDropsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dropFamilySlug?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  position?: InputMaybe<Scalars['Float']>;
  positionGte?: InputMaybe<Scalars['Float']>;
  positionLte?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryDropsMediasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  openEditionId?: InputMaybe<Scalars['Float']>;
  openEditionSlug?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryEditionActivityArgs = {
  activeMedia?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categorySlug?: InputMaybe<Scalars['String']>;
  collectorSlug?: InputMaybe<Scalars['String']>;
  digitalMediaId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  likesUserBySlug?: InputMaybe<Scalars['String']>;
  mediaBurned?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryEthTxArgs = {
  txid?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryExploreDrops2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryFollowFeaturedCreatorsListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['Int']>;
  rank_Gte?: InputMaybe<Scalars['Int']>;
  rank_Lte?: InputMaybe<Scalars['Int']>;
  storeSlug?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryFollowUserListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  followeeId?: InputMaybe<Scalars['ID']>;
  followeeUsername?: InputMaybe<Scalars['String']>;
  followerId?: InputMaybe<Scalars['ID']>;
  followerUsername?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryFollowUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  followee?: InputMaybe<Scalars['String']>;
  follower?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryFollowersArgs = {
  globalSlug: Scalars['String'];
};


/** Top-level query object for gql endpoint */
export type QueryGasRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  gasRequestId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  objectAction?: InputMaybe<Scalars['String']>;
  objectId?: InputMaybe<Scalars['String']>;
  objectType?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryHomepageFeaturedNftsArgs = {
  activeMedia?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  authorSlug?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categorySlug?: InputMaybe<Scalars['String']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  collectorSlug?: InputMaybe<Scalars['String']>;
  digitalMediaId?: InputMaybe<Scalars['Float']>;
  dropFamilySlug?: InputMaybe<Scalars['String']>;
  filterPublished?: InputMaybe<Scalars['Boolean']>;
  finderSearchCursor?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isLiveOnChain?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  likesUserBySlug?: InputMaybe<Scalars['String']>;
  mediaBurned?: InputMaybe<Scalars['Boolean']>;
  nftId?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  printEdition?: InputMaybe<Scalars['String']>;
  productSlug?: InputMaybe<Scalars['String']>;
  relatedImageLimit?: InputMaybe<Scalars['Float']>;
  textForSearch?: InputMaybe<Scalars['String']>;
  textSearchLimit?: InputMaybe<Scalars['Float']>;
};


/** Top-level query object for gql endpoint */
export type QueryHomepageSectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryNftCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  authorSlug?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  ownerSlug?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryNftCollectorHistoryArgs = {
  onlyProduct?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['Int'];
};


/** Top-level query object for gql endpoint */
export type QueryNftCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  nftId?: InputMaybe<Scalars['String']>;
  nftMetadataId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['Float']>;
};


/** Top-level query object for gql endpoint */
export type QueryNftContractArgs = {
  address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryNftEditionOwnersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  releaseId: Scalars['Int'];
};


/** Top-level query object for gql endpoint */
export type QueryNftExhibitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryNftExhibitsTilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryNftExhibitsVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryNftMetadataArgs = {
  pk: Scalars['Int'];
};


/** Top-level query object for gql endpoint */
export type QueryNftMultiEditionProvenanceArgs = {
  nftMetadataId: Scalars['Int'];
};


/** Top-level query object for gql endpoint */
export type QueryNftRecentHistoryArgs = {
  releaseId: Scalars['Int'];
};


/** Top-level query object for gql endpoint */
export type QueryNftsArgs = {
  activeMedia?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  authorSlug?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categorySlug?: InputMaybe<Scalars['String']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  collectorSlug?: InputMaybe<Scalars['String']>;
  digitalMediaId?: InputMaybe<Scalars['Float']>;
  dropFamilySlug?: InputMaybe<Scalars['String']>;
  filterPublished?: InputMaybe<Scalars['Boolean']>;
  finderSearchCursor?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isLiveOnChain?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  likesUserBySlug?: InputMaybe<Scalars['String']>;
  mediaBurned?: InputMaybe<Scalars['Boolean']>;
  nftId?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  printEdition?: InputMaybe<Scalars['String']>;
  processingState?: InputMaybe<DigitalMediaProcessedEnum>;
  productSlug?: InputMaybe<Scalars['String']>;
  relatedImageLimit?: InputMaybe<Scalars['Float']>;
  textForSearch?: InputMaybe<Scalars['String']>;
  textSearchLimit?: InputMaybe<Scalars['Float']>;
  txstate?: InputMaybe<EthereumTransactionStateEnum>;
};


/** Top-level query object for gql endpoint */
export type QueryNftsByArtistArgs = {
  after?: InputMaybe<Scalars['String']>;
  artists?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  aspectRatios?: InputMaybe<Array<InputMaybe<AspectRatioEnum>>>;
  before?: InputMaybe<Scalars['String']>;
  editions?: InputMaybe<Array<InputMaybe<EditionTypeEnum>>>;
  filters?: InputMaybe<Array<InputMaybe<KeyValues>>>;
  first?: InputMaybe<Scalars['Int']>;
  genres?: InputMaybe<Array<InputMaybe<GenreEnum>>>;
  last?: InputMaybe<Scalars['Int']>;
  mediaTypes?: InputMaybe<Array<InputMaybe<MediaTypeEnum>>>;
  price?: InputMaybe<RangeType>;
  query?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<SizeType>;
  slug?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  sortType?: InputMaybe<SortTypeEnum>;
  status?: InputMaybe<Array<InputMaybe<StatusEnum>>>;
};


/** Top-level query object for gql endpoint */
export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryOwnDigitalMediasV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['Float']>;
  categorySlug?: InputMaybe<Scalars['String']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  collectorSlug?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  globalSlug?: InputMaybe<Scalars['String']>;
  hasCategory?: InputMaybe<Scalars['Boolean']>;
  hiddenOnCreate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBurned?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  likesUserBySlug?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  totalSupply?: InputMaybe<Scalars['Int']>;
  totalSupply_Gte?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryPreRevealDetailsArgs = {
  digitalMediaId?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryPriceRecommendationArgs = {
  productId?: InputMaybe<Scalars['Int']>;
  releaseId?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryProductBidsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  mediaId: Scalars['ID'];
};


/** Top-level query object for gql endpoint */
export type QueryProductsV2Args = {
  after?: InputMaybe<Scalars['String']>;
  availableForSale?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  digitalMediaRelease_DigitalMediaId?: InputMaybe<Scalars['ID']>;
  excludeAuthor?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  productSlug?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  storeOwner?: InputMaybe<Scalars['String']>;
  whitelisted?: InputMaybe<Scalars['Boolean']>;
};


/** Top-level query object for gql endpoint */
export type QueryProfileArgs = {
  globalSlug: Scalars['String'];
};


/** Top-level query object for gql endpoint */
export type QueryReleaseCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  digitalMediaId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QuerySendFollowersArgs = {
  onlyCreators?: InputMaybe<Scalars['Boolean']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  username: Scalars['String'];
};


/** Top-level query object for gql endpoint */
export type QuerySendsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  senderId: Scalars['Float'];
};


/** Top-level query object for gql endpoint */
export type QueryStoreCategoriesV3Args = {
  categoryType: CategoryType;
  globalSlug: Scalars['String'];
};


/** Top-level query object for gql endpoint */
export type QueryStoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  storeSlug?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryTopCarouselArgs = {
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Top-level query object for gql endpoint */
export type QueryTopCarouselsUpcomingAndLiveArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dropFamilySlug?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  position?: InputMaybe<Scalars['Float']>;
  positionGte?: InputMaybe<Scalars['Float']>;
  positionLte?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};


/** Top-level query object for gql endpoint */
export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  txtId: Scalars['String'];
};


/** Top-level query object for gql endpoint */
export type QueryTrendingNftsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Rgb = {
  __typename?: 'RGB';
  b?: Maybe<Scalars['Int']>;
  g?: Maybe<Scalars['Int']>;
  r?: Maybe<Scalars['Int']>;
};

export type RaffleType = {
  __typename?: 'RaffleType';
  endsAt: Scalars['DateTime'];
  id?: Maybe<Scalars['Int']>;
  startsAt: Scalars['DateTime'];
};

export type RangeType = {
  currency?: InputMaybe<Scalars['String']>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

/** An enumeration. */
export enum RankedAuctionBidFinalizationStatus {
  /** PENDING */
  A_1 = 'A_1',
  /** TRANSFERRED */
  A_2 = 'A_2',
  /** REFUNDED */
  A_3 = 'A_3',
  /** NOTIFIED */
  A_4 = 'A_4'
}

/** An enumeration. */
export enum RankedAuctionBidStatus {
  /** PENDING_CONFIRMED */
  A_1 = 'A_1',
  /** CONFIRMED */
  A_2 = 'A_2',
  /** CANCELLED */
  A_3 = 'A_3',
  /** PENDING_REJECTED */
  A_4 = 'A_4',
  /** REJECTED */
  A_5 = 'A_5',
  /** PENDING_ACCEPTED */
  A_6 = 'A_6',
  /** ACCEPTED */
  A_7 = 'A_7',
  /** PENDING_CANCELLATION */
  A_8 = 'A_8',
  /** OUTBID */
  A_9 = 'A_9',
  /** FAILED */
  A_10 = 'A_10',
  /** PENDING_AUTO_CANCELLATION */
  A_11 = 'A_11',
  /** AUTO_CANCELLED */
  A_12 = 'A_12',
  /** DISPUTED */
  A_13 = 'A_13',
  /** IGNORED */
  A_14 = 'A_14'
}

/** An enumeration. */
export enum RankedAuctionStatus {
  /** WAIT_TO_START */
  A_1 = 'A_1',
  /** RUNNING */
  A_2 = 'A_2',
  /** ENDED */
  A_3 = 'A_3'
}

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

/** Refresh External Token metadata */
export type RefreshExternalTokenMetadata = {
  __typename?: 'RefreshExternalTokenMetadata';
  success?: Maybe<Scalars['Boolean']>;
};

export type RefreshManifoldTokenArguments = {
  nftId: Scalars['Int'];
};

export type RegisterArguments = {
  creatorInviteCode?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  inviteCode?: InputMaybe<Scalars['String']>;
  isCreator?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  recaptchaToken?: InputMaybe<Scalars['String']>;
};

export type RegisterWalletArguments = {
  address: Scalars['HexString'];
  isCreationEnabled: Scalars['Boolean'];
  signature: Scalars['String'];
};

export type RegisterWalletMutation = {
  __typename?: 'RegisterWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type RejectProductBidWithCustodialWalletArguments = {
  bidId: Scalars['String'];
};

export type RejectProductBidWithCustodialWalletMutation = {
  __typename?: 'RejectProductBidWithCustodialWalletMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type RejectProductBidWithPersonalWalletInCcArguments = {
  bidId: Scalars['String'];
};

export type RejectProductBidWithPersonalWalletInCcMutation = {
  __typename?: 'RejectProductBidWithPersonalWalletInCCMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type RejectProductBidWithPersonalWalletInEthArguments = {
  bidId: Scalars['Int'];
  fromAddress: Scalars['HexString'];
  nonce: Scalars['Int'];
  transactionId: Scalars['HexString'];
};

export type RejectProductBidWithPersonalWalletInEthMutation = {
  __typename?: 'RejectProductBidWithPersonalWalletInETHMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ReleaseCommentType = GlobalIdConnectionNode & {
  __typename?: 'ReleaseCommentType';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  digitalMedia: NftMetadataAbstractType;
  digitalMediaRelease: NftAbstractType;
  hidden: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isOwner: Scalars['Boolean'];
  parent?: Maybe<NftCommentType>;
  pk: Scalars['ID'];
  releasecommentSet: NftCommentTypeConnection;
  replyToUser?: Maybe<AccountSessionType>;
  threadCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: AccountSessionType;
};


export type ReleaseCommentTypeReleasecommentSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ReleaseCommentTypeConnection = {
  __typename?: 'ReleaseCommentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReleaseCommentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ReleaseCommentType` and its cursor. */
export type ReleaseCommentTypeEdge = {
  __typename?: 'ReleaseCommentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ReleaseCommentType>;
};

export type ResendVerificationEmailMutation = {
  __typename?: 'ResendVerificationEmailMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum ReserveCurrencyEnum {
  Eth = 'ETH',
  Usd = 'USD'
}

export type RevealProduct = {
  __typename?: 'RevealProduct';
  status?: Maybe<Scalars['String']>;
};

/** Refer to pending_eth_purchase in storefront/views.py */
export type SalePurchaseDepositDataType = {
  __typename?: 'SalePurchaseDepositDataType';
  depositMetadataId?: Maybe<Scalars['Int']>;
  depositMetadataMarketPriceInWei?: Maybe<Scalars['BigInt']>;
  depositRequestId?: Maybe<Scalars['Int']>;
};

export type SaleType = {
  __typename?: 'SaleType';
  custodialPriceInUsd?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

export type SendFollowerType = {
  __typename?: 'SendFollowerType';
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** The required payload for SendNFTMutation. Refer to SendTokenForm */
export type SendNftArguments = {
  fromAddress?: InputMaybe<Scalars['HexString']>;
  nftId: Scalars['Int'];
  nonce?: InputMaybe<Scalars['Int']>;
  recipientAddress: Scalars['HexString'];
  recipientEmail?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['HexString']>;
};

/** Send an NFT */
export type SendNftMutation = {
  __typename?: 'SendNFTMutation';
  nftId?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SessionMutation = {
  __typename?: 'SessionMutation';
  account?: Maybe<AccountSessionType>;
  contractNetwork?: Maybe<Scalars['Int']>;
  contractNetworkUrl?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
  sentrySdkDsn?: Maybe<Scalars['String']>;
  statsigClientKey?: Maybe<Scalars['String']>;
};

export type SessionType = Node & {
  __typename?: 'SessionType';
  environment?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['String']>;
  statsigClientKey?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
};

export type SetDefaultCreditCardArguments = {
  paymentId: Scalars['String'];
};

export type SetDefaultCreditCardMutation = {
  __typename?: 'SetDefaultCreditCardMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** If no ids input, it will set all notifications owned by the user to read */
export type SetNotificationsAsReadMutation = {
  __typename?: 'SetNotificationsAsReadMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** If no ids input, it will set all notifications owned by the user to read */
export type SetReadNotificationsArguments = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type SetUpTwoFactorArguments = {
  channel: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type SetUpTwoFactorMutation = {
  __typename?: 'SetUpTwoFactorMutation';
  recipient?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SetUserStates = {
  __typename?: 'SetUserStates';
  status?: Maybe<Scalars['String']>;
};

export type SignGenerativeMintMessage = {
  __typename?: 'SignGenerativeMintMessage';
  nonce?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SignGenerativeMintMessageArguments = {
  contractAddress: Scalars['String'];
  numMax: Scalars['Int'];
  openEditionId: Scalars['Int'];
  toAddress: Scalars['String'];
};

export type SizeType = {
  minHeight?: InputMaybe<Scalars['Float']>;
  minWidth?: InputMaybe<Scalars['Float']>;
};

/** An enumeration. */
export enum SortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** An enumeration. */
export enum SortTypeEnum {
  CreatedAt = 'CREATED_AT',
  Edition = 'EDITION',
  Popularity = 'POPULARITY',
  Price = 'PRICE',
  Recommended = 'RECOMMENDED'
}

export type SpeedUpFreeMintArguments = {
  freeMintId: Scalars['Int'];
  nftId: Scalars['Int'];
  requestId: Scalars['Int'];
};

export type SpeedUpFreeMintMutation = {
  __typename?: 'SpeedUpFreeMintMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum StatusEnum {
  BuyNow = 'BUY_NOW',
  HasOffers = 'HAS_OFFERS',
  InAuction = 'IN_AUCTION'
}

/** An enumeration. */
export enum StoreCategoryCategoryType {
  /** STORE */
  A_1 = 'A_1',
  /** COLLECTION */
  A_2 = 'A_2'
}

export type StoreCategoryType = GlobalIdConnectionNode & {
  __typename?: 'StoreCategoryType';
  categoryName: Scalars['String'];
  categorySlug: Scalars['String'];
  categoryType: StoreCategoryCategoryType;
  createdAt: Scalars['DateTime'];
  defaultCategory: StoreTypeV3Connection;
  defaultCollection: StoreTypeV3Connection;
  description?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  store: StoreTypeV3;
};


export type StoreCategoryTypeDefaultCategoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type StoreCategoryTypeDefaultCollectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum StoreLinkLinkType {
  /** Artstation */
  Artstation = 'ARTSTATION',
  /** Behance */
  Behance = 'BEHANCE',
  /** Discord_User */
  DiscordUser = 'DISCORD_USER',
  /** Facebook */
  Facebook = 'FACEBOOK',
  /** Instagram */
  Instagram = 'INSTAGRAM',
  /** Pinterest */
  Pinterest = 'PINTEREST',
  /** Steemit */
  Steemit = 'STEEMIT',
  /** Twitter */
  Twitter = 'TWITTER',
  /** Website */
  Website = 'WEBSITE',
  /** Youtube Channel */
  YoutubeChannel = 'YOUTUBE_CHANNEL',
  /** Youtube User */
  YoutubeUser = 'YOUTUBE_USER'
}

export type StoreLinkType = GlobalIdConnectionNode & {
  __typename?: 'StoreLinkType';
  /** The ID of the object. */
  id: Scalars['ID'];
  linkType: StoreLinkLinkType;
  pk: Scalars['ID'];
  store: StoreTypeV3;
  value: Scalars['String'];
};

export type StoreType = {
  __typename?: 'StoreType';
  description?: Maybe<Scalars['String']>;
  displayLogoUrl?: Maybe<Scalars['String']>;
  hidePublicStorefront: Scalars['Boolean'];
  id?: Maybe<Scalars['Int']>;
  owner?: Maybe<ArtistType>;
  storeName: Scalars['String'];
  storeSlug?: Maybe<Scalars['String']>;
  storeUrl?: Maybe<Scalars['String']>;
};

export type StoreTypeV3 = GlobalIdConnectionNode & {
  __typename?: 'StoreTypeV3';
  aboutBanner?: Maybe<Scalars['String']>;
  acceptsCreditCard?: Maybe<Scalars['Boolean']>;
  cloudinaryLogoUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayLogoUrl?: Maybe<Scalars['String']>;
  floorPrice?: Maybe<FloorPriceType>;
  hidePublicStorefront: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  minimumBidAmountInEther?: Maybe<Scalars['Float']>;
  minimumBidAmountInUsd?: Maybe<Scalars['Float']>;
  numOfOwners?: Maybe<Scalars['Int']>;
  owner?: Maybe<AccountAbstractType>;
  pk: Scalars['ID'];
  storeName: Scalars['String'];
  storeSlug?: Maybe<Scalars['String']>;
  storeUrl?: Maybe<Scalars['String']>;
  totalItems?: Maybe<Scalars['Int']>;
  totalVolume?: Maybe<TotalVolumeType>;
};

export type StoreTypeV3Connection = {
  __typename?: 'StoreTypeV3Connection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StoreTypeV3Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StoreTypeV3` and its cursor. */
export type StoreTypeV3Edge = {
  __typename?: 'StoreTypeV3Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StoreTypeV3>;
};

/** An enumeration. */
export enum TagTypeEnum {
  EndingSoon = 'ENDING_SOON',
  Featured = 'FEATURED',
  NewMints = 'NEW_MINTS',
  RankedAuction = 'RANKED_AUCTION',
  RecentlySold = 'RECENTLY_SOLD',
  RecentBid = 'RECENT_BID'
}

/** An enumeration. */
export enum TopDropTypeEnum {
  Live = 'Live',
  Upcoming = 'Upcoming'
}

export type TotalVolumeNode = {
  __typename?: 'TotalVolumeNode';
  totalVolumeInEth?: Maybe<Scalars['Float']>;
  totalVolumeInUsd?: Maybe<Scalars['Float']>;
};

export type TotalVolumeType = {
  __typename?: 'TotalVolumeType';
  totalVolumeInEth?: Maybe<Scalars['Float']>;
  totalVolumeInUsd?: Maybe<Scalars['Float']>;
};

export type TraitTypeType = {
  __typename?: 'TraitTypeType';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type TraitsType = {
  __typename?: 'TraitsType';
  rarity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  typeSlug?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueSlug?: Maybe<Scalars['String']>;
};

export type TransactionAbstractType = GlobalIdConnectionNode & {
  __typename?: 'TransactionAbstractType';
  action?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['Int']>;
  contractFunction?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  events?: Maybe<Array<Maybe<TransactionEventType>>>;
  fromAddress: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  nonce?: Maybe<Scalars['Int']>;
  pk: Scalars['ID'];
  toAddress?: Maybe<Scalars['HexString']>;
  transactionState: EthereumTransactionStateEnum;
  transactionUrl?: Maybe<Scalars['String']>;
  txid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type TransactionAbstractTypeConnection = {
  __typename?: 'TransactionAbstractTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TransactionAbstractTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TransactionAbstractType` and its cursor. */
export type TransactionAbstractTypeEdge = {
  __typename?: 'TransactionAbstractTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TransactionAbstractType>;
};

/**
 * Ideally, we make a Union class merging all the possible arg classes shapes based on all inputs from
 * `smart_contract_abis/*`. However, this is a lot of work, so deferring until later.
 */
export type TransactionEventType = {
  __typename?: 'TransactionEventType';
  address?: Maybe<Scalars['HexString']>;
  args?: Maybe<Scalars['JSONString']>;
  blockHash?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['Int']>;
  event?: Maybe<Scalars['String']>;
  logIndex?: Maybe<Scalars['Int']>;
  transactionHash?: Maybe<Scalars['HexString']>;
  transactionIndex?: Maybe<Scalars['Int']>;
};

export type TwoFactorArguments = {
  channel?: InputMaybe<VerifyChannelEnum>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type TwoFactorMutation = {
  __typename?: 'TwoFactorMutation';
  channel?: Maybe<VerifyChannelEnum>;
  recipient?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  twoFactorEnabled?: Maybe<Scalars['Boolean']>;
  twoFactorSessionId?: Maybe<Scalars['String']>;
};

export type TwoFactorRecordType = GlobalIdConnectionNode & {
  __typename?: 'TwoFactorRecordType';
  /** The ID of the object. */
  id: Scalars['ID'];
  isUserSessionVerified?: Maybe<Scalars['Boolean']>;
  pk: Scalars['ID'];
  recipient: Scalars['String'];
  verifiedAt?: Maybe<Scalars['DateTime']>;
};

/**
 * This is similar to the concept of an NFTAbstractType in a 'draft' state.
 * The key difference is that, 'drafts' are not considered live, purchasable NFTs.
 * These "Off-chain NFTs" are not on-chain but are ready to be sold and minted on-demand or post-drop.
 * For example, OpenEditions are under this class bucket.
 */
export type UnMintedNftAbstractType = GlobalIdConnectionNode & {
  __typename?: 'UnMintedNFTAbstractType';
  createdAt?: Maybe<Scalars['DateTime']>;
  currentEthRate?: Maybe<Scalars['Float']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  listing?: Maybe<NftListingAbstractUnion>;
  metadata?: Maybe<NftMetadataAbstractType>;
  slug?: Maybe<Scalars['String']>;
};

export type UnfollowUserMutation = {
  __typename?: 'UnfollowUserMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type UnlockListingArguments = {
  productId: Scalars['Int'];
};

/** For Sale purchase unlock */
export type UnlockListingMutation = {
  __typename?: 'UnlockListingMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type UnlockNftUnlockableArguments = {
  amountInCents?: InputMaybe<Scalars['Int']>;
  nftId: Scalars['Int'];
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  rememberCard?: InputMaybe<Scalars['Boolean']>;
  shippingAddressCity?: InputMaybe<Scalars['String']>;
  shippingAddressCountry?: InputMaybe<Scalars['String']>;
  shippingAddressFullName?: InputMaybe<Scalars['String']>;
  shippingAddressLine1?: InputMaybe<Scalars['String']>;
  shippingAddressLine2?: InputMaybe<Scalars['String']>;
  shippingAddressPhoneNumber?: InputMaybe<Scalars['String']>;
  shippingAddressPostalCode?: InputMaybe<Scalars['String']>;
  shippingAddressState?: InputMaybe<Scalars['String']>;
};

export type UnlockNftUnlockableMutation = {
  __typename?: 'UnlockNFTUnlockableMutation';
  intentId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateNftMetadataArguments = {
  nftMetadataId: Scalars['Int'];
  previewFile?: InputMaybe<Scalars['Upload']>;
  uploadedFile?: InputMaybe<Scalars['Upload']>;
};

/** Update NFT metadata (uploaded or preview file) */
export type UpdateNftMetadataMutation = {
  __typename?: 'UpdateNFTMetadataMutation';
  redirectUrl?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateNftMetadataTagArguments = {
  coreTagName: Scalars['String'];
  isCreateTag: Scalars['Boolean'];
  nftMetadataId: Scalars['Int'];
};

export type UpdateNftMetadataTagMutation = {
  __typename?: 'UpdateNFTMetadataTagMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateStoreCategoryInput = {
  categoryName: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  pk: Scalars['Int'];
  productIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** An enumeration. */
export enum UserAccountType {
  Anon = 'ANON',
  Consumer = 'CONSUMER',
  Creator = 'CREATOR',
  DowngradedCreator = 'DOWNGRADED_CREATOR'
}

export type UserAddressType = GlobalIdConnectionNode & {
  __typename?: 'UserAddressType';
  address: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  isCustodial?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
};

/** An enumeration. */
export enum UserStates {
  AutoFollowed = 'AUTO_FOLLOWED',
  BeepleBanner = 'BEEPLE_BANNER',
  CollectorNuxCompleted = 'COLLECTOR_NUX_COMPLETED',
  ConnectedWalletFollowup = 'CONNECTED_WALLET_FOLLOWUP',
  CreatorFollowupInitialNumOfCreation = 'CREATOR_FOLLOWUP_INITIAL_NUM_OF_CREATION',
  CreatorFollowupNumOfCreation = 'CREATOR_FOLLOWUP_NUM_OF_CREATION',
  CreatorFollowupTransitionState = 'CREATOR_FOLLOWUP_TRANSITION_STATE',
  CreatorOnboardHiddenStep = 'CREATOR_ONBOARD_HIDDEN_STEP',
  Deferred = 'DEFERRED',
  DeferredGweiLimit = 'DEFERRED_GWEI_LIMIT',
  DeferredSpeed = 'DEFERRED_SPEED',
  DeferredTokensLimit = 'DEFERRED_TOKENS_LIMIT',
  DisableWriteActions = 'DISABLE_WRITE_ACTIONS',
  FreeAccountInfo = 'FREE_ACCOUNT_INFO',
  GasDiscountFactor = 'GAS_DISCOUNT_FACTOR',
  InitialSetup = 'INITIAL_SETUP',
  LimitedPrintEditions = 'LIMITED_PRINT_EDITIONS',
  MahomesBanner = 'MAHOMES_BANNER',
  MaxFileLimit = 'MAX_FILE_LIMIT',
  MonthlyUploadLimit = 'MONTHLY_UPLOAD_LIMIT',
  NuxCompleted = 'NUX_COMPLETED',
  PriorityCreator = 'PRIORITY_CREATOR',
  TrustedCcUser = 'TRUSTED_CC_USER',
  UrsaMajorBanner = 'URSA_MAJOR_BANNER',
  WalletIntro = 'WALLET_INTRO'
}

export type UserType = GlobalIdConnectionNode & {
  __typename?: 'UserType';
  accountType: UserAccountType;
  didCompleteOnboard?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullLandingUrl?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  landingUrl?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  marketplaceUrl?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  profileImageUrl: Scalars['String'];
  username: Scalars['String'];
};

export type ValidateAcceptRejectEthBidArguments = {
  bidId: Scalars['Int'];
  cancelledByCustomer: Scalars['Boolean'];
  isGlobalBid: Scalars['Boolean'];
  productId?: InputMaybe<Scalars['Int']>;
};

export type ValidateAcceptRejectEthBidMutation = {
  __typename?: 'ValidateAcceptRejectETHBidMutation';
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * This end point will validate the contract address, check to see if:
 * - The contract address is a valid ERC-721 token address.
 * - The contract is not exist.
 * - The submitted user is the owner of the contract address.
 * - Return the validation result and the sign params
 */
export type ValidateContractAddress = {
  __typename?: 'ValidateContractAddress';
  message?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type ValidateCreateEthBidArguments = {
  bidAmount: Scalars['BigInt'];
  bidderAddress: Scalars['HexString'];
  emailAddress?: InputMaybe<Scalars['String']>;
  isGlobalBid: Scalars['Boolean'];
  productId?: InputMaybe<Scalars['Int']>;
};

export type ValidateCreateEthBidMutation = {
  __typename?: 'ValidateCreateETHBidMutation';
  bidAmountInWei?: Maybe<Scalars['BigInt']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ValidateSale = {
  __typename?: 'ValidateSale';
  errors?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

/** An enumeration. */
export enum VerifyChannelEnum {
  Email = 'email',
  Sms = 'sms'
}

export type WalletAbstractType = {
  __typename?: 'WalletAbstractType';
  address: Scalars['HexString'];
  canDisconnect: Scalars['Boolean'];
  collectedTokenNumber: Scalars['Int'];
  createdTokenNumber: Scalars['Int'];
  isCreatorWallet: Scalars['Boolean'];
  isCustodialCreatorWallet: Scalars['Boolean'];
  isSafebox: Scalars['Boolean'];
  isSelectedMintingWallet: Scalars['Boolean'];
  name: Scalars['String'];
};
