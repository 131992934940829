import { useCallback, useState } from 'react';

import { MPActionButton } from '@mp-frontend/core-components';
import { FilterIcon } from '@mp-frontend/core-components/icons';

import GTM from 'GTM';
import ArtworkChips from 'pages/explore/artwork/Chips';
import ArtworkFilterDialog from 'pages/explore/artwork/FilterDialog';
import FilterSortButton from 'pages/explore/artwork/FilterSortButton';
import useArtworkFilterState from 'pages/explore/artwork/UseArtworkFilterState';
import { Genres } from 'pages/explore/genre';

import * as styles from 'css/components/explore/FilterRow.module.css';

export default function FilterRow() {
  const filterParams = useArtworkFilterState();
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  const shouldShowSortButton = !(
    !!filterParams.query[0] || filterParams.genre[0]() !== Genres.All
  );

  const handleOpenFilterDialog = useCallback(() => {
    setIsFilterDialogOpen(true);
    GTM.explore.trackOpenFilterBox();
  }, []);

  return (
    <>
      <div className={styles.filterRow}>
        <div className={styles.filterRowLeft}>
          <ArtworkChips />
        </div>
        <div className={styles.filterRowRight}>
          {!!shouldShowSortButton && <FilterSortButton />}
          <MPActionButton
            startIcon={<FilterIcon />}
            variant="tertiary"
            onClick={handleOpenFilterDialog}
          >
            Filters
          </MPActionButton>
        </div>
      </div>
      {!!isFilterDialogOpen && (
        <ArtworkFilterDialog
          open={isFilterDialogOpen}
          close={() => setIsFilterDialogOpen(false)}
        />
      )}
    </>
  );
}
