.filters {
  box-sizing: border-box;
  padding: 0 var(--activity-horizontal-padding);

  @media (--mq-is-mobile) {
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 0;
  }

  & > div {
    flex-flow: nowrap;
    white-space: nowrap;
    width: fit-content;

    @media (--mq-is-mobile) {
      padding-right: var(--activity-horizontal-padding);
    }
  }
}

.artistsFilter {
  box-sizing: border-box;
  gap: 2px;
  list-style-type: none;
  margin: -8px;
  padding: 0;

  @media (--mq-is-mobile) {
    gap: 6px;
  }

  & > li > label {
    box-sizing: border-box;
    padding: 8px;
    width: 100%;

    &:hover {
      background-color: var(--color-backgroundDefault);
    }
  }
}

.artistFilterContainer {
  width: 320px;
  @media (--mq-is-mobile) {
    width: 100%;
  }
}

.artistSearchSection, .activityArtistDivider {
  padding: 8px 0;
}

.availableArtistSection {
  padding-top: 10px;
}

.artistSearchTitle {
  padding: 8px 0 4px 0;
}

.artistSearchIcon {
  height: 18px;
  width: 18px;
}

.priceFilter .input {
  max-width: 136px;
}

.dateFilter .input {
  max-width: 139px;
}

.priceFilter .input,
.dateFilter .input {
  flex-basis: 0;
  flex-grow: 1;

  @media (--mq-is-mobile) {
    max-width: none;
  }
}

.dateFilter .calendar {
  border: 1px solid var(--color-SolidNeutralGray3);
  padding: 6px 16px;

  & .calendarHead {
    box-sizing: border-box;
    padding: 6.5px 8px;
  }

  & .calendarNavigationButton {
    width: 19px;
    height: 19px;
  }

  & .calendarTable {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto;
  }

  & .calendarTableCell {
    border: none;
    padding: 0;
  }

  & .calendarDay {
    appearance: none;
    background-color: var(--color-commonWhite);
    border: none;
    color: var(--color-SolidNeutralGray5);
    height: 38px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 37px;

    &:focus-visible {
      outline: none;
    }
  }

  & .calendarDayDisabled.calendarDayDisabled {
    color: var(--color-SolidNeutralGray1);
  }

  & .calendarDay:not(.calendarDateRangeMiddle):not(.calendarDayDisabled):hover,
  & .calendarDateRangeEnd,
  & .calendarDateRangeStart {
    color: var(--color-commonWhite);
    background-color: var(--color-commonBlack);
  }

  & .calendarDateRangeMiddle {
    color: var(--color-SolidNeutralGray5);
    background-color: var(--color-SolidNeutralGray1);
  }
}
