import { useEffect, useMemo, useState } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import { MPDivider } from '@mp-frontend/core-components';

import OpenEditionQueryType, {
  OpenEditionQuery,
} from 'graphql/__generated__/OpenEditionQuery.graphql';

import { FourOFour } from 'components/ErrorBoundaries/FourOFourErrorBoundary';
import { TrackingContext } from 'components/trackingContext';
import { APP_NAME } from 'constants/Utils';
import { EcommerceSourceType } from 'GTM';
import ProductAboutArtists from 'pages/product/aboutArtists';
import ProductAuthorDetails from 'pages/product/ProductAuthorDetails';
import { ProductDescriptions } from 'pages/product/ProductDescription';
import ProductDetailsMetadata from 'pages/product/ProductDetailsMetadata';
import ProductLayout from 'pages/product/ProductLayout';
import ProductLayoutContainer from 'pages/product/ProductLayoutContainer';
import ProductTitle from 'pages/product/ProductTitle';
import CSSMargin from 'types/enums/css/Margin';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import setDocTitle from 'utils/setDocTitle';

import OpenEditionLeaderboard, { OpenEditionUpdateEvent } from './Leaderboard';
import OpenEditionActions from './OpenEditionActions';
import OpenEditionLabels from './OpenEditionLabels';

interface OpenEditionPageProps {
  openEditionQuery: WithLoadQueryProps<OpenEditionQuery>;
}

const OpenEditionPage = withLoadQuery(
  ({ openEditionQuery: { queryRef, invalidate } }: OpenEditionPageProps) => {
    const result = usePreloadedQuery<OpenEditionQuery>(
      OpenEditionQueryType,
      queryRef
    );

    const nftMetadata = useMemo(
      () => result.dropsMedias.edges[0]?.node.nftMetadata ?? null,
      [result]
    );
    const dropMetadata = nftMetadata?.dropMetadata ?? null;
    const openEdition = dropMetadata?.listing ?? null;
    const [{ dropsAt, endsAt, editionsSold }, setOpenEditionState] =
      useState<OpenEditionUpdateEvent>({
        dropsAt: dropMetadata?.dropsAt,
        editionsSold: openEdition?.editionsSold,
        endsAt: dropMetadata?.endsAt,
      });

    useEffect(() => {
      if (!nftMetadata?.id) return;

      setDocTitle([nftMetadata.title, APP_NAME].join(' | '));
    }, [nftMetadata?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!nftMetadata) return <FourOFour resetErrorBoundary={() => null} />;

    return (
      <TrackingContext
        source={EcommerceSourceType.OpenEditionPage}
        title={nftMetadata.title}
        storeSlug={nftMetadata.author.store.storeSlug}
      >
        <ProductLayoutContainer>
          <ProductLayout nftMetadata={nftMetadata}>
            <ProductTitle title={nftMetadata.title} />
            <ProductAuthorDetails
              author={nftMetadata.author}
              collaborators={nftMetadata.collaborators}
              collection={nftMetadata.nftCollection}
            />
            <OpenEditionLabels
              dropMetadata={dropMetadata}
              dropsAt={dropsAt}
              endsAt={endsAt}
              editionsSold={editionsSold}
              forceUpdate={invalidate}
              openEdition={openEdition}
            />
            <OpenEditionActions
              allowFiat={nftMetadata.author.store.acceptsCreditCard}
              dropMetadata={dropMetadata}
              dropsAt={dropsAt}
              endsAt={endsAt}
              openEdition={openEdition}
              invalidate={invalidate}
            />
            <div className={CSSMargin.BOTTOM[32]} />
            <OpenEditionLeaderboard
              openEditionSlug={openEdition.slug}
              onOpenEditionUpdate={setOpenEditionState}
            />
            <ProductAboutArtists
              author={nftMetadata.author}
              collaborators={nftMetadata.collaborators}
            />
            <MPDivider />
            <ProductDescriptions
              artwork={{
                description: nftMetadata.description,
                videoPreviewImageUrl: nftMetadata.descriptionImageUrl,
                videoUrl: nftMetadata.descriptionVideoUrl,
              }}
              collectorReward={{
                description: nftMetadata.collectorReward?.description,
              }}
            />
            <ProductDetailsMetadata
              etherscanUrl={nftMetadata.contract?.etherscanUrl}
              height={nftMetadata.mediaMetadata.height}
              metadataUrl={nftMetadata.metadataUrl}
              rawMediaUrl={nftMetadata.rawMediaUrl}
              width={nftMetadata.mediaMetadata.width}
            />
          </ProductLayout>
        </ProductLayoutContainer>
      </TrackingContext>
    );
  },
  {
    openEditionQuery: { concreteRequest: OpenEditionQueryType },
  }
);

export default function Page() {
  const { slugAndId } = useParams();
  const [, slug, id] = slugAndId?.match(/(.*)-(\d+)$/) ?? [];

  if (!id || !slug) return <FourOFour resetErrorBoundary={() => null} />;

  return (
    <OpenEditionPage
      openEditionQuery={{
        variables: {
          openEditionId: parseInt(id, 10),
          openEditionSlug: slug,
        },
      }}
    />
  );
}
