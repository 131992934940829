import { MPChip } from '@mp-frontend/core-components';

import {
  AspectRatioEnum,
  EditionTypeEnum,
  MediaTypeEnum,
  StatusEnum,
} from 'types/__generated__/graphql';

import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generatePriceString from 'utils/currency/generatePricing';

import useArtworkFilterState from './UseArtworkFilterState';

export default function ArtworkChips() {
  const filterParams = useArtworkFilterState();
  const [filterStatus, setFilterStatus] = filterParams.status;
  const [filterEditions, setFilterEditions] = filterParams.editions;
  const [filterMedia, setFilterMedia] = filterParams.media;

  const [filterPrice, setPrice] = filterParams.price;
  const minPriceFormatted = generatePriceString(
    parseFloat(filterPrice?.min || '0'),
    CurrencyDisplayMode.USD,
    { maximumFractionDigits: 0, minimumFractionDigits: 0 }
  );
  const maxPriceFormatted = generatePriceString(
    parseFloat(filterPrice?.max || '0'),
    CurrencyDisplayMode.USD,
    { maximumFractionDigits: 0, minimumFractionDigits: 0 }
  );

  const [filterDimensions, setFilterDimensions] = filterParams.dimensions;

  const [filterMinWidthHeight, setFilterMinWidthHeight] =
    filterParams.minWidthHeight;

  const deleteStatusFilter = (name: StatusEnum) =>
    setFilterStatus((status) => ({ ...status, ...{ [name]: undefined } }));
  const deleteEditionsFilter = (name: EditionTypeEnum) =>
    setFilterEditions((status) => ({ ...status, ...{ [name]: undefined } }));
  const deletePriceFilter = (name: string) =>
    setPrice((status) => ({ ...status, ...{ [name]: undefined } }));
  const deleteBothPriceFilters = () => setPrice({ max: '', min: '' });
  const deleteMediaFilter = (name: string) =>
    setFilterMedia((status) => ({ ...status, ...{ [name]: undefined } }));
  const deleteDimensionsFilter = (name: AspectRatioEnum) =>
    setFilterDimensions((status) => ({ ...status, ...{ [name]: undefined } }));
  const deleteHeightWidthFilter = (name: string) =>
    setFilterMinWidthHeight((status) => ({
      ...status,
      ...{ [name]: undefined },
    }));

  const [query, setQuery] = filterParams.query;

  return (
    <>
      {!!query && (
        <MPChip
          label={`Results for: "${query}"`}
          onDelete={() => setQuery(undefined)}
        />
      )}
      {!!filterStatus[StatusEnum.BuyNow] && (
        <MPChip
          label="Buy Now"
          onDelete={() => deleteStatusFilter(StatusEnum.BuyNow)}
        />
      )}

      {!!filterStatus[StatusEnum.InAuction] && (
        <MPChip
          label="In Auction"
          onDelete={() => deleteStatusFilter(StatusEnum.InAuction)}
        />
      )}

      {!!filterStatus[StatusEnum.HasOffers] && (
        <MPChip
          label="Has Offers"
          onDelete={() => deleteStatusFilter(StatusEnum.HasOffers)}
        />
      )}

      {!!filterEditions[EditionTypeEnum.Single] && (
        <MPChip
          label="Uniques"
          onDelete={() => deleteEditionsFilter(EditionTypeEnum.Single)}
        />
      )}

      {!!filterEditions[EditionTypeEnum.Limited] && (
        <MPChip
          label="Limited Editions"
          onDelete={() => deleteEditionsFilter(EditionTypeEnum.Limited)}
        />
      )}

      {!!filterPrice.min && !filterPrice.max && (
        <MPChip
          label={`Min Price: ${minPriceFormatted}`}
          onDelete={() => deletePriceFilter('min')}
        />
      )}

      {!!filterPrice.max && !filterPrice.min && (
        <MPChip
          label={`Max Price: ${maxPriceFormatted}`}
          onDelete={() => deletePriceFilter('max')}
        />
      )}

      {!!filterPrice.min && !!filterPrice.max && (
        <MPChip
          label={`Price: ${minPriceFormatted} - ${maxPriceFormatted}`}
          onDelete={deleteBothPriceFilters}
        />
      )}

      {!!filterMedia[MediaTypeEnum.Still] && (
        <MPChip
          label="Still"
          onDelete={() => deleteMediaFilter(MediaTypeEnum.Still)}
        />
      )}

      {!!filterMedia[MediaTypeEnum.Motion] && (
        <MPChip
          label="Motion"
          onDelete={() => deleteMediaFilter(MediaTypeEnum.Motion)}
        />
      )}

      {!!filterDimensions[AspectRatioEnum.Landscape] && (
        <MPChip
          label="Landscape"
          onDelete={() => deleteDimensionsFilter(AspectRatioEnum.Landscape)}
        />
      )}

      {!!filterDimensions[AspectRatioEnum.Portrait] && (
        <MPChip
          label="Portrait"
          onDelete={() => deleteDimensionsFilter(AspectRatioEnum.Portrait)}
        />
      )}

      {!!filterDimensions[AspectRatioEnum.Square] && (
        <MPChip
          label="Square"
          onDelete={() => deleteDimensionsFilter(AspectRatioEnum.Square)}
        />
      )}

      {!!filterMinWidthHeight.height && (
        <MPChip
          label={`Min Height: ${filterMinWidthHeight.height}px`}
          onDelete={() => deleteHeightWidthFilter('height')}
        />
      )}

      {!!filterMinWidthHeight.width && (
        <MPChip
          label={`Min Width: ${filterMinWidthHeight.width}px`}
          onDelete={() => deleteHeightWidthFilter('width')}
        />
      )}
    </>
  );
}
