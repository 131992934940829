  .filterRow {
    justify-content: space-between;
    margin: 0;
    display: flex;
    padding: 22px 0;
  }

  .filterRowRight {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  .filterRowLeft > :global(.MuiChip-root) {
    margin: 2px;
  }

  .filterRowRight > :global(.MuiChip-root) {
    margin: 2px;
    padding: 0 12px;
  }

  .filterRowRight > :global(.MuiChip-root) > :global(.MuiChip-label) {
    padding-left: 6px;
  }

  @media (--mq-is-mobile) {
    .filterRow {
        flex-direction: column-reverse;
        gap: 16px;
    }
  }
