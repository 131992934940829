import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { useIsMobile } from '@mp-frontend/core-components';

import { CloudinaryParams, withCloudinaryParams } from 'utils/cloudinaryUtils';
import useOnEnterKey from 'utils/eventHandlers/useOnEnterKey';
import { isNFTGif } from 'utils/nftUtils';

import { Media, MediaSize } from '.';

export const getRenderedMediaSize = (
  size: MediaSize,
  containerSize: MediaSize
): MediaSize => ({
  height:
    size?.height && containerSize?.height
      ? Math.min(
          containerSize.height,
          (size.height * containerSize.width) / size.width
        )
      : 0,
  width:
    size?.width && containerSize?.width
      ? Math.min(
          containerSize.width,
          (size.width * containerSize.height) / size.height
        )
      : 0,
});

interface ImageProps {
  isFullScreen: boolean;
  media: Media;
  onClick: (event: MouseEvent) => void;
  onLoad: () => void;
  size: MediaSize;
  className?: string;
  cloudinaryParams?: CloudinaryParams;
  rect?: MediaSize;
}

export default function Image({
  isFullScreen,
  media,
  size,
  rect,
  className,
  onClick,
  onLoad,
  cloudinaryParams = {
    crop: 'limit',
    height: 1000,
    quality: 'auto:good',
    width: 1000,
  },
}: ImageProps) {
  const isMobile = useIsMobile();
  const ariaOnEnter = useOnEnterKey(onClick);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const handleLoad = useCallback(() => {
    setIsLoaded(true);
    onLoad();
  }, [onLoad]);

  const [standardImageUrl, highResImageUrl] = useMemo(
    () =>
      media.highResBaseUrl
        ? [
            withCloudinaryParams(media.highResBaseUrl, cloudinaryParams),
            withCloudinaryParams(media.highResBaseUrl, {
              crop: 'limit',
              quality: 'auto:best',
              ...(isMobile
                ? { height: 5000, width: 5000 }
                : { height: 6000, width: 6000 }),
            }),
          ]
        : [media.mediumResUrl, media.highResUrl],
    [
      media.highResUrl,
      media.highResBaseUrl,
      media.mediumResUrl,
      cloudinaryParams,
      isMobile,
    ]
  );

  return isNFTGif(media.rawfileExtension) ? (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <picture
      className={className}
      onClick={onClick}
      onKeyPress={ariaOnEnter}
      onLoad={onLoad}
    >
      {isFullScreen ? (
        <source
          srcSet={highResImageUrl}
          {...getRenderedMediaSize(size, rect)}
        />
      ) : (
        <>
          <source media="(min-width: 1000px)" srcSet={highResImageUrl} />
          <source srcSet={standardImageUrl} />

          <img
            src={standardImageUrl}
            onLoad={handleLoad}
            style={{ display: isLoaded ? 'block' : 'none' }}
            alt=""
          />
        </>
      )}
    </picture>
  ) : (
    <img
      aria-hidden="true"
      className={className}
      onClick={onClick}
      onLoad={onLoad}
      {...(isFullScreen
        ? {
            src: highResImageUrl,
            ...getRenderedMediaSize(size, rect),
          }
        : {
            src: media.mediumResUrl,
          })}
    />
  );
}
