import { ChangeEvent, FocusEvent, useCallback, useState } from 'react';

import {
  MPColorClass,
  MPDialogProps,
  MPFonts,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { AccountStripeCardsQuery } from 'graphql/__generated__/AccountStripeCardsQuery.graphql';
import { ProductTypeBidEnum } from 'types/__generated__/graphql';

import ErrorDisplay from 'components/Error';
import {
  CreditCardPaymentFormState,
  FormState,
} from 'hooks/product/usePaymentFormState';
import ProductPendingOnChain from 'pages/product/ProductPendingOnChain';
import CSSGlobal from 'types/enums/css/Global';
import { getGeneralError } from 'utils/flows/purchaseBids';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import { WithLoadQueryPropsV1 } from 'utils/hocs/withLoadQuery';

import PaymentViewBody from '../../product/purchaseOfferDialog/paymentView/PaymentViewBody';
import PaymentViewDialog from '../../product/purchaseOfferDialog/paymentView/PaymentViewDialog';
import PaymentViewFooter from '../../product/purchaseOfferDialog/paymentView/PaymentViewFooter';
import PaymentViewForm from '../../product/purchaseOfferDialog/paymentView/PaymentViewForm';
import PaymentViewPricing from '../../product/purchaseOfferDialog/paymentView/PaymentViewPricing';

interface PurchaseDialogProps {
  allowFiat: boolean;
  count: string;
  onClose: MPDialogProps['onClose'];
  onCountChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSuccessPurchase: () => void;
  priceInEth: number;
  priceInUsd: number;
  purchaseWithCreditCard: () => Promise<void>;
  purchaseWithCreditCardState: FormState;
  purchaseWithEthereum: () => Promise<void>;
  purchaseWithEthereumState: FormState;
  stripeQuery: WithLoadQueryPropsV1<AccountStripeCardsQuery>;
  updateFormState: (newState: Partial<CreditCardPaymentFormState>) => void;
  purchaseWithEthereumTransaction?: string;
}

function PurchaseDialog({
  purchaseWithEthereum,
  purchaseWithCreditCard,
  stripeQuery,
  priceInEth,
  priceInUsd,
  updateFormState,
  purchaseWithCreditCardState,
  purchaseWithEthereumState,
  count: validCount,
  onCountChange: onValidCountChange,
  allowFiat,
  purchaseWithEthereumTransaction,
  onClose,
  onSuccessPurchase,
}: PurchaseDialogProps) {
  const [paymentMethod, setPaymentMethod] = useState<ProductTypeBidEnum>(
    ProductTypeBidEnum.Creditcard
  );
  const [currentCount, setCurrentCount] = useState(validCount);
  const currentCountNum = parseInt(currentCount, 10);
  const currentCountValidNum =
    currentCount && currentCountNum && currentCountNum >= 0
      ? currentCountNum
      : 0;

  const purchaseState =
    paymentMethod === ProductTypeBidEnum.Creditcard
      ? purchaseWithCreditCardState
      : purchaseWithEthereumState;

  const totalInUSD = priceInUsd ? priceInUsd * currentCountValidNum : 0;
  const totalInETH = priceInEth ? priceInEth * currentCountValidNum : 0;

  const handleCountChanging = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setCurrentCount(event.target.value),
    []
  );
  const handleCountChanged = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const valueNum = parseInt(event.target.value, 10);
      if (!event.target.value || !valueNum || valueNum < 1) {
        setCurrentCount('1');
        return;
      }

      setCurrentCount(`${valueNum}`);
      onValidCountChange(event);
    },
    [onValidCountChange]
  );
  const generalError = getGeneralError(purchaseState);

  return (
    <PaymentViewDialog title="Make a Purchase" onClose={onClose}>
      {purchaseWithEthereumTransaction ? (
        <ProductPendingOnChain
          queryVariables={{
            txtId: purchaseWithEthereumTransaction,
          }}
          onSuccess={onSuccessPurchase}
        />
      ) : (
        <>
          <PaymentViewBody>
            {generalError ? (
              <ErrorDisplay
                error={generalError}
                className={CSSGlobal.TextAlign.Centered}
              />
            ) : null}
            <PaymentViewForm
              paymentMethod={paymentMethod}
              stripeCardsQueryRef={stripeQuery.queryRef}
              onPaymentMethodChange={setPaymentMethod}
              onFormChange={updateFormState}
              acceptFiat={allowFiat}
            >
              <MPStyledTextField
                value={currentCount}
                onChange={handleCountChanging}
                onBlur={handleCountChanged}
                label="# of Editions"
                type="number"
                required
              />
            </PaymentViewForm>
            <PaymentViewPricing
              amountInEth={totalInETH}
              amountInUsd={totalInUSD}
              paymentMethod={paymentMethod}
              error={generalError}
            />
          </PaymentViewBody>
          <PaymentViewFooter
            submitTitle="Purchase"
            onSubmit={
              paymentMethod === ProductTypeBidEnum.Creditcard
                ? purchaseWithCreditCard
                : purchaseWithEthereum
            }
            isDisabled={
              purchaseState?.isDisabled ||
              purchaseState?.isValidating ||
              !validCount ||
              currentCountValidNum <= 0
            }
            useWalletButton={paymentMethod === ProductTypeBidEnum.Ether}
          >
            {purchaseState.mutationError ? (
              <div
                className={joinClasses(
                  MPFonts.textSmallMedium,
                  MPColorClass.ErrorMain
                )}
              >
                {purchaseState.mutationError.message}
              </div>
            ) : null}
          </PaymentViewFooter>
        </>
      )}
    </PaymentViewDialog>
  );
}

export default withDefaultErrorBoundary(PurchaseDialog, {
  hideState: true,
});
