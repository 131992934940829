import { ChangeEvent, useCallback, useState } from 'react';

import { MPCheckbox, MPDivider } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useSession from 'hooks/useSession';
import CSSGlobal from 'types/enums/css/Global';

import ArtistFilterList from './ArtistFilterList';
import ArtistFilterSearchInput from './ArtistFilterSearchInput';

import * as styles from 'css/pages/activity/ActivityFilters.module.css';

interface ArtistFilterInnerProps {
  artistValues: string[];
  isShowFollowing: boolean;
  onChangeArtistSelection: (values: string[]) => void;
  onChangeFollowArtist: (value: boolean) => void;
}

function ArtistFilterInner({
  isShowFollowing,
  onChangeFollowArtist,
  artistValues,
  onChangeArtistSelection,
}: ArtistFilterInnerProps) {
  const session = useSession();
  const [removedArtistValues, setRemovedArtistValues] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<string[]>([]);

  const handleCheckboxFollowingChange = useCallback(
    (checked: boolean) => {
      onChangeFollowArtist(checked ? true : undefined);
    },
    [onChangeFollowArtist]
  );

  const handleAvailableAccountsChange = useCallback(
    (checked: boolean, value: string) => {
      onChangeArtistSelection(
        checked
          ? [...artistValues, value]
          : artistValues.filter((v) => v !== value)
      );
      if (!checked && searchResults.length > 0)
        setRemovedArtistValues((prev) => [...prev, value]);
    },
    [artistValues, onChangeArtistSelection, searchResults.length]
  );

  const showDivider = artistValues.length > 0 || session.isLoggedIn();

  return (
    <div className={styles.artistFilterContainer}>
      <ul className={joinClasses(CSSGlobal.Flex.Col, styles.artistsFilter)}>
        {session.isLoggedIn() ? (
          <li key="artist-following">
            <MPCheckbox
              label="Artists You Follow"
              name="artist-following"
              isChecked={isShowFollowing}
              onChange={(
                event: ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => handleCheckboxFollowingChange(checked)}
            />
          </li>
        ) : null}
        <ArtistFilterList
          usernames={artistValues}
          toExcludeUsernames={artistValues}
          handleAvailableAccountsChange={handleAvailableAccountsChange}
        />
      </ul>
      {showDivider ? (
        <MPDivider className={styles.activityArtistDivider} />
      ) : null}
      <ArtistFilterSearchInput
        {...{ artistValues, removedArtistValues, setSearchResults }}
        accountsQuery={{ variables: { searchText: '' } }}
      />
      <div className={styles.availableArtistSection}>
        <ul className={joinClasses(CSSGlobal.Flex.Col, styles.artistsFilter)}>
          <ArtistFilterList
            usernames={searchResults}
            toExcludeUsernames={artistValues}
            handleAvailableAccountsChange={handleAvailableAccountsChange}
          />
        </ul>
      </div>
    </div>
  );
}

export default ArtistFilterInner;
