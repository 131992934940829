import { useCallback, useState } from 'react';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import useArtworkFilterState from 'pages/explore/artwork/UseArtworkFilterState';
import { NFTType } from 'types/graphql/NFT';

import ExploreGrid from './explore/ExploreGrid';
import FilterRow from './explore/FilterRow';
import ArtworksByArtistContent from './ArtworksByArtistContent';
import { TrackingContext } from './trackingContext';

import * as styles from 'css/components/ArtworkContent.module.css';

function ArtworkContent() {
  const filterParams = useArtworkFilterState();
  const [isArtistSearchVisible, setIsArtistSearchVisible] = useState(false);
  const handleArtworksByArtistLoad = useCallback(
    (results: NFTType[]) => setIsArtistSearchVisible(!!results.length),
    []
  );

  return (
    <div className={styles.page}>
      <TrackingContext genre={filterParams.genre[0]()}>
        <FilterRow />
        <ArtworksByArtistContent onDataLoad={handleArtworksByArtistLoad} />
        <DefaultErrorBoundary>
          <ExploreGrid title={isArtistSearchVisible ? 'Artworks' : null} />
        </DefaultErrorBoundary>
      </TrackingContext>
    </div>
  );
}

export default ArtworkContent;
