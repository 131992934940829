import { useCallback, useEffect, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { useGateValue } from '@statsig/react-bindings';

import { joinClasses } from '@mp-frontend/core-utils';

import ArtworksByArtistRequest, {
  NFTsExploreByArtistPaginatedQuery,
} from 'graphql/__generated__/NFTsExploreByArtistPaginatedQuery.graphql';

import ArtworkCard from 'components/cards/ArtworkCard';
import Carousel from 'components/carousel/Carousel';
import GTM from 'GTM';
import useArtworkFilterState from 'pages/explore/artwork/UseArtworkFilterState';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import { useExploreQueryParams } from './explore/ExploreGrid';

import * as exploreStyles from 'css/pages/explore/explore.module.css';
import * as homepageCardStyles from 'css/pages/homepage/HomepageStandardCardsSection.module.css';

const CAROUSEL_NAME = 'explore_artworks_by_artist';

interface ArtworksByArtistContentProps {
  artworksByArtistQuery: WithLoadQueryProps<NFTsExploreByArtistPaginatedQuery>;
  onDataLoad?: (
    results: NFTsExploreByArtistPaginatedQuery['response']['nftsByArtist']['edges'][number]['node'][]
  ) => void;
}

const PreloadedArtworksByArtistContent = withLoadQuery(
  ({ onDataLoad, artworksByArtistQuery }: ArtworksByArtistContentProps) => {
    const { nftsByArtist } =
      usePreloadedQuery<NFTsExploreByArtistPaginatedQuery>(
        ArtworksByArtistRequest,
        artworksByArtistQuery.queryRef
      );

    const nfts = useMemo(
      () => nftsByArtist.edges.map((edge) => edge.node),
      [nftsByArtist.edges]
    );

    useEffect(() => {
      onDataLoad(nfts);
    }, [nfts, onDataLoad]);

    const logViewAbleCardsToGA = useCallback(
      (start: number, end: number) => {
        GTM.ecommerce.trackViewItemList(nfts.slice(start, end), CAROUSEL_NAME);
      },
      [nfts]
    );

    return (
      !!nfts.length && (
        <div className={exploreStyles.artistCarouselContainer}>
          <Carousel
            header={<>Artists</>}
            items={nfts.map((nft, idx) => (
              <ArtworkCard
                key={nft.id}
                nft={nft}
                className={joinClasses(homepageCardStyles.card, {
                  [homepageCardStyles.last]: idx === nfts.length - 1,
                })}
              />
            ))}
            containerName={CAROUSEL_NAME}
            logViewAbleCardsToGA={logViewAbleCardsToGA}
          />
        </div>
      )
    );
  },
  {
    artworksByArtistQuery: { concreteRequest: ArtworksByArtistRequest },
  }
);

function ArtworksByArtistContent({
  onDataLoad,
}: Omit<ArtworksByArtistContentProps, 'artworksByArtistQuery'>) {
  const showArtistSearch = useGateValue('gate_artist_search');
  const filterParams = useArtworkFilterState();
  const queryParams = useExploreQueryParams(null, null);
  const hide = !showArtistSearch || !filterParams.query[0];

  useEffect(() => {
    if (!hide) return;

    onDataLoad([]);
  }, [hide, onDataLoad]);

  return (
    !hide && (
      <PreloadedArtworksByArtistContent
        onDataLoad={onDataLoad}
        artworksByArtistQuery={{ variables: queryParams }}
      />
    )
  );
}

export default ArtworksByArtistContent;
