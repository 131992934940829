/**
 * @generated SignedSource<<f0bb70dee71162ba42d587536751c96f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountStripeCardsQuery$variables = {};
export type AccountStripeCardsQuery$data = {
  readonly stripeCards: ReadonlyArray<{
    readonly id: string | null;
    readonly brand: string | null;
    readonly number: string | null;
    readonly image: string | null;
    readonly expMonth: number | null;
    readonly expYear: number | null;
  }>;
};
export type AccountStripeCardsQuery = {
  variables: AccountStripeCardsQuery$variables;
  response: AccountStripeCardsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PaymentMethodType",
    "kind": "LinkedField",
    "name": "stripeCards",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "brand",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "number",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "image",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expMonth",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expYear",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountStripeCardsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountStripeCardsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e049571f34c7f5b2d636cb2f6499c8c6",
    "id": null,
    "metadata": {},
    "name": "AccountStripeCardsQuery",
    "operationKind": "query",
    "text": "query AccountStripeCardsQuery {\n  stripeCards {\n    id\n    brand\n    number\n    image\n    expMonth\n    expYear\n  }\n}\n"
  }
};
})();

(node as any).hash = "3e03b5dea57d5734c7c2a8b33fbff8d0";

export default node;
