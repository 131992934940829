.container {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--heroCard-padding);

  @media (--mq-is-mobile) {
    padding: var(--heroCard-padding) 0 0 0;
  }
}

.rail {
  box-sizing: border-box;
  padding: 0 var(--heroCard-padding) 0 0;
  width: calc(350px + var(--heroCard-padding));

  @media (--mq-is-mobile) {
    padding: 0 20px var(--heroCard-padding);
    width: 100%;
  }
}

.description {
  --line-clamp: 4;
}

.image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.desktop {
    display: none;

    @media (--mq-is-not-mobile) {
      display: block;
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  &.mobile {
    display: none;

    @media (--mq-is-mobile) {
      display: block;
      height: 314px;
      width: 100%;
    }
  }
}
