import { MouseEventHandler } from 'react';

import {
  MPActionButton,
  MPDialogProps,
  MPFonts,
  MPInlineTextLinkButton,
} from '@mp-frontend/core-components';
import { DefaultUserIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import CongratulationDialog from 'components/dialogs/CongratulationDialog';
import ROUTES from 'constants/Routes';
import GTM from 'GTM';
import usePreventScrollEffect from 'hooks/django/usePreventScrollEffect';

import * as styles from 'css/components/LoginRequiredButton.module.css';

interface SignUpCompleteDialogProps {
  container: MPDialogProps['container'];
  onContinue: MouseEventHandler<HTMLButtonElement>;
  open: MPDialogProps['open'];
  onClose?: MPDialogProps['onClose'];
  showCreatorLink?: boolean;
}

const navigateToSwitchToCreatorAccount = () => {
  GTM.loginModal.trackGoToActivateCreatorAccount();
  window.open(ROUTES.APPLICATION());
};

export default function SignUpCompleteDialog({
  open,
  onContinue,
  onClose,
  showCreatorLink,
  container,
}: SignUpCompleteDialogProps) {
  usePreventScrollEffect(open);
  return (
    <CongratulationDialog
      open={open}
      onClose={onClose}
      title={null}
      container={container}
    >
      <div className={styles.signUpSuccessDialogContentWrapper}>
        <div className={styles.newAccountIconWrapper}>
          <DefaultUserIcon className={styles.newAccountIconInner} />
        </div>
        <h1
          className={joinClasses(
            MPFonts.headline3,
            styles.welcomeToMakersPlaceHeading
          )}
        >
          Welcome to MakersPlace
        </h1>
        <p
          className={joinClasses(
            MPFonts.bodyMedium,
            styles.welcomeToMakersPlaceDescription
          )}
        >
          Browse and collect premium artworks from verified creators.
        </p>
        <MPActionButton onClick={onContinue} size="large" fullWidth>
          Continue
        </MPActionButton>

        {showCreatorLink ? (
          <p className={MPFonts.bodyMedium}>
            Are you a creator?
            <br />
            <MPInlineTextLinkButton onClick={navigateToSwitchToCreatorAccount}>
              Switch to a Creator Account
            </MPInlineTextLinkButton>
          </p>
        ) : null}
      </div>
    </CongratulationDialog>
  );
}
