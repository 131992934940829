import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import {
  MPColorClass,
  MPColorValue,
  MPFonts,
  MPTooltip,
} from '@mp-frontend/core-components';
import {
  AccessTimeIcon,
  CheckCircleIcon,
  InfoIcon,
  NotificationsIcon,
  NotifyBidAcceptedIcon,
  NotifyBidPlacedIcon,
  TimerIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import DropStatusQueryRequest, {
  DropStatusQuery,
} from 'graphql/__generated__/DropStatusQuery.graphql';
import { DropPhaseEnum } from 'types/__generated__/graphql';

import AuctionTimer from 'components/auction/AuctionTimer';
import LoginRequiredButton from 'components/LoginRequiredButton';
import { UNBREAKABLE_SPACE_UNICODE } from 'constants/Symbols';
import { DropClickEventType } from 'GTM';
import usePollLoadQuery from 'hooks/graphqlLogic/usePollLoadQuery';
import useOnNewLogin from 'hooks/session/useOnNewLogin';
import useCountdown, { UseCountdownProps } from 'hooks/useCountdown';
import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import useDropPageGTM from 'utils/GTM/drop';
import hasDatePassed from 'utils/hasDatePassed';

import SubscriptionDialog from './SubscriptionDialog';

import * as styles from 'css/pages/drops/components/StatusBar.module.css';

type DropNode = DropStatusQuery['response']['drops']['edges'][0]['node'];

function DropStatus({ drop }: { drop: DropNode }) {
  return (
    <div
      className={joinClasses(CSSGlobal.Flex.InlineRowCenterAlign, CSSGap[4])}
    >
      {drop.dropPhase === DropPhaseEnum.New && (
        <>
          <TimerIcon
            fill={MPColorValue.CommonBlack}
            fontSize="19"
            stroke={MPColorValue.CommonWhite}
          />
          <span>New Exhibition</span>
        </>
      )}
      {drop.dropPhase === DropPhaseEnum.Upcoming && (
        <>
          <AccessTimeIcon />
          <span>Upcoming Exhibition</span>
        </>
      )}
      {drop.dropPhase === DropPhaseEnum.Presale && (
        <>
          <NotifyBidAcceptedIcon />
          <span>Exhibition Pre-Sale</span>
          <MPTooltip
            title={`Only eligible users can purchase/bid during the${UNBREAKABLE_SPACE_UNICODE}pre-sale period.`}
            placement="right"
          >
            <InfoIcon
              className={MPColorClass.SolidNeutralGray3}
              fontSize="16"
            />
          </MPTooltip>
        </>
      )}
      {drop.dropPhase === DropPhaseEnum.SoldOut && (
        <>
          <NotifyBidPlacedIcon />
          <span>Sold Out Exhibition</span>
        </>
      )}
    </div>
  );
}

function TimerDate(props: UseCountdownProps) {
  const countdown = useCountdown(props);
  const newDate = dayjs(props.endDate);

  if (countdown.days >= 1) {
    return (
      <>
        {countdown.days >= 7 ? newDate.format('MMM D') : newDate.format('dddd')}
      </>
    );
  }

  return <AuctionTimer {...countdown} />;
}

export function Timer({
  hideIcon = false,
  ...dateProps
}: UseCountdownProps & {
  hideIcon?: boolean;
}) {
  return (
    <span className={CSSGlobal.Flex.InlineRowCenterAlign}>
      <span className={CSSGlobal.NoWrapWhitespace}>
        <TimerDate {...dateProps} />
      </span>
      {!hideIcon && <NotificationsIcon />}
    </span>
  );
}

function DropAction({
  drop,
  invalidate,
}: {
  drop: DropNode;
  invalidate: () => void;
}) {
  const session = useSession();
  useOnNewLogin(invalidate);
  const [showSubscriptionDialog, setShowSubscriptionDialog] =
    useState<boolean>(false);
  const track = useDropPageGTM();

  const handleOpenSubscriptionDialog = useCallback(() => {
    setShowSubscriptionDialog(true);
    track.trackClickEventType(DropClickEventType.SubscribeForUpdates);
  }, [track]);
  const handleCloseSubscriptionDialog = useCallback(
    (subscribed = false) => {
      if (subscribed) invalidate();

      setShowSubscriptionDialog(false);
    },
    [invalidate]
  );

  const timerEndTime =
    drop.presale?.startsAt &&
    drop.isEligibleForPresale &&
    !hasDatePassed(drop.presale.startsAt)
      ? drop.presale.startsAt
      : drop.dropsAt;

  return (
    <>
      <div
        className={joinClasses(CSSGlobal.Flex.InlineRowCenterAlign, CSSGap[4])}
      >
        {drop.dropPhase === DropPhaseEnum.Presale && (
          <>
            {session.isLoggedIn() ? (
              <>
                {drop.isEligibleForPresale ? (
                  <span className={MPColorClass.GoldMain}>Eligible</span>
                ) : (
                  <span className={MPColorClass.SolidNeutralGray3}>
                    Not Eligible
                  </span>
                )}
              </>
            ) : (
              <LoginRequiredButton
                variant="tertiary-custom"
                className={styles.actionButton}
                onClick={() =>
                  track.trackClickEventType(
                    DropClickEventType.PreSaleEligibilityCheck
                  )
                }
              >
                <span
                  className={joinClasses(
                    MPFonts.textSmallSemiBold,
                    styles.actionButtonTxt
                  )}
                >
                  Check Eligibility
                </span>
              </LoginRequiredButton>
            )}
          </>
        )}
        {drop.dropPhase === DropPhaseEnum.Upcoming &&
          (drop.subscribePhoneNumber ? (
            <div
              className={joinClasses(
                CSSGlobal.Flex.InlineRow,
                MPFonts.textSmallSemiBold,
                styles.noActionTimer
              )}
            >
              <Timer
                endDate={timerEndTime}
                hideIcon
                skipHandlerIfDateWasInPast
                onEnd={invalidate}
              />
              <CheckCircleIcon />
            </div>
          ) : (
            <LoginRequiredButton
              variant="tertiary-custom"
              className={styles.actionButton}
              onClick={handleOpenSubscriptionDialog}
            >
              <Timer
                endDate={timerEndTime}
                skipHandlerIfDateWasInPast
                onEnd={invalidate}
              />
            </LoginRequiredButton>
          ))}
      </div>

      {!!showSubscriptionDialog && (
        <SubscriptionDialog
          dropId={drop.pk}
          dropTitle={drop.dropTitle}
          dropsAt={drop.dropsAt}
          onClose={handleCloseSubscriptionDialog}
        />
      )}
    </>
  );
}

function DropStatusBar({
  queryRef,
  invalidate,
}: {
  invalidate: () => void;
  queryRef: PreloadedQuery<DropStatusQuery>;
}) {
  const drop = usePreloadedQuery<DropStatusQuery>(
    DropStatusQueryRequest,
    queryRef
  ).drops.edges[0].node;

  return drop.dropPhase === DropPhaseEnum.Live ? null : (
    <div
      className={joinClasses(
        MPFonts.textSmallMedium,
        CSSGlobal.Flex.RowSpaceBetween,
        CSSGlobal.Flex.RowCenterAlign,
        CSSGlobal.Cursor.Default,
        styles.statusBar,
        drop.dropPhase === DropPhaseEnum.SoldOut && styles.soldOut
      )}
    >
      <DropStatus drop={drop} />
      <DropAction drop={drop} invalidate={invalidate} />
    </div>
  );
}

export default function DropStatusBarWithPolling({ invalidate }) {
  const { dropSlug } = useParams();

  const [statusQueryRef, , , invalidateStatus] =
    usePollLoadQuery<DropStatusQuery>({
      intervalMs: 1000,
      queryType: DropStatusQueryRequest,
      queryVariables: { dropFamilySlug: dropSlug },
    });

  const handleInvalidate = useCallback(() => {
    invalidate();
    invalidateStatus();
  }, [invalidate, invalidateStatus]);

  return (
    statusQueryRef && (
      <DropStatusBar queryRef={statusQueryRef} invalidate={handleInvalidate} />
    )
  );
}
