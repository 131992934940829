.carouselContainerTitle {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px 20px 0;
}

.carouselContent {
  position: relative;
}

.carouselScrollIcons {
  display: flex;
  margin-left: auto;
  gap: 10px;
  flex-direction: row;
}

.carouselActionArrow {
  align-items: center;
  background-color: var(--color-commonWhite);
  border-color: var(--color-backgroundDefault);
  border-radius: 14px;
  border-style: solid;
  border-width: 1px;
  box-sizing: content-box;
  color: var(--color-commonBlack);
  cursor: pointer;
  display: flex;
  height: 68px;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: color 300ms ease-in-out, border-color 300ms ease-in-out;
  width: 27px;

  &:hover,
  &:active {
    transition: color 300ms ease-out, border-color 300ms ease-out;
  }

  &:hover {
    border-color: var(--color-SolidNeutralGray1);
    color: var(--color-SolidNeutralGray5);
  }

  &:active {
    border-color: var(--color-SolidNeutralGray1);
    color: var(--color-SolidNeutralGray1);
  }

  &.previous {
    left: -20px;

    @media screen and (min-width: 1920px) {
      left: -47px;
    }
  }

  &.next {
    right: 0;

    @media screen and (min-width: 1920px) {
      right: -30px;
    }
  }
}

.cardsContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0px;
  scroll-behavior: smooth;
}

@media (--mq-is-mobile) {
  .carouselScrollContainer,
  .carouselContainerTitle {
    margin-left: 24px;
  }
}
