/* eslint-disable jsx-a11y/anchor-is-valid */
/* TODO: should be using the Link from react-router-dom. */
import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import { noop } from 'lodash';
import { PreloadedQuery, useMutation, usePreloadedQuery } from 'react-relay';
import { Link } from '@mui/material';

import {
  MPActionButton,
  MPFonts,
  useMPConfirmDialog,
} from '@mp-frontend/core-components';

import AccountDeleteCreditCard, {
  AccountDeleteCreditCardMutation,
} from 'graphql/__generated__/AccountDeleteCreditCardMutation.graphql';
import AccountStripeCards, {
  AccountStripeCardsQuery,
} from 'graphql/__generated__/AccountStripeCardsQuery.graphql';

import DefaultSuspense from 'components/DefaultSuspense';
import ROUTES from 'constants/Routes';
import useLoadQuery from 'hooks/useLoadQuery';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';

import * as styles from 'css/pages/store/EditAccountPage.module.css';

interface CreditCard {
  brand: string;
  id: string;
  image: string;
  number: string;
}

interface CreditCardRowProps {
  card: CreditCard;
  onDelete: (
    cardId: string,
    onError: Dispatch<SetStateAction<boolean>>
  ) => void;
}

function CreditCardRow({ card, onDelete }: CreditCardRowProps) {
  const [hasError, setHasError] = useState(false);

  return (
    <div className={styles.creditCardRow}>
      <img className={styles.creditCardLogo} src={card.image} />
      <span className="ml-2">
        {capitalizeFirstLetter(card.brand)} ending in {card.number}
      </span>
      <span className={styles.creditCardDelete}>
        <MPActionButton
          variant="secondary"
          onClick={() => onDelete(card.id, setHasError)}
        >
          Delete
        </MPActionButton>
      </span>
      {!!hasError && (
        <span className={styles.rightSideErrorMessage}>
          Error deleting credit card.
        </span>
      )}
    </div>
  );
}

interface CardRefProps {
  cardId: string;
  setHasError: (boolean) => void;
}

interface CreditCardContentsProps {
  invalidateStripeCards: () => void;
  stripeCardsQueryRef: PreloadedQuery<AccountStripeCardsQuery>;
}

function CreditCardContents({
  invalidateStripeCards,
  stripeCardsQueryRef,
}: CreditCardContentsProps) {
  const { stripeCards } = usePreloadedQuery<AccountStripeCardsQuery>(
    AccountStripeCards,
    stripeCardsQueryRef
  );
  const [deleteCreditCardCall] = useMutation<AccountDeleteCreditCardMutation>(
    AccountDeleteCreditCard
  );

  const cardIdRef = useRef<CardRefProps>();

  const deleteCreditCard = useCallback(async () => {
    deleteCreditCardCall({
      onCompleted: () => cardIdRef.current.setHasError(false),
      onError: () => cardIdRef.current.setHasError(true),
      updater: invalidateStripeCards,
      variables: { cardId: cardIdRef.current.cardId },
    });
  }, [cardIdRef, deleteCreditCardCall, invalidateStripeCards]);

  const [, setDialogOpen, ConfirmDialog] = useMPConfirmDialog({
    content: 'Are you sure you wish to delete this saved credit card?',
    onCancel: noop,
    onConfirm: deleteCreditCard,
    title: 'Are you sure you wish to delete this saved credit card?',
  });

  const onDelete = async (
    cardId: string,
    setHasError: Dispatch<SetStateAction<boolean>>
  ) => {
    cardIdRef.current = { cardId, setHasError };
    setDialogOpen(true);
  };

  return (
    stripeCards.length > 0 && (
      <>
        <h4 className={MPFonts.titleSmall}>Saved Credit Cards</h4>

        {/* TODO: use a pop-up dialog similar to UploadNFTPage. */}
        <Link
          href={ROUTES.FAQ.SAVED_CREDIT_CARDS()}
          className={MPFonts.textSmallSemiBold}
          target="_blank"
        >
          Learn more.
        </Link>

        <br />
        <br />

        {stripeCards.map((card) => (
          <CreditCardRow key={card.id} card={card} onDelete={onDelete} />
        ))}
        {ConfirmDialog}
      </>
    )
  );
}

function CreditCardSection() {
  const [stripeCardsQueryRef, , invalidateStripeCards] =
    useLoadQuery<AccountStripeCardsQuery>(AccountStripeCards, {});

  return (
    <DefaultSuspense>
      {!!stripeCardsQueryRef && (
        <CreditCardContents
          invalidateStripeCards={invalidateStripeCards}
          stripeCardsQueryRef={stripeCardsQueryRef}
        />
      )}
    </DefaultSuspense>
  );
}

export default CreditCardSection;
