import { useState } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import useResizeObserver from 'use-resize-observer';

import { MPFonts, useIsMobile } from '@mp-frontend/core-components';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import HomepageGenresQueryType, {
  HomepageGenresQuery,
} from 'graphql/__generated__/HomepageGenresQuery.graphql';

import HomepageGenreCard from './HomepageGenreCard';

import * as styles from 'css/pages/homepage/HomepageGenres.module.css';

const DEFAULT_ACTIVE_IDX = -1;

interface HomepageGenresProps {
  queryRef: PreloadedQuery<HomepageGenresQuery>;
}

function HomepageGenres({ queryRef }: HomepageGenresProps) {
  const { homepageGenres } = usePreloadedQuery<HomepageGenresQuery>(
    HomepageGenresQueryType,
    queryRef
  );
  const isMobile = useIsMobile();
  const [listRef, , setListRef] = useRefState<HTMLUListElement>(null);
  const [activeIdx, setActiveIdx] = useState<number>(DEFAULT_ACTIVE_IDX);
  const [listWidth, setListWidth] = useState<number>(0);
  useResizeObserver<HTMLUListElement>({
    onResize: (size) => setListWidth(size.width || 0),
    ref: listRef,
  });

  const handleEnter = (event: MouseEvent, idx: number) => {
    event.preventDefault();
    setActiveIdx(idx);
  };
  const handleLeave = (event: MouseEvent) => {
    event.preventDefault();
    setActiveIdx(DEFAULT_ACTIVE_IDX);
  };

  const gap =
    (listRef &&
      parseInt(
        window.getComputedStyle(listRef as Element).getPropertyValue('gap'),
        10
      )) ||
    0;
  const cardsWidth = listRef
    ? Math.max(listWidth - gap * (homepageGenres.length - 1), 0)
    : 0;
  const defaultCardWidth = cardsWidth / homepageGenres.length;
  const activeCardWidth = defaultCardWidth * 2;
  const inactiveCardWidth =
    (cardsWidth - activeCardWidth) / (homepageGenres.length - 1);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <div className={joinClasses(MPFonts.headline4, styles.header)}>
          Explore Genres
        </div>

        <ul ref={setListRef} className={styles.list}>
          {homepageGenres.map(
            ({ title, tag, mediaUrl, mediaArtist, mediaTitle }, idx) => (
              <HomepageGenreCard
                key={tag}
                as="li"
                style={{
                  width:
                    !isMobile && listRef
                      ? activeIdx === -1
                        ? defaultCardWidth
                        : activeIdx === idx
                        ? activeCardWidth
                        : inactiveCardWidth
                      : 'initial',
                }}
                onMouseEnter={(event) => handleEnter(event, idx)}
                onMouseLeave={handleLeave}
                isActive={activeIdx === idx}
                title={title}
                tag={tag}
                mediaUrl={mediaUrl}
                mediaArtist={mediaArtist}
                mediaTitle={mediaTitle}
              />
            )
          )}
        </ul>
      </div>
    </div>
  );
}

export default HomepageGenres;
