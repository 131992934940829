.homePage {
  --homepage-gap: 60px;

  @media (--mq-is-mobile) {
    --homepage-gap: 32px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--homepage-gap);
  margin: 0 auto var(--homepage-gap) auto;
  max-width: 1440px;

  @media screen and (min-width: 1920px) {
    max-width: 1920px;
  }
}

.content > section {
  margin: 0 16px 0 32px;

  @media screen and (min-width: 1920px) {
    margin: 0 96px 0 112px;
  }

  @media (--mq-is-mobile) {
    margin: 0;
  }
}
