import { useMemo } from 'react';

import { MPColorClass, MPFonts } from '@mp-frontend/core-components';
import {
  CollectorRewardIcon,
  UnlockableIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';
import { NFTType, PurchasableNFTType } from 'types/graphql/NFT';

export interface ProductBadgesProps {
  nft: Pick<PurchasableNFTType, 'unlockable'>;
  nftMetadata: {
    collectorReward?: NFTType['metadata']['collectorReward'];
  };
}

export const useBadges = ({ nft, nftMetadata }: ProductBadgesProps) => {
  const hasUnlockable = !!nft.unlockable?.description;
  const hasCollectorReward = !!nftMetadata.collectorReward;
  const hasBadges = hasUnlockable || hasCollectorReward;

  return {
    hasBadges,
    hasCollectorReward,
    hasUnlockable,
  } as const;
};

export default function ProductBadges({
  nft,
  nftMetadata,
}: ProductBadgesProps) {
  const { hasBadges, hasCollectorReward, hasUnlockable } = useBadges({
    nft,
    nftMetadata,
  });
  const badges = useMemo(
    () =>
      [
        hasUnlockable && {
          Icon: UnlockableIcon,
          title: 'Unlockable',
        },
        hasCollectorReward && {
          Icon: CollectorRewardIcon,
          title: 'Collector Reward',
        },
      ].filter(Boolean),
    [hasCollectorReward, hasUnlockable]
  );

  return (
    hasBadges && (
      <div
        className={joinClasses(
          CSSGlobal.Flex.Row,
          CSSGap[16],
          CSSMargin.BOTTOM[6],
          MPColorClass.GoldMain
        )}
      >
        {badges.map(({ title, Icon }) => (
          <div
            key={title}
            className={joinClasses(
              CSSGlobal.Flex.InlineRowCenterAlign,
              CSSGap[4],
              MPFonts.textNormalMedium
            )}
          >
            <Icon />
            <span>{title}</span>
          </div>
        ))}
      </div>
    )
  );
}
