import MPSVGIcon from './MPSVGIcon';

export default function LoaderIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <rect>
        <animate
          attributeName="width"
          values="5;10;5"
          keyTimes="0;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="height"
          values="5;10;5"
          keyTimes="0;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="x"
          values="1.7;4.7;1.7"
          keyTimes="0;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="y"
          values="1.7;4.7;1.7"
          keyTimes="0;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
      </rect>
      <circle>
        <animate
          attributeName="r"
          values="7.5;3.25;7.5"
          keyTimes="0;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="cx"
          values="12;17;12"
          keyTimes="0;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="cy"
          values="12;17;12"
          keyTimes="0;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
    </MPSVGIcon>
  );
}
