import { useEffect, useState } from 'react';

function useIntersection(element, rootMargin = '0px') {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const current = element?.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );
    if (current) {
      observer.observe(current);
    }

    return () => current && observer.unobserve(current);
  }, [rootMargin, element]);

  return isVisible;
}

export default useIntersection;
