import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { MPAnimations, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useNavbarGTM from 'utils/GTM/navbar';

import useCloseAllPanelsContext from './useCloseAllPanelsContext';

import * as panelStyles from 'css/components/Navbar/Unified/panel.module.css';

interface NavLinkProps extends Omit<LinkProps, 'onClick'> {
  to: string;
  fontClassName?: MPFonts;
  onClick?: LinkProps['onClick'];
  trackItem?: string;
}

export function NavLink({
  children,
  to,
  reloadDocument,
  replace,
  state,
  trackItem,
  onClick,
  fontClassName = MPFonts.textLargeRegular,
  ...props
}: NavLinkProps) {
  const NavLinkClassName = joinClasses(
    'minContentWidth',
    'invisibleAnchor',
    'max100Width',
    MPAnimations.Color.DarkToLight,
    fontClassName
  );
  const Track = useNavbarGTM();

  const CloseAllPanels = useCloseAllPanelsContext();

  return to.startsWith('http') ? (
    <a
      href={to}
      className={NavLinkClassName}
      onClick={(e) => {
        Track.clickNavigationItem(trackItem ?? to);
        if (onClick) onClick(e);
        CloseAllPanels();
      }}
      {...props}
    >
      {children}
    </a>
  ) : (
    <Link
      to={to}
      className={NavLinkClassName}
      reloadDocument={reloadDocument}
      replace={replace}
      state={state}
      onClick={(e) => {
        Track.clickNavigationItem(trackItem ?? to);
        if (onClick) onClick(e);
        CloseAllPanels();
      }}
      {...props}
    >
      {children}
    </Link>
  );
}

interface LinkSectionProps {
  children: ReactNode;
  className?: string;
  title?: string;
}

export default function LinkSection({
  title,
  className,
  children,
}: LinkSectionProps) {
  return (
    <div className={joinClasses(panelStyles.linkSection, className)}>
      <span
        className={joinClasses(
          MPFonts.textSmallMedium,
          panelStyles.sectionHeading
        )}
      >
        {title}
      </span>
      {children}
    </div>
  );
}
