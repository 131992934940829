import { useCallback, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';

import { joinClasses } from '@mp-frontend/core-utils';

import HomepageFeaturedNftsQueryType, {
  HomepageFeaturedNftsQuery,
} from 'graphql/__generated__/HomepageFeaturedNftsQuery.graphql';

import ArtworkCard from 'components/cards/ArtworkCard';
import Carousel from 'components/carousel/Carousel';
import ROUTES from 'constants/Routes';
import GTM from 'GTM';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import { DISCOVER_ARTWORKS_CAROUSEL_NAME } from './HomepageConstants';

import * as homepageCardStyles from 'css/pages/homepage/HomepageStandardCardsSection.module.css';

function ExploreArtworks({
  featuredNftsQuery: { queryRef },
}: {
  featuredNftsQuery: WithLoadQueryProps<HomepageFeaturedNftsQuery>;
}) {
  const { homepageFeaturedNfts } = usePreloadedQuery<HomepageFeaturedNftsQuery>(
    HomepageFeaturedNftsQueryType,
    queryRef
  );

  const nfts = useMemo(
    () => homepageFeaturedNfts.edges.map((edge) => edge.node),
    [homepageFeaturedNfts.edges]
  );

  const logViewAbleCardsToGA = useCallback(
    (start: number, end: number) => {
      GTM.ecommerce.trackViewItemList(
        nfts.slice(start, end),
        DISCOVER_ARTWORKS_CAROUSEL_NAME
      );
    },
    [nfts]
  );

  return (
    !!nfts.length && (
      <Carousel
        header={<>Discover Artworks</>}
        viewAllLink={ROUTES.EXPLORE.ARTWORKS()}
        items={nfts.map((nft, idx) => (
          <ArtworkCard
            key={nft.id}
            nft={nft}
            className={joinClasses(homepageCardStyles.card, {
              [homepageCardStyles.last]: idx === nfts.length - 1,
            })}
          />
        ))}
        containerName="Artwork"
        logViewAbleCardsToGA={logViewAbleCardsToGA}
      />
    )
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(ExploreArtworks, {
    featuredNftsQuery: { concreteRequest: HomepageFeaturedNftsQueryType },
  }),
  { errorFallback: null, hideState: true }
);
