import { InputAdornment } from '@mui/material';

import {
  MPActionButton,
  MPButton,
  MPCheckbox,
  MPColorClass,
  MPDialog,
  MPFonts,
  MPStyledTextField,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import {
  AspectRatioEnum,
  EditionTypeEnum,
  MediaTypeEnum,
  StatusEnum,
} from 'types/__generated__/graphql';

import useArtworkFilters from './useArtworkFilters';

import * as styles from 'css/pages/explore/artwork/FilterDialog.module.css';

interface ArtworkFilterDialogProps {
  close: () => void;
  open: boolean;
}

const INTEGER_REGEX = /^[1-9][0-9]*|0$/;

function ArtworkFilterDialog({ open, close }: ArtworkFilterDialogProps) {
  const isMobile = useIsMobile();
  const {
    getStatus,
    getEditions,
    getMinWidthHeight,
    getPrice,
    getMedia,
    getDimensions,
    constrainMinPrice,
    constrainMaxPrice,
    update,
    clearAll,
    setStatus,
    setEditions,
    setPrice,
    setDimensions,
    setMedia,
    setMinWidthHeight,
  } = useArtworkFilters();

  const sectionHeaderClassName = joinClasses(
    MPFonts.textSmallMedium,
    MPColorClass.SolidNeutralGray4
  );

  return (
    <MPDialog
      title="Artwork Filters"
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          height: isMobile ? '100vh' : 'auto',
          width: isMobile ? '100%' : '500px',
        },
      }}
      onClose={close}
      actionButton={
        <>
          <MPButton variant="text" onClick={clearAll}>
            Clear All
          </MPButton>
          <MPActionButton
            onClick={() => {
              update();
              close();
            }}
          >
            Apply
          </MPActionButton>
        </>
      }
    >
      <div className={styles.content}>
        <div className={styles.block}>
          <p className={sectionHeaderClassName}>Status</p>
          <div className={styles.blockInputs}>
            <MPCheckbox
              isChecked={!!getStatus(StatusEnum.InAuction)}
              onChange={(event) =>
                setStatus(StatusEnum.InAuction, event?.target?.checked)
              }
              name="auctions"
              label="In Auction"
            />
            <MPCheckbox
              isChecked={!!getStatus(StatusEnum.HasOffers)}
              onChange={(event) =>
                setStatus(StatusEnum.HasOffers, event?.target?.checked)
              }
              name="has-offers"
              label="Has Offers"
            />
            <MPCheckbox
              isChecked={!!getStatus(StatusEnum.BuyNow)}
              onChange={(event) =>
                setStatus(StatusEnum.BuyNow, event?.target?.checked)
              }
              name="buy-now"
              label="Buy Now"
            />
          </div>
        </div>
        <div className={styles.block}>
          <p className={sectionHeaderClassName}>Editions</p>
          <div className={styles.blockInputs}>
            <MPCheckbox
              isChecked={!!getEditions(EditionTypeEnum.Single)}
              disabled={!!getEditions(EditionTypeEnum.Limited)}
              onChange={(event) =>
                setEditions(EditionTypeEnum.Single, event?.target?.checked)
              }
              name="single-edition"
              label="Uniques"
            />
            <MPCheckbox
              isChecked={!!getEditions(EditionTypeEnum.Limited)}
              disabled={!!getEditions(EditionTypeEnum.Single)}
              onChange={(event) =>
                setEditions(EditionTypeEnum.Limited, event?.target?.checked)
              }
              name="multiple-edition"
              label="Limited Editions"
            />
          </div>
        </div>
        <div className={styles.block}>
          <p className={sectionHeaderClassName}>Price Point</p>
          <div className={styles.blockTextInputs}>
            <MPStyledTextField
              placeholder="Min Price"
              value={getPrice('min') ?? ''}
              setValue={(val) => setPrice('min', val)}
              onBlur={constrainMinPrice}
              inputMode="numeric"
              startAdornment="$"
            />
            <MPStyledTextField
              placeholder="Max Price"
              value={getPrice('max') ?? ''}
              setValue={(val) => setPrice('max', val)}
              onBlur={constrainMaxPrice}
              inputMode="numeric"
              startAdornment="$"
            />
          </div>
        </div>
        <div className={styles.block}>
          <p className={sectionHeaderClassName}>Media Type</p>
          <div className={styles.blockInputs}>
            <MPCheckbox
              isChecked={!!getMedia(MediaTypeEnum.Still)}
              disabled={!!getMedia(MediaTypeEnum.Motion)}
              onChange={(event) =>
                setMedia(MediaTypeEnum.Still, event?.target?.checked)
              }
              name="still"
              label="Still"
            />
            <MPCheckbox
              isChecked={!!getMedia(MediaTypeEnum.Motion)}
              disabled={!!getMedia(MediaTypeEnum.Still)}
              onChange={(event) =>
                setMedia(MediaTypeEnum.Motion, event?.target?.checked)
              }
              name="motion"
              label="Motion"
            />
          </div>
        </div>
        <div className={styles.block}>
          <p className={sectionHeaderClassName}>Dimensions</p>
          <div className={styles.blockInputs}>
            <MPCheckbox
              isChecked={!!getDimensions(AspectRatioEnum.Landscape)}
              disabled={
                !!getDimensions(AspectRatioEnum.Portrait) &&
                !!getDimensions(AspectRatioEnum.Square)
              }
              onChange={(event) =>
                setDimensions(AspectRatioEnum.Landscape, event?.target?.checked)
              }
              name="landscape"
              label="Landscape"
            />
            <MPCheckbox
              isChecked={!!getDimensions(AspectRatioEnum.Portrait)}
              disabled={
                !!getDimensions(AspectRatioEnum.Landscape) &&
                !!getDimensions(AspectRatioEnum.Square)
              }
              onChange={(event) =>
                setDimensions(AspectRatioEnum.Portrait, event?.target?.checked)
              }
              name="portrait"
              label="Portrait"
            />
            <MPCheckbox
              isChecked={!!getDimensions(AspectRatioEnum.Square)}
              disabled={
                !!getDimensions(AspectRatioEnum.Landscape) &&
                !!getDimensions(AspectRatioEnum.Portrait)
              }
              onChange={(event) =>
                setDimensions(AspectRatioEnum.Square, event?.target?.checked)
              }
              name="square"
              label="Square"
            />
          </div>
        </div>
        <div className={joinClasses(styles.block, styles.missingLabel)}>
          <div className={styles.blockTextInputs}>
            <MPStyledTextField
              placeholder="Min Width"
              value={getMinWidthHeight('width') ?? ''}
              setValue={(val) => {
                if (INTEGER_REGEX.test(val as string) || val === '')
                  setMinWidthHeight('width', val);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <span className={MPColorClass.SolidNeutralGray4}>px</span>
                </InputAdornment>
              }
            />
            <MPStyledTextField
              placeholder="Min Height"
              value={getMinWidthHeight('height') ?? ''}
              setValue={(val) => {
                if (INTEGER_REGEX.test(val as string) || val === '')
                  setMinWidthHeight('height', val);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <span className={MPColorClass.SolidNeutralGray4}>px</span>
                </InputAdornment>
              }
            />
          </div>
        </div>
      </div>
    </MPDialog>
  );
}

export default ArtworkFilterDialog;
