.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flexInlineRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-self: unset;
}

.spaceBetween {
  justify-content: space-between;
}

.centered {
  justify-content: center;
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

.centerAlignItems {
  align-items: center;
}

.noWrap {
  flex-flow: nowrap;
}

.defaultLink {
  color: inherit;
  text-decoration: none;
  transition: var(--transition-colors-smoothly);
}

.overflowHidden {
  overflow: hidden;
}

.overflowAuto {
  overflow: auto;
}

.noScrollbar {
  scrollbar-width: none; /* Firefox */
}

.noScrollbar::-webkit-scrollbar {
  display: none;
}

.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.noWrapWhitespace {
  white-space: nowrap;
}

.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.lineClamp {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.widthMaxContent {
  width: max-content;
}
