import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery,
} from 'react-relay';

import {
  MPActionButton,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTsExplorePaginatedFragmentNode, {
  NFTsExplorePaginatedFragment$key,
} from 'graphql/__generated__/NFTsExplorePaginatedFragment.graphql';
import { NFTsProductCardFragment$data } from 'graphql/__generated__/NFTsProductCardFragment.graphql';
import PaginatedExploreNFTsQueryNode, {
  PaginatedExploreNFTsQuery,
} from 'graphql/__generated__/PaginatedExploreNFTsQuery.graphql';
import {
  AspectRatioEnum,
  EditionTypeEnum,
  MediaTypeEnum,
  StatusEnum,
} from 'types/__generated__/graphql';

import ArtworkCard from 'components/cards/ArtworkCard';
import { DefaultLoader } from 'components/DefaultSuspense';
import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import GTM from 'GTM';
import useInfiniteQueryScroll from 'hooks/useInfiniteQueryScroll';
import useLoadQuery from 'hooks/useLoadQuery';
import useOnBodyResize from 'hooks/useOnBodyResize';
import useArtworkFilterState, {
  IsArtworkFilterApplied,
} from 'pages/explore/artwork/UseArtworkFilterState';
import { Genres } from 'pages/explore/genre';

import * as exploreStyles from 'css/pages/explore/explore.module.css';

export const EVENT_EXPLORE_GRID_READY = 'EXPLORE_GRID_READY';

export interface NoResultsProps {
  areFiltersApplied: boolean;
  resetFilters: () => void;
}

interface ExploreGridInnerProps {
  queryRef: PreloadedQuery<PaginatedExploreNFTsQuery>;
  first?: number;
  noResults?: (props: NoResultsProps) => JSX.Element;
  title?: string;
}

function NoResults({ areFiltersApplied, resetFilters }: NoResultsProps) {
  return areFiltersApplied ? (
    <div className="centerText">
      <h2 className={MPFonts.headline4}>
        There are no artworks with the filters you&#39;ve selected.
      </h2>
      <p>Clear all filters to search all Artworks</p>
      <MPActionButton onClick={resetFilters}>Clear All Filters</MPActionButton>
    </div>
  ) : (
    <div className="centerText">
      <h2 className={MPFonts.headline4}>
        There are no artworks here at this time. Please check back later!
      </h2>
    </div>
  );
}

function ExploreGridInner({
  queryRef,
  title,
  noResults,
  first = 10,
}: ExploreGridInnerProps) {
  const visibilityRef = useRef(null);
  const scrollRef = useRef(null);

  const result = useInfiniteQueryScroll({
    getConnectionField: (data) => data.artwork.results,
    onUpdate: (data) =>
      GTM.ecommerce.trackViewItemList(data.slice(0 - first), 'None'),
    pageSize: first,
    paginatedQueryResults: usePaginationFragment<
      PaginatedExploreNFTsQuery,
      NFTsExplorePaginatedFragment$key
    >(
      NFTsExplorePaginatedFragmentNode,
      usePreloadedQuery<PaginatedExploreNFTsQuery>(
        PaginatedExploreNFTsQueryNode,
        queryRef
      )
    ),
    ref: visibilityRef,
    scrollRef,
  });
  const nfts = result.data as NFTsProductCardFragment$data[];

  const domSelf = useRef(null);

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (isInitialized || result.loading) return;
    setIsInitialized(true);
    domSelf?.current.dispatchEvent(
      new Event(EVENT_EXPLORE_GRID_READY, { bubbles: true })
    );
  }, [isInitialized, result.loading]);

  const filters = useArtworkFilterState();

  const setStatus = filters.status[1];
  const setEditions = filters.editions[1];
  const setMedia = filters.media[1];
  const setPrice = filters.price[1];
  const setDimensions = filters.dimensions[1];
  const setMinWidthHeight = filters.minWidthHeight[1];
  const setTraits = filters.traits[1];

  const resetFilters = useCallback(() => {
    setStatus({});
    setEditions({});
    setMedia({});
    setPrice({});
    setDimensions({});
    setMinWidthHeight({});
    setTraits({});
  }, [
    setStatus,
    setEditions,
    setMedia,
    setPrice,
    setDimensions,
    setMinWidthHeight,
    setTraits,
  ]);

  const NoResultsComp = noResults ?? NoResults;

  return (
    <div ref={domSelf}>
      {!result.loading && !nfts.length && (
        <NoResultsComp
          areFiltersApplied={IsArtworkFilterApplied(filters)}
          resetFilters={resetFilters}
        />
      )}
      <div className={exploreStyles.grid} ref={scrollRef}>
        {!!title && (
          <p
            className={joinClasses(
              MPFonts.headline4,
              'flexVCenter',
              'gridSpanRow',
              'reset'
            )}
          >
            <span>{title}</span>
          </p>
        )}
        {nfts.map((node) => (
          <ArtworkCard nft={node} key={node.id} />
        ))}
        <div
          ref={visibilityRef}
          className={joinClasses(exploreStyles.shiftUpOneRow, 'gridSpanRow')}
        >
          &nbsp;
        </div>
      </div>
      {!!result.loading && <DefaultLoader />}
    </div>
  );
}

function useQueryParamMemo(record) {
  const values = Object.entries(record ?? {})
    .filter((entry) => !!entry[1])
    .map((entry) => entry[0]);

  // Make it simplier to support arrays as param type
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const params = useMemo(() => values, [values.join('-')]);
  return values.length ? params : undefined;
}

interface ExploreGridProps {
  artist?: string;
  noResults?: ExploreGridInnerProps['noResults'];
  slug?: string;
  title?: string;
}
export const EXPLORE_GRID_PARAMS = {
  gap: 32,
  rectHeight: 508,
  rectWidth: 320,
};

const GRID_PADDING = 32;

export const useExploreQueryParams = (
  artist: string,
  slug: string
): PaginatedExploreNFTsQuery['variables'] => {
  const isMobile = useIsMobile();

  // Rather than listen on changes to each individual element, just listen to the body along with everyone else as a quick approximation
  const { width: resizeBodyWidth } = useOnBodyResize();
  const bodyWidth = resizeBodyWidth ?? document.body.offsetWidth;

  const cardsPerRow = Math.floor(
    (bodyWidth + EXPLORE_GRID_PARAMS.gap - 2 * GRID_PADDING) /
      (EXPLORE_GRID_PARAMS.rectWidth + EXPLORE_GRID_PARAMS.gap)
  );

  const first = isMobile ? 10 : bodyWidth ? 3 * cardsPerRow : 20;

  const filterParams = useArtworkFilterState();
  const status = useQueryParamMemo(
    filterParams.status[0] ?? {}
  ) as StatusEnum[];
  const editions = useQueryParamMemo(
    filterParams.editions[0] ?? {}
  ) as EditionTypeEnum[];

  const { max: maxPrice, min: minPrice } = filterParams.price[0];
  const price = useMemo(
    () => ({
      max: maxPrice ? parseFloat(maxPrice) : undefined,
      min: minPrice ? parseFloat(minPrice) : undefined,
    }),
    [maxPrice, minPrice]
  );

  const { width, height } = filterParams.minWidthHeight[0];
  const size = useMemo(
    () => ({
      minHeight: height ? parseFloat(height) : undefined,
      minWidth: width ? parseFloat(width) : undefined,
    }),
    [width, height]
  );

  const _genre = filterParams.genre[0]();
  const genres = useMemo(
    () => (_genre === Genres.All ? undefined : [_genre]),
    [_genre]
  );

  const aspectRatios = useQueryParamMemo(
    filterParams.dimensions[0] ?? {}
  ) as AspectRatioEnum[];

  const mediaTypes = useQueryParamMemo(
    filterParams.media[0] ?? {}
  ) as MediaTypeEnum[];

  const _traits = useMemo(
    () => filterParams.traits[0] ?? {},
    [filterParams.traits]
  );
  const traits = useMemo(
    () =>
      Object.keys(_traits).map((key) => ({
        name: _traits[key],
        value: key,
      })),
    [_traits]
  );

  return {
    artists: artist ? [artist] : undefined,
    aspectRatios,
    editions,
    first,
    genres,
    mediaTypes,
    price,
    query: filterParams.query[0],
    size,
    slug,
    sortOrder: filterParams.sortOrder[0],
    sortType: filterParams.sortType[0],
    status,
    traits,
  };
};

export default function ExploreGrid({
  artist,
  slug,
  title,
  noResults,
}: ExploreGridProps) {
  const queryParams = useExploreQueryParams(artist, slug);

  const [queryRef] = useLoadQuery<PaginatedExploreNFTsQuery>(
    PaginatedExploreNFTsQueryNode,
    queryParams,
    'store-and-network'
  );
  return (
    <DefaultErrorBoundary>
      {!!queryRef && (
        <ExploreGridInner
          queryRef={queryRef}
          title={title}
          noResults={noResults}
          first={queryParams.first}
        />
      )}
    </DefaultErrorBoundary>
  );
}
