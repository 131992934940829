import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import ROUTES from 'constants/Routes';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { HomepageExhibitionType } from 'types/graphql/Drop';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';

import ExhibitionFadingEffect from './ExhibitionFadingEffect';
import ExhibitionThumbnail from './ExhibitionThumbnail';

import * as baseStyles from 'css/components/cards/BaseCard.module.css';
import * as styles from 'css/components/cards/ExhibitionCard.module.css';

interface ExhibitionCardProps {
  exhibition: HomepageExhibitionType;
  className?: string;
}

function ExhibitionCard({ exhibition, className }: ExhibitionCardProps) {
  const {
    slug,
    dropTitle,
    presenterText,
    creators,
    sortedNftMetadatas: { edges: sortedNftMetadatas },
    heroBackgroundColor: { r: red, g: green, b: blue },
  } = exhibition;

  const track = useHomepageGTM();
  const [isLong, setIsLong] = useState<boolean>(false);
  const [thumbnailsContainer, setThumbnailsContainerRef] =
    useRefState<HTMLDivElement>(null);
  const [lastThumbnail, setLastThumbnailRef] =
    useRefState<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!thumbnailsContainer || !lastThumbnail) return undefined;

    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsLong(!entry.isIntersecting);
      },
      {
        root: thumbnailsContainer,
        rootMargin: '0px',
        threshold: 0,
      }
    );
    if (lastThumbnail) {
      observerRef.current.observe(lastThumbnail);
      return () => {
        observerRef.current.unobserve(lastThumbnail);
      };
    }
    return undefined;
  }, [lastThumbnail, thumbnailsContainer]);

  const handleClick = useCallback(
    () =>
      track.clickCard(
        CardType.ExhibitionCard,
        exhibition.pk,
        exhibition.dropTitle
      ),
    [track, exhibition.pk, exhibition.dropTitle]
  );

  return sortedNftMetadatas.length ? (
    <Link
      reloadDocument
      className={joinClasses(
        baseStyles.standardCard,
        styles.exhibitionCard,
        className
      )}
      to={ROUTES.EXHIBITION(slug)}
      style={
        {
          '--exhibitionCard-colorFrom': `rgba(${red}, ${green}, ${blue}, 0.1)`,
          '--exhibitionCard-colorTo': `rgba(${red}, ${green}, ${blue}, 1)`,
          '--exhibitionCard-mediaUrl': `url(${sortedNftMetadatas[0].node.standardImage})`,
        } as any
      }
      onClick={handleClick}
    >
      <div className={styles.exhibitionCardOverlay}>
        <div className={styles.exhibitionCardOverlayContent}>
          <div
            className={joinClasses(
              MPColorClass.CommonWhite,
              CSSGlobal.Flex.Col,
              CSSGap[4]
            )}
          >
            <ExhibitionFadingEffect>
              {creators.map((creator) => (
                <div
                  key={creator.id}
                  className={joinClasses(
                    MPFonts.textLargeMedium,
                    CSSGlobal.Ellipsis,
                    styles.exhibitionCardOverlayText
                  )}
                >
                  {creator.fullName}
                </div>
              ))}
            </ExhibitionFadingEffect>
            <div
              className={joinClasses(
                MPFonts.textNormalMedium,
                styles.exhibitionCardOverlayText
              )}
            >
              {dropTitle}
            </div>
            {!!presenterText && (
              <div
                className={joinClasses(
                  MPFonts.textSmallMedium,
                  MPColorClass.CommonWhite,
                  styles.exhibitionCardOverlayPresenter,
                  styles.exhibitionCardOverlayText
                )}
              >
                Presented by {presenterText.replace(/( )?present(s|ed)?$/i, '')}
              </div>
            )}
          </div>

          {!isMobile && (
            <div
              ref={setThumbnailsContainerRef}
              className={joinClasses(styles.exhibitionCardOverlayThumbnails, {
                [styles.long]: isLong,
              })}
            >
              <div
                className={joinClasses(
                  CSSGlobal.Flex.Row,
                  CSSGap[8],
                  CSSGlobal.Flex.NoWrap
                )}
              >
                {sortedNftMetadatas.map(({ node: nftMetadata }) => (
                  <ExhibitionThumbnail
                    key={nftMetadata.id}
                    className={styles.exhibitionCardOverlayThumbnail}
                    nftMetadata={nftMetadata}
                    cloudinaryParams={{
                      crop: 'limit',
                      height: 120,
                      quality: 'auto:good',
                    }}
                  />
                ))}

                <div ref={setLastThumbnailRef}>&nbsp;</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  ) : null;
}

export default ExhibitionCard;
