import { useMemo } from 'react';

import { useTrackingContext } from 'components/trackingContext';
import { createGATrackingQueryString } from 'GTM';

import { tagPush } from './base';

export enum CardType {
  ArtworkCard = 'click_artwork_card',
  EditorialCard = 'click_editorial_card',
  ExhibitionCard = 'click_exhibition_card',
  HeroArtistCard = 'click_hero_artist_card',
  HeroArtworkCard = 'click_hero_artwork_card',
  HeroCollectionCard = 'click_hero_collection_card',
  HeroEditorialCard = 'click_hero_editorial_card',
  HeroExhibitionCard = 'click_hero_exhibition_card',
  UpcomingExhibitionCard = 'click_upcoming_exhibition_card',
}

export interface HomepageViewItem {
  currency: string;
  item_brand: string;
  item_category: 'drop' | 'pdp' | 'exhibition';
  item_category4: string;
  item_id: string | number;
  item_name: string;
  price: number;
  price_in_eth: number;
  quantity: number;
}

export default function useHomepageGTM() {
  const { source } = useTrackingContext();

  return useMemo(
    () => ({
      async clickArtwork(artist: string, artwork: string, type: string) {
        return tagPush('View Artwork', {
          detail: createGATrackingQueryString({
            artist,
            artwork,
          }),
          type,
        });
      },
      async clickCard(
        cardType: CardType,
        objectId: string | number,
        objectTitle: string,
        objectParams: Record<string, string> = {}
      ) {
        return tagPush(cardType, {
          detail: createGATrackingQueryString({
            object_id: objectId.toString(),
            object_title: objectTitle,
            source,
            ...objectParams,
          }),
        });
      },
      async clickCarouselPaddle(name: string, direction: string) {
        return tagPush(`${name} Paddle Click`, {
          detail: createGATrackingQueryString({
            direction,
          }),
        });
      },
      async clickCarouselViewAll(name: string) {
        return tagPush(`View All ${name}`, {});
      },
      async clickGenre(genre: string) {
        return tagPush('Genre Bucket Click', {
          detail: createGATrackingQueryString({
            genre,
          }),
        });
      },
      async clickLearnMoreOnInitialVisit() {
        return tagPush(`Learn More Click`, {});
      },
      async viewItem(data: HomepageViewItem[], id: string) {
        return tagPush('view_item_list', {
          ecommerce: { items: data },
          item_list_id: id,
          item_list_name: id,
        });
      },
    }),
    [source]
  );
}
