import { useCallback, useState } from 'react';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { NotificationsIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import { DropPhaseEnum } from 'types/__generated__/graphql';

import LoginRequiredButton from 'components/LoginRequiredButton';
import useSession from 'hooks/useSession';
import SubscriptionDialog from 'pages/drops/components/SubscriptionDialog';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { NFTDropMetadataType, NFTDropType } from 'types/graphql/NFT';
import hasDatePassed from 'utils/hasDatePassed';
import { convertToDate } from 'utils/nftUtils';

interface ProductNotLiveActionsProps {
  dropMetadata: {
    drop: Pick<
      NFTDropType,
      'dropTitle' | 'dropPhase' | 'pk' | 'presale' | 'subscribePhoneNumber'
    >;
  };
  dropsAt: NFTDropMetadataType['dropsAt'];
  invalidate: () => void;
  isAuctionFinalizing: boolean;
  isPresaleActive: boolean;
  isPresaleEligible: boolean;
  onPresalePurchaseClick: () => void;
  showPresale: boolean;
}

export default function ProductNotLiveActions({
  dropMetadata,
  dropsAt,
  isAuctionFinalizing,
  isPresaleActive,
  isPresaleEligible,
  showPresale,
  invalidate,
  onPresalePurchaseClick,
}: ProductNotLiveActionsProps) {
  const session = useSession();
  const [showSubscriptionDialog, setShowSubscriptionDialog] =
    useState<boolean>(false);

  const handleOpenSubscriptionDialog = useCallback(
    () => setShowSubscriptionDialog(true),
    []
  );
  const handleCloseSubscriptionDialog = useCallback(
    (subscribed = false) => {
      if (subscribed) invalidate();

      setShowSubscriptionDialog(false);
    },
    [invalidate]
  );

  return (
    <>
      {!!showSubscriptionDialog && (
        <SubscriptionDialog
          dropId={dropMetadata.drop.pk}
          dropTitle={dropMetadata.drop.dropTitle}
          dropsAt={dropsAt}
          onClose={handleCloseSubscriptionDialog}
        />
      )}

      {showPresale && !session.isLoggedIn() ? (
        <LoginRequiredButton fullWidth size="large" onClick={invalidate}>
          Check Eligibility for Pre-Sale
        </LoginRequiredButton>
      ) : isPresaleActive && isPresaleEligible ? (
        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[8])}>
          <MPActionButton
            variant="primary-premium"
            fullWidth
            size="large"
            onClick={onPresalePurchaseClick}
          >
            Purchase
          </MPActionButton>

          <span className={MPFonts.paragraphSmall}>
            Pre-Sale price is available until{' '}
            {convertToDate(dropMetadata.drop.presale.endsAt, 'MMMM D, hh:mmA')}
          </span>
        </div>
      ) : isAuctionFinalizing ? (
        <MPActionButton fullWidth size="large" disabled>
          Auction Ended
        </MPActionButton>
      ) : (
        <MPActionButton fullWidth size="large" disabled>
          Coming Soon
        </MPActionButton>
      )}

      {dropMetadata?.drop?.dropPhase === DropPhaseEnum.Upcoming &&
        !dropMetadata.drop.subscribePhoneNumber &&
        !hasDatePassed(dropsAt) && (
          <LoginRequiredButton
            variant="secondary"
            fullWidth
            size="large"
            onClick={handleOpenSubscriptionDialog}
          >
            <span
              className={joinClasses(
                CSSGlobal.Flex.InlineRowCenterAlign,
                CSSGap[4]
              )}
            >
              Get Exhibition Reminder
              <NotificationsIcon fontSize="20" />
            </span>
          </LoginRequiredButton>
        )}
    </>
  );
}
