/**
 * @generated SignedSource<<48ad744f956519b5a9d8a1e3a8b6640c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserAccountType = "CREATOR" | "CONSUMER" | "ANON" | "DOWNGRADED_CREATOR";
export type SessionUserInformationMutation$variables = {};
export type SessionUserInformationMutation$data = {
  readonly session: {
    readonly account: {
      readonly id: string;
      readonly pk: string;
      readonly username: string;
      readonly email: string;
      readonly fullName: string | null;
      readonly accountType: UserAccountType;
      readonly profileImageUrl: string;
      readonly landingUrl: string | null;
      readonly phoneNumber: string | null;
      readonly twoFactorEmailEnabled: boolean;
      readonly twoFactorSmsEnabled: boolean;
      readonly numSessions: number | null;
      readonly isAnonAccount: boolean;
      readonly didCompleteOnboard: boolean | null;
      readonly wallets: ReadonlyArray<{
        readonly address: string;
        readonly isCreatorWallet: boolean;
        readonly isCustodialCreatorWallet: boolean;
        readonly isSelectedMintingWallet: boolean;
        readonly canDisconnect: boolean;
      }>;
      readonly contractApproval: ReadonlyArray<{
        readonly id: string;
        readonly pk: string;
        readonly address: string;
        readonly approved: boolean;
        readonly deleted: boolean;
        readonly operatorContractAddress: string | null;
        readonly approvalContractAddress: string | null;
        readonly canRevoke: boolean | null;
      } | null> | null;
      readonly store: {
        readonly storeName: string;
        readonly aboutBanner: string | null;
      } | null;
    } | null;
    readonly lastLogin: string | null;
    readonly environment: string | null;
    readonly statsigClientKey: string | null;
    readonly contractNetwork: number | null;
    readonly contractNetworkUrl: string | null;
    readonly sentrySdkDsn: string | null;
  } | null;
};
export type SessionUserInformationMutation = {
  variables: SessionUserInformationMutation$variables;
  response: SessionUserInformationMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "landingUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "twoFactorEmailEnabled",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "twoFactorSmsEnabled",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numSessions",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAnonAccount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "didCompleteOnboard",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "WalletAbstractType",
  "kind": "LinkedField",
  "name": "wallets",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCreatorWallet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCustodialCreatorWallet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSelectedMintingWallet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canDisconnect",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ApprovalType",
  "kind": "LinkedField",
  "name": "contractApproval",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approved",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorContractAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalContractAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canRevoke",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aboutBanner",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastLogin",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "environment",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statsigClientKey",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNetwork",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNetworkUrl",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sentrySdkDsn",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionUserInformationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionMutation",
        "kind": "LinkedField",
        "name": "session",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountSessionType",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreTypeV3",
                "kind": "LinkedField",
                "name": "store",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionUserInformationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionMutation",
        "kind": "LinkedField",
        "name": "session",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountSessionType",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreTypeV3",
                "kind": "LinkedField",
                "name": "store",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "497f83f71c500e84186889c40a7f6d09",
    "id": null,
    "metadata": {},
    "name": "SessionUserInformationMutation",
    "operationKind": "mutation",
    "text": "mutation SessionUserInformationMutation {\n  session {\n    account {\n      id\n      pk\n      username\n      email\n      fullName\n      accountType\n      profileImageUrl\n      landingUrl\n      phoneNumber\n      twoFactorEmailEnabled\n      twoFactorSmsEnabled\n      numSessions\n      isAnonAccount\n      didCompleteOnboard\n      wallets {\n        address\n        isCreatorWallet\n        isCustodialCreatorWallet\n        isSelectedMintingWallet\n        canDisconnect\n      }\n      contractApproval {\n        id\n        pk\n        address\n        approved\n        deleted\n        operatorContractAddress\n        approvalContractAddress\n        canRevoke\n      }\n      store {\n        storeName\n        aboutBanner\n        id\n      }\n    }\n    lastLogin\n    environment\n    statsigClientKey\n    contractNetwork\n    contractNetworkUrl\n    sentrySdkDsn\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf4e53e9f8ef1bcf1ca44ec793262aa1";

export default node;
