.heroCard {
  --heroCard-padding: 32px;

  display: flex;
  flex-direction: row;
  height: 760px;

  @media (--mq-is-desktop) {
    height: 700px;
  }

  @media (--mq-is-mobile) {
    flex-direction: column;
    height: initial;
  }
}

.standardCard {
  box-sizing: border-box;
  display: flex;
  height: var(--standardCard-desktopHeight, 600px);
  overflow: hidden;
  position: relative;
  width: inherit;

  @media (--mq-is-mobile) {
    height: var(--standardCard-mobileHeight, 480px);
  }

  @media screen and (min-width: 1920px) {
    height: var(--standardCard-monitorHeight, 700px);
  }
}
