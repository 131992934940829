import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/global/Global.module.css';

const CSSGlobal = {
  Cursor: {
    Default: styles.cursorDefault,
    Pointer: styles.cursorPointer,
  },
  DefaultLink: styles.defaultLink,
  Ellipsis: styles.ellipsis,
  Flex: {
    Centered: styles.centered,
    CenteredCol: joinClasses(styles.centered, styles.flexCol),
    CenteredRow: joinClasses(styles.centered, styles.flexRow),
    Col: styles.flexCol,
    ColCenterAlign: joinClasses(styles.flexCol, styles.justifyCenter),
    InlineRow: styles.flexInlineRow,
    InlineRowCenterAlign: joinClasses(
      styles.flexInlineRow,
      styles.centerAlignItems
    ),
    JustifyCenter: styles.justifyCenter,
    NoWrap: styles.noWrap,
    Row: styles.flexRow,
    RowCenterAlign: joinClasses(styles.flexRow, styles.centerAlignItems),
    RowSpaceBetween: joinClasses(styles.flexRow, styles.spaceBetween),
  },
  Hidden: styles.hidden,
  LineClamp: styles.lineClamp,
  NoScrollbar: styles.noScrollbar,
  NoWrapWhitespace: styles.noWrapWhitespace,
  Overflow: {
    Auto: styles.overflowAuto,
    Hidden: styles.overflowHidden,
  },
  TextAlign: {
    Centered: styles.textAlignCenter,
    Left: styles.textAlignLeft,
  },
  Visible: styles.visible,
  Width: {
    maxContent: styles.widthMaxContent,
  },
} as const;

export default CSSGlobal;
