import { some } from 'lodash';
import moment from 'moment-timezone';

import {
  AuctionTypeEnum,
  MpErrors,
  NftMetadataAbstractType,
  NftSaleStatusEnum,
  StoreTypeV3,
} from '../types/__generated__/graphql';
import { NFTsRelatedCarouselQuery } from 'graphql/__generated__/NFTsRelatedCarouselQuery.graphql';

import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { ListingType } from 'types/graphql/Listing';
import { NFTDropType, NFTRankedAuctionType, NFTType } from 'types/graphql/NFT';

import { NFTProvenanceType } from '../types/graphql/NFT';
import generateEthAndUsdPricing from './currency/generateEthAndUsdPricing';
import roundUpToNearestCent from './currency/roundUpToNearestCent';
import generateFormattedUserFullName from './generateFormattedUserFullName';
import hasDatePassed, { hasDateBetween } from './hasDatePassed';

export interface NFTCardProps {
  bidInEth: number | null;
  bidInUsd: number | null;
  creatorName: string;
  creatorSlug: string;
  imageSrc: string;
  itemId: string;
  key: string;
  lowestAskInEth: number | null;
  lowestAskInUsd: number | null;
  nftEdition: number;
  nftSlug: string;
  title: string;
  totalSupply: number;
  carouselName?: string;
}

export enum NFTListingType {
  BUY_NOW = 'BUY_NOW',
  RESERVE = 'RESERVE',
}

export enum NFTActionType {
  BUY = 'buy',
  DEFAULT = '',
  OFFER = 'offer',
}

export enum NFTCardSelectionType {
  New = 'new',
  Saved = 'saved',
}

export const MIN_CHARGE_AMOUNT_ETH = 0.0001;
export const MIN_CHARGE_AMOUNT_USD = 1.0;
export const MIN_RANKED_CHARGE_AMOUNT_USD = 20.0;
export const MINIMUM_BID = 1.1;
export const USD_MINIMUM_ACCEPTABLE_BID = 1.0;
export const ETH_DISPLAY_SIGNIFICANT_DIGITS = 4;

export function getNFTCardData(
  nft: NFTsRelatedCarouselQuery['response']['nfts']['edges'][0]['node'],
  carouselName = ''
): NFTCardProps {
  return {
    bidInEth: nft.listing.liveBid ? nft.listing.liveBid.bidInEther : null,
    bidInUsd: nft.listing.liveBid ? nft.listing.liveBid.bidInUsd : null,
    carouselName,
    creatorName: generateFormattedUserFullName(nft.metadata.author.fullName),
    creatorSlug: nft.metadata.author.store.storeSlug,
    imageSrc: nft.metadata.thumbnailImage,
    itemId: nft.pk,
    key: nft.id,
    lowestAskInEth: nft.listing.lowestAskInEth,
    lowestAskInUsd: nft.listing.lowestAskInUsd,
    nftEdition: nft.printEdition,
    nftSlug: nft.listing.productSlug,
    title: nft.metadata.title,
    totalSupply: nft.metadata.totalSupply,
  };
}

export function nftHasExistingBuyNowPrice(nft: NFTType): boolean {
  return !!nft.listing?.liveSale?.fixedpricesale;
}

export function nftHasExistingListing(nft: NFTType): boolean {
  return (
    !!nft.listing?.liveSale?.fixedpricesale || nft.listing?.hasReservePrice
  );
}

export function nftHasCustodialSale(nft: NFTType): boolean {
  return nft.isCustodialOwner && nft.listing.liveSale !== undefined;
}

export function getNftReservePrice(nft: NFTType): number {
  if (nft.listing.reserveCurrency === CurrencyDisplayMode.USD) {
    return nft.listing.reservePriceInUsd;
  }

  return nft.listing.reservePriceInEth;
}

function zeroToBlank(n: number): string {
  return n ? n.toString() : '';
}

export function getNftBuyNowDisplayPrice(
  nft: NFTType,
  displayMode: CurrencyDisplayMode
): string {
  // TODO: fixes sale price should be populated here
  if (!nftHasExistingListing(nft)) {
    return '';
  }

  if (nft.isCustodialOwner) {
    if (displayMode === CurrencyDisplayMode.USD) {
      return zeroToBlank(nft.listing.lowestAskInUsd);
    }
    return zeroToBlank(nft.listing.lowestAskInEth);
  }
  if (displayMode === CurrencyDisplayMode.USD) {
    return zeroToBlank(nft.listing.liveSale?.fixedpricesale.priceUsd);
  }
  return zeroToBlank(nft.listing.liveSale?.fixedpricesale.priceEth);
}

/**
 *
 * @param nft
 * @returns
 * @deprecated
 */
export function getExistingListingAmount(nft: NFTType) {
  if (!nftHasExistingListing(nft)) {
    return 0;
  }

  if (nft.listing.hasReservePrice) {
    return getNftReservePrice(nft);
  }
  return nft.listing.liveSale?.fixedpricesale.priceEth;
}

export function nftHasReservePrice(nft: NFTType): boolean {
  return nft.listing?.hasReservePrice;
}

export function getNFTReservePriceDisplayReserveCurrency(nft: NFTType) {
  if (!nftHasReservePrice(nft)) {
    return CurrencyDisplayMode.USD;
  }

  return nft.listing.reserveCurrency as CurrencyDisplayMode;
}

export function getReservePriceListingAmount(nft: NFTType): string {
  if (!nftHasReservePrice(nft)) {
    return '';
  }

  if (nft.listing.reserveCurrency === CurrencyDisplayMode.USD) {
    return nft.listing.reservePriceInUsd.toString();
  }

  return nft.listing.reservePriceInEth.toString();
}

export function getListingMakeOfferDisplayPrice(
  nft: NFTType,
  currency: CurrencyDisplayMode
): number {
  if (nft.listing?.liveBid) {
    if (currency === CurrencyDisplayMode.ETH) {
      return nft.listing?.liveBid.bidInEther;
    }
    return nft.listing?.liveBid.bidInUsd;
  }

  if (currency === CurrencyDisplayMode.ETH) {
    return nft.listing.lowestAskInEth;
  }
  return nft.listing.lowestAskInUsd;
}

export function nftHasRankedAuction(nft: {
  listing?: { rankedAuction: NFTRankedAuctionType };
}): boolean {
  return nft.listing?.rankedAuction?.auctionType === AuctionTypeEnum.Ranked;
}

export function nftHasUserPlacedExistingRankedOffer(nft: {
  listing?: { rankedAuction: NFTRankedAuctionType };
}): boolean {
  return (
    nftHasRankedAuction(nft) && !!nft.listing.rankedAuction.lastBid?.bidType
  );
}

export enum AuctionState {
  Finalizing = 'finalizing',
  Live = 'live',
  None = 'none',
  Upcoming = 'upcoming',
}

export function getUserExistingRankedOffer(
  nft: {
    currentEthRate: NFTType['currentEthRate'];
    listing?: { rankedAuction: NFTRankedAuctionType };
  },
  currency: CurrencyDisplayMode
): number {
  if (!nftHasUserPlacedExistingRankedOffer(nft)) {
    return 0;
  }

  if (currency === CurrencyDisplayMode.USD) {
    return nft.listing.rankedAuction.lastBid.isCcBid
      ? nft.listing.rankedAuction.lastBid.bidInUsd
      : nft.listing.rankedAuction.lastBid.bidInEther * nft.currentEthRate;
  }

  return nft.listing.rankedAuction.lastBid.isCcBid
    ? nft.listing.rankedAuction.lastBid.bidInUsd / nft.currentEthRate
    : nft.listing.rankedAuction.lastBid.bidInEther;
}

export function getExistingBid(
  nft: {
    listing?: Pick<ListingType, 'rankedAuction'> & {
      liveBid: Pick<ListingType['liveBid'], 'bidInEther' | 'bidInUsd'>;
    };
  },
  currency: CurrencyDisplayMode
): number {
  if (
    nftHasRankedAuction(nft) ||
    !(nft.listing?.liveBid?.bidInEther || nft.listing?.liveBid?.bidInUsd)
  ) {
    return 0;
  }

  return currency === CurrencyDisplayMode.USD
    ? nft.listing.liveBid.bidInUsd
    : nft.listing.liveBid.bidInEther;
}

export function getDefaultMinimumAcceptableBid(
  nft: {
    currentEthRate: NFTType['currentEthRate'];
    listing?: Pick<ListingType, 'rankedAuction'> & {
      liveBid: Pick<ListingType['liveBid'], 'bidInEther' | 'bidInUsd'>;
    };
  },
  currency: CurrencyDisplayMode
): number {
  const rankedOfferAmount = getUserExistingRankedOffer(nft, currency);
  if (rankedOfferAmount) {
    return currency === CurrencyDisplayMode.USD
      ? roundUpToNearestCent(rankedOfferAmount + MIN_RANKED_CHARGE_AMOUNT_USD)
      : parseFloat(
          (
            rankedOfferAmount +
            MIN_RANKED_CHARGE_AMOUNT_USD / nft.currentEthRate
          ).toFixed(ETH_DISPLAY_SIGNIFICANT_DIGITS)
        );
  }

  const existingBidAmount = getExistingBid(nft, currency);
  return existingBidAmount
    ? currency === CurrencyDisplayMode.USD
      ? roundUpToNearestCent(existingBidAmount * MINIMUM_BID)
      : parseFloat(
          (existingBidAmount * MINIMUM_BID).toFixed(
            ETH_DISPLAY_SIGNIFICANT_DIGITS
          )
        )
    : currency === CurrencyDisplayMode.USD
    ? USD_MINIMUM_ACCEPTABLE_BID
    : USD_MINIMUM_ACCEPTABLE_BID / nft.currentEthRate;
}

export function getMinimumAcceptableBid(
  nft: {
    currentEthRate: NFTType['currentEthRate'];
    currentOwner: {
      store: Pick<
        StoreTypeV3,
        'minimumBidAmountInEther' | 'minimumBidAmountInUsd'
      >;
    };
  },
  currency: CurrencyDisplayMode
): number {
  const defaultMinimumBid = getDefaultMinimumAcceptableBid(nft, currency);
  const ownerMinimumBid =
    currency === CurrencyDisplayMode.USD
      ? nft.currentOwner.store.minimumBidAmountInUsd
      : nft.currentOwner.store.minimumBidAmountInEther;

  return Math.max(defaultMinimumBid, ownerMinimumBid);
}

export function nftHasFinalizingProductAuction(nft: {
  listing?: {
    liveBid: Pick<ListingType['liveBid'], 'bidInEther' | 'bidInUsd'>;
    productAuction: Pick<ListingType['productAuction'], 'endsAt'>;
  };
}): boolean {
  return !!(
    nft.listing?.liveBid?.bidInUsd &&
    hasDatePassed(nft.listing.productAuction?.endsAt)
  );
}

export function nftHasFinalizingRankedAuction(nft: {
  listing?: { rankedAuction: NFTRankedAuctionType };
}): boolean {
  return !!(
    nftHasRankedAuction(nft) && hasDatePassed(nft.listing.rankedAuction.endsAt)
  );
}

export function isNFTPendingSale(listing: {
  liveSale?: {
    listingStatus: ListingType['liveSale']['listingStatus'];
  };
}) {
  return listing.liveSale?.listingStatus === NftSaleStatusEnum.PendingSale;
}

export function isExternalToken(nft: NFTType): boolean {
  return nft.contract.isExternal;
}

export const isNFTAlreadyReservedFromErrorString = (
  errorString: string
): boolean => {
  const matchingErrors: MpErrors[] = [
    MpErrors.EditionsSoldOutRetryLater,
    MpErrors.ArtworkUnavailable,
    MpErrors.ProductReloadPageToPurchaseNextEdition,
    MpErrors.ProductUnavailableToPurchase,
  ];

  const isErrorCodeMatch = (errorCode: MpErrors) =>
    errorString.includes(errorCode);

  return some(matchingErrors, isErrorCodeMatch);
};

export const paddedNumberToString = (number: number, length = 2) =>
  number?.toString().padStart(length, '0');

export const getProvenancePriceString = (provenanceItem: NFTProvenanceType) =>
  generateEthAndUsdPricing(provenanceItem.amountEth, provenanceItem.amountUsd);

export const getProvenanceFormattedFullName = (
  provenanceItem: NFTProvenanceType
) =>
  provenanceItem.store
    ? generateFormattedUserFullName(provenanceItem.store.storeName)
    : generateFormattedUserFullName(provenanceItem.source);

export const convertToDate = (dt: string, format = 'DD MMM, YYYY') =>
  moment.tz(moment(dt), moment.tz.guess()).format(format);

export const generateTweetText = (nft: {
  metadata: Pick<NftMetadataAbstractType, 'title'> & {
    author: Pick<NftMetadataAbstractType['author'], 'fullName'>;
  };
}) =>
  `${nft.metadata.title}, a rare digital art by ${nft.metadata.author.fullName}.`;

export const isMintedOnChain = (nft: NFTType) =>
  !!nft.onchainId || nft.contract.isExternal;

export const getTransactionUrl = (etherscanUrl: string, txId: string) =>
  `${etherscanUrl}tx/${txId}`;

/**
 * NFT is considered a Dynamic Token if it's an HTML file
 * @param rawfileExtension string
 * @returns boolean
 */
export const isNFTDynamic = (rawfileExtension: string) =>
  rawfileExtension === 'html';

/**
 * NFT is considered a GIF if it's a GIF file
 * @param rawfileExtension string
 * @returns boolean
 */
export const isNFTGif = (rawfileExtension: string) =>
  rawfileExtension === 'gif';

export const getNFTPrimaryMedia = (
  nftMetadata: Pick<
    NFTType['metadata'],
    | 'hasVideo'
    | 'highResImage'
    | 'id'
    | 'thumbnailImage'
    | 'standardImage'
    | 'rawfileExtension'
    | 'videoUrl'
  >,
  isMobile: boolean
) => ({
  hasVideo: nftMetadata.hasVideo,
  highResBaseUrl: nftMetadata.highResImage,
  highResUrl: `${nftMetadata.highResImage}?tx=${[
    'q_auto:best',
    'c_limit',
    ...(isMobile ? ['w_5000', 'h_5000'] : ['w_6000', 'h_6000']),
  ].join(',')}`,
  id: nftMetadata.id,
  lowResUrl: nftMetadata.thumbnailImage,
  mediumResUrl: nftMetadata.standardImage,
  rawfileExtension: nftMetadata.rawfileExtension,
  videoUrl: nftMetadata.videoUrl,
});

export const getNFTAdditionalMedias = (
  nftMetadata: Pick<NFTType['metadata'], 'nftAdditionalMediasRanked'>
) =>
  nftMetadata?.nftAdditionalMediasRanked?.map(
    ({ externalContentUrl, id, externalThumbnailUrl }) => ({
      hasVideo: false,
      highResUrl: externalContentUrl,
      id,
      lowResUrl: externalThumbnailUrl || externalContentUrl,
      mediumResUrl: externalContentUrl,
      videoUrl: '',
    })
  );

export const getNFTPresaleState = (
  hasPresalePrice: boolean,
  dropMetadata: {
    drop: Pick<NFTDropType, 'isEligibleForPresale' | 'presale'>;
  }
) => {
  const hasPresale = !!(hasPresalePrice && dropMetadata.drop?.presale);
  const isPresaleActive =
    hasPresale &&
    hasDateBetween(
      dropMetadata.drop.presale.startsAt,
      dropMetadata.drop.presale.endsAt
    );
  const isPresaleEligible = !!(
    hasPresale && dropMetadata.drop.isEligibleForPresale
  );
  const showPresale =
    hasPresale && !hasDatePassed(dropMetadata.drop.presale.endsAt);

  return { isPresaleActive, isPresaleEligible, showPresale } as const;
};
