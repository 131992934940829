import { useEffect } from 'react';
import { useQueryLoaderHookType } from 'react-relay/relay-hooks/useQueryLoader';
import { PreloadableConcreteRequest, useQueryLoader } from 'react-relay';
import { FetchPolicy, GraphQLTaggedNode, OperationType } from 'relay-runtime';

export type useLoadQueryHookType<TQuery extends OperationType> = [
  useQueryLoaderHookType<TQuery>[0],
  useQueryLoaderHookType<TQuery>[1],
  useQueryLoaderHookType<TQuery>[2],
  () => void
];

/* eslint-disable react-hooks/exhaustive-deps */
function useLoadQuery<TQuery extends OperationType>(
  query: GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>,
  queryParams: TQuery['variables'],
  fetchPolicy: FetchPolicy | null | undefined = 'network-only'
): useLoadQueryHookType<TQuery> {
  const [queryRef, loadQuery, disposeFunction] = useQueryLoader<TQuery>(query);
  const invalidate = () => loadQuery(queryParams, { fetchPolicy });
  useEffect(() => {
    loadQuery(queryParams);
  }, Object.values(queryParams));

  return [queryRef, loadQuery, disposeFunction, invalidate];
}

export default useLoadQuery;
